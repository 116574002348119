import React from 'react';
import {NullSum, NullBypassSum, NullDiv, NullMax, DispFunc, GameResult} from '../common/CommonFunctions';
import { gameKicking } from '../common/TableColumnWidths';
import Tabulator from 'tabulator-tables';
import "tabulator-tables/dist/css/tabulator.min.css";

class GameLogKPKickingTabulator extends React.Component {
    constructor(props) {
        super(props);
        this.kickingstats = this.props.kickingstats;
        this.data = [];
        let gameInfoCols = null;
        this.colNum = 0;
        this.maxPlayerSeason = ''

        // Set game information columns
        gameInfoCols = [
            {
                title: "Date", field: "gameSeasonDateSSSSYYYYMMDD", cssClass: "single-row-header textColumn",
                titleFormatter: "html", headerSort: false,
                formatter: function (cell, formatterParams, onRendered) {
                    return (cell.getValue().substr(9, 1) === 'T' ? 'Total' : parseInt(cell.getValue().substr(9, 2)) + '/' + parseInt(cell.getValue().substr(12, 2)));
                },
                width: `${gameKicking[this.colNum++]}`
            },
            {
                title: "Wk", field: "gameWeek", cssClass: "single-row-header small-column-center",
                titleFormatter: "html", headerSort: false,
                width: `${gameKicking[this.colNum++]}`,
                //              formatter: function (cell, formatterParams, onRendered) {
                //                 return cell = 99 ? '' : cell;
                //              }
            },
            {
                title: "Teams", field: "matchup", cssClass: "single-row-header textColumn",
                titleFormatter: "html", headerSort: false,
                width: `${gameKicking[this.colNum++]}`
            },
            {
                title: "Score", field: "gameResult", cssClass: "single-row-header break textColumn",
                titleFormatter: "html", headerSort: false,
                width: `${gameKicking[this.colNum++]}`
            },
            {
                title: "G", field: "games", cssClass: "single-row-header small-column break",
                titleFormatter: "html", headerSort: false,
                width: `${gameKicking[this.colNum++]}`
            }
        ];

        // Set Remaining Columns
        this.cols = [
            { title: "",
                columns: gameInfoCols
            },
            {
                title: "Overall",
                columns:[
                    {
                        title: "Made", field: "overallMade", cssClass: "single-row-header",
                        headerSortStartingDir: "desc", headerSort: false, 
                        width: `${gameKicking[this.colNum++]}`,
                        formatter: function(cell, formatterParams, onRendered){
                            return DispFunc(cell, formatterParams, onRendered, 0, false, false);
                        }
                    },
                    {
                        title: "Att", field: "overallAttempts", cssClass: "single-row-header",
                        headerSortStartingDir: "desc", headerSort: false, 
                        width: `${gameKicking[this.colNum++]}`,
                        formatter: function(cell, formatterParams, onRendered){
                            return DispFunc(cell, formatterParams, onRendered, 0, false, false);
                        }
                    },
                    {
                        title: "Pct", field: "overallPct", cssClass: "single-row-header",
                        headerSortStartingDir: "desc", headerSort: false, 
                        width: `${gameKicking[this.colNum++]}`,
                        formatter: function(cell, formatterParams, onRendered){
                            return DispFunc(cell, formatterParams, onRendered, 1, false, false);
                        }
                    },
                    {
                        title: "Long", field: "overallLong", cssClass: "single-row-header",
                        headerSortStartingDir: "desc", headerSort: false, 
                        width: `${gameKicking[this.colNum++]}`,
                        formatter: function(cell, formatterParams, onRendered){
                            return DispFunc(cell, formatterParams, onRendered, 0, false, false);
                        }
                    },
                    {
                        title: "Pts", field: "overallPoints", cssClass: "single-row-header break",
                        headerSortStartingDir: "desc", headerSort: false, 
                        width: `${gameKicking[this.colNum++]}`,
                        formatter: function(cell, formatterParams, onRendered){
                            return DispFunc(cell, formatterParams, onRendered, 0, false, false);
                        }
                    },
                ],
            },
            {
                title: "1-29 Yds",
                columns:[ 
                    {
                        title: "Made", field: "fg1to29Made", cssClass: "single-row-header",
                        headerSortStartingDir: "desc", headerSort: false, 
                        width: `${gameKicking[this.colNum++]}`,
                        formatter: function(cell, formatterParams, onRendered){
                            return DispFunc(cell, formatterParams, onRendered, 0, false, false);
                        }
                    },
                    {
                        title: "Att", field: "fg1to29Attempts", cssClass: "single-row-header",
                        headerSortStartingDir: "desc", headerSort: false, 
                        width: `${gameKicking[this.colNum++]}`,
                        formatter: function(cell, formatterParams, onRendered){
                            return DispFunc(cell, formatterParams, onRendered, 0, false, false);
                        }
                    },
                    {
                        title: "Pct", field: "fg1to29Pct", cssClass: "single-row-header break",
                        headerSortStartingDir: "desc", headerSort: false, 
                        width: `${gameKicking[this.colNum++]}`,
                        formatter: function(cell, formatterParams, onRendered){
                            return DispFunc(cell, formatterParams, onRendered, 1, false, false);
                        }
                    }
                ],
            },
            {
                title: "30-39 Yds",
                columns:[ 
                    {
                        title: "Made", field: "fg30to39Made", cssClass: "single-row-header",
                        headerSortStartingDir: "desc", headerSort: false, 
                        width: `${gameKicking[this.colNum++]}`,
                        formatter: function(cell, formatterParams, onRendered){
                            return DispFunc(cell, formatterParams, onRendered, 0, false, false);
                        }
                    },
                    {
                        title: "Att", field: "fg30to39Attempts", cssClass: "single-row-header",
                        headerSortStartingDir: "desc", headerSort: false, 
                        width: `${gameKicking[this.colNum++]}`,
                        formatter: function(cell, formatterParams, onRendered){
                            return DispFunc(cell, formatterParams, onRendered, 0, false, false);
                        }
                    },
                    {
                        title: "Pct", field: "fg30to39Pct", cssClass: "single-row-header break",
                        headerSortStartingDir: "desc", headerSort: false, 
                        width: `${gameKicking[this.colNum++]}`,
                        formatter: function(cell, formatterParams, onRendered){
                            return DispFunc(cell, formatterParams, onRendered, 1, false, false);
                        }
                    }
                ]
            },
            {
                title: "40-49 Yds",
                columns: [
                    {
                        title: "Made", field: "fg40to49Made", cssClass: "single-row-header",
                        headerSortStartingDir: "desc", headerSort: false, 
                        width: `${gameKicking[this.colNum++]}`,
                        formatter: function(cell, formatterParams, onRendered){
                            return DispFunc(cell, formatterParams, onRendered, 0, false, false);
                        }
                    },
                    { 
                        title: "Att", field: "fg40to49Attempts", cssClass: "single-row-header",
                        headerSortStartingDir: "desc", headerSort: false, 
                        width: `${gameKicking[this.colNum++]}`,
                        formatter: function(cell, formatterParams, onRendered){
                            return DispFunc(cell, formatterParams, onRendered, 0, false, false);
                        }
                    },
                    { 
                        title: "Pct", field: "fg40to49Pct", cssClass: "single-row-header break",
                        headerSortStartingDir: "desc", headerSort: false, 
                        width: `${gameKicking[this.colNum++]}`,
                        formatter: function(cell, formatterParams, onRendered){
                            return DispFunc(cell, formatterParams, onRendered, 1, false, false);
                        }
                    },
                ]
            },
            {
                title: "50+ Yds",
                columns: [
                    { 
                        title: "Made", field: "fg50plusMade", cssClass: "single-row-header",
                        headerSortStartingDir: "desc", headerSort: false, 
                        width: `${gameKicking[this.colNum++]}`,
                        formatter: function(cell, formatterParams, onRendered){
                            return DispFunc(cell, formatterParams, onRendered, 0, false, false);
                        }
                    },
                    { 
                        title: "Att", field: "fg50plusAttempts", cssClass: "single-row-header",
                        width: `${gameKicking[this.colNum++]}`,
                        headerSortStartingDir: "desc", headerSort: false, 
                        formatter: function(cell, formatterParams, onRendered){
                            return DispFunc(cell, formatterParams, onRendered, 0, false, false);
                        }
                    },
                    { 
                        title: "Pct", field: "fg50plusPct", cssClass: "single-row-header break",
                        width: `${gameKicking[this.colNum++]}`,
                        headerSortStartingDir: "desc", headerSort: false, 
                        formatter: function(cell, formatterParams, onRendered){
                            return DispFunc(cell, formatterParams, onRendered, 1, false, false);
                        }
                    },
                ]
            },
            {
                title: "PAT",
                columns: [
                    { 
                        title: "Made", field: "patMade", cssClass: "single-row-header",
                        headerSortStartingDir: "desc", headerSort: false, 
                        width: `${gameKicking[this.colNum++]}`,
                        formatter: function(cell, formatterParams, onRendered){
                            return DispFunc(cell, formatterParams, onRendered, 0, false, false);
                        }
                    },
                    { 
                        title: "Att", field: "patAttempts", cssClass: "single-row-header",
                        headerSortStartingDir: "desc", headerSort: false, 
                        width: `${gameKicking[this.colNum++]}`,
                        formatter: function(cell, formatterParams, onRendered){
                            return DispFunc(cell, formatterParams, onRendered, 0, false, false);
                        }
                    },
                    { 
                        title: "Pct", field: "patPct", cssClass: "single-row-header break",
                        headerSortStartingDir: "desc", headerSort: false, 
                        width: `${gameKicking[this.colNum++]}`,
                        formatter: function(cell, formatterParams, onRendered){
                            return DispFunc(cell, formatterParams, onRendered, 1, false, false);
                        }
                    },
                ],
            },
            {
                title: "Tot Pts Earned", field: "PointsEarned",
                headerSortStartingDir: "desc", headerSort: false, 
                titleFormatter:"textarea",
                width: `${gameKicking[this.colNum++]}`,
                formatter: function(cell, formatterParams, onRendered){
                    return DispFunc(cell, formatterParams, onRendered, 0, false, false);
                }
            },
        ]
        
        this.el = React.createRef();
        this.tabulator = null;
    }

    // Consolidate the data into one data array to be used for tabulator(called on component mount)
    ConsolidateData(){
        let kickingstats = this.kickingstats;
        let sortKey = 0;
        let season = 0;
        let playerName = '';
        let playerId = 0;
        let positionName = '';
        let team = '';
        let matchup = '';
        let oppTeam = '';
        let gameWeek = 0;
        let games = 0;
        let gameDate = '';
        let gameSeasonDateSSSSYYYYMMDD = '';
        let gameResult = '';
        let overallMade = 0;
        let overallAttempts = 0;
        let overallPct = 0;
        let overallLong = 0;
        let overallPoints = 0;
        let fg1to29Made = 0;
        let fg1to29Attempts = 0;
        let fg1to29Pct = 0;
        let fg30to39Made = 0;
        let fg30to39Attempts = 0;
        let fg30to39Pct = 0;
        let fg40to49Made = 0;
        let fg40to49Attempts = 0;
        let fg40to49Pct = 0;
        let fg50plusMade = 0;
        let fg50plusAttempts = 0;
        let fg50plusPct = 0;
        let patMade = 0;
        let patAttempts = 0;
        let patPct = 0;
        let PointsEarned = 0;

        let rowdata = [];
        // create rowdata[] object for each row in kickingstats data
        if(kickingstats.length !==0) {
            for (var i = 0; i < kickingstats.length; i++) {
                sortKey = (9999 - kickingstats[i].season) * 1000000 + kickingstats[i].season * 100 + kickingstats[i].week; //XXXXYYYYWW
                season = kickingstats[i].season;
                playerName = kickingstats[i].playerName;
                playerId = kickingstats[i].playerId;
                positionName = kickingstats[i].positionName;
                team = kickingstats[i].teamName;
                matchup = kickingstats[i].teamName.trim() + ' ' + kickingstats[i].oppTeamName.trim();
                games = 1;
                gameDate = kickingstats[i].gameDate;
                gameSeasonDateSSSSYYYYMMDD = kickingstats[i].season.toString() + kickingstats[i].gameDate.substring(0, 10);
                gameResult = GameResult(kickingstats[i].teamScore, kickingstats[i].oppTeamScore);
                gameWeek = kickingstats[i].week;
                overallMade = kickingstats[i].fieldGoalMakes;
                overallAttempts = kickingstats[i].fieldGoalAtt;
                overallPoints = (kickingstats[i].fieldGoalMakes * 3) + kickingstats[i].extraPointMakes;;
                overallLong = kickingstats[i].long;
                fg1to29Made = NullSum(kickingstats[i].fgMade1to19,kickingstats[i].fgMade20to29);
                fg1to29Attempts = NullSum(kickingstats[i].fgAtt1to19,kickingstats[i].fgAtt20to29);
                fg30to39Made = kickingstats[i].fgMade30to39;
                fg30to39Attempts = kickingstats[i].fgAtt30to39;
                fg40to49Made = kickingstats[i].fgMade40to49;
                fg40to49Attempts = kickingstats[i].fgAtt40to49;
                fg50plusMade = kickingstats[i].fgMade50Plus;
                fg50plusAttempts = kickingstats[i].fgAtt50Plus;
                patMade = kickingstats[i].extraPointMakes;
                patAttempts = kickingstats[i].extraPointAtt;
                overallPct = NullDiv(overallMade,overallAttempts)*100;
                fg1to29Pct = NullDiv(fg1to29Made,fg1to29Attempts)*100;
                fg30to39Pct = NullDiv(fg30to39Made,fg30to39Attempts)*100;
                fg40to49Pct = NullDiv(fg40to49Made,fg40to49Attempts)*100;
                fg50plusPct = NullDiv(fg50plusMade,fg50plusAttempts)*100;
                patPct = NullDiv(patMade,patAttempts)*100;
                PointsEarned = kickingstats[i].eps;
                rowdata.push({
                    sortKey,
                    season,
                    playerName,
                    playerId,
                    positionName,
                    team,
                    matchup,
                    games,
                    gameDate,
                    gameSeasonDateSSSSYYYYMMDD,
                    gameResult,
                    gameWeek,
                    oppTeam,
                    overallMade,
                    overallAttempts,
                    overallPct,
                    overallLong,
                    overallPoints,
                    fg1to29Made,
                    fg1to29Attempts,
                    fg1to29Pct,
                    fg30to39Made,
                    fg30to39Attempts,
                    fg30to39Pct,
                    fg40to49Made,
                    fg40to49Attempts,
                    fg40to49Pct,
                    fg50plusMade,
                    fg50plusAttempts,
                    fg50plusPct,
                    patMade,
                    patAttempts,
                    patPct,
                    PointsEarned
                });
            }
        }
        if (rowdata.length !== 0) {
            rowdata.sort((a, b) => a.sortKey > b.sortKey ? 1 : -1); // orders rows by sortkey
            this.SumData(rowdata); // insert seasonal totals
        }
        this.data = rowdata;
    }

    SumData(d){
        let sortKey = 0;
        let season = d[0].season;
        let playerName = '';
        let playerId = '';
        let positionName = '';
        let team = '';
        let matchup = '';
        let games = 0;
        let gameDate = '';
        let gameSeasonDateSSSSYYYYMMDD = '';
        let gameResult = '';
        let gameWeek = '';
        let oppTeam = ''
        let overallMade = 0;
        let overallAttempts = 0;
        let overallPct = 0;
        let overallLong = 0;
        let overallPoints = 0;
        let fg1to29Made = 0;
        let fg1to29Attempts = 0;
        let fg1to29Pct = 0;
        let fg30to39Made = 0;
        let fg30to39Attempts = 0;
        let fg30to39Pct = 0;
        let fg40to49Made = 0;
        let fg40to49Attempts = 0;
        let fg40to49Pct = 0;
        let fg50plusMade = 0;
        let fg50plusAttempts = 0;
        let fg50plusPct = 0;
        let patMade = 0;
        let patAttempts = 0;
        let patPct = 0;
        let PointsEarned = 0;
        let rowcount = d.length;
        for (var i = 0; i < rowcount; i++) {
            if (season !== d[i].season) {
                sortKey = (9999 - season) * 1000000 + season * 100 + 99; //XXXXYYYY99
                gameSeasonDateSSSSYYYYMMDD = season.toString() + season.toString() + " Total";
                overallPct = NullDiv(overallMade, overallAttempts) * 100;
                fg1to29Pct = NullDiv(fg1to29Made, fg1to29Attempts) * 100;
                fg30to39Pct = NullDiv(fg30to39Made, fg30to39Attempts) * 100;
                fg40to49Pct = NullDiv(fg40to49Made, fg40to49Attempts) * 100;
                fg50plusPct = NullDiv(fg50plusMade, fg50plusAttempts) * 100;
                patPct = NullDiv(patMade, patAttempts) * 100;
                d.push({
                    sortKey,
                    season,
                    playerName,
                    playerId,
                    positionName,
                    team,
                    matchup,
                    games,
                    gameDate,
                    gameSeasonDateSSSSYYYYMMDD,
                    gameResult,
                    gameWeek,
                    oppTeam,
                    overallMade,
                    overallAttempts,
                    overallPct,
                    overallLong,
                    overallPoints,
                    fg1to29Made,
                    fg1to29Attempts,
                    fg1to29Pct,
                    fg30to39Made,
                    fg30to39Attempts,
                    fg30to39Pct,
                    fg40to49Made,
                    fg40to49Attempts,
                    fg40to49Pct,
                    fg50plusMade,
                    fg50plusAttempts,
                    fg50plusPct,
                    patMade,
                    patAttempts,
                    patPct,
                    PointsEarned
                });
                season = d[i].season;
                playerName = '';
                playerId = '';
                positionName = '';
                team = '';
                matchup = '';
                games = 0;
                gameDate = '';
                gameSeasonDateSSSSYYYYMMDD = '';
                gameResult = '';
                gameWeek = '';
                oppTeam = ''
                overallMade = 0;
                overallAttempts = 0;
                overallPct = 0;
                overallLong = 0;
                overallPoints = 0;
                fg1to29Made = 0;
                fg1to29Attempts = 0;
                fg1to29Pct = 0;
                fg30to39Made = 0;
                fg30to39Attempts = 0;
                fg30to39Pct = 0;
                fg40to49Made = 0;
                fg40to49Attempts = 0;
                fg40to49Pct = 0;
                fg50plusMade = 0;
                fg50plusAttempts = 0;
                fg50plusPct = 0;
                patMade = 0;
                patAttempts = 0;
                patPct = 0;
                PointsEarned = 0;
            }
            games = NullSum(games, d[i].games);
            overallMade = NullSum(overallMade,d[i].overallMade);
            overallAttempts = NullSum(overallAttempts,d[i].overallAttempts);
            overallPoints = NullSum(overallPoints, (d[i].overallMade * 3));
            overallLong = NullMax(overallLong,d[i].overallLong);
            fg1to29Made = NullSum(fg1to29Made,d[i].fg1to29Made);
            fg1to29Attempts = NullSum(fg1to29Attempts,d[i].fg1to29Attempts);
            fg30to39Made = NullSum(fg30to39Made,d[i].fg30to39Made);
            fg30to39Attempts = NullSum(fg30to39Attempts,d[i].fg30to39Attempts);
            fg40to49Made = NullSum(fg40to49Made,d[i].fg40to49Made);
            fg40to49Attempts = NullSum(fg40to49Attempts,d[i].fg40to49Attempts);
            fg50plusMade = NullSum(fg50plusMade,d[i].fg50plusMade);
            fg50plusAttempts = NullSum(fg50plusAttempts,d[i].fg50plusAttempts);
            patMade = NullSum(patMade,d[i].patMade);
            patAttempts = NullSum(patAttempts, d[i].patAttempts);
            PointsEarned = NullBypassSum(PointsEarned, d[i].PointsEarned);
        }
        sortKey = (9999 - season) * 1000000 + season * 100 + 99; //XXXXYYYY99
        gameSeasonDateSSSSYYYYMMDD = season.toString() + season.toString() + " Total";
        overallPct = NullDiv(overallMade,overallAttempts)*100;
        fg1to29Pct = NullDiv(fg1to29Made,fg1to29Attempts)*100;
        fg30to39Pct = NullDiv(fg30to39Made,fg30to39Attempts)*100;
        fg40to49Pct = NullDiv(fg40to49Made,fg40to49Attempts)*100;
        fg50plusPct = NullDiv(fg50plusMade,fg50plusAttempts)*100;
        patPct = NullDiv(patMade,patAttempts)*100;
        d.push({
            sortKey,
            season,
            playerName,
            playerId,
            positionName,
            team,
            matchup,
            games,
            gameDate,
            gameSeasonDateSSSSYYYYMMDD,
            gameResult,
            gameWeek,
            oppTeam,
            overallMade,
            overallAttempts,
            overallPct,
            overallLong,
            overallPoints,
            fg1to29Made,
            fg1to29Attempts,
            fg1to29Pct,
            fg30to39Made,
            fg30to39Attempts,
            fg30to39Pct,
            fg40to49Made,
            fg40to49Attempts,
            fg40to49Pct,
            fg50plusMade,
            fg50plusAttempts,
            fg50plusPct,
            patMade,
            patAttempts,
            patPct,
            PointsEarned
        });
        d.sort((a, b) => a.sortKey > b.sortKey ? 1 : -1); // corrects for new season total rows
    }
    
    componentDidMount() {
        this.ConsolidateData();
        let pagination = 'local';
        let paginationSize = 50;
        let maxPlayerSeason = this.data.length ? this.data[0].season : 0;
        pagination = null;
        paginationSize = 0;
        this.tabulator = new Tabulator(this.el, {
            data: this.data,
            reactiveData: true,
            columns: this.cols,
            layout: "fitColumns",  // added by Ruben 7/25/19
            rowFormatter: function (row) {
                var data = row.getData();
                if (data.gameSeasonDateSSSSYYYYMMDD.substr(9, 5) === 'Total') {
                    row.getElement().style.borderTop = "2px solid #6f88a8";
                    row.getElement().style.borderBottom = "2px solid #6f88a8";
                }
            },
            pagination: pagination,
            paginationSize: paginationSize,
            groupBy: function (row) {
                return row.gameSeasonDateSSSSYYYYMMDD.substr(0, 4);
            },
            groupHeader: function (groupKey, rowcount, data, group) {
                return groupKey + ' Season <span style="color:SlateBlue">&#9660</span>';
            },
            groupStartOpen: function (groupKey, rowcount, data, group) {
                return groupKey === maxPlayerSeason.toString();
            },
            groupToggleElement: "header",
            resizableColumns: false,
            selectable: false
        });
    }

    componentDidUpdate() {
        this.tabulator.replaceData(this.data);
    }

    render() {
        return (
            <React.Fragment>
                <div className="shadowdiv">
                    <div id="table-title">Kicking</div>
                    <div ref={el => (this.el = el)} />
                </div>
            </React.Fragment>
        );
    }
}
export default GameLogKPKickingTabulator;
