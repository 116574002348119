import React, { useState } from 'react';

function Filters(d) {
    const [minFilter, setminFilter] = useState(d.minFilter);
    if (minFilter < 1 && minFilter !== ''){
        setminFilter('');
    }
    return(
        <React.Fragment>
            <div className="col-sm-12" id="filtersPanel">
                <div className="form-group row">
                    <div className="col-sm-3"></div>
                    <label htmlFor="attempts" className="col-sm-2 col-form-label-sm">Min. {d.label}:</label>
                    <input className="form-control form-control-sm col-sm-1" type="number" name="attempts" min="1" max="999" 
                            value={minFilter} onChange={(event) => setminFilter(Number(event.target.value))} />
                    <div className="col-sm-1"></div>
                    <button type="submit" className="btn btn-primary" onClick={(e) => d.onSubmit(e, minFilter)}>Submit</button>
                </div>
            </div>
        </React.Fragment>
    );
}

export default Filters;