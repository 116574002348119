import React from 'react';
import {NullSum, NullBypassSum, NullMult, NullDiv, Tst2015, DispFunc} from '../common/CommonFunctions';
import { advRushing } from '../common/TableColumnWidths';
import Tabulator from 'tabulator-tables';
import "tabulator-tables/dist/css/tabulator.min.css";
import Filters from '../common/Filters';

class TableRBWRAdvancedRushingTabulator extends React.Component {
    constructor(props) {
        super(props);
        this.rushingstats = this.props.rushingstats;
        this.isLeaderboards = this.props.isLeaderboards;
        this.minFilter = 1;
        this.data = [];
        let bioCols = null;
        let sortable = false;
        this.initSort = null;
        this.colNum = this.isLeaderboards ? 0 : 2;

        this.onSubmit = this.onSubmit.bind(this);

        // Custom sort function for players page so Total stays at the bottom of the table.
        let sortFunc = (a, b, aRow, bRow, column, dir, sorterParams) => {
            var aData = aRow.getData();
            var bData = bRow.getData();
            if (aData.season === 'Total'){
                if (dir === 'desc'){
                    return -1;
                }
                else if (dir === 'asc'){
                    return 1
                }
            }
            else if (bData.season === 'Total'){
                if (dir === 'desc'){
                    return 1;
                }
                else if (dir === 'asc'){
                    return -1;
                }            
            }
            else {
                return a-b;
            }
        };

        // Set first group of columns
        // If this is a leaderboards page include player name, remove custom sorter and set initial sort to proper column.
        if (this.isLeaderboards){
            sortable = true;
            bioCols = [
                { 
                    title: "Player", field: "player", cssClass: "textColumn", 
                    width: `${advRushing[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                    formatter: "link", formatterParams:{
                        labelField: "player", 
                        urlPrefix:"/players/", 
                        urlField: "playerId"
                    }
                },
                { 
                    title: "Pos", field: "positionName", cssClass: "textColumn",
                    width: `${advRushing[this.colNum++][this.isLeaderboards ? 0 : 1]}`
                },
                { 
                    title: "Year", field: "season", cssClass: "textColumn",
                    width: `${advRushing[this.colNum++][this.isLeaderboards ? 0 : 1]}`
                },
                { 
                    title: "Team", field: "team", cssClass: "textColumn break", 
                    width: `${advRushing[this.colNum++][this.isLeaderboards ? 0 : 1]}`
                }
            ];
            this.sorterFunc = null;
            this.initSort = [
                { column: "rushPointsEarned", dir: "desc" }
            ];
        }
        // Else don't include player name and set initial sort to season.
        else{
            bioCols = [
                { 
                    title: "Year", field: "season", cssClass: "textColumn", 
                    width: `${advRushing[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                    headerSort: sortable, sorter: sortFunc
                 },
                { 
                    title: "Team", field: "team", cssClass: "textColumn break", 
                    width: `${advRushing[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                    headerSort: sortable, sorter: sortFunc 
                },
            ];
            this.initSort = [
                { column: "season", dir: "asc" }
            ];
        }
        // Set Remaining Columns
        this.cols = [
            { 
                title: "",
                columns: bioCols
            },
            {
                title: "First Downs",
                columns:[
                    { 
                        title: "Total", field: "firstDown", 
                        headerSortStartingDir:"desc", headerSort: sortable, sorter: sortFunc,
                        width: `${advRushing[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                        formatter: function(cell, formatterParams, onRendered){
                            return DispFunc(cell, formatterParams, onRendered, 0, false, false);
                        }
                    },
                    { 
                        title: "Pct", field: "firstDownPct", cssClass: "break", 
                        headerSortStartingDir:"desc", headerSort: sortable, sorter: sortFunc, 
                        width: `${advRushing[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                        formatter: function(cell, formatterParams, onRendered){
                            return DispFunc(cell, formatterParams, onRendered, 1, false, false);
                        }
                    }
                ],
            },
            {
                title: "Inside",
                columns:[
                    { 
                        title: "Att", field: "insideAtt", 
                        headerSortStartingDir:"desc", headerSort: sortable, sorter: sortFunc,
                        width: `${advRushing[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                        formatter: function(cell, formatterParams, onRendered){
                            return DispFunc(cell, formatterParams, onRendered, 0, false, false);
                        }
                    },
                    { 
                        title: "Avg", field: "yardsPerInsideAtt", cssClass: "break", 
                        headerSortStartingDir:"desc", headerSort: sortable, sorter: sortFunc, 
                        width: `${advRushing[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                        formatter: function(cell, formatterParams, onRendered){
                            return DispFunc(cell, formatterParams, onRendered, 1, false, false);
                        }
                    }
                ],
            },
            {
                title: "Off Tackle",
                columns:[
                    { 
                        title: "Att", field: "offTackleAtt", 
                        headerSortStartingDir:"desc", headerSort: sortable, sorter: sortFunc,
                        width: `${advRushing[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                        formatter: function(cell, formatterParams, onRendered){
                            return DispFunc(cell, formatterParams, onRendered, 0, false, false);
                        }
                    },
                    { 
                        title: "Avg", field: "yardsPerOffTackleAtt", cssClass: "break", 
                        headerSortStartingDir:"desc", headerSort: sortable, sorter: sortFunc, 
                        width: `${advRushing[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                        formatter: function(cell, formatterParams, onRendered){
                            return DispFunc(cell, formatterParams, onRendered, 1, false, false);
                        }
                    }
                ],
            },
            {
                title: "Outside",
                columns:[
                    { 
                        title: "Att", field: "outsideAtt", 
                        headerSortStartingDir:"desc", headerSort: sortable, sorter: sortFunc,
                        width: `${advRushing[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                        formatter: function(cell, formatterParams, onRendered){
                            return DispFunc(cell, formatterParams, onRendered, 0, false, false);
                        }
                    },
                    { 
                        title: "Avg", field: "yardsPerOutsideAtt", cssClass: "break", 
                        headerSortStartingDir:"desc", headerSort: sortable, sorter: sortFunc, 
                        width: `${advRushing[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                        formatter: function(cell, formatterParams, onRendered){
                            return DispFunc(cell, formatterParams, onRendered, 1, false, false);
                        }
                    },
                ],            
            },
            {
                title: "Yards After Contact",
                columns:[
                    { 
                        title: "Yards", field: "yardsAfterContact",
                        headerSortStartingDir:"desc", headerSort: sortable, sorter: sortFunc, 
                        width: `${advRushing[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                        formatter: function(cell, formatterParams, onRendered){
                            return DispFunc(cell, formatterParams, onRendered, 0, false, false);
                        }
                    },
                    { 
                        title: "Avg", field: "yardsAfterContactPerAtt", cssClass: "break", 
                        headerSortStartingDir:"desc", headerSort: sortable, sorter: sortFunc, 
                        width: `${advRushing[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                        formatter: function(cell, formatterParams, onRendered){
                            return DispFunc(cell, formatterParams, onRendered, 1, false, false);
                        }
                    }
                ],
            },
            {
                title: "Brkn + Missed Tkls",
                columns:[
                    { 
                        title: "Total", field: "brokenTackles", 
                        headerSortStartingDir:"desc", headerSort: sortable, sorter: sortFunc,
                        width: `${advRushing[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                        formatter: function(cell, formatterParams, onRendered){
                            return DispFunc(cell, formatterParams, onRendered, 0, false, false);
                        }
                    },
                    { 
                        title: "Pct", field: "brokenTacklesPerAtt", cssClass: "break", 
                        headerSortStartingDir:"desc", headerSort: sortable, sorter: sortFunc, 
                        width: `${advRushing[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                        formatter: function(cell, formatterParams, onRendered){
                            return DispFunc(cell, formatterParams, onRendered, 1, false, false);
                        }
                    }
                ],
            },
            { 
                title: "Rush Points Earned", field: "rushPointsEarned", 
                headerSortStartingDir:"desc", headerSort: sortable, sorter: sortFunc, 
                titleFormatter:"textarea",
                width: `${advRushing[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                formatter: function(cell, formatterParams, onRendered){
                    return DispFunc(cell, formatterParams, onRendered, 0, false, false);
                }
            }
        ];
        
        this.el = React.createRef();
        this.tabulator = null;

    }

    // Consolidate the data into one data array to be used for tabulator(called on component mount)
    ConsolidateData(){
        let data = [];
        let player = '';
        let playerId = 0;
        let positionName = '';
        let season = 0;
        let team = '';
        let rushAttempts = 0;
        let firstDown = 0;
        let firstDownPct = 0;
        let insideAtt = 0;
        let yardsPerInsideAtt = 0;
        let offTackleAtt = 0;
        let yardsPerOffTackleAtt = 0;
        let outsideAtt = 0;
        let yardsPerOutsideAtt = 0;
        let yardsAfterContact = 0;
        let yardsAfterContactPerAtt = 0;
        let brokenTackles = 0;
        let brokenTacklesPerAtt = 0;
        let eps = 0;
        let rushPointsEarned = 0;
        let rushingstats = this.rushingstats;
        if (rushingstats.length !== 0){
            for (var i=0; i<rushingstats.length; i++) {
                if (!this.isLeaderboards || rushingstats[i].att >= this.minFilter){
                    player = rushingstats[i].player;
                    playerId = rushingstats[i].playerId;
                    positionName = rushingstats[i].positionName;
                    season = rushingstats[i].season;
                    team = rushingstats[i].team;
                    rushAttempts = rushingstats[i].att;
                    firstDown = rushingstats[i].firstDown;
                    firstDownPct = rushingstats[i].firstDownPct*100;
                    insideAtt = rushingstats[i].insideAtt;
                    yardsPerInsideAtt = rushingstats[i].yardsPerInsideAtt;
                    offTackleAtt = rushingstats[i].offtackleAtt;
                    yardsPerOffTackleAtt = rushingstats[i].yardsPerOffTackleAtt;
                    outsideAtt = rushingstats[i].outsideAtt;
                    yardsPerOutsideAtt = rushingstats[i].yardsPerOutsideAtt;
                    yardsAfterContact = rushingstats[i].yardsAfterContact;
                    yardsAfterContactPerAtt = NullDiv(rushingstats[i].yardsAfterContact, rushingstats[i].att);
                    brokenTackles = rushingstats[i].brokenTackles;
                    brokenTacklesPerAtt = NullDiv(rushingstats[i].brokenTackles, rushingstats[i].att)*100;
                    eps = Tst2015(this.rushingstats[i].season, this.rushingstats[i].eps);
                    rushPointsEarned = Tst2015(rushingstats[i].season,rushingstats[i].eps);
                    data.push(
                        {
                            player,
                            playerId,
                            positionName,
                            season,
                            team,
                            rushAttempts,
                            firstDown,
                            firstDownPct,
                            insideAtt,
                            yardsPerInsideAtt,
                            offTackleAtt,
                            yardsPerOffTackleAtt,
                            outsideAtt,
                            yardsPerOutsideAtt,
                            yardsAfterContact,
                            yardsAfterContactPerAtt,
                            brokenTackles,
                            brokenTacklesPerAtt,
                            eps,
                            rushPointsEarned,
                        }
                    );
                }
            }
            this.data = data;
        }
    }

    // Add totals row to data.(called on component mount if this is the players page.)
    SumData() {
        let season = 'Total';
        let team = '';
        let rushAttempts = 0;
        let firstDown = 0;
        let firstDownPct = 0;
        let insideAtt = 0;
        let rushInsideYards = 0;
        let yardsPerInsideAtt = 0;
        let yardsPerOffTackleAtt = 0;
        let offTackleAtt = 0;
        let rushOffTackleYards = 0;
        let outsideAtt = 0;
        let rushOutsideYards = 0;
        let yardsPerOutsideAtt = 0;
        let yardsAfterContact = 0;
        let yardsAfterContactPerAtt = 0;
        let brokenTackles = 0;
        let brokenTacklesPerAtt = 0;
        let rushPointsEarned = 0;
        if (this.data.length !== 0){
            for (var i = 0; i < this.data.length; i++) {
                rushAttempts = NullSum(rushAttempts,this.data[i].rushAttempts);
                firstDown = NullSum(firstDown,this.data[i].firstDown);
                insideAtt = NullSum(insideAtt,this.data[i].insideAtt);
                rushInsideYards = NullSum(rushInsideYards,NullMult(this.data[i].insideAtt,this.data[i].yardsPerInsideAtt));
                offTackleAtt = NullSum(offTackleAtt,this.data[i].offTackleAtt);
                rushOffTackleYards = NullSum(rushOffTackleYards,NullMult(this.data[i].offTackleAtt,this.data[i].yardsPerOffTackleAtt));
                outsideAtt = NullSum(outsideAtt,this.data[i].outsideAtt);
                rushOutsideYards = NullSum(rushOutsideYards, NullMult(this.data[i].outsideAtt,this.data[i].yardsPerOutsideAtt));
                yardsAfterContact = NullSum(yardsAfterContact,this.data[i].yardsAfterContact);
                brokenTackles = NullSum(brokenTackles,this.data[i].brokenTackles);
                rushPointsEarned = NullBypassSum(rushPointsEarned,Tst2015(this.data[i].season,this.data[i].eps));
            }
            if (rushAttempts) {
                firstDownPct = NullDiv(firstDown,rushAttempts)*100;
                brokenTacklesPerAtt = NullDiv(brokenTackles,rushAttempts)*100;
                yardsAfterContactPerAtt = NullDiv(yardsAfterContact,rushAttempts);
            }
            yardsPerInsideAtt = NullDiv(rushInsideYards,insideAtt);
            yardsPerOffTackleAtt = NullDiv(rushOffTackleYards,offTackleAtt);
            yardsPerOutsideAtt = NullDiv(rushOutsideYards,outsideAtt);
            this.data.push(
                {
                    season,
                    team,
                    firstDown,
                    firstDownPct,
                    insideAtt,
                    yardsPerInsideAtt,
                    offTackleAtt,
                    yardsPerOffTackleAtt,
                    outsideAtt,
                    yardsPerOutsideAtt,
                    yardsAfterContact,
                    yardsAfterContactPerAtt,
                    brokenTackles,
                    brokenTacklesPerAtt,
                    rushPointsEarned,
                }
            );
        }
    }
    
    componentDidMount() {
        this.ConsolidateData();
        let pagination = 'local';
        let paginationSize = 50;
        if (!this.isLeaderboards){
            this.SumData();
            pagination = null;
            paginationSize = 0;
        }
        this.tabulator = new Tabulator(this.el, {
            data: this.data,
            reactiveData: true,
            columns: this.cols,
            layout: "fitColumns",  // added by Ruben 7/25/19
            rowFormatter:function(row){
                var data = row.getData();
                if (data.season === 'Total'){
                    row.getElement().style.borderTop = "2px solid #6f88a8";
                    row.getElement().style.borderBottom = "2px solid #6f88a8";
                }
            },
            pagination: pagination,
            paginationSize: paginationSize,
            initialSort: this.initSort,
            resizableColumns: false,
            selectable: false
        });
    }

    componentDidUpdate() {
        this.tabulator.replaceData(this.data);
    }

    onSubmit(e, minFilter){
        this.minFilter = minFilter;
        this.ConsolidateData();
        this.forceUpdate();
    }

    render() {
        return (
            <React.Fragment>
                {
                    this.isLeaderboards &&
                    <div className="shadowdiv" id="filtersDiv">
                        <Filters minFilter={this.minFilter} onSubmit={this.onSubmit} label={"Carries"} />
                        <div ref={el => (this.el = el)} />
                    </div>
                }
                {
                    !this.isLeaderboards &&
                    <div className="shadowdiv">
                        <div id="table-title">Advanced Rushing</div>
                        <div ref={el => (this.el = el)} />
                    </div>
                }
            </React.Fragment>
        );
    }
}

export default TableRBWRAdvancedRushingTabulator;