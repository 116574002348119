import React from 'react';
import {NullSum, NullBypassSum, NullSub, NullDiv, Tst2015, DispFunc} from '../common/CommonFunctions';
import { cgtSummary } from '../common/TableColumnWidths';
import Tabulator from 'tabulator-tables';
import "tabulator-tables/dist/css/tabulator.min.css";
import Filters from '../common/Filters';

class TableQBSummaryTabulator extends React.Component {
    constructor(props) {
        super(props);
        this.blockingstats = this.props.blockingstats;
        this.isLeaderboards = this.props.isLeaderboards;
        this.minFilter = 1;
        this.data = [];
        let bioCols = null;
        let sortable = false;
        this.initSort = null;
        this.colNum = this.isLeaderboards ? 0 : 2;

        this.onSubmit = this.onSubmit.bind(this);

        // Custom sort function for players page so Total stays at the bottom of the table.
        let sortFunc = (a, b, aRow, bRow, column, dir, sorterParams) => {
            var aData = aRow.getData();
            var bData = bRow.getData();
            if (aData.season === 'Total'){
                if (dir === 'desc'){
                    return -1;
                }
                else if (dir === 'asc'){
                    return 1
                }
            }
            else if (bData.season === 'Total'){
                if (dir === 'desc'){
                    return 1;
                }
                else if (dir === 'asc'){
                    return -1;
                }            
            }
            else {
                return a-b;
            }
        };

        // Set first group of columns
        // If this is a leaderboards page include player name, remove custom sorter and set initial sort to proper column.
        if (this.isLeaderboards){
            sortable = true;
            bioCols = [
                { 
                    title: "Player", field: "player", cssClass: "textColumn", 
                    width: `${cgtSummary[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                    formatter: "link", formatterParams:{
                        labelField: "player", 
                        urlPrefix:"/players/", 
                        urlField: "playerId"
                    }
                },
                { 
                    title: "Pos", field: "positionName", cssClass: "textColumn",
                    width: `${cgtSummary[this.colNum++][this.isLeaderboards ? 0 : 1]}`
                },
                { 
                    title: "Year", field: "season", cssClass: "textColumn",
                    width: `${cgtSummary[this.colNum++][this.isLeaderboards ? 0 : 1]}`
                },
                { 
                    title: "Team", field: "team", cssClass: "textColumn", 
                    width: `${cgtSummary[this.colNum++][this.isLeaderboards ? 0 : 1]}`
                },
                { 
                    title: "G", field: "games",
                    headerSortStartingDir: "desc",
                    width: `${cgtSummary[this.colNum++][this.isLeaderboards ? 0 : 1]}`
                },
                { 
                    title: "GS", field: "starts", cssClass: "break",
                    width: `${cgtSummary[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                    headerSortStartingDir: "desc"
                }        
            ];
            this.sorterFunc = null;
            this.initSort = [
                { column: "pointsEarned", dir: "desc" }
            ];
        }
        // Else don't include player name and set initial sort to season.
        else{
            bioCols = [
                { 
                    title: "Year", field: "season", cssClass: "textColumn",
                    width: `${cgtSummary[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                    headerSort: sortable, sorter: sortFunc
                },
                { 
                    title: "Team", field: "team", cssClass: "textColumn",
                    width: `${cgtSummary[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                    headerSort: sortable, sorter: sortFunc
                },
                { 
                    title: "G", field: "games", 
                    width: `${cgtSummary[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                    headerSortStartingDir: "desc", headerSort: sortable, sorter: sortFunc
                },
                { 
                    title: "GS", field: "starts", cssClass: "break", 
                    width: `${cgtSummary[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                    headerSortStartingDir: "desc", headerSort: sortable, sorter: sortFunc
                }        
            ];
            this.initSort = [
                { column: "season", dir: "asc" }
            ];
        }
        // Set Remaining Columns
        this.cols = [
            { 
                title: "",
                columns: bioCols
            },
            { 
                title: "Total Snaps", field: "snaps", cssClass: "single-row-header break", 
                headerSortStartingDir: "desc", headerSort: sortable, sorter: sortFunc, 
                titleFormatter:"textarea", 
                width: `${cgtSummary[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                formatter: function(cell, formatterParams, onRendered){
                    return DispFunc(cell, formatterParams, onRendered, 0, true, false);
                }
            },
            {
                title: "Blown Blocks",
                columns:[
                    { 
                        title: "Total", field: "blownBlocks", 
                        headerSortStartingDir: "desc", headerSort: sortable, sorter: sortFunc,
                        width: `${cgtSummary[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                        formatter: function(cell, formatterParams, onRendered){
                            return DispFunc(cell, formatterParams, onRendered, 0, false, false);
                        }
                    },
                    { 
                        title: "Pass", field: "bbPass", 
                        headerSortStartingDir: "desc", headerSort: sortable, sorter: sortFunc,
                        width: `${cgtSummary[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                        formatter: function(cell, formatterParams, onRendered){
                            return DispFunc(cell, formatterParams, onRendered, 0, false, false);
                        }
                    },
                    { 
                        title: "Rush", field: "bbRuns", 
                        headerSortStartingDir: "desc", headerSort: sortable, sorter: sortFunc,
                        width: `${cgtSummary[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                        formatter: function(cell, formatterParams, onRendered){
                            return DispFunc(cell, formatterParams, onRendered, 0, false, false);
                        }
                    },      
                    { 
                        title: "Pct", field: "blownBlockPct", cssClass: "break", 
                        headerSortStartingDir: "desc", headerSort: sortable, sorter: sortFunc, 
                        width: `${cgtSummary[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                        formatter: function(cell, formatterParams, onRendered){
                            return DispFunc(cell, formatterParams, onRendered, 1, false, false);
                        }
                    },
                ],
            },
            {
                title: "Penalties",
                columns:[
                    { 
                        title: "Total", field: "penalties", 
                        headerSortStartingDir: "desc", headerSort: sortable, sorter: sortFunc,
                        width: `${cgtSummary[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                        formatter: function(cell, formatterParams, onRendered){
                            return DispFunc(cell, formatterParams, onRendered, 0, false, false);
                        }
                    },
                    { 
                        title: "Holding", field: "totalHolds", 
                        headerSortStartingDir: "desc", headerSort: sortable, sorter: sortFunc,
                        width: `${cgtSummary[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                        formatter: function(cell, formatterParams, onRendered){
                            return DispFunc(cell, formatterParams, onRendered, 0, false, false);
                        }
                    },
                    { 
                        title: "False Starts", field: "falseStarts", 
                        headerSortStartingDir: "desc", headerSort: sortable, sorter: sortFunc,
                        width: `${cgtSummary[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                        formatter: function(cell, formatterParams, onRendered){
                            return DispFunc(cell, formatterParams, onRendered, 0, false, false);
                        }
                    },
                    { 
                        title: "Other", field: "otherPenalties", 
                        headerSortStartingDir: "desc", headerSort: sortable, sorter: sortFunc,
                        width: `${cgtSummary[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                        formatter: function(cell, formatterParams, onRendered){
                            return DispFunc(cell, formatterParams, onRendered, 0, false, false);
                        }
                    },
                    { 
                        title: "Pct", field: "penaltyPct", cssClass: "break", 
                        headerSortStartingDir: "desc", headerSort: sortable, sorter: sortFunc, 
                        width: `${cgtSummary[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                        formatter: function(cell, formatterParams, onRendered){
                            return DispFunc(cell, formatterParams, onRendered, 1, false, false);
                        }
                    }
                ],
            },
            { 
                title: "Tot Points Earned", field: "pointsEarned", 
                headerSortStartingDir: "desc", headerSort: sortable, sorter: sortFunc, 
                titleFormatter:"textarea",
                width: `${cgtSummary[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                formatter: function(cell, formatterParams, onRendered){
                    return DispFunc(cell, formatterParams, onRendered, 0, false, false);
                }
            }
        ];
        
        this.el = React.createRef();
        this.tabulator = null;

    }

    // Consolidate the data into one data array to be used for tabulator(called on component mount)
    ConsolidateData(){
        let data = [];
        let player = '';
        let playerId = 0;
        let positionName = '';
        let season = 0;
        let team = '';
        let games = 0;
        let starts = 0;
        let snaps = 0;
        let blownBlocks = 0;
        let bbPass = 0;
        let bbRuns = 0;
        let blownBlockPct = 0;
        let penalties = 0;
        let totalHolds = 0;
        let falseStarts = 0;
        let otherPenalties = 0;
        let penaltyPct = 0;
        let pointsEarned = 0;
        if (this.blockingstats.length !== 0){
            for (var i=0; i<this.blockingstats.length; i++) {
                if (!this.isLeaderboards || NullSum(this.blockingstats[i].passSnap,this.blockingstats[i].rushSnap) >= this.minFilter){
                    player = this.blockingstats[i].player;
                    playerId = this.blockingstats[i].playerId;
                    positionName = this.blockingstats[i].positionName;
                    season = this.blockingstats[i].season;
                    team = this.blockingstats[i].team;
                    games = this.blockingstats[i].starts > this.blockingstats[i].games ?
                        this.blockingstats[i].starts :
                        this.blockingstats[i].games;
                    starts = this.blockingstats[i].starts;
                    snaps = NullSum(this.blockingstats[i].passSnap,this.blockingstats[i].rushSnap);
                    blownBlocks = NullSum(this.blockingstats[i].bbPass,this.blockingstats[i].bbRuns);
                    bbPass = this.blockingstats[i].bbPass;
                    bbRuns = this.blockingstats[i].bbRuns;
                    blownBlockPct = NullDiv(blownBlocks,snaps)*100;
                    penalties = this.blockingstats[i].penalties;
                    totalHolds = this.blockingstats[i].passHolds + this.blockingstats[i].rushHolds;
                    falseStarts = this.blockingstats[i].falseStarts;
                    otherPenalties = NullSub(this.blockingstats[i].penalties,NullSum(this.blockingstats[i].passHolds,NullSum(this.blockingstats[i].rushHolds,this.blockingstats[i].falseStarts)));
                    penaltyPct = NullDiv(this.blockingstats[i].penalties,snaps)*100;
                    pointsEarned = Tst2015(this.blockingstats[i].season,this.blockingstats[i].totalEPS);
        
                    data.push(
                        {
                            player,
                            playerId,
                            positionName,
                            season,
                            team,
                            games,
                            starts,
                            snaps,
                            blownBlocks,
                            bbPass,
                            bbRuns,
                            blownBlockPct,
                            penalties,
                            totalHolds,
                            falseStarts,
                            otherPenalties,
                            penaltyPct,
                            pointsEarned
                        }
                    );
                }
            }
            this.data = data;
        }
    }

    // Add totals row to data.(called on component mount if this is the players page.)
    SumData() {
        let season = 'Total';
        let team = '';
        let games = 0;
        let starts = 0;
        let snaps = 0;
        let blownBlocks = 0;
        let bbPass = 0;
        let bbRuns = 0;
        let blownBlockPct = 0;
        let penalties = 0;
        let totalHolds = 0;
        let falseStarts = 0;
        let otherPenalties = 0;
        let penaltyPct = 0;
        let pointsEarned = 0;
        if (this.blockingstats.length !== 0){
            for (var i = 0; i < this.blockingstats.length; i++) {
                games = NullSum(games,this.blockingstats[i].games);
                starts = NullSum(starts,this.blockingstats[i].starts);
                snaps = NullSum(snaps,NullSum(this.blockingstats[i].passSnap,this.blockingstats[i].rushSnap));
                bbPass = NullSum(bbPass,this.blockingstats[i].bbPass);
                bbRuns = NullSum(bbRuns,this.blockingstats[i].bbRuns);
                penalties = NullSum(penalties,this.blockingstats[i].penalties);
                totalHolds = NullSum(totalHolds,NullSum(this.blockingstats[i].passHolds,this.blockingstats[i].rushHolds));
                falseStarts = NullSum(falseStarts,this.blockingstats[i].falseStarts);
                pointsEarned = NullBypassSum(pointsEarned,Tst2015(this.blockingstats[i].season,this.blockingstats[i].totalEPS));
            };
            blownBlocks = NullSum(bbPass,bbRuns);
            otherPenalties = NullSub(penalties,NullSum(totalHolds,falseStarts));
            if (snaps) {
                blownBlockPct = NullDiv(blownBlocks,snaps)*100;
                penaltyPct = NullDiv(penalties,snaps)*100;
            };
            this.data.push(
                {
                    season,
                    team,
                    games,
                    starts,
                    snaps,
                    blownBlocks,
                    bbPass,
                    bbRuns,
                    blownBlockPct,
                    penalties,
                    totalHolds,
                    falseStarts,
                    otherPenalties,
                    penaltyPct,
                    pointsEarned
                }
            );
        }
    }
    
    componentDidMount() {
        this.ConsolidateData();
        let pagination = 'local';
        let paginationSize = 50;
        if (!this.isLeaderboards){
            this.SumData();
            pagination = null;
            paginationSize = 0;
        }
        this.tabulator = new Tabulator(this.el, {
            data: this.data,
            reactiveData: true,
            columns: this.cols,
            layout: "fitColumns",  // added by Ruben 7/25/19
            rowFormatter:function(row){
                var data = row.getData();
                if (data.season === 'Total'){
                    row.getElement().style.borderTop = "2px solid #6f88a8";
                    row.getElement().style.borderBottom = "2px solid #6f88a8";
                }
            },
            pagination: pagination,
            paginationSize: paginationSize,
            initialSort: this.initSort,
            resizableColumns: false,
            selectable: false
        });
    }

    componentDidUpdate() {
        this.tabulator.replaceData(this.data);
    }

    onSubmit(e, minFilter){
        this.minFilter = minFilter;
        this.ConsolidateData();
        this.forceUpdate();
    }

    render() {
        return (
            <React.Fragment>
                {
                    this.isLeaderboards &&
                    <div className="shadowdiv" id="filtersDiv">
                        <Filters minFilter={this.minFilter} onSubmit={this.onSubmit} label={"Snaps"} />
                        <div ref={el => (this.el = el)} />
                    </div>
                }
                {
                    !this.isLeaderboards &&
                    <div className="shadowdiv">
                        <div id="table-title">Summary</div>
                        <div ref={el => (this.el = el)} />
                    </div>
                }
            </React.Fragment>
        );
    }
}

export default TableQBSummaryTabulator;