import React, { Component } from 'react';
import QBSection from './components/leaderboards/QBSection';
import DefenseSection from './components/leaderboards/DefenseSection';
import WRRBSection from './components/leaderboards/WRRBSection';
import TESection from './components/leaderboards/TESection';
import OLSection from './components/leaderboards/OLSection';
import KickingSection from './components/leaderboards/KickingSection';
import PuntingSection from './components/leaderboards/PuntingSection';
import TableDefenseBuildData from './components/pageDefense/TableDefenseBuildData';
import DropDown from './components/common/DropDown';


class Leaderboards extends Component {
    constructor(props, context) {
        const currentDate = new Date();
        const currentMonth = currentDate.getMonth();
        const currentYear = currentDate.getFullYear();

        super(props, context);
        this.state = {
            searchUserInput: '',                    // user input from autocomplete. triggers selection list updates
            selectedPlayerID: ''                    // set to true when API calls complete for a given player. triggers page update
        }
        this.version = '2022-09-13a';
        this.alldependenciescomplete = false;
        this.loadingmessage = 'Loading...';
        this.passingstats = [];                      // passing data retrieved from data API 
        this.rushingstats = [];                       // rushing data retrieved from data API
        this.accuracystats = [];                      // accuracy data retrieved from data API
        this.receivingstats = [];                     // receiving data retrieved from data API
        this.passdefensestats = [];                   // passdefense data retrieved from data API
        this.passrushstats = [];                      // passrush data retrieved from data API
        this.rushdefensestats = [];                 // rushdefense data retrieved from data API
        this.defensestats = [];                     // defense data retrieved from data API
        this.kickingstats = [];                       // kicking data retrieved from data API
        this.kickretstats = [];                       // kickret data retrieved from data API
        this.puntingstats = [];                       // punting data retrieved from data API
        this.puntretstats = [];                       // puntret data retrieved from data API
        this.blockingstats = [];                      // blocking data retrieved from data API
        this.globalstats = [];                        // global stats regardless of position - currently not in use
        this.defenseData = [];                        // array to consolidate defense data - unique to defense due to condiional layout
        this.token = '';                            // token value retrieved from authorization API and used for data API
        this.playerSelectionList = [];              // all NFL players to be listed in the Autocomplete dropdown
        this.asyncAPIcount = 0;                     // number of API calls completed for a given player position
        this.asyncAPIcallstocomplete = 0;           // number of API call to be completed for a given player position
        this.playername = '(No player selected)';   // player name to be displayed on page
        this.playerposition = '';                   // indicates which stats to be displayed. also displayed on page. 
        this.playerid = 0;                          // populated on autocomplete choice and used to retrieve data
        this.usererrormessage = '';                 // error message to be displayed on the page in place of stats
        this.pagelayout = '';                       // indicate which type of page to be rendered
        this.category = 'QB';
        this.startSeason = currentMonth >= 8 ? currentYear : currentYear - 1;
        this.endSeason = currentMonth >= 8 ? currentYear : currentYear - 1;
        this.minAttempts = 0;
        this.filter = { startSeason: String(this.startSeason), endSeason: String(this.endSeason), minAttempts: '1' };
        this.scrollY = 0;
        
        this.reScroll = this.reScroll.bind(this);
        this.onSubmit = this.onSubmit.bind(this);

        if (this.props.match.params.cat){
            switch (this.props.match.params.cat){
                case 'QB':
                    this.category = 'QB';
                    break;
                case 'DE':
                case 'DT':
                case 'DE/DT': 
                    this.category = 'DE/DT';
                    break;
                case 'LB':
                    this.category = 'LB';
                    break;
                case 'CB':
                case 'S':
                case 'CB/S':
                    this.category = 'CB/S';
                    break;
                case 'RB':
                case 'FB':
                case 'RB/FB':
                    this.category = 'RB/FB';
                    break;
                case 'WR':
                case 'TE':
                case 'WR/TE':
                    this.category = 'WR/TE';
                    break;
                case 'T':
                case 'G':
                case 'C':
                case 'C/G/T':
                    this.category = 'C/G/T';
                    break;
                case 'K':
                    this.category = 'K';
                    break;
                case 'P':
                    this.category = 'P';
                    break;
                default:
                    this.category= 'QB';
                    break;
            }
        };
        this.setCookie();
        let cookies = document.cookie.split(';');
        for (var i=0; i < cookies.length; i++){
            let c = cookies[i];
            while(c.substring(0, 1) === ' ') c = c.substring(1, c.length);
            if (c.indexOf('playerId') === 0 && !isNaN(c.substring(9, c.length))) {
                this.playerId = c.substring(9, c.length);
            }
        }
    }

    setCookie(){
        document.cookie = 'playerPos=' + this.category + '; path=/;';
    }

    componentDidMount(){
        window.addEventListener('scroll', this.handleScroll.bind(this));
        this.getSecurityToken();
    }

    componentWillUnmount(){
        window.removeEventListener('scroll', this.handleScroll.bind(this));
    }

        // There are three API calls that must complete in sequence when this page loads:
    //
    // 1) obtain token from security API
    // 2) use token to access the data API
    // 3) load NFL players to an array for user selection
    //
    // API chaining is accomplished by each API routine invoking the next on successfull completion:
    // 1) getSecurityToken --> 2) accessNFLdata --> 3) loadNFLplayers
    //
    getSecurityToken = () => {
        this.executeHTTPRequestPost(
            'https://auth.sportsinfosolutions.com/connect/token',
            (response) => {
                this.token = response.access_token;
                this.changeCategory(this.category, null);
            },
            (error) => {
                console.log("getSecurityToken",error);
            }
        )
    }
    
    // Gain access to NFL data API using the token obtained from the secutiry API
    getAllPlayers = (event) => {
        const reqUrl = 'https://api.sportsinfosolutions.com/api/v1/nfl/players/allplayerswithteamnew';
        this.executeHTTPRequest(
            reqUrl,
            'get',
            (response) => {
                this.playerSelectionList = response;
                this.asyncAPImonitor(this.playerSelectionList);
            },
            (error) => {
                console.log("getAllPlayers",error);
            }
        )
    }

    filterPlayers = (ids, data) => {
        return data.filter(d => ids.indexOf(d.playerId) > -1);
    }

    // get passing stats from NFL data API
    getPassingStats = (ids) => {
        const requesturl = `https://api.sportsinfosolutions.com/api/v1/nfl/seasons/players/passing`;
        this.executeHTTPRequest(
            requesturl,
            'get',
            (response) => {
                this.passingstats = response.data;
                this.asyncAPImonitor(this.passingstats);
            },
            (error) => {
                console.log("getPassingStats",error);
            },
            true
        )
    }

    // get rushing stats from NFL API
    getRushingStats = (ids) => {
        const requesturl = `https://api.sportsinfosolutions.com/api/v1/nfl/seasons/players/rushing`;
        this.executeHTTPRequest(
            requesturl,
            'get',
            (response) => {
                this.rushingstats = response.data;
                this.asyncAPImonitor(this.rushingstats);
            },
            (error) => {
                console.log("getRushingStats",error);
            },
            true
        )
    }

    // get accuracy stats from NFL API
    getAccuracyStats = (ids) => {
        const requesturl = `https://api.sportsinfosolutions.com/api/v1/nfl/seasons/players/qb_dd`;
        this.executeHTTPRequest(
            requesturl,
            'get',
            (response) => {
                this.accuracystats = response.data;
                this.asyncAPImonitor(this.accuracystats);
            },
            (error) => {
                console.log("getAccuracyStats",error);
            },
            true
        )
    }

    // get receiving stats from NFL API
    getReceivingStats = (ids) => {
        const requesturl = `https://api.sportsinfosolutions.com/api/v1/nfl/seasons/players/receiving`;
        this.executeHTTPRequest(
            requesturl,
            'get',
            (response) => {
                this.receivingstats = response.data;
                this.asyncAPImonitor(this.receivingstats);
            },
            (error) => {
                console.log("getReceivingStats",error);
            },
            true
        )
    }

    // get blocking stats from NFL API
    getBlockingStats = (ids) => {
        const requesturl = `https://api.sportsinfosolutions.com/api/v1/nfl/seasons/players/blocking`;
        this.executeHTTPRequest(
            requesturl,
            'get',
            (response) => {
                this.blockingstats = response.data;
                this.asyncAPImonitor(this.blockingstats);
            },
            (error) => {
                console.log("getBlockingStats",error);
            },
            true
        )
    }

    // get puntret stats from NFL API
    getPuntRetStats = (ids) => {
        const requesturl = `https://api.sportsinfosolutions.com/api/v1/nfl/seasons/players/puntret`;
        this.executeHTTPRequest(
            requesturl,
            'get',
            (response) => {
                this.puntretstats = response.data;
                this.asyncAPImonitor(this.puntretstats);
            },
            (error) => {
                console.log("getPuntRetStats",error);
            },
            true
        )
    }

    // get kickret stats from NFL API
    getKickRetStats = (ids) => {
        const requesturl = `https://api.sportsinfosolutions.com/api/v1/nfl/seasons/players/kickret`;
        this.executeHTTPRequest(
            requesturl,
            'get',
            (response) => {
                this.kickretstats = response.data;
                this.asyncAPImonitor(this.kickretstats);
            },
            (error) => {
                console.log("getKickRetStats",error);
            },
            true
        )
    }

    // get punting stats from NFL API
    getPuntingStats = (ids) => {
        const requesturl = `https://api.sportsinfosolutions.com/api/v1/nfl/seasons/players/punting`;
        this.executeHTTPRequest(
            requesturl,
            'get',
            (response) => {
                this.puntingstats = response.data;
                this.asyncAPImonitor(this.puntingstats);
            },
            (error) => {
                console.log("getPuntingStats",error);
            },
            true
        )
    }

    // get kicking stats from NFL API
    getKickingStats = (ids) => {
        const requesturl = `https://api.sportsinfosolutions.com/api/v1/nfl/seasons/players/kicking`;
        this.executeHTTPRequest(
            requesturl,
            'get',
            (response) => {
                this.kickingstats = response.data;
                this.asyncAPImonitor(this.kickingstats);
            },
            (error) => {
                console.log("getKickingStats",error);
            },
            true
        )
    }

    // get passdefense stats from NFL API
    getPassDefenseStats = (ids) => {
        const requesturl = `https://api.sportsinfosolutions.com/api/v1/nfl/seasons/players/passdefense`;
        this.executeHTTPRequest(
            requesturl,
            'get',
            (response) => {
                this.passdefensestats = response.data;
                this.asyncAPImonitor(this.passdefensestats);
            },
            (error) => {
                console.log("getPassDefenseStats",error);
            },
            true
        )
    }

    // get passrush stats from NFL API
    getPassRushStats = (ids) => {
        const requesturl = `https://api.sportsinfosolutions.com/api/v1/nfl/seasons/players/passrush`;
        this.executeHTTPRequest(
            requesturl,
            'get',
            (response) => {
                this.passrushstats = response.data;
                this.asyncAPImonitor(this.passrushstats);
            },
            (error) => {
                console.log("getPassRushStats",error);
            },
            true
        )
    }

    // get rushdefense stats from NFL API
    getRushDefenseStats = (ids) => {
        const requesturl = `https://api.sportsinfosolutions.com/api/v1/nfl/seasons/players/rushdefense`;
        this.executeHTTPRequest(
            requesturl,
            'get',
            (response) => {
                this.rushdefensestats = response.data;
                this.asyncAPImonitor(this.rushdefensestats);
            },
            (error) => {
                console.log("getRushDefenseStats",error);
            },
            true
        )
    }

    // get defense stats from NFL API
    getDefenseStats = (ids) => {
        const requesturl = `https://api.sportsinfosolutions.com/api/v1/nfl/seasons/players/defense`;
        this.executeHTTPRequest(
            requesturl,
            'get',
            (response) => {
                this.defensestats = response.data;
                this.asyncAPImonitor(this.defensestats);
            },
            (error) => {
                console.log("getDefenseStats",error);
            },
            true
        )
    }

    // get global stats and player bio info
    getGlobalStats = (ids) => {
        const requesturl = `https://api.sportsinfosolutions.com/api/va/nfl/players/globalinfo?PlayerId=0`;
        this.executeHTTPRequest(
            requesturl,
            'get',
            (response) => {
                this.globalstats = response.data;
                this.asyncAPImonitor();
            },
            (error) => {
                console.log("getGlobalStats",error);
            }
        )
    }

    // general purpose fetch method called by each API related method
    executeHTTPRequest = (url, method, callback, failback, incParameters) => {
        if (incParameters){
            url += '?startSeason=' + this.filter.startSeason + '&endSeason=' + this.filter.endSeason;
        }
        fetch(
            url, {
            method: method,
            headers: {
                Accept: 'application/json, text/plain, */*',
                    'Content-Type': 'application/json',
                Authorization: `Bearer ${this.token}`
            },
        })
        .then((response) => {
            let json = response.json();
            if(response.ok) {
                if (callback) {
                    json.then((result) => {
                        callback(result);
                    });
                }
            } else {
              return json.then(Promise.reject.bind(Promise));
            }
        })
        .catch((error) => {
            if (failback) {
                failback(error);
            }
        });
    }

    executeHTTPRequestPost = (url, callback, failback) => {
        var details = {'client_id': 'FootballApp@sportsinfosolutions.com',
        'client_secret': 'xPc14+wU2zHtydg12AgglRCkL+ALNlX/miH2JJHp2/k=',
        'grant_type': 'client_credentials',
        'scope': 'sisapi'};

        var formBody = [];
        for (var property in details){
            var encodedKey = encodeURIComponent(property);
            var encodedValue = encodeURIComponent(details[property]);
            formBody.push(encodedKey + "=" + encodedValue);
        }
        formBody = formBody.join("&");

        fetch(
            url, {
                method: "POST",
                cache: "no-cache",
                headers: {'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'},
                body: formBody,  
        })
        .then((response) => {
            let json = response.json();
            if (response.ok) {
                if (callback) {
                    json.then((result) => {
                        callback(result);
                    });
                }
            } else {
                return json.then(Promise.reject.bind(Promise));
            }
        })
        .catch((error) => {
            if (failback) {
                failback(error);
            }
        });
    }

    // Replacement for Promise.all to ensure that parallel async
    // dependencies complete before triggering a render by updating
    // state.
    //
    // Prior to launching parallel async tasks, set dependencycount
    // to zero and dependenciestocomplete to the number of async tasks
    // to be complete. Each task must call this function upon successful
    // completion. When dependencycount is equal to dependenciestocomplete,
    // state will be updated and dependencycount and dependenciestocomplete
    // are reset to zero.
    //
    asyncAPImonitor = (d) => {
        this.asyncAPIcount += 1;
        this.asyncAPIrows += d.length;
        if (this.asyncAPIcount === this.asyncAPIcallstocomplete) {
            var filteredPlayers = [];
            switch (this.category) {
                case 'QB':
                    filteredPlayers = this.playerSelectionList.map(p => {
                        if (p.positionCategory === 'QB'){
                            return p.playerId;
                        };
                        return null;
                    });
                    this.passingstats = this.filterPlayers(filteredPlayers, this.passingstats);
                    this.rushingstats = this.filterPlayers(filteredPlayers, this.rushingstats);
                    this.accuracystats = this.filterPlayers(filteredPlayers, this.accuracystats);
                    break;
                case 'DE/DT':                                          
                    filteredPlayers = this.playerSelectionList.map(p => {
                        if (['DE', 'DT'].indexOf(p.positionCategory) > -1){
                            return p.playerId;
                        };
                        return null;
                    });
                    this.passdefensestats = this.filterPlayers(filteredPlayers, this.passdefensestats);
                    this.passrushstats = this.filterPlayers(filteredPlayers, this.passrushstats);
                    this.rushdefensestats = this.filterPlayers(filteredPlayers, this.rushdefensestats);
                    this.defensestats = this.filterPlayers(filteredPlayers, this.defensestats);
                    break;
                case 'LB':
                    filteredPlayers = this.playerSelectionList.map(p => {
                        if (p.positionCategory === 'LB'){
                            return p.playerId;
                        };
                        return null;
                    });
                    this.passdefensestats = this.filterPlayers(filteredPlayers, this.passdefensestats);
                    this.passrushstats = this.filterPlayers(filteredPlayers, this.passrushstats);
                    this.rushdefensestats = this.filterPlayers(filteredPlayers, this.rushdefensestats);
                    this.defensestats = this.filterPlayers(filteredPlayers, this.defensestats);
                    break;
                case 'CB/S':
                    filteredPlayers = this.playerSelectionList.map(p => {
                        if (['CB', 'S'].indexOf(p.positionCategory) > -1){
                            return p.playerId;
                        };
                        return null;
                    });
                    this.passdefensestats = this.filterPlayers(filteredPlayers, this.passdefensestats);
                    this.passrushstats = this.filterPlayers(filteredPlayers, this.passrushstats);
                    this.rushdefensestats = this.filterPlayers(filteredPlayers, this.rushdefensestats);
                    this.defensestats = this.filterPlayers(filteredPlayers, this.defensestats);
                    this.puntretstats = this.filterPlayers(filteredPlayers, this.puntretstats);
                    this.kickretstats = this.filterPlayers(filteredPlayers, this.kickretstats);
                    break;
                case 'RB/FB':
                    filteredPlayers = this.playerSelectionList.map(p => {
                        if (['RB', 'FB'].indexOf(p.positionCategory) > -1){
                            return p.playerId;
                        };
                        return null;
                    });
                    this.rushingstats = this.filterPlayers(filteredPlayers, this.rushingstats);
                    this.receivingstats = this.filterPlayers(filteredPlayers, this.receivingstats);
                    this.puntretstats = this.filterPlayers(filteredPlayers, this.puntretstats);
                    this.kickretstats = this.filterPlayers(filteredPlayers, this.kickretstats);
                    break;
                case 'WR/TE':
                        filteredPlayers = this.playerSelectionList.map(p => {
                            if (['WR', 'TE'].indexOf(p.positionCategory) > -1){
                                return p.playerId;
                            };
                            return null;
                        });
                        this.blockingstats = this.filterPlayers(filteredPlayers, this.blockingstats);
                        this.rushingstats = this.filterPlayers(filteredPlayers, this.rushingstats);
                        this.receivingstats = this.filterPlayers(filteredPlayers, this.receivingstats);
                        this.puntretstats = this.filterPlayers(filteredPlayers, this.puntretstats);
                        this.kickretstats = this.filterPlayers(filteredPlayers, this.kickretstats);
                        break;
                case 'C/G/T':
                    filteredPlayers = this.playerSelectionList.map(p => {
                        if (['T', 'G', 'C'].indexOf(p.positionCategory) > -1){
                            return p.playerId;
                        };
                        return null;
                    });
                    this.blockingstats = this.filterPlayers(filteredPlayers, this.blockingstats);
                    this.rushingstats = this.filterPlayers(filteredPlayers, this.rushingstats);
                    break;
                case 'K':
                    filteredPlayers = this.playerSelectionList.map(p => {
                        if (p.positionCategory === 'K'){
                            return p.playerId;
                        };
                        return null;
                    });
                    this.kickingstats = this.filterPlayers(filteredPlayers, this.kickingstats);
                    break;
                case 'P':
                    filteredPlayers = this.playerSelectionList.map(p => {
                        if (p.positionCategory === 'P'){
                            return p.playerId;
                        };
                        return null;
                    });
                    this.puntingstats = this.filterPlayers(filteredPlayers, this.puntingstats);
                    this.passingstats = this.filterPlayers(filteredPlayers, this.passingstats);
                    this.rushingstats = this.filterPlayers(filteredPlayers, this.rushingstats);
                    break;
                default:
                    console.log("Leaderboard asyncAPImonitor - invalid category:", this.category);
            }
            this.alldependenciescomplete = true;
            this.asyncAPIcount = 0;
            this.asyncAPIcallstocomplete = 0;
            this.usererrormessage = '';
            this.loadingmessage = '';
            this.forceUpdate();
        } 
    }

    changeCategory(category, event){
        this.category = category;
        this.alldependenciescomplete = false; 
        this.asyncAPIcount = 0;
        this.asyncAPIrows = 0; 
        this.asyncAPIcallstocomplete = 0;
        this.setCookie();
        window.history.replaceState({prevCategory: category}, 'NFL Player Stats' + this.category, '/leaderboards/' + this.category.split("/")[0]);
        this.forceUpdate();
        switch (this.category){
            case 'QB':
                this.asyncAPIcallstocomplete += 4;
                this.getAllPlayers();
                this.getPassingStats();
                this.getRushingStats();
                this.getAccuracyStats();
                break;
            case 'DE/DT':                                          
                this.asyncAPIcallstocomplete += 5;
                this.getAllPlayers();
                this.getDefenseStats();
                this.getPassDefenseStats();
                this.getPassRushStats();
                this.getRushDefenseStats();
                break;
            case 'LB':
                this.asyncAPIcallstocomplete += 5;
                this.getAllPlayers();
                this.getDefenseStats();
                this.getPassDefenseStats();
                this.getPassRushStats();
                this.getRushDefenseStats();
                break;
            case 'CB/S':
                this.asyncAPIcallstocomplete += 7;
                this.getAllPlayers();
                this.getDefenseStats();
                this.getPassDefenseStats();
                this.getPassRushStats();
                this.getRushDefenseStats();
                this.getPuntRetStats();
                this.getKickRetStats();
                break;
            case 'RB/FB':
                this.asyncAPIcallstocomplete += 5;
                this.getAllPlayers();
                this.getRushingStats();
                this.getReceivingStats();
                this.getPuntRetStats();
                this.getKickRetStats();
                break;
            case 'WR/TE':
                this.asyncAPIcallstocomplete += 6;
                this.getAllPlayers();
                this.getBlockingStats();
                this.getRushingStats();
                this.getReceivingStats();
                this.getPuntRetStats();
                this.getKickRetStats();
                this.blockingstats = [];
                break;
            case 'C/G/T':
                this.asyncAPIcallstocomplete += 3;
                this.getAllPlayers();
                this.getBlockingStats();
                this.getRushingStats();
                break;
            case 'K':
                this.asyncAPIcallstocomplete += 2;
                this.getAllPlayers();
                this.getKickingStats();
                break;
            case 'P':
                this.asyncAPIcallstocomplete += 4;
                this.getAllPlayers();
                this.getPuntingStats();
                this.getPassingStats();
                this.getRushingStats();
                break;
            default:
                this.usererrormessage = '('+this.category+' not supported at this time)';
                this.alldependenciescomplete = true;
                break;
        }
    }

    onSubmit(event, startSeason, endSeason, minAttempts){
        this.filter.minAttempts = minAttempts;
        if (this.filter.startSeason !== startSeason || this.filter.endSeason !== endSeason){
            this.filter.startSeason = startSeason;
            this.filter.endSeason = endSeason;
            this.changeCategory(this.category, null);
        }
        else{
            this.forceUpdate();
        }
    }

    tabSelected(pos){
        if (pos === this.category){
            return 'true';
        }
        else{
            return 'false';
        }
    }

    tabActive(pos){
        if (pos === this.category){
            return 'nav-link active';
        }
        else{
            return 'nav-link';
        }
    }

    handleScroll(){
        if (window.scrollY > 0){
            this.scrollY = window.scrollY;
        }
        else{
            this.reScroll(this.scrollY);
        }
    }
    
    reScroll(scrollY = this.scrollY){
        window.scrollTo(0, scrollY);
    }

    render() {
        if (['DE/DT', 'LB', 'CB/S'].indexOf(this.category) > -1 && this.alldependenciescomplete) {
            this.defenseData = [];
            this.defenseData = TableDefenseBuildData(this.defensestats, this.passdefensestats,this.passrushstats,this.rushdefensestats,this.kickretstats,this.puntretstats);
        }
        if (!this.playerId){
            this.playerId = '';
        }

        return (
            <div className="App">
                <div className="container">
                    <div className="row page-header">
                        <div className='col-md-12'><br /></div>
                        <div className="col-md-6">
                            <a href="http://sisdatahub.com">
                                <img src="/images/logo.png" alt="logo" height="70" width="166" />
                            </a>
                        </div>
                        <div className="col-md-6">
                            <div id="player-autocomplete-wrapper">
                                <p><font color="MediumSeaGreen"><b>Player Search:</b></font></p>
                                {
                                    this.playerSelectionList &&
                                    <DropDown players={this.playerSelectionList} />
                                }
                                <i><font size="2">last, first name (pos, team #jersey)</font></i>
                            </div>
                        </div>
                        <div className='col-md-12'><br /></div>
                        <div style={{ backgroundColor: 'red', textAlign: 'center' }} className="col-md-12"><a href="http://pro.sisdatahub.com">
                            <b style={{ color: 'white' }}>Go PRO! Click here for more details!</b></a>
                        </div>
                        
                        <div id="hero1" className="col-md-12">SIS DataHub</div>
                        <ul className="nav nav-tabs col-md-12" role="tablist">
                            <li className="nav-item">
                                <a className="nav-link" href={"/players/" + this.playerId}>PLAYERS</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href={"/playergamelogs/" + this.playerId}>GAME LOGS</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link active" href={"/leaderboards/" + this.category}>LEADERBOARDS</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href={"/glossary"}>GLOSSARY</a>
                            </li>
                        </ul>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <ul className="nav nav-pills nav-fill" role="tablist">
                                <li className="nav-item">
                                    <a className={this.tabActive('QB')} id="home-tab" data-toggle="tab" href="#QB" role="tab" aria-controls="QB" aria-selected={this.tabSelected('QB')} onClick={this.changeCategory.bind(this, 'QB')}>QB</a>
                                </li>
                                <li className="nav-item">
                                    <a className={this.tabActive('RB/FB')} id="home-tab" data-toggle="tab" href="#RB/FB" role="tab" aria-controls="RB/FB" aria-selected={this.tabSelected('RB/FB')} onClick={this.changeCategory.bind(this, 'RB/FB')}>RB/FB</a>
                                </li>
                                <li className="nav-item">
                                    <a className={this.tabActive('WR/TE')} id="home-tab" data-toggle="tab" href="#WR/TE" role="tab" aria-controls="WR/TE" aria-selected={this.tabSelected('WR/TE')} onClick={this.changeCategory.bind(this, 'WR/TE')}>WR/TE</a>
                                </li>                                
                                <li className="nav-item">
                                    <a className={this.tabActive('C/G/T')} id="home-tab" data-toggle="tab" href="#C/G/T" role="tab" aria-controls="C/G/T" aria-selected={this.tabSelected('C/G/T')} onClick={this.changeCategory.bind(this, 'C/G/T')}>C/G/T</a>
                                </li>                                
                                <li className="nav-item">
                                    <a className={this.tabActive('DE/DT')} id="home-tab" data-toggle="tab" href="#DE/DT" role="tab" aria-controls="DE/DT" aria-selected={this.tabSelected('DE/DT')} onClick={this.changeCategory.bind(this, 'DE/DT')}>DE/DT</a>
                                </li>
                                <li className="nav-item">
                                    <a className={this.tabActive('LB')} id="home-tab" data-toggle="tab" href="#LB" role="tab" aria-controls="LB" aria-selected={this.tabSelected('LB')} onClick={this.changeCategory.bind(this, 'LB')}>LB</a>
                                </li>
                                <li className="nav-item">
                                    <a className={this.tabActive('CB/S')} id="home-tab" data-toggle="tab" href="#CB/S" role="tab" aria-controls="CB/S" aria-selected={this.tabSelected('CB/S')} onClick={this.changeCategory.bind(this, 'CB/S')}>CB/S</a>
                                </li>
                                <li className="nav-item">
                                    <a className={this.tabActive('K')} id="home-tab" data-toggle="tab" href="#K" role="tab" aria-controls="K" aria-selected={this.tabSelected('K')} onClick={this.changeCategory.bind(this, 'K')}>K</a>
                                </li>
                                <li className="nav-item">
                                    <a className={this.tabActive('P')} id="home-tab" data-toggle="tab" href="#P" role="tab" aria-controls="P" aria-selected={this.tabSelected('P')} onClick={this.changeCategory.bind(this, 'P')}>P</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    
                    <div className="row">
                        
                        <div className="col-md-12">
                        {
                            !this.alldependenciescomplete &&
                                <div>
                                    <br />
                                    <br />
                                    <i className='fa fa-spinner fa-spin loading-icon'></i>
                                    <br />
                                </div>
                        }
                        {
                            this.category === 'QB' && this.alldependenciescomplete && this.asyncAPIrows !== 0 &&
                            <QBSection passingstats={this.passingstats} rushingstats={this.rushingstats} accuracystats={this.accuracystats} 
                                onSubmit={this.onSubmit} filters={this.filter} reScroll={this.reScroll}
                            />
                        }
                        {
                            this.category === 'RB/FB' && this.alldependenciescomplete && this.asyncAPIrows !== 0 &&
                            <WRRBSection rushingstats={this.rushingstats} receivingstats={this.receivingstats} 
                                puntretstats={this.puntretstats} kickretstats={this.kickretstats}
                                onSubmit={this.onSubmit} filters={this.filter} reScroll={this.reScroll}
                            />
                        }
                        {
                            this.category === 'WR/TE' && this.alldependenciescomplete && this.asyncAPIrows !== 0 &&
                            <TESection rushingstats={this.rushingstats} receivingstats={this.receivingstats} puntretstats={this.puntretstats} 
                                kickretstats={this.kickretstats} blockingstats={this.blockingstats} 
                                onSubmit={this.onSubmit} filters={this.filter} reScroll={this.reScroll} />
                        }
                        {
                            this.category === 'C/G/T' && this.alldependenciescomplete && this.asyncAPIrows !== 0 &&
                            <OLSection blockingstats={this.blockingstats} onSubmit={this.onSubmit} filters={this.filter} reScroll={this.reScroll} />
                        }
                        {
                            this.category === 'DE/DT' && this.alldependenciescomplete && this.asyncAPIrows !== 0 &&
                            <DefenseSection defensestats={this.defenseData} onSubmit={this.onSubmit} filters={this.filter} reScroll={this.reScroll} />
                        }
                        {
                            this.category === 'LB' && this.alldependenciescomplete && this.asyncAPIrows !== 0 &&
                            <DefenseSection defensestats={this.defenseData} onSubmit={this.onSubmit} filters={this.filter} reScroll={this.reScroll} />
                        }
                        {
                            this.category === 'CB/S' && this.alldependenciescomplete && this.asyncAPIrows !== 0 &&
                            <DefenseSection defensestats={this.defenseData} onSubmit={this.onSubmit} filters={this.filter} reScroll={this.reScroll} />
                        }
                        {
                            this.category === 'K' && this.alldependenciescomplete && this.asyncAPIrows !== 0 &&
                            <KickingSection playerlist={this.playerSelectionList} kickingstats={this.kickingstats}
                                onSubmit={this.onSubmit} filters={this.filter} reScroll={this.reScroll} 
                            />
                        }
                        {
                            this.category === 'P' && this.alldependenciescomplete && this.asyncAPIrows !== 0 &&
                            <PuntingSection playerlist={this.playerSelectionList} puntingstats={this.puntingstats} 
                                passingstats={this.passingstats} rushingstats={this.rushingstats}
                                onSubmit={this.onSubmit} filters={this.filter}  reScroll={this.reScroll}
                            />
                        }
                        </div>
                    </div>
                    <br />
                    <br />
                    <p><font size='1'> v. {this.version}</font></p>
                </div>
            </div>
        )
    }
}

export default Leaderboards;