import React from 'react';
import {NullSum, NullBypassSum, NullMax, NullDiv, DispFunc, GameResult} from '../common/CommonFunctions';
import { gamePunting } from '../common/TableColumnWidths';
import Tabulator from 'tabulator-tables';
import "tabulator-tables/dist/css/tabulator.min.css";

class GameLogKPPuntingTabulator extends React.Component {
    constructor(props) {
        super(props);
        this.puntingstats = this.props.puntingstats;
        this.data = [];
        let gameInfoCols = null;
        this.colNum = 0;
        this.maxPlayerSeason = ''

        // Set game information columns
        gameInfoCols = [
            {
                title: "Date", field: "gameSeasonDateSSSSYYYYMMDD", cssClass: "single-row-header textColumn",
                titleFormatter: "html", headerSort: false,
                formatter: function (cell, formatterParams, onRendered) {
                    return (cell.getValue().substr(9, 1) === 'T' ? 'Total' : parseInt(cell.getValue().substr(9, 2)) + '/' + parseInt(cell.getValue().substr(12, 2)));
                },
                width: `${gamePunting[this.colNum++]}`
            },
            {
                title: "Wk", field: "gameWeek", cssClass: "single-row-header small-column",
                titleFormatter: "html", headerSort: false,
                width: `${gamePunting[this.colNum++]}`,
                //              formatter: function (cell, formatterParams, onRendered) {
                //                 return cell = 99 ? '' : cell;
                //              }
            },
            {
                title: "Teams", field: "matchup", cssClass: "single-row-header textColumn",
                titleFormatter: "html", headerSort: false,
                width: `${gamePunting[this.colNum++]}`
            },
            {
                title: "Score", field: "gameResult", cssClass: "single-row-header break textColumn",
                titleFormatter: "html", headerSort: false,
                width: `${gamePunting[this.colNum++]}`
            },
            {
                title: "G", field: "games", cssClass: "single-row-header small-column break",
                titleFormatter: "html", headerSort: false,
                width: `${gamePunting[this.colNum++]}`
            }
        ];

        // Set Remaining Columns
        this.cols = [
            {
                title: "",
                columns: gameInfoCols
            },
            {
                title: "Punts", field: "punts", cssClass: "single-row-header",
                headerSortStartingDir: "desc", headerSort: false,
                width: `${gamePunting[this.colNum++]}`,
                formatter: function (cell, formatterParams, onRendered) {
                    return DispFunc(cell, formatterParams, onRendered, 0, false, false);
                }
            },
            {
                title: "Yards", field: "puntYards", cssClass: "single-row-header",
                headerSortStartingDir: "desc", headerSort: false,
                width: `${gamePunting[this.colNum++]}`,
                formatter: function (cell, formatterParams, onRendered) {
                    return DispFunc(cell, formatterParams, onRendered, 0, true, false);
                }
            },
            {
                title: "Avg", field: "puntAvgYards", cssClass: "single-row-header break",
                headerSortStartingDir: "desc", headerSort: false,
                width: `${gamePunting[this.colNum++]}`,
                formatter: function (cell, formatterParams, onRendered) {
                    return DispFunc(cell, formatterParams, onRendered, 1, false, false);
                }
            },
            {
                title: "Opp Ret", field: "puntsReturned", cssClass: "single-row-header",
                headerSortStartingDir: "desc", headerSort: false,
                width: `${gamePunting[this.colNum++]}`,
                formatter: function (cell, formatterParams, onRendered) {
                    return DispFunc(cell, formatterParams, onRendered, 0, true, false);
                }
            },
            {
                title: "Ret Yards", field: "returnYards", cssClass: "single-row-header",
                headerSortStartingDir: "desc", headerSort: false,
                width: `${gamePunting[this.colNum++]}`,
                formatter: function (cell, formatterParams, onRendered) {
                    return DispFunc(cell, formatterParams, onRendered, 0, false, false);
                }
            },
            {
                title: "Net Avg", field: "puntAvgNetYards", cssClass: "single-row-header break",
                headerSortStartingDir: "desc", headerSort: false,
                width: `${gamePunting[this.colNum++]}`,
                formatter: function (cell, formatterParams, onRendered) {
                    return DispFunc(cell, formatterParams, onRendered, 1, false, false);
                }
            },
            {
                title: "Long", field: "long", cssClass: "single-row-header",
                headerSortStartingDir: "desc", headerSort: false,
                width: `${gamePunting[this.colNum++]}`,
                formatter: function (cell, formatterParams, onRendered) {
                    return DispFunc(cell, formatterParams, onRendered, 0, false, false);
                }
            },
            {
                title: "Blocks", field: "blockedPunts", cssClass: "single-row-header",
                headerSortStartingDir: "desc", headerSort: false,
                width: `${gamePunting[this.colNum++]}`,
                formatter: function (cell, formatterParams, onRendered) {
                    return DispFunc(cell, formatterParams, onRendered, 0, false, false);
                }
            },
            {
                title: "Partial Blocks", field: "partialBlockedPunts", cssClass: "single-row-header",
                headerSortStartingDir: "desc", headerSort: false,
                titleFormatter: "textarea",
                width: `${gamePunting[this.colNum++]}`,
                formatter: function (cell, formatterParams, onRendered) {
                    return DispFunc(cell, formatterParams, onRendered, 0, false, false);
                }
            },
            {
                title: "Fair Catches", field: "fCs", cssClass: "single-row-header",
                headerSortStartingDir: "desc", headerSort: false,
                titleFormatter: "textarea",
                width: `${gamePunting[this.colNum++]}`,
                formatter: function (cell, formatterParams, onRendered) {
                    return DispFunc(cell, formatterParams, onRendered, 0, false, false);
                }
            },
            {
                title: "Touchbacks", field: "touchBacks", cssClass: "single-row-header",
                headerSortStartingDir: "desc", headerSort: false,
                titleFormatter: "textarea",
                width: `${gamePunting[this.colNum++]}`,
                formatter: function (cell, formatterParams, onRendered) {
                    return DispFunc(cell, formatterParams, onRendered, 0, false, false);
                }
            },
            {
                title: "Inside 20", field: "puntsInside20", cssClass: "single-row-header break",
                headerSortStartingDir: "desc", headerSort: false,
                width: `${gamePunting[this.colNum++]}`,
                formatter: function (cell, formatterParams, onRendered) {
                    return DispFunc(cell, formatterParams, onRendered, 0, false, false);
                }
            },
            {
                title: "Tot Points Earned", field: "sumPointsEarned", cssClass: "single-row-header",
                headerSortStartingDir: "desc", headerSort: false,
                titleFormatter: "textarea",
                width: `${gamePunting[this.colNum++]}`,
                formatter: function (cell, formatterParams, onRendered) {
                    return DispFunc(cell, formatterParams, onRendered, 0, false, false);
                }
            }
        ]
        this.el = React.createRef();
        this.tabulator = null;
    }

    // Consolidate the data into one data array to be used for tabulator(called on component mount)
    ConsolidateData(){
        let puntingstats = this.puntingstats;
        let sortKey = 0;
        let season = 0;
        let playerName = '';
        let playerId = 0;
        let positionName = '';
        let team = '';
        let matchup = '';
        let oppTeam = '';
        let gameWeek = 0;
        let games = 0;
        let gameDate = '';
        let gameSeasonDateSSSSYYYYMMDD = '';
        let gameResult = '';
        let punts = 0;
        let puntYards = 0;
        let puntAvgYards = 0;
        let puntsReturned = 0;
        let returnYards = 0;
        let netPuntYards = 0;
        let puntAvgNetYards = 0;
        let long = 0;
        let blockedPunts = 0;
        let partialBlockedPunts = 0;
        let fCs = 0;
        let touchBacks = 0;
        let puntsInside20 = 0;
        let sumPointsEarned = 0;

        let rowdata = [];
        // create rowdata[] object for each row in puntingstats data
        if (puntingstats.length !== 0){
            for (var i=0; i<puntingstats.length; i++) {
                sortKey = (9999 - puntingstats[i].season) * 1000000 + puntingstats[i].season * 100 + puntingstats[i].week; //XXXXYYYYWW
                season = puntingstats[i].season;
                playerName = puntingstats[i].playerName;
                playerId = puntingstats[i].playerId;
                positionName = puntingstats[i].positionName;
                team = puntingstats[i].teamName;
                matchup = puntingstats[i].teamName.trim() + ' ' + puntingstats[i].oppTeamName.trim();
                games = 1;
                gameDate = puntingstats[i].gameDate;
                gameSeasonDateSSSSYYYYMMDD = puntingstats[i].season.toString() + puntingstats[i].gameDate.substring(0, 10);
                gameResult = GameResult(puntingstats[i].teamScore, puntingstats[i].oppTeamScore);
                gameWeek = puntingstats[i].week;
                punts = puntingstats[i].punts;
                puntYards = puntingstats[i].puntYards;
                puntAvgYards = NullDiv(puntingstats[i].puntYards, puntingstats[i].punts);
                puntsReturned = puntingstats[i].puntsReturned;
                returnYards = puntingstats[i].returnYards;
                netPuntYards = puntingstats[i].netPuntYards;
                puntAvgNetYards = NullDiv(puntingstats[i].netPuntYards, puntingstats[i].punts - puntingstats[i].blockedPunts);
                long = puntingstats[i].long;
                blockedPunts = puntingstats[i].blockedPunts;
                partialBlockedPunts = puntingstats[i].partialBlockedPunts;
                fCs = puntingstats[i].fCs;
                touchBacks = puntingstats[i].touchbacks;
                puntsInside20 = puntingstats[i].puntsInside20;
                sumPointsEarned = puntingstats[i].eps;
                rowdata.push({
                    sortKey,
                    season,
                    playerName,
                    playerId,
                    positionName,
                    team,
                    matchup,
                    games,
                    gameDate,
                    gameSeasonDateSSSSYYYYMMDD,
                    gameResult,
                    gameWeek,
                    oppTeam,
                    punts,
                    puntYards,
                    puntAvgYards,
                    puntsReturned,
                    returnYards,
                    netPuntYards,
                    puntAvgNetYards,
                    long,
                    blockedPunts,
                    partialBlockedPunts,
                    fCs,
                    touchBacks,
                    puntsInside20,
                    sumPointsEarned
                });
            }
        }
        if (rowdata.length !== 0) {
            rowdata.sort((a, b) => a.sortKey > b.sortKey ? 1 : -1); // orders rows by sortkey
            this.SumData(rowdata); // insert seasonal totals
        }
        this.data = rowdata;
    }

    // Add totals row to data.(called on component mount if this is the players page.)
    SumData(d) {
        let sortKey = 0;
        let season = d[0].season;
        let playerName = '';
        let playerId = '';
        let positionName = '';
        let team = '';
        let matchup = '';
        let games = 0;
        let gameDate = '';
        let gameSeasonDateSSSSYYYYMMDD = '';
        let gameResult = '';
        let gameWeek = '';
        let oppTeam = ''
        let punts = 0;
        let puntYards = 0;
        let puntsReturned = 0;
        let returnYards = 0;
        let netPuntYards=0;
        let long = 0;
        let blockedPunts = 0;
        let partialBlockedPunts = 0;
        let fCs = 0;
        let touchBacks = 0;
        let puntsInside20 = 0;
        let puntAvgYards = 0;
        let puntAvgNetYards = 0;
        let sumPointsEarned = 0;
        let rowcount = d.length;
        for (var i = 0; i < rowcount; i++) {
            if (season !== d[i].season) {
                sortKey = (9999 - season) * 1000000 + season * 100 + 99; //XXXXYYYY99
                gameSeasonDateSSSSYYYYMMDD = season.toString() + season.toString() + " Total";
                puntAvgYards = NullDiv(puntYards, punts);
                puntAvgNetYards = NullDiv(netPuntYards, punts - blockedPunts);
                d.push({
                    sortKey,
                    season,
                    playerName,
                    playerId,
                    positionName,
                    team,
                    matchup,
                    games,
                    gameDate,
                    gameSeasonDateSSSSYYYYMMDD,
                    gameResult,
                    gameWeek,
                    oppTeam,
                    punts,
                    puntYards,
                    puntAvgYards,
                    puntsReturned,
                    returnYards,
                    netPuntYards,
                    puntAvgNetYards,
                    long,
                    blockedPunts,
                    partialBlockedPunts,
                    fCs,
                    touchBacks,
                    puntsInside20,
                    sumPointsEarned
                });
                season = d[i].season;
                playerName = '';
                playerId = '';
                positionName = '';
                team = '';
                matchup = '';
                games = 0;
                gameDate = '';
                gameSeasonDateSSSSYYYYMMDD = '';
                gameResult = '';
                gameWeek = '';
                oppTeam = ''
                punts = 0;
                puntYards = 0;
                puntsReturned = 0;
                returnYards = 0;
                netPuntYards = 0;
                long = 0;
                blockedPunts = 0;
                partialBlockedPunts = 0;
                fCs = 0;
                touchBacks = 0;
                puntsInside20 = 0;
                puntAvgYards = 0;
                puntAvgNetYards = 0;
                sumPointsEarned = 0;
            }
            games = NullSum(games, d[i].games);
            punts = NullSum(punts, d[i].punts);
            puntYards = NullSum(puntYards, d[i].puntYards);
            puntsReturned = NullSum(puntsReturned, d[i].puntsReturned);
            returnYards = NullSum(returnYards, d[i].returnYards);
            netPuntYards = NullSum(netPuntYards, d[i].netPuntYards);
            long = NullMax(long, d[i].long);
            blockedPunts = NullSum(blockedPunts, d[i].blockedPunts);
            partialBlockedPunts = NullSum(partialBlockedPunts, d[i].partialBlockedPunts);
            touchBacks = NullSum(touchBacks, d[i].touchBacks);
            fCs = NullSum(fCs, d[i].fCs);
            puntsInside20 = NullSum(puntsInside20, d[i].puntsInside20);
            sumPointsEarned = NullBypassSum(sumPointsEarned, d[i].sumPointsEarned);
        }
        sortKey = (9999 - season) * 1000000 + season * 100 + 99; //XXXXYYYY99
        gameSeasonDateSSSSYYYYMMDD = season.toString() + season.toString() + " Total";
        puntAvgYards = NullDiv(puntYards, punts);
        puntAvgNetYards = NullDiv(netPuntYards, punts - blockedPunts);
        d.push({
            sortKey,
            season,
            playerName,
            playerId,
            positionName,
            team,
            matchup,
            games,
            gameDate,
            gameSeasonDateSSSSYYYYMMDD,
            gameResult,
            gameWeek,
            oppTeam,
            punts,
            puntYards,
            puntAvgYards,
            puntsReturned,
            returnYards,
            netPuntYards,
            puntAvgNetYards,
            long,
            blockedPunts,
            partialBlockedPunts,
            fCs,
            touchBacks,
            puntsInside20,
            sumPointsEarned
        });
        d.sort((a, b) => a.sortKey > b.sortKey ? 1 : -1); // corrects for new season total rows
    }
    
    componentDidMount() {
        this.ConsolidateData();
        let pagination = 'local';
        let paginationSize = 50;
        let maxPlayerSeason = this.data.length ? this.data[0].season : 0;
        pagination = null;
        paginationSize = 0;
        this.tabulator = new Tabulator(this.el, {
            data: this.data,
            reactiveData: true,
            columns: this.cols,
            layout: "fitColumns",  // added by Ruben 7/25/19
            rowFormatter: function (row) {
                var data = row.getData();
                if (data.gameSeasonDateSSSSYYYYMMDD.substr(9, 5) === 'Total') {
                    row.getElement().style.borderTop = "2px solid #6f88a8";
                    row.getElement().style.borderBottom = "2px solid #6f88a8";
                }
            },
            pagination: pagination,
            paginationSize: paginationSize,
            groupBy: function (row) {
                return row.gameSeasonDateSSSSYYYYMMDD.substr(0, 4);
            },
            groupHeader: function (groupKey, rowcount, data, group) {
                return groupKey + ' Season <span style="color:SlateBlue">&#9660</span>';
            },
            groupStartOpen: function (groupKey, rowcount, data, group) {
                return groupKey === maxPlayerSeason.toString();
            },
            groupToggleElement: "header",
            resizableColumns: false,
            selectable: false
        });
    }

    componentDidUpdate() {
        this.tabulator.replaceData(this.data);
    }

    render() {
        return (
            <React.Fragment>
                <div className="shadowdiv">
                    <div id="table-title">Punting</div>
                    <div ref={el => (this.el = el)} />
                </div>
            </React.Fragment>
        );
    }
}

export default GameLogKPPuntingTabulator;