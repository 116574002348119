import React from 'react';
import {NullSum, NullBypassSum, NullDiv, Tst2015, DispFunc} from '../common/CommonFunctions';
import { dedtSummary, lbSummary, cbsSummary } from '../common/TableColumnWidths';
import Tabulator from 'tabulator-tables';
import "tabulator-tables/dist/css/tabulator.min.css";
import Filters from '../common/Filters';

class TableDefenseSummaryTabulator extends React.Component {
    constructor(props) {
        super(props);
        this.defensestats = this.props.defensestats;
        this.isLeaderboards = this.props.isLeaderboards;
        this.minFilter = 1;
        this.data = [];
        this.sortable = false;
        this.bioCols = null;
        this.initSort = null;
        this.colNum = this.isLeaderboards ? 0 : 2;

        this.onSubmit = this.onSubmit.bind(this);

        // Custom sort function for players page so Total stays at the bottom of the table.
        this.sortFunc = (a, b, aRow, bRow, column, dir, sorterParams) => {
            var aData = aRow.getData();
            var bData = bRow.getData();
            if (aData.season === 'Total'){
                if (dir === 'desc'){
                    return -1;
                }
                else if (dir === 'asc'){
                    return 1
                }
            }
            else if (bData.season === 'Total'){
                if (dir === 'desc'){
                    return 1;
                }
                else if (dir === 'asc'){
                    return -1;
                }            
            }
            else {
                return a-b;
            }
        };

        // Set first group of columns
        // If this is a leaderboards page include player name, remove custom sorter and set initial sort to proper column.
        if (this.isLeaderboards){
            this.sortable = true;
            this.bioCols = [
                {
                    title: "Player", field: "player", cssClass: "single-row-header textColumn",
                    width: `${dedtSummary[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                    formatter: "link", formatterParams:{
                        labelField: "player",
                        urlPrefix:"/players/",
                        urlField: "playerId"
                    }
                },
                {
                    title: "Pos", field: "positionName", cssClass: "single-row-header textColumn small-column",
                    width: `${dedtSummary[this.colNum++][this.isLeaderboards ? 0 : 1]}`
                },
                {
                    title: "Year", field: "season", cssClass: "single-row-header textColumn",
                    width: `${dedtSummary[this.colNum++][this.isLeaderboards ? 0 : 1]}`
                },
                {
                    title: "Team", field: "team", cssClass: "single-row-header textColumn",
                    width: `${dedtSummary[this.colNum++][this.isLeaderboards ? 0 : 1]}`
                },
                {
                    title: "G", field: "games", cssClass: "single-row-header small-column",
                    width: `${dedtSummary[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                    headerSortStartingDir: "desc"
                },
                {
                    title: "GS", field: "starts", cssClass: "single-row-header small-column break",
                    width: `${dedtSummary[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                    headerSortStartingDir: "desc"
                }        
            ];
            this.sorterFunc = null;
            this.initSort = [
                { column: "pointsSaved", dir: "desc" }
            ];
        }
        // Else don't include player name and set initial sort to season.
        else{
            this.bioCols = [
                {
                    title: "Year", field: "season", cssClass: "single-row-header textColumn",
                    width: `${dedtSummary[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                    headerSort: this.sortable, sorter: this.sortFunc
                },
                {
                    title: "Team", field: "team", cssClass: "single-row-header textColumn",
                    width: `${dedtSummary[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                    headerSort: this.sortable, sorter: this.sortFunc
                },
                {
                    title: "G", field: "games", cssClass: "single-row-header small-column",
                    width: `${dedtSummary[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                    headerSortStartingDir: "desc", headerSort: this.sortable, sorter: this.sortFunc
                },
                {
                    title: "GS", field: "starts", cssClass: "single-row-header small-column break",
                    width: `${dedtSummary[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                    headerSortStartingDir: "desc", headerSort: this.sortable, sorter: this.sortFunc
                }        
            ];
            this.initSort = [
                { column: "season", dir: "asc" }
            ];
        }
        
        this.el = React.createRef();
        this.tabulator = null;
    }

    // Consolidate the data into one data array to be used for tabulator(called on component mount)
    ConsolidateData(){
        let data = [];
        let player = '';
        let playerId = 0;
        let season = '';
        let team = 0;
        let games = 0;
        let starts = 0;
        let targets = 0;
        let completions = 0;
        let completionPct = 0;
        let passYards = 0;
        let passYardsPerTgt = 0;
        let td = 0;
        let breakups = 0;
        let interceptions = 0;
        let droppedInts = 0;
        let tackles = 0;
        let tackleForLoss = 0;
        let forcedFumbles = 0;
        let pointsSaved = 0;
        let sacks = 0;
        let pressures = 0;
        let hurries = 0;
        let hits = 0;
        let knockdowns = 0;
        let passesBatted = 0;
        let pntretAttempts = 0;
        let pntretYds = 0;
        let pntretYdsPerAtt = 0;
        let pntretTD = 0;
        let kickretAttempts = 0;
        let kickretYds = 0;
        let kickretYdsPerAtt = 0;
        let kickretTD = 0;
            
        let defensestats = this.defensestats;
        if (defensestats.length !== 0){
            let positionName = this.defensestats[0].positionName;
            for (var i=0; i<defensestats.length; i++){
                if (!this.isLeaderboards || this.defensestats[i].games >= this.minFilter){
                    player = defensestats[i].player;
                    playerId = defensestats[i].playeridpr || defensestats[i].playeridrd || defensestats[i].playeridpd || defensestats[i].playeriddf;
                    positionName = this.defensestats[i].positionName;
                    season = defensestats[i].season;
                    team = defensestats[i].team;
                    games = defensestats[i].starts > defensestats[i].games ?
                        defensestats[i].starts :
                        defensestats[i].games;
                    starts = defensestats[i].starts;
                    targets = defensestats[i].targets;
                    completions = defensestats[i].completions;
                    completionPct = defensestats[i].completionPct;
                    passYards = defensestats[i].passYards;
                    passYardsPerTgt = defensestats[i].passYardsPerTgt;
                    td = defensestats[i].td;
                    breakups = defensestats[i].breakups;
                    interceptions = defensestats[i].interceptions;
                    droppedInts = defensestats[i].droppedInts;
                    tackles = defensestats[i].tackles;
                    tackleForLoss = defensestats[i].tackleForLoss;
                    forcedFumbles = defensestats[i].forcedFumbles;
                    sacks = defensestats[i].sacks;
                    pressures = defensestats[i].pressures;
                    hurries = defensestats[i].hurries;
                    hits = defensestats[i].hits;
                    knockdowns = defensestats[i].knockdowns;
                    passesBatted = defensestats[i].passesBatted;
                    pntretAttempts = defensestats[i].pntretAttempts;
                    pntretYds = defensestats[i].pntretYds;
                    pntretYdsPerAtt = defensestats[i].pntretYdsPerAtt;
                    pntretTD = defensestats[i].pntretTD;
                    kickretAttempts = defensestats[i].kickretAttempts;
                    kickretYds = defensestats[i].kickretYds;
                    kickretYdsPerAtt = defensestats[i].kickretYdsPerAtt;
                    kickretTD = defensestats[i].kickretTD;
                    pointsSaved = Tst2015(defensestats[i].season, NullSum(defensestats[i].pointsSavedPassDef,NullSum(defensestats[i].pointsSavedPassRush,defensestats[i].pointsSavedRushDef)))
                    data.push(
                        {
                            player,
                            playerId,
                            positionName,
                            season,
                            team,
                            games,
                            starts,
                            targets,
                            completions,
                            completionPct,
                            passYards,
                            passYardsPerTgt,
                            td,
                            breakups,
                            interceptions,
                            droppedInts,
                            tackles,
                            tackleForLoss,
                            forcedFumbles,
                            pointsSaved,
                            sacks,
                            pressures,
                            hurries,
                            hits,
                            knockdowns,
                            passesBatted,
                            pntretAttempts,
                            pntretYds,
                            pntretYdsPerAtt,
                            pntretTD,
                            kickretAttempts,
                            kickretYds,
                            kickretYdsPerAtt,
                            kickretTD
                        }
                    );
                }
            }
            this.data = data;
        }
    }

    // Add totals row to data.(called on component mount if this is the players page.)
    SumData() {
        let season = 'Total';
        let team = '';
        let assistedTackles = 0;
        let batDowns = 0;
        let breakups = 0;
        let brokenTackles = 0;
        let completionPct = 0;
        let completions = 0;
        let defensed = 0;
        let deflected = 0;
        let droppedInts = 0;
        let forcedFumbles = 0;
        let games = 0;
        let hits = 0;
        let hurries = 0;
        let interceptions = 0;
        let knockdowns = 0;
        let passesBatted = 0;
        let passRushes = 0;
        let passRushSacks = 0;
        let passSnaps = 0;
        let pointsSaved = 0;
        let pressures = 0;
        let rushsnaps = 0;
        let sacks = 0;
        let soloTackles = 0;
        let starts = 0;
        let tackleForLoss = 0;
        let tackles = 0;
        let targets = 0;
        let td = 0;
        let passYards = 0;
        let passYardsPerTgt = 0;
        let pntretAttempts = 0;
        let pntretYds = 0;
        let pntretYdsPerAtt = 0;
        let pntretTD = 0;
        let kickretAttempts = 0;
        let kickretYds = 0;
        let kickretYdsPerAtt = 0;
        let kickretTD = 0;
        if (this.defensestats.length !== 0){
            for (var i = 0; i < this.defensestats.length; i++) {
                assistedTackles = NullBypassSum(assistedTackles,this.defensestats[i].assistedTackles);
                batDowns = NullBypassSum(batDowns,this.defensestats[i].batDowns);
                breakups = NullBypassSum(breakups,this.defensestats[i].breakups);
                brokenTackles = NullBypassSum(brokenTackles,this.defensestats[i].brokenTackles);
                completions = NullBypassSum(completions,this.defensestats[i].completions);
                defensed = NullBypassSum(defensed,this.defensestats[i].defensed);
                deflected = NullBypassSum(deflected,this.defensestats[i].deflected);
                droppedInts = NullBypassSum(droppedInts,this.defensestats[i].droppedInts);
                forcedFumbles = NullBypassSum(forcedFumbles,this.defensestats[i].forcedFumbles);
                games = NullBypassSum(games,this.defensestats[i].games);
                hits = NullBypassSum(hits,this.defensestats[i].hits);
                hurries = NullBypassSum(hurries,this.defensestats[i].hurries);
                interceptions = NullBypassSum(interceptions,this.defensestats[i].interceptions);
                knockdowns = NullBypassSum(knockdowns,this.defensestats[i].knockdowns);
                passesBatted = NullBypassSum(passesBatted,this.defensestats[i].passesBatted);
                passRushes = NullBypassSum(passRushes,this.defensestats[i].passRushes);
                passRushSacks = NullBypassSum(passRushSacks,this.defensestats[i].passRushSacks);
                passSnaps = NullBypassSum(passSnaps,this.defensestats[i].passSnaps);
                pointsSaved = NullBypassSum(pointsSaved,Tst2015(this.defensestats[i].season,
                    NullBypassSum(this.defensestats[i].pointsSavedPassDef,NullBypassSum(this.defensestats[i].pointsSavedPassRush,this.defensestats[i].pointsSavedRushDef))));
                pressures = NullBypassSum(pressures,this.defensestats[i].pressures);
                rushsnaps = NullBypassSum(rushsnaps,this.defensestats[i].rushsnaps);
                sacks = NullBypassSum(sacks,this.defensestats[i].sacks);
                soloTackles = NullBypassSum(soloTackles,this.defensestats[i].soloTackles);
                starts = NullBypassSum(starts,this.defensestats[i].starts);
                tackleForLoss = NullBypassSum(tackleForLoss,this.defensestats[i].tackleForLoss);
                tackles = NullBypassSum(tackles,this.defensestats[i].tackles);
                targets = NullBypassSum(targets,this.defensestats[i].targets);
                td = NullBypassSum(td,this.defensestats[i].td);
                passYards = NullBypassSum(passYards,this.defensestats[i].passYards);
                pntretAttempts = NullBypassSum(pntretAttempts, this.defensestats[i].pntretAttempts);
                pntretYds = NullBypassSum(pntretYds, this.defensestats[i].pntretYds);
                pntretTD = NullBypassSum(pntretTD, this.defensestats[i].pntretTD);
                kickretAttempts = NullBypassSum(kickretAttempts, this.defensestats[i].kickretAttempts);
                kickretYds = NullBypassSum(kickretYds, this.defensestats[i].kickretYds);
                kickretTD = NullBypassSum(kickretTD, this.defensestats[i].kickretTD);
            }
            completionPct = NullDiv(completions,targets)*100;
            passYardsPerTgt = NullDiv(passYards,targets);
            pntretYdsPerAtt = NullDiv(pntretYds,pntretAttempts);
            kickretYdsPerAtt = NullDiv(kickretYds,kickretAttempts);
    
            this.data.push(
                {
                    season,
                    team,
                    games,
                    starts,
                    targets,
                    completions,
                    completionPct,
                    passYards,
                    passYardsPerTgt,
                    td,
                    breakups,
                    interceptions,
                    droppedInts,
                    tackles,
                    tackleForLoss,
                    forcedFumbles,
                    pointsSaved,
                    sacks,
                    pressures,
                    hurries,
                    hits,
                    knockdowns,
                    passesBatted,
                    pntretAttempts,
                    pntretYds,
                    pntretYdsPerAtt,
                    pntretTD,
                    kickretAttempts,
                    kickretYds,
                    kickretYdsPerAtt,
                    kickretTD
            }
            );
        }
    }
    
    componentDidMount() {
        this.ConsolidateData();
        let pagination = 'local';
        let paginationSize = 50;
        if (!this.isLeaderboards){
            this.SumData();
            pagination = null;
            paginationSize = 0;
        }
        if (this.defensestats[0]){
            switch (this.defensestats[0].positionName){
                case 'CB':
                case 'S':
                    this.cols = [
                        {
                            title: "",
                            columns: this.bioCols,
                        },
                        {
                            title: "Coverage",
                            columns: [
                                {
                                    title: "Tgtd", field:"targets", cssClass: "single-row-header",
                                    headerSortStartingDir: "desc", headerSort: this.sortable, sorter: this.sortFunc,
                                    width: `${cbsSummary[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                                    formatter: function(cell, formatterParams, onRendered){
                                        return DispFunc(cell, formatterParams, onRendered, 0, false, false);
                                    }
                                },
                                {
                                    title: "Cmp", field: "completions", cssClass: "single-row-header small-column",
                                    headerSortStartingDir: "desc", headerSort: this.sortable, sorter: this.sortFunc,
                                    width: `${cbsSummary[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                                    formatter: function(cell, formatterParams, onRendered){
                                        return DispFunc(cell, formatterParams, onRendered, 0, false, false);
                                    }
                                },
                                {
                                    title: "Cmp%", field: "completionPct", cssClass: "single-row-header",
                                    headerSortStartingDir: "desc", headerSort: this.sortable, sorter: this.sortFunc,
                                    width: `${cbsSummary[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                                    formatter: function(cell, formatterParams, onRendered){
                                        return DispFunc(cell, formatterParams, onRendered, 1, false, false);
                                    }
                                },
                                {
                                    title: "Yards", field: "passYards", cssClass: "single-row-header",
                                    headerSortStartingDir: "desc", headerSort: this.sortable, sorter: this.sortFunc,
                                    width: `${cbsSummary[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                                    formatter: function(cell, formatterParams, onRendered){
                                        return DispFunc(cell, formatterParams, onRendered, 0, true, false);
                                    }
                                },
                                {
                                    title: "Yds/ Target", field: "passYardsPerTgt", cssClass: "single-row-header",
                                    headerSortStartingDir: "desc", headerSort: this.sortable, sorter: this.sortFunc,
                                    titleFormatter: "textarea",
                                    width: `${cbsSummary[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                                    formatter: function(cell, formatterParams, onRendered){
                                        return DispFunc(cell, formatterParams, onRendered, 1, false, false);
                                    }
                                },
                                {
                                    title: "TD Allwd", field: "td", titleFormatter: "textarea", cssClass: "single-row-header small-column",
                                    headerSortStartingDir: "desc", headerSort: this.sortable, sorter: this.sortFunc,
                                    width: `${cbsSummary[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                                    formatter: function(cell, formatterParams, onRendered){
                                        return DispFunc(cell, formatterParams, onRendered, 0, false, false);
                                    }
                                },
                                {
                                    title: "Pass Brkps", field: "breakups", cssClass: "single-row-header",
                                    headerSortStartingDir: "desc", headerSort: this.sortable, sorter: this.sortFunc,
                                    titleFormatter: "textarea",
                                    width: `${cbsSummary[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                                    formatter: function(cell, formatterParams, onRendered){
                                        return DispFunc(cell, formatterParams, onRendered, 0, false, false);
                                    }
                                },
                                {
                                    title: "Int", field: "interceptions", cssClass: "single-row-header small-column",
                                    headerSortStartingDir: "desc", headerSort: this.sortable, sorter: this.sortFunc,
                                    width: `${cbsSummary[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                                    formatter: function(cell, formatterParams, onRendered){
                                        return DispFunc(cell, formatterParams, onRendered, 0, false, false);
                                    }
                                },
                                {
                                    title: "Int Drops", field: "droppedInts", cssClass: "single-row-header break",
                                    headerSortStartingDir: "desc", headerSort: this.sortable, sorter: this.sortFunc,
                                    titleFormatter: "textarea",
                                    width: `${cbsSummary[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                                    formatter: function(cell, formatterParams, onRendered){
                                        return DispFunc(cell, formatterParams, onRendered, 0, false, false);
                                    }
                                }
                            ]
                        },
                        {
                            title: "Tkls (exc spl tms)",
                            columns:[
                                {
                                    title: "Tackles", field: "tackles",
                                    headerSortStartingDir: "desc", headerSort: this.sortable, sorter: this.sortFunc,
                                    cssClass: "single-row-header",
                                    width: `${cbsSummary[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                                    formatter: function(cell, formatterParams, onRendered){
                                        return DispFunc(cell, formatterParams, onRendered, 0, false, false);
                                    }
                                },
                                {
                                    title: "TFL", field: "tackleForLoss",
                                    headerSortStartingDir: "desc", headerSort: this.sortable, sorter: this.sortFunc,
                                    cssClass: "single-row-header small-column",
                                    width: `${cbsSummary[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                                    formatter: function(cell, formatterParams, onRendered){
                                        return DispFunc(cell, formatterParams, onRendered, 0, false, false);
                                    }
                                },
                                {
                                    title: "FF", field: "forcedFumbles", cssClass: "single-row-header small-column break",
                                    headerSortStartingDir: "desc", headerSort: this.sortable, sorter: this.sortFunc,
                                    width: `${cbsSummary[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                                    formatter: function(cell, formatterParams, onRendered){
                                        return DispFunc(cell, formatterParams, onRendered, 0, false, false);
                                    }
                                }        
                            ],
                        },
                        {
                            title: "Punt Returns",
                            columns: [
                                {
                                    title: "Num", field: "pntretAttempts", cssClass: "single-row-header small-column",
                                    width: `${cbsSummary[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                                    headerSortStartingDir: "desc", headerSort: this.sortable, sorter: this.sortFunc
                                },
                                {
                                    title: "Yards", field: "pntretYds", cssClass: "single-row-header",
                                    width: `${cbsSummary[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                                    headerSortStartingDir: "desc", headerSort: this.sortable, sorter: this.sortFunc,
                                    formatter: "money", formatterParams: {
                                        thousand: ',',
                                        precision: 0
                                    }
                                },
                                {
                                    title: "Avg", field: "pntretYdsPerAtt", cssClass: "single-row-header",
                                    width: `${cbsSummary[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                                    headerSortStartingDir: "desc", headerSort: this.sortable, sorter: this.sortFunc,
                                    formatter: "money", formatterParams: {
                                        precision: 1
                                    }
                                },
                                {
                                    title: "TD", field: "pntretTD", cssClass: "single-row-header break small-column",
                                    width: `${cbsSummary[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                                    headerSortStartingDir: "desc", headerSort: this.sortable, sorter: this.sortFunc
                                }
                            ],
                        },
                        {
                            title: "Kickoff Returns",
                            columns: [
                                {
                                    title: "Num", field: "kickretAttempts", cssClass: "single-row-header small-column",
                                    width: `${cbsSummary[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                                    headerSortStartingDir: "desc", headerSort: this.sortable, sorter: this.sortFunc
                                },
                                {
                                    title: "Yards", field: "kickretYds", cssClass: "single-row-header",
                                    width: `${cbsSummary[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                                    headerSortStartingDir: "desc", headerSort: this.sortable, sorter: this.sortFunc,
                                    formatter: "money", formatterParams: {
                                        thousand: ',',
                                        precision: 0
                                    }
                                },
                                {
                                    title: "Avg", field: "kickretYdsPerAtt", cssClass: "single-row-header",
                                    width: `${cbsSummary[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                                    headerSortStartingDir: "desc", headerSort: this.sortable, sorter: this.sortFunc,
                                    formatter: "money", formatterParams: {
                                        precision: 1
                                    }
                                },
                                {
                                    title: "TD", field: "kickretTD", cssClass: "single-row-header break small-column",
                                    width: `${cbsSummary[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                                    headerSortStartingDir: "desc", headerSort: this.sortable, sorter: this.sortFunc
                                },
                            ],
                        },
                                    {
                            title: "Tot Pts Saved", field: "pointsSaved", cssClass: "single-row-header",
                            headerSortStartingDir: "desc", headerSort: this.sortable, sorter: this.sortFunc,
                            titleFormatter:"textarea",
                            width: `${cbsSummary[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                            formatter: function(cell, formatterParams, onRendered){
                                return DispFunc(cell, formatterParams, onRendered, 0, false, false);
                            }
                        }
                    ];
                    break;
                case 'LB':
                    this.cols = [
                        {
                            title: "",
                            columns: this.bioCols,
                        },
                        {
                            title: "Tackling (excl. special teams)",
                            columns:[
                                {
                                    title: "Tackles", field: "tackles", cssClass: "single-row-header",
                                    headerSortStartingDir: "desc", headerSort: this.sortable, sorter: this.sortFunc,
                                    width: `${lbSummary[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                                    formatter: function(cell, formatterParams, onRendered){
                                        return DispFunc(cell, formatterParams, onRendered, 0, false, false);
                                    }
                                },
                                {
                                    title: "TFL", field: "tackleForLoss", cssClass: "single-row-header",
                                    headerSortStartingDir: "desc", headerSort: this.sortable, sorter: this.sortFunc,
                                    width: `${lbSummary[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                                    formatter: function(cell, formatterParams, onRendered){
                                        return DispFunc(cell, formatterParams, onRendered, 0, false, false);
                                    }
                                },
                                {
                                    title: "FF", field: "forcedFumbles", cssClass: "single-row-header break",
                                    headerSortStartingDir: "desc", headerSort: this.sortable, sorter: this.sortFunc,
                                    width: `${lbSummary[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                                    formatter: function(cell, formatterParams, onRendered){
                                        return DispFunc(cell, formatterParams, onRendered, 0, false, false);
                                    }
                                }        
                            ],
                        },
                        {
                            title: "Pass Rush",
                            columns:[
                                {
                                    title: "Sacks", field: "sacks", cssClass: "single-row-header",
                                    headerSortStartingDir: "desc", headerSort: this.sortable, sorter: this.sortFunc,
                                    width: `${lbSummary[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                                    formatter: function(cell, formatterParams, onRendered){
                                        return DispFunc(cell, formatterParams, onRendered, 1, false, false);
                                    }
                                },
                                {
                                    title: "Pressures", field: "pressures", cssClass: "single-row-header break",
                                    headerSortStartingDir: "desc", headerSort: this.sortable, sorter: this.sortFunc,
                                    width: `${lbSummary[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                                    formatter: function(cell, formatterParams, onRendered){
                                        return DispFunc(cell, formatterParams, onRendered, 0, false, false);
                                    }
                                }
                            ],
                        },
                        {
                            title: "Coverage",
                            columns: [
                                {
                                    title: "Targeted", field:"targets", cssClass: "single-row-header",
                                    headerSortStartingDir: "desc", headerSort: this.sortable, sorter: this.sortFunc,
                                    width: `${lbSummary[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                                    formatter: function(cell, formatterParams, onRendered){
                                        return DispFunc(cell, formatterParams, onRendered, 0, false, false);
                                    }
                                },
                                {
                                    title: "Cmp", field:"completions", cssClass: "single-row-header",
                                    headerSortStartingDir: "desc", headerSort: this.sortable, sorter: this.sortFunc,
                                    width: `${lbSummary[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                                    formatter: function(cell, formatterParams, onRendered){
                                        return DispFunc(cell, formatterParams, onRendered, 0, false, false);
                                    }
                                },
                                {
                                    title: "Cmp%", field:"completionPct", cssClass: "single-row-header",
                                    headerSortStartingDir: "desc", headerSort: this.sortable, sorter: this.sortFunc,
                                    width: `${lbSummary[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                                    formatter: function(cell, formatterParams, onRendered){
                                        return DispFunc(cell, formatterParams, onRendered, 1, false, false);
                                    }
                                },
                                {
                                    title: "Yards", field:"passYards", cssClass: "single-row-header",
                                    headerSortStartingDir: "desc", headerSort: this.sortable, sorter: this.sortFunc,
                                    width: `${lbSummary[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                                    formatter: function(cell, formatterParams, onRendered){
                                        return DispFunc(cell, formatterParams, onRendered, 0, false, false);
                                    }
                                },
                                {
                                    title: "Yds/Trgt", field:"passYardsPerTgt", cssClass: "single-row-header",
                                    headerSortStartingDir: "desc", headerSort: this.sortable, sorter: this.sortFunc,
                                    width: `${lbSummary[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                                    formatter: function(cell, formatterParams, onRendered){
                                        return DispFunc(cell, formatterParams, onRendered, 1, false, false);
                                    }
                                },
                                {
                                    title: "Int", field:"interceptions", cssClass: "single-row-header break",
                                    headerSortStartingDir: "desc", headerSort: this.sortable, sorter: this.sortFunc,
                                    width: `${lbSummary[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                                    formatter: function(cell, formatterParams, onRendered){
                                        return DispFunc(cell, formatterParams, onRendered, 0, false, false);
                                    }
                                },
                            ]
                        },
                        {
                            title: "Tot Points Saved", field: "pointsSaved",
                            headerSortStartingDir: "desc", headerSort: this.sortable, sorter: this.sortFunc,
                            titleFormatter:"textarea",
                            width: `${lbSummary[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                            formatter: function(cell, formatterParams, onRendered){
                                return DispFunc(cell, formatterParams, onRendered, 0, false, false);
                            }
                        }
                    ];
                    break;
                default: // DE, DT
                    this.cols = [
                        {
                            title: "",
                            columns: this.bioCols,
                        },
                        {
                            title: "Tackling (excl. special teams)",
                            columns:[
                                {
                                    title: "Tackles", field: "tackles", cssClass: "single-row-header",
                                    headerSortStartingDir: "desc", headerSort: this.sortable, sorter: this.sortFunc,
                                    width: `${dedtSummary[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                                    formatter: function(cell, formatterParams, onRendered){
                                        return DispFunc(cell, formatterParams, onRendered, 0, false, false);
                                    }
                                },
                                {
                                    title: "TFL", field: "tackleForLoss", cssClass: "single-row-header",
                                    headerSortStartingDir: "desc", headerSort: this.sortable, sorter: this.sortFunc,
                                    width: `${dedtSummary[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                                    formatter: function(cell, formatterParams, onRendered){
                                        return DispFunc(cell, formatterParams, onRendered, 0, false, false);
                                    }
                                },
                                {
                                    title: "FF", field: "forcedFumbles", cssClass: "single-row-header break",
                                    headerSortStartingDir: "desc", headerSort: this.sortable, sorter: this.sortFunc,
                                    width: `${dedtSummary[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                                    formatter: function(cell, formatterParams, onRendered){
                                        return DispFunc(cell, formatterParams, onRendered, 0, false, false);
                                    }
                                }        
                            ],
                        },
                        {
                            title: "Pass Rush",
                            columns:[
                                {
                                    title: "Sacks", field: "sacks",cssClass: "single-row-header",
                                    headerSortStartingDir: "desc", headerSort: this.sortable, sorter: this.sortFunc, 
                                    width: `${dedtSummary[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                                    formatter: function(cell, formatterParams, onRendered){
                                        return DispFunc(cell, formatterParams, onRendered, 1, false, false);
                                    }
                                },
                                {
                                    title: "Hurries", field: "hurries",cssClass: "single-row-header",
                                    headerSortStartingDir: "desc", headerSort: this.sortable, sorter: this.sortFunc,
                                    width: `${dedtSummary[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                                    formatter: function(cell, formatterParams, onRendered){
                                        return DispFunc(cell, formatterParams, onRendered, 0, false, false);
                                    }
                                },
                                {
                                    title: "Hits", field: "hits",cssClass: "single-row-header",
                                    headerSortStartingDir: "desc", headerSort: this.sortable, sorter: this.sortFunc,
                                    width: `${dedtSummary[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                                    formatter: function(cell, formatterParams, onRendered){
                                        return DispFunc(cell, formatterParams, onRendered, 0, false, false);
                                    }
                                },
                                {
                                    title: "Knockdowns", field: "knockdowns",cssClass: "single-row-header",
                                    headerSortStartingDir: "desc", headerSort: this.sortable, sorter: this.sortFunc,
                                    width: `${dedtSummary[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                                    formatter: function(cell, formatterParams, onRendered){
                                        return DispFunc(cell, formatterParams, onRendered, 0, false, false);
                                    }
                                },
                                {
                                    title: "Pressures", field: "pressures",cssClass: "single-row-header",
                                    headerSortStartingDir: "desc", headerSort: this.sortable, sorter: this.sortFunc,
                                    width: `${dedtSummary[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                                    formatter: function(cell, formatterParams, onRendered){
                                        return DispFunc(cell, formatterParams, onRendered, 0, false, false);
                                    }
                                },
                                {
                                    title: "Passes Batted", field: "passesBatted", cssClass: "single-row-header break",
                                    headerSortStartingDir: "desc", headerSort: this.sortable, sorter: this.sortFunc,
                                    width: `${dedtSummary[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                                    formatter: function(cell, formatterParams, onRendered){
                                        return DispFunc(cell, formatterParams, onRendered, 0, false, false);
                                    }
                                }
                            ],
                        },
                        {
                            title: "Tot Points Saved", field: "pointsSaved",
                            headerSortStartingDir: "desc", headerSort: this.sortable, sorter: this.sortFunc,
                            titleFormatter:"textarea",
                            width: `${dedtSummary[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                            formatter: function(cell, formatterParams, onRendered){
                                return DispFunc(cell, formatterParams, onRendered, 0, false, false);
                            }
                        }
                    ];
                    break;
            }
            this.tabulator = new Tabulator(this.el, {
                data: this.data,
                reactiveData: true,
                columns: this.cols,
                layout: "fitColumns",  // added by Ruben 7/25/19
                rowFormatter:function(row){
                    var data = row.getData();
                    if (data.season === 'Total'){
                        row.getElement().style.borderTop = "2px solid #6f88a8";
                        row.getElement().style.borderBottom = "2px solid #6f88a8";
                    }
                },
                pagination: pagination,
                paginationSize: paginationSize,
                initialSort: this.initSort,
                resizableColumns: false,
                selectable: false
            });    
        }
        
    }

    componentDidUpdate() {
        this.tabulator.replaceData(this.data);
    }

    onSubmit(e, minFilter){
        this.minFilter = minFilter;
        this.ConsolidateData();
        this.forceUpdate();
    }

    render() {
        return (
            <React.Fragment>
                {
                    this.isLeaderboards &&
                    <div className="shadowdiv" id="filtersDiv">
                        <Filters minFilter={this.minFilter} onSubmit={this.onSubmit} label={"Games"} />
                        <div ref={el => (this.el = el)} />
                    </div>
                }
                {
                    !this.isLeaderboards &&
                    <div className="shadowdiv">
                        <div id="table-title">Summary</div>
                        <div ref={el => (this.el = el)} />
                    </div>
                }
            </React.Fragment>
        );
    }
}

export default TableDefenseSummaryTabulator;
