// TableColumnWidths
// First value is for leaderboards
// Second column for player pages
// This is used in each tabulator module when creating the data array
// 
export var qbSummary = [
    ["108", "0"],   // Player
    ["40", "40"],   // Year
    ["75", "76"],   // Team
    ["33", "33"],   // G
    ["33", "33"],   // GS
    ["40", "50"],   // Passing Att
    ["40", "50"],   // Passing Cmp
    ["40", "48"],   // Passing Pct
    ["54", "66"],   // Passing Yds
    ["50", "50"],   // Passing Yds/Att
    ["50", "50"],   // Passing Y/G
    ["40", "44"],   // Passing Lg
    ["40", "44"],   // Passing TD
    ["40", "44"],   // Passing Int
    ["40", "50"],   // Rushing Att
    ["44", "66"],   // Rushing Yds
    ["40", "44"],   // Rushing Avg
    ["40", "45"],   // Rushing Lg
    ["40", "45"],   // Rushing TD
    ["40", "45"],   // Fumbles Fum
    ["40", "45"],   // Fumbles Lost
    ["60", "65"],   // QB Rating
    ["51", "45"],   // IQR
    ["65", "65"]    // Total Points Earned
];

export var qbAdvPassing = [
    ["119", "0"],   // Player
    ["40", "40"],   // Year
    ["70", "63"],   // Team
    ["50", "58"],   // Dropbacks
    ["50", "56"],   // Sacks
    ["40", "46"],   // Sack Yards
    ["50", "56"],   // Sack %
    ["52", "58"],   // TD%
    ["51", "57"],   // Int %
    ["44", "56"],   // On Target
    ["59", "62"],   // Catchable
    ["50", "57"],   // On Target %
    ["76", "80"],   // Catchable %
    ["65", "71"],   // Completed Air Yards
    ["75", "81"],   // Yards After Catch
    ["50", "65"],   // Air Yards%
    ["68", "69"],   // Intended Air Yards
    ["78", "78"],   // Average Throw Depth
    ["55", "89"]    // Passing Points Earned
];

export var qbEffectiveness = [
    ["110", "0"],   // Player
    ["42", "40"],   // Year
    ["89", "105"],  // Team
    ["33", "33"],   // Behind LOS Attempts
    ["33", "33"],   // Behind LOS Completions
    ["40", "46"],   // Behind LOS IQR
    ["40", "46"],   // Behind LOS Attempts Left %
    ["40", "46"],   // Behind LOS Attempts Middle %
    ["40", "46"],   // Behind LOS Attempts Right %
    ["33", "33"],   // Short Attempts
    ["33", "33"],   // Short Completions
    ["40", "46"],   // Short IQR
    ["40", "46"],   // Short Attempts Left %
    ["40", "46"],   // Short Attempts Middle %
    ["40", "46"],   // Short Attempts Right %
    ["33", "33"],   // Intermediate Attempts
    ["33", "33"],   // Intermediate Completiong
    ["40", "46"],   // Intermediate IQR
    ["40", "46"],   // Intermediate Attempts Left %
    ["40", "46"],   // Intermediate Attempts Middle %
    ["40", "46"],   // Intermediate Attempts Right %
    ["33", "33"],   // Deep Attempts
    ["33", "33"],   // Deep Completions
    ["40", "46"],   // Deep IQR
    ["40", "46"],   // Deep Attempts Left %
    ["40", "46"],   // Deep Attempts Middle %
    ["40", "46"],   // Deep Attempts Right %
];

export var qbRushing = [
    ["120", "0"],   // Player
    ["40", "40"],   // Year
    ["88", "105"],  // Team
    ["68", "76"],   // First Downs Total
    ["68", "76"],   // First Downs Pct
    ["68", "76"],   // Inside Att
    ["68", "76"],   // Inside Avg
    ["68", "76"],   // Off Tackle Att
    ["68", "76"],   // Off Tackle Avg
    ["68", "76"],   // Outside Att
    ["68", "76"],   // Outside Avg
    ["68", "76"],   // Yards After Contact Yards
    ["68", "76"],   // Yards After Contact Avg
    ["68", "76"],   // Broken Tackles Total
    ["68", "76"],   // Broken Tackles Pct
    ["80", "87"]    // Rushing Points Earned
];

export var rbfbSummary = [
    ["93", "0"],    // Player
    ["33", "0"],    // Position
    ["40", "73"],   // Year
    ["58", "85"],   // Team
    ["33", "33"],   // G
    ["33", "33"],   // GS
    ["33", "33"],   // Rushing Att
    ["44", "52"],   // Rushing Yds
    ["40", "40"],   // Rushing Avg
    ["33", "33"],   // Rushing Lg
    ["33", "33"],   // Rushing TD
    ["40", "50"],   // Rushing Y/G
    ["33", "33"],   // Receiving Rec
    ["44", "52"],   // Receiving Yds
    ["40", "40"],   // Receiving Avg
    ["33", "33"],   // Receiving Lg
    ["33", "33"],   // Receiving TD
    ["45", "50"],   // Receiving Y/G
    ["33", "33"],   // Punt Returns Num
    ["40", "47"],   // Punt Returns Yds
    ["40", "40"],   // Punt Returns Avg
    ["33", "33"],   // Punt Returns TD
    ["33", "33"],   // Kickoff Returns Num
    ["33", "48"],   // Kickoff Returns Yds
    ["40", "40"],   // Kickoff Returns Avg
    ["33", "33"],   // Kickoff Returns TD
    ["33", "33"],   // Fumbles Fum 
    ["33", "33"],   // Fumbles Lost 
    ["43", "64"]    // Total Points Earned
];

export var advRushing = [
    ["125", "0"],   // Player
    ["59", "0"],    // Pos
    ["59", "73"],   // Year
    ["91", "98"],   // Team
    ["60", "74"],   // First Downs Total
    ["60", "74"],   // First Downs Pct
    ["60", "74"],   // Inside Att
    ["60", "74"],   // Inside Avg
    ["60", "74"],   // Off Tackle Att
    ["60", "74"],   // Off Tackle Avg
    ["60", "74"],   // Outside Att
    ["60", "74"],   // Outside Avg
    ["60", "74"],   // Yards After Contact Yards
    ["60", "74"],   // Yards After Contact Avg
    ["60", "74"],   // Broken Tackles Total
    ["60", "74"],   // Broken Tackles Pct
    ["90", "85"]    // Rushing Points Earned
];;

export var advReceiving = [
    ["120", "0"],   // Player
    ["40", "0"],    // Pos
    ["40", "73"],   // Year
    ["88", "89"],   // Team
    ["40", "60"],   // Targets All
    ["40", "60"],   // Targets Rec
    ["48", "60"],   // Targets Rec %
    ["45", "60"],   // Targets Share %
    ["60", "65"],   // Targets Intended Air Yards
    ["55", "60"],   // Targets Avg Depth
    ["41", "60"],   // Catchable All
    ["46", "62"],   // Catchable Catch %
    ["62", "65"],   // Completed Air Yards
    ["59", "65"],   // Yards After Catch
    ["57", "55"],   // YAC per Rec
    ["40", "50"],   // Drops
    ["50", "50"],   // Drop %
    ["45", "45"],   // First Downs
    ["50", "50"],   // First Down %
    ["60", "60"],   // Receiver Rating
    ["59", "56"]    // Receiving Points Earned
];

export var cgtSummary = [
    ["125", "0"],   // Player
    ["57", "0"],    // Pos
    ["57", "71"],   // Year
    ["98", "100"],  // Team
    ["53", "71"],   // G
    ["54", "71"],   // GS
    ["70", "71"],   // Total Snaps
    ["58", "74"],   // Blown Blocks Total
    ["58", "74"],   // Blown Blocks Pass
    ["58", "74"],   // Blown Blocks Rush
    ["58", "74"],   // Blown Blocks Pct
    ["58", "74"],   // Penalties Total
    ["58", "74"],   // Penalties Holding
    ["80", "84"],   // Penalties False Starts
    ["58", "74"],   // Penalties Other
    ["66", "80"],   // Penalties Pct
    ["75", "75"]    // Total Points Earned
];

export var passBlocking = [
    ["125", "0"],   // Player
    ["76", "0"],    // Pos
    ["76", "90"],   // Year
    ["95", "100"],  // Team
    ["66", "66"],   // Passing Snaps
    ["77", "99"],   // Blown Blocks Total
    ["77", "99"],   // Blown Blocks Pct
    ["77", "99"],   // Penalties Total
    ["77", "99"],   // Penalties Holding
    ["77", "99"],   // Penalties Other
    ["77", "99"],   // Penalties Pct
    ["77", "99"],   // Opp Sacks Total
    ["83", "103"],  // Opp Sacks Pct
    ["82", "90"]    // Pass Block Points Earned
];

export var runBlocking = [
    ["125", "0"],   // Player
    ["76", "0"],    // Pos
    ["76", "90"],   // Year
    ["95", "100"],  // Team
    ["66", "66"],   // Rushing Snaps
    ["77", "99"],   // Blown Blocks Total
    ["77", "99"],   // Blown Blocks Pct
    ["77", "99"],   // Penalties Total
    ["77", "99"],   // Penalties Holding
    ["77", "99"],   // Penalties Other
    ["77", "99"],   // Penalties Pct
    ["77", "99"],   // Opp Sacks Total
    ["83", "103"],  // Opp Sacks Pct
    ["82", "90"]    // Run Block Points Earned
];

export var dedtSummary = [
    ["119", "0"],   // Player
    ["33", "0"],    // Pos
    ["40", "60"],   // Year
    ["70", "80"],   // Team
    ["33", "33"],   // G
    ["33", "33"],   // GS
    ["80", "95"],   // Taclkes Tackles
    ["80", "95"],   // Tackles TFL
    ["80", "95"],   // Tackles FF
    ["80", "95"],   // Pass Rush Sacks
    ["80", "95"],   // Pass Rush Hurries
    ["80", "94"],   // Pass Rush Hits
    ["90", "94"],   // Pass Rush Knockdowns
    ["80", "94"],   // Pass Rush Preassures
    ["92", "99"],   // Pass Rush Passes Batted
    ["70", "78"]    // Total Points Saved
];

export var lbSummary = [
    ["0", "0"],     // Player
    ["0", "0"],     // Pos
    ["0", "0"],     // Year
    ["0", "0"],     // Team
    ["0", "0"],     // G
    ["0", "0"],     // GS
    ["67", "78"],   // Tackling Tackles
    ["67", "78"],   // Tackling TFL
    ["67", "78"],   // Tackling FF
    ["71", "78"],   // Pass Rush Sacks
    ["72", "78"],   // Pass Rush Preassures
    ["67", "78"],   // Coverage Targeted
    ["67", "78"],   // Coverage Cmp
    ["67", "78"],   // Coverage Cmp%
    ["67", "78"],   // Coverage Yards
    ["67", "78"],   // Coverage Yds/Trgt
    ["67", "78"],   // Coverage Int
    ["67", "77"]    // Total Points Saved
];

export var cbsSummary = [
    ["0", "0"],     // Player
    ["0", "0"],     // Pos
    ["0", "0"],     // Year
    ["0", "0"],     // Team
    ["0", "0"],     // G
    ["0", "0"],     // GS
    ["41", "44"],   // Coverage Targetted
    ["33", "33"],   // CoverageCmp
    ["42", "56"],   // CoverageCmp%
    ["44", "46"],   // CoverageYards
    ["50", "60"],   // Coverage Yds/Tgt
    ["33", "33"],   // Coverage TD Allowed
    ["48", "60"],   // Coverage Pass Breakups
    ["33", "33"],   // Coverage Int
    ["42", "60"],   // Coverage Int Drops
    ["46", "53"],   // Tackling Tackles
    ["33", "33"],   // Tackling TFL
    ["33", "33"],   // Tackling FF
    ["33", "33"],   // Punt Ret Attempts
    ["40", "60"],   // Punt Ret Yards
    ["40", "40"],   // Punt Ret Yards per Attempt
    ["33", "33"],   // Punt Ret TD
    ["33", "33"],   // Kick Ret Attempts
    ["40", "60"],   // Kick Ret Yards
    ["40", "40"],   // Kick Ret Yards per Attempt
    ["33", "33"],   // Kick Ret TD
    ["44", "60"]    // Total Points Saved
];

export var advDefense = [
    ["125", "0"],   // Player
    ["51", "0"],    // Pos
    ["51", "64"],   // Year
    ["80", "80"],   // Team
    ["51", "66"],   // Snaps Pass
    ["51", "66"],   // Snaps Run
    ["70", "80"],   // Deserved Catch %
    ["60", "70"],   // Pass Breakup Defensed
    ["60", "70"],   // Pass Breakup Deflected
    ["60", "70"],   // Pass Breakup Batted
    ["60", "70"],   // Pass Breakup Total
    ["51", "68"],   // Tackling Total
    ["51", "68"],   // Tackling Solo
    ["51", "68"],   // Tackling Ast
    ["52", "68"],   // Tackling TFL
    ["73", "80"],   // Avg Tackles Depth
    ["72", "77"],   // Broken + Missed
    ["73", "77"]    // Broken + Missed%
];

export var passRush = [
    ["125", "0"],   // Player
    ["54", "0"],    // Pos
    ["54", "68"],   // Year
    ["80", "80"],   // Team
    ["54", "73"],   // Pass Rushes Total
    ["54", "73"],   // Pass Rushes Rush%
    ["54", "73"],   // Pass Rush Totals Sacks
    ["54", "73"],   // Pass Rush Totals Hurries
    ["54", "73"],   // Pass Rush Totals Hits
    ["75", "75"],   // Pass Rush Totals Knockdowns
    ["60", "73"],   // Pass Rush Totals Pressures
    ["53", "54"],   // Pass Rush Totals Passes Batted
    ["58", "73"],   // Pass Rush Percentages Sack%
    ["58", "73"],   // Pass Rush Percentages Hurry%
    ["58", "73"],   // Pass Rush Percentages Hit%
    ["78", "75"],   // Pass Rush Percentages Knockdown%
    ["64", "73"],   // Pass Rush Percentages Preassure%
    ["53", "58"]    // Pass Rush Percentages Passes Batted%
];

export var kicking = [
    ["107", "0"],   // Player
    ["40", "43"],   // Year
    ["65", "85"],   // Team
    ["33", "33"],   // G
    ["40", "45"],   // Overall Made
    ["40", "45"],   // Overall Att
    ["45", "46"],   // Overall Pct
    ["40", "46"],   // Overall Long
    ["45", "46"],   // Overall Pts
    ["40", "46"],   // 1-29 Yds Made
    ["40", "46"],   // 1-29 Yds Att
    ["45", "46"],   // 1-29 Yds Pct
    ["40", "46"],   // 30-39 Yds Made
    ["40", "46"],   // 30-39 Yds Att
    ["45", "46"],   // 30-39 Yds Pct
    ["40", "46"],   // 40-49 Yds Made
    ["40", "46"],   // 40-49 Yds Att
    ["45", "46"],   // 40-49 Yds Pct
    ["40", "46"],   // 50+ Yds Made
    ["40", "46"],   // 50+ Yds Att
    ["45", "46"],   // 50+ Yds Pct
    ["40", "46"],   // PAT Made
    ["40", "46"],   // PAT Att
    ["45", "46"],   // PAT Pct
    ["64", "64"]    // Total Points Earned
];

export var punting = [
    ["153", "0"],   // Player
    ["63", "67"],   // Year
    ["80", "99"],   // Team
    ["33", "33"],   // G
    ["60", "64"],   // Punts
    ["60", "64"],   // Yards
    ["60", "63"],   // Avg
    ["60", "72"],   // Opp Ret
    ["60", "72"],   // Ret Yards
    ["60", "72"],   // Net Avg
    ["50", "60"],   // Long
    ["50", "60"],   // Blocks
    ["64", "80"],   // Partial Blocks
    ["60", "80"],   // Fair Catches
    ["78", "72"],   // Touchbacks
    ["60", "72"],   // Inside 20
    ["90", "110"]   // Total Points Earned
];

export var advPunting = [
    ["153", "0"],   // Player
    ["90", "72"],   // Year
    ["65", "94"],   // Team
    ["52", "76"],   // Fair Catch%
    ["65", "76"],   // Return%
    ["65", "76"],   // Inside 20%
    ["53", "76"],   // Touchback%
    ["70", "76"],   // Punts Inside 10
    ["70", "76"],   // Punts Inside 10%
    ["65", "74"],   // Rushing Att
    ["65", "74"],   // Rushing Yards
    ["65", "74"],   // Passing Att
    ["65", "74"],   // Passing Cmp
    ["65", "74"],   // Passing Yards
    ["65", "74"],   // Passing TD
    ["68", "74"]    // Passing Int
];

export var qbGameSummary = [
    ["45"],         // Game Date
    ["33"],         // Week
    ["78"],        // Matchup
    ["60"],         // Score
    ["33"],         // G
    ["33"],         // GS
    ["43"],         // Passing Att
    ["43"],         // Passing Cmp
    ["44"],         // Passing Pct
    ["58"],         // Passing Yds
    ["54"],         // Passing Yds/Att
//    ["50"],         // Passing Y/G   // removed as requested by Matt during initial gauntlet
    ["44"],         // Passing Lg
    ["44"],         // Passing TD
    ["44"],         // Passing Int
    ["44"],         // Rushing Att
    ["48"],         // Rushing Yds
    ["44"],         // Rushing Avg
    ["44"],         // Rushing Lg
    ["44"],         // Rushing TD
    ["44"],         // Fumbles Fum
    ["44"],         // Fumbles Lost
    ["68"],         // QB Rating
    ["54"],         // IQR
    ["54"]          // Total Points Earned
];

export var rbfbGameSummary = [
    ["45"],         // Game Date
    ["33"],         // Week
    ["78"],        // Matchup
    ["60"],         // Score
    ["33"],         // G
    ["33"],         // GS
    ["41"],         // Rushing Att
    ["41"],         // Rushing Yds
    ["41"],         // Rushing Avg
    ["41"],         // Rushing Lg
    ["41"],         // Rushing TD
//    ["40"],         // Rushing Y/G
    ["41"],         // Receiving Targets - added 12/03/2020 per Matt
    ["41"],         // Receiving Rec
    ["40"],         // Receiving Yds
    ["40"],         // Receiving Avg
    ["40"],         // Receiving Lg
    ["40"],         // Receiving TD
  //  ["44"],         // Receiving Y/G
    ["33"],         // Punt Returns Num
    ["40"],         // Punt Returns Yds
    ["40"],         // Punt Returns Avg
    ["33"],         // Punt Returns TD
    ["33"],         // Kickoff Returns Num
    ["33"],         // Kickoff Returns Yds
    ["40"],         // Kickoff Returns Avg
    ["33"],         // Kickoff Returns TD
    ["33"],         // Fumbles Fum 
    ["33"],         // Fumbles Lost 
    ["57"]          // Total Points Earned
];

export var cgtGameSummary = [
    ["45"],         // Game Date
    ["33"],         // Week
    ["108"],        // Matchup
    ["60"],         // Score
    ["33"],         // G
    ["33"],         // GS
    ["85"],         // Total Snaps
    ["74"],         // Blown Blocks Total
    ["74"],         // Blown Blocks Pass
    ["74"],         // Blown Blocks Rush
    ["74"],         // Blown Blocks Pct
    ["74"],         // Penalties Total
    ["74"],         // Penalties Holding
    ["80"],         // Penalties False Starts
    ["74"],         // Penalties Other
    ["73"],         // Penalties Pct
    ["75"]          // Total Points Earned
];

export var gameKicking = [
    ["45"],         // Game Date
    ["33"],         // Week
    ["108"],        // Matchup
    ["60"],         // Score
    ["33"],         // G
    ["40"],         // Overall Made
    ["40"],         // Overall Att
    ["42"],         // Overall Pct
    ["40"],         // Overall Long
    ["42"],         // Overall Pts
    ["40"],         // 1-29 Yds Made
    ["40"],         // 1-29 Yds Att
    ["42"],         // 1-29 Yds Pct
    ["40"],         // 30-39 Yds Made
    ["40"],         // 30-39 Yds Att
    ["42"],         // 30-39 Yds Pct
    ["40"],         // 40-49 Yds Made
    ["40"],         // 40-49 Yds Att
    ["42"],         // 40-49 Yds Pct
    ["40"],         // 50+ Yds Made
    ["40"],         // 50+ Yds Att
    ["42"],         // 50+ Yds Pct
    ["40"],         // PAT Made
    ["40"],         // PAT Att
    ["42"],         // PAT Pct
    ["53"]          // Total Points Earned
];

export var gamePunting = [
    ["45"],         // Game Date
    ["33"],         // Week
    ["108"],        // Matchup
    ["60"],         // Score
    ["33"],         // G
    ["64"],         // Punts
    ["64"],         // Yards
    ["64"],         // Avg
    ["64"],         // Opp Ret
    ["64"],         // Ret Yards
    ["64"],         // Net Avg
    ["56"],         // Long
    ["56"],         // Blocks
    ["64"],         // Partial Blocks
    ["67"],         // Fair Catches
    ["80"],         // Touchbacks
    ["62"],         // Inside 20
    ["95"]          // Total Points Earned
];

export var advGamePunting = [
    ["45"],         // Game Date
    ["33"],         // Week
    ["108"],        // Matchup
    ["60"],         // Score
    ["33"],         // G
    ["33"],         // GS
    ["52"],         // Fair Catch%
    ["65"],         // Return%
    ["65"],         // Inside 20%
    ["53"],         // Touchback%
    ["70"],         // Punts Inside 10
    ["70"],         // Punts Inside 10%
    ["65"],         // Rushing Att
    ["65"],         // Rushing Yards
    ["65"],         // Passing Att
    ["65"],         // Passing Cmp
    ["65"],         // Passing Yards
    ["65"],         // Passing TD
    ["68"]          // Passing Int
];

export var dedtGameSummary = [
    ["45"],         // Game Date
    ["33"],         // Week
    ["108"],        // Matchup
    ["60"],         // Score
    ["33"],         // G
    ["33"],         // GS
    ["83"],         // Taclkes Tackles
    ["83"],         // Tackles TFL
    ["83"],         // Tackles FF
    ["83"],         // Pass Rush Sacks
    ["83"],         // Pass Rush Hurries
    ["83"],         // Pass Rush Hits
    ["93"],         // Pass Rush Knockdowns
    ["83"],         // Pass Rush Preassures
    ["93"],         // Pass Rush Passes Batted
    ["63"]          // Total Points Saved
];

export var lbGameSummary = [
    ["0"],          // Game Date
    ["0"],          // Week
    ["0"],          // Matchup
    ["0"],          // Score
    ["0"],          // G
    ["0"],          // GS
    ["69"],         // Tackling Tackles
    ["69"],         // Tackling TFL
    ["69"],         // Tackling FF
    ["70"],         // Pass Rush Sacks
    ["70"],         // Pass Rush Preassures
    ["69"],         // Coverage Targeted
    ["69"],         // Coverage Cmp
    ["69"],         // Coverage Cmp%
    ["69"],         // Coverage Yards
    ["69"],         // Coverage Yds/Trgt
    ["69"],         // Coverage Int
    ["70"]          // Total Points Saved
];

export var cbsGameSummary = [
    ["0"],          // Game Date
    ["0"],          // Week
    ["0"],          // Matchup
    ["0"],          // Score
    ["0"],          // G
    ["0"],          // GS
    ["46"],         // Coverage Targetted
    ["33"],         // CoverageCmp
    ["46"],         // CoverageCmp%
    ["46"],         // CoverageYards
    ["50"],         // Coverage Yds/Tgt
    ["33"],         // Coverage TD Allowed
    ["48"],         // Coverage Pass Breakups
    ["33"],         // Coverage Int
    ["46"],         // Coverage Int Drops
    ["46"],         // Tackling Tackles
    ["33"],         // Tackling TFL
    ["33"],         // Tackling FF
    ["33"],         // Punt Ret Attempts
    ["40"],         // Punt Ret Yards
    ["40"],         // Punt Ret Yards per Attempt
    ["33"],         // Punt Ret TD
    ["33"],         // Kick Ret Attempts
    ["40"],         // Kick Ret Yards
    ["40"],         // Kick Ret Yards per Attempt
    ["33"],         // Kick Ret TD
    ["47"]          // Total Points Saved
];
