import React from 'react';
import {NullSum, NullDiv, NullMult, DispFunc, CalcPlayerRating} from '../common/CommonFunctions';
import { qbEffectiveness } from '../common/TableColumnWidths';
import Tabulator from 'tabulator-tables';
import "tabulator-tables/dist/css/tabulator.min.css";
import Filters from '../common/Filters';

class TableQBPassingAccuracyTabulator extends React.Component {
    constructor(props) {
        super(props);
        this.accuracystats = this.props.accuracystats;
        this.isLeaderboards = this.props.isLeaderboards;
        this.minFilter = 1;
        this.data = [];
        this.colNum = this.isLeaderboards ? 0 : 1;

        this.onSubmit = this.onSubmit.bind(this);
        
        let bioCols = null;
        let sortable = false;
        this.initSort = null;

        this.totals = {
            behindlosatt: 0,
            behindloscomp: 0,
            behindlosIqr: 0,
            behindlosIqrAttempts: 0,
            behindlosIqrCompletions: 0,
            behindlosIqrYards: 0,
            behindlosIqrTD: 0,
            behindlosIqrInts: 0,
            behindlosleftatt: 0,
            behindlosmidatt: 0,
            behindlosrightatt: 0,
            shortatt: 0,
            shortcomp: 0,
            shortIqr: 0,
            shortIqrAttempts: 0,
            shortIqrCompletions: 0,
            shortIqrYards: 0,
            shortIqrTD: 0,
            shortIqrInts: 0,
            shortleftatt: 0,
            shortmidatt: 0,
            shortrightatt: 0,
            intatt: 0,
            intcomp: 0,
            intIqr: 0,
            intIqrAttempts: 0,
            intIqrCompletions: 0,
            intIqrYards: 0,
            intIqrTD: 0,
            intIqrInts: 0,
            intleftatt: 0,
            intmidatt: 0,
            intrightatt: 0,
            deepatt: 0,
            deepcomp: 0,
            deepIqr: 0,
            deepIqrAttempts: 0,
            deepIqrCompletions: 0,
            deepIqrYards: 0,
            deepIqrTD: 0,
            deepIqrInts: 0,
            deepleftatt: 0,
            deepmidatt: 0,
            deeprightatt: 0
        }

        // Custom sort function for players page so Total stays at the bottom of the table.
        let sortFunc = (a, b, aRow, bRow, column, dir, sorterParams) => {
            var aData = aRow.getData();
            var bData = bRow.getData();
            if (aData.season === 'Total'){
                if (dir === 'desc'){
                    return -1;
                }
                else if (dir === 'asc'){
                    return 1
                }
            }
            else if (bData.season === 'Total'){
                if (dir === 'desc'){
                    return 1;
                }
                else if (dir === 'asc'){
                    return -1;
                }            
            }
            else {
                return a-b;
            }
        };

        // Set first group of columns
        // If this is a leaderboards page include player name, remove custom sorter and set initial sort to proper column.
        if (this.isLeaderboards){
            sortable = true;
            bioCols = [
                {
                    title: "Player", field: "playerName", cssClass: "textColumn",
                    width: `${qbEffectiveness[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                    formatter: "link", formatterParams:{
                        labelField: "playerName",
                        urlPrefix:"/players/",
                        urlField: "playerId"
                    }
                },
                //{ title: "Pos", field: "positionName", cssClass: "textColumn", headerSortStartingDir:"desc" },
                {
                    title: "Year", field: "season", cssClass: "textColumn",
                    width: `${qbEffectiveness[this.colNum++][this.isLeaderboards ? 0 : 1]}`
                },
                {
                    title: "Team", field: "team", cssClass: "textColumn break",
                    width: `${qbEffectiveness[this.colNum++][this.isLeaderboards ? 0 : 1]}`
                }
            ];
            this.sorterFunc = null;
            this.initSort = [
                { column: "deepiqr", dir: "desc" }
            ];
        }
        // Else don't include player name and set initial sort to season.
        else{
            bioCols = [
                {
                    title: "Year", field: "season", cssClass: "textColumn",
                    headerSort: sortable, sorter: sortFunc,
                    width: `${qbEffectiveness[this.colNum++][this.isLeaderboards ? 0 : 1]}`
                },
                {
                    title: "Team", field: "team", cssClass: "textColumn break",
                    headerSort: sortable, sorter: sortFunc,
                    width: `${qbEffectiveness[this.colNum++][this.isLeaderboards ? 0 : 1]}`
                }
            ];
            this.initSort = [
                { column: "season", dir: "asc" }
            ];
        }
        // Set Remaining Columns
        this.cols = [
            { 
                title: "",
                columns: [
                    {
                        title: "",
                        columns: bioCols
                    }
                ]
            },
            {
                title: "Behind Line of Scrimmage",
                columns: [
                    {
                        title: "",
                        columns: [
                            {
                                title: "Att", field: "behindlosatt", cssClass: "small-column",
                                headerSortStartingDir:"desc", headerSort: sortable, sorter: sortFunc,
                                width: `${qbEffectiveness[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                                formatter: function(cell, formatterParams, onRendered){
                                    return DispFunc(cell, formatterParams, onRendered, 0, false, false);
                                }
                            },
                            {
                                title: "Cmp", field: "behindloscomp", cssClass: "small-column",
                                headerSortStartingDir:"desc", headerSort: sortable, sorter: sortFunc,
                                width: `${qbEffectiveness[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                                formatter: function(cell, formatterParams, onRendered){
                                    return DispFunc(cell, formatterParams, onRendered, 0, false, false);
                                }
                            },
                            {
                                title: "IQR", field: "behindlosiqr", cssClass: "break",
                                headerSortStartingDir:"desc", headerSort: sortable, sorter: sortFunc,
                                width: `${qbEffectiveness[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                                formatter: function(cell, formatterParams, onRendered){
                                    return DispFunc(cell, formatterParams, onRendered, 0, false, false);
                                }
                            }
                        ]
                    },
                    {
                        title: "Attempt Pct",
                        columns:[
                            {
                                title: "Left", field: "behindlosleftpct",
                                headerSortStartingDir:"desc", headerSort: sortable, sorter: sortFunc,
                                width: `${qbEffectiveness[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                                formatter: function(cell, formatterParams, onRendered){
                                    return DispFunc(cell, formatterParams, onRendered, 0, false, true);
                                }
                            },
                            {
                                title: "Mid", field: "behindlosmidpct",
                                headerSortStartingDir:"desc", headerSort: sortable, sorter: sortFunc,
                                width: `${qbEffectiveness[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                                formatter: function(cell, formatterParams, onRendered){
                                    return DispFunc(cell, formatterParams, onRendered, 0, false, true);
                                }
                            },
                            {
                                title: "Right", field: "behindlosrightpct", cssClass: "break",
                                headerSortStartingDir:"desc", headerSort: sortable, sorter: sortFunc,
                                width: `${qbEffectiveness[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                                formatter: function(cell, formatterParams, onRendered){
                                    return DispFunc(cell, formatterParams, onRendered, 0, false, true);
                                }
                            }
                        ]
                    }
                ]
            },
            {
                title: "Short",
                columns: [
                    {
                        title: "",
                        columns: [
                            {
                                title: "Att", field: "shortatt", cssClass: "small-column",
                                headerSortStartingDir:"desc", headerSort: sortable, sorter: sortFunc,
                                width: `${qbEffectiveness[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                                formatter: function(cell, formatterParams, onRendered){
                                    return DispFunc(cell, formatterParams, onRendered, 0, false, false);
                                }
                            },
                            {
                                title: "Cmp", field: "shortcomp", cssClass: "small-column",
                                headerSortStartingDir:"desc", headerSort: sortable, sorter: sortFunc,
                                width: `${qbEffectiveness[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                                formatter: function(cell, formatterParams, onRendered){
                                    return DispFunc(cell, formatterParams, onRendered, 0, false, false);
                                }
                            },
                            {
                                title: "IQR", field: "shortiqr", cssClass: "break",
                                headerSortStartingDir:"desc", headerSort: sortable, sorter: sortFunc,
                                width: `${qbEffectiveness[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                                formatter: function(cell, formatterParams, onRendered){
                                    return DispFunc(cell, formatterParams, onRendered, 0, false, false);
                                }
                            }
                        ]
                    },
                    {
                        title: "Attempt Pct",
                        columns:[
                            {
                                title: "Left", field: "shortleftpct",
                                headerSortStartingDir:"desc", headerSort: sortable, sorter: sortFunc,
                                width: `${qbEffectiveness[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                                formatter: function(cell, formatterParams, onRendered){
                                    return DispFunc(cell, formatterParams, onRendered, 0, false, true);
                                }
                            },
                            {
                                title: "Mid", field: "shortmidpct",
                                headerSortStartingDir:"desc", headerSort: sortable, sorter: sortFunc,
                                width: `${qbEffectiveness[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                                formatter: function(cell, formatterParams, onRendered){
                                    return DispFunc(cell, formatterParams, onRendered, 0, false, true);
                                }
                            },
                            {
                                title: "Right", field: "shortrightpct", cssClass: "break",
                                headerSortStartingDir:"desc", headerSort: sortable, sorter: sortFunc,
                                width: `${qbEffectiveness[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                                formatter: function(cell, formatterParams, onRendered){
                                    return DispFunc(cell, formatterParams, onRendered, 0, false, true);
                                }
                            }
                        ]
                    }
                ]
            },
            {
                title: "Intermediate",
                columns: [
                    {
                        title: "",
                        columns: [
                            {
                                title: "Att", field: "intatt", cssClass: "small-column",
                                headerSortStartingDir:"desc", headerSort: sortable, sorter: sortFunc,
                                width: `${qbEffectiveness[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                                formatter: function(cell, formatterParams, onRendered){
                                    return DispFunc(cell, formatterParams, onRendered, 0, false, false);
                                }
                            },
                            {
                                title: "Cmp", field: "intcomp", cssClass: "small-column",
                                headerSortStartingDir:"desc", headerSort: sortable, sorter: sortFunc,
                                width: `${qbEffectiveness[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                                formatter: function(cell, formatterParams, onRendered){
                                    return DispFunc(cell, formatterParams, onRendered, 0, false, false);
                                }
                            },
                            {
                                title: "IQR", field: "intiqr", cssClass: "break",
                                headerSortStartingDir:"desc", headerSort: sortable, sorter: sortFunc,
                                width: `${qbEffectiveness[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                                formatter: function(cell, formatterParams, onRendered){
                                    return DispFunc(cell, formatterParams, onRendered, 0, false, false);
                                }
                            }
                        ]
                    },
                    {
                        title: "Attempt Pct",
                        columns:[
                            {
                                title: "Left", field: "intleftpct",
                                headerSortStartingDir:"desc", headerSort: sortable, sorter: sortFunc,
                                width: `${qbEffectiveness[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                                formatter: function(cell, formatterParams, onRendered){
                                    return DispFunc(cell, formatterParams, onRendered, 0, false, true);
                                }
                            },
                            {
                                title: "Mid", field: "intmidpct",
                                headerSortStartingDir:"desc", headerSort: sortable, sorter: sortFunc,
                                width: `${qbEffectiveness[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                                formatter: function(cell, formatterParams, onRendered){
                                    return DispFunc(cell, formatterParams, onRendered, 0, false, true);
                                }
                            },
                            {
                                title: "Right", field: "intrightpct", cssClass: "break",
                                headerSortStartingDir:"desc", headerSort: sortable, sorter: sortFunc,
                                width: `${qbEffectiveness[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                                formatter: function(cell, formatterParams, onRendered){
                                    return DispFunc(cell, formatterParams, onRendered, 0, false, true);
                                }
                            }
                        ]
                    }
                ]
            },
            {
                title: "Deep",
                columns: [
                    {
                        title: "",
                        columns: [
                            {
                                title: "Att", field: "deepatt", cssClass: "small-column",
                                headerSortStartingDir:"desc", headerSort: sortable, sorter: sortFunc,
                                width: `${qbEffectiveness[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                                formatter: function(cell, formatterParams, onRendered){
                                    return DispFunc(cell, formatterParams, onRendered, 0, false, false);
                                }
                            },
                            {
                                title: "Cmp", field: "deepcomp", cssClass: "small-column",
                                headerSortStartingDir:"desc", headerSort: sortable, sorter: sortFunc,
                                width: `${qbEffectiveness[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                                formatter: function(cell, formatterParams, onRendered){
                                    return DispFunc(cell, formatterParams, onRendered, 0, false, false);
                                }
                            },
                            {
                                title: "IQR", field: "deepiqr", cssClass: "break",
                                headerSortStartingDir:"desc", headerSort: sortable, sorter: sortFunc,
                                width: `${qbEffectiveness[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                                formatter: function(cell, formatterParams, onRendered){
                                    return DispFunc(cell, formatterParams, onRendered, 0, false, false);
                                }
                            }
                        ]
                    },
                    {
                        title: "Attempt Pct",
                        columns:[
                            {
                                title: "Left", field: "deepleftpct",
                                headerSortStartingDir:"desc", headerSort: sortable, sorter: sortFunc,
                                width: `${qbEffectiveness[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                                formatter: function(cell, formatterParams, onRendered){
                                    return DispFunc(cell, formatterParams, onRendered, 0, false, true);
                                }
                            },
                            {
                                title: "Mid", field: "deepmidpct",
                                headerSortStartingDir:"desc", headerSort: sortable, sorter: sortFunc,
                                width: `${qbEffectiveness[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                                formatter: function(cell, formatterParams, onRendered){
                                    return DispFunc(cell, formatterParams, onRendered, 0, false, true);
                                }
                            },
                            {
                                title: "Right", field: "deeprightpct",
                                headerSortStartingDir:"desc", headerSort: sortable, sorter: sortFunc,
                                width: `${qbEffectiveness[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                                formatter: function(cell, formatterParams, onRendered){
                                    return DispFunc(cell, formatterParams, onRendered, 0, false, true);
                                }
                            }
                        ]
                    }
                ]
            }
        ];
        
        this.el = React.createRef();
        this.tabulator = null;
    }

    // Consolidate the data into one data array to be used for tabulator(called on component mount)
    ConsolidateData() {
        let qbdd = this.accuracystats;
        if (this.isLeaderboards){
            qbdd = qbdd.sort( function(a, b) {
                if (a.playerId < b.playerId){
                    return 1;
                }
                if (a.playerId > b.playerId){
                    return -1;
                }
                return 0;
            });
        }

        this.data = [];
        
        // loop through accuracy data generating separate lines for each season
        if (qbdd.length !== 0){
            let rowcontent = {
                season: qbdd[0].season,
                playerName: qbdd[0].name,
                playerId: qbdd[0].playerId,
                positionName: 'QB',
                team: qbdd[0].teamName,
                behindlosatt: 0,
                behindloscomp: 0,
                behindlosiqr: 0,
                behindlosIqrAttempts: 0,
                behindlosIqrCompletions: 0,
                behindlosIqrYards: 0,
                behindlosIqrTD: 0,
                behindlosIqrInts: 0,
                behindlosleftatt: 0,
                behindlosmidatt: 0,
                behindlosrightatt: 0,
                shortatt: 0,
                shortcomp: 0,
                shortiqr: 0,
                shortIqrAttempts: 0,
                shortIqrCompletions: 0,
                shortIqrYards: 0,
                shortIqrTD: 0,
                shortIqrInts: 0,
                shortleftatt: 0,
                shortmidatt: 0,
                shortrightatt: 0,
                intatt: 0,
                intcomp: 0,
                intiqr: 0,
                intIqrAttempts: 0,
                intIqrCompletions: 0,
                intIqrYards: 0,
                intIqrTD: 0,
                intIqrInts: 0,
                intleftatt: 0,
                intmidatt: 0,
                intrightatt: 0,
                deepatt: 0,
                deepcomp: 0,
                deepiqr: 0,
                deepIqrAttempts: 0,
                deepIqrCompletions: 0,
                deepIqrYards: 0,
                deepIqrTD: 0,
                deepIqrInts: 0,
                deepleftatt: 0,
                deepmidatt: 0,
                deeprightatt: 0
            }    
            for (var i=0; i<qbdd.length; i++) {
                if (rowcontent.season !== qbdd[i].season || rowcontent.team !== qbdd[i].teamName || rowcontent.playerId !== qbdd[i].playerId) {  // new season or team value
                    this.GenerateSeasonRow(rowcontent,this.totals,this.isLeaderboards);
                    // capture the new season and team
                    rowcontent = {
                        season: qbdd[i].season,
                        playerName: qbdd[i].name,
                        playerId: qbdd[i].playerId,
                        positionName: 'QB',
                        team: qbdd[i].teamName,
                        behindlosatt: 0,
                        behindloscomp: 0,
                        behindlosiqr: 0,
                        behindlosIqrAttempts: 0,
                        behindlosIqrCompletions: 0,
                        behindlosIqrYards: 0,
                        behindlosIqrTD: 0,
                        behindlosIqrInts: 0,
                        behindlosleftatt: 0,
                        behindlosmidatt: 0,
                        behindlosrightatt: 0,
                        shortatt: 0,
                        shortcomp: 0,
                        shortiqr: 0,
                        shortIqrAttempts: 0,
                        shortIqrCompletions: 0,
                        shortIqrYards: 0,
                        shortIqrTD: 0,
                        shortIqrInts: 0,
                        shortleftatt: 0,
                        shortmidatt: 0,
                        shortrightatt: 0,
                        intatt: 0,
                        intcomp: 0,
                        intiqr: 0,
                        intIqrAttempts: 0,
                        intIqrCompletions: 0,
                        intIqrYards: 0,
                        intIqrTD: 0,
                        intIqrInts: 0,
                        intleftatt: 0,
                        intmidatt: 0,
                        intrightatt: 0,
                        deepatt: 0,
                        deepcomp: 0,
                        deepiqr: 0,
                        deepIqrAttempts: 0,
                        deepIqrCompletions: 0,
                        deepIqrYards: 0,
                        deepIqrTD: 0,
                        deepIqrInts: 0,
                        deepleftatt: 0,
                        deepmidatt: 0,
                        deeprightatt: 0
                    }; 
                }
                switch (qbdd[i].y) {   // y = depth
                    case 1:     // behind los
                        rowcontent.behindloscomp = NullSum(rowcontent.behindloscomp,qbdd[i].comp);
                        rowcontent.behindlosiqr = qbdd[i].depthIQR; // no need to check null since direct move
                        rowcontent.behindlosIqrAttempts = NullSum(rowcontent.behindlosIqrAttempts,qbdd[i].iqrAttempts);
                        rowcontent.behindlosIqrCompletions = NullSum(rowcontent.behindlosIqrCompletions,qbdd[i].accCmplDrop);
                        rowcontent.behindlosIqrYards = NullSum(rowcontent.behindlosIqrYards,NullSum(qbdd[i].yards,qbdd[i].dropYards));
                        rowcontent.behindlosIqrTD = NullSum(rowcontent.behindlosIqrTD,qbdd[i].tDandTDDrops);
                        rowcontent.behindlosIqrInts = NullSum(rowcontent.behindlosIqrInts,qbdd[i].intsDroppedInts);
                        break;
                    case 2:     // short
                        rowcontent.shortcomp = NullSum(rowcontent.shortcomp,qbdd[i].comp);
                        rowcontent.shortiqr = qbdd[i].depthIQR; // no need to check null since direct move
                        rowcontent.shortIqrAttempts = NullSum(rowcontent.shortIqrAttempts,qbdd[i].iqrAttempts);
                        rowcontent.shortIqrCompletions = NullSum(rowcontent.shortIqrCompletions,qbdd[i].accCmplDrop);
                        rowcontent.shortIqrYards = NullSum(rowcontent.shortIqrYards,NullSum(qbdd[i].yards,qbdd[i].dropYards));
                        rowcontent.shortIqrTD = NullSum(rowcontent.shortIqrTD,qbdd[i].tDandTDDrops);
                        rowcontent.shortIqrInts = NullSum(rowcontent.shortIqrInts,qbdd[i].intsDroppedInts);
                        break;
                    case 3:     // intermediate
                        rowcontent.intcomp = NullSum(rowcontent.intcomp,qbdd[i].comp);
                        rowcontent.intiqr = qbdd[i].depthIQR; // no need to check null since direct move
                        rowcontent.intIqrAttempts = NullSum(rowcontent.intIqrAttempts,qbdd[i].iqrAttempts);
                        rowcontent.intIqrCompletions = NullSum(rowcontent.intIqrCompletions,qbdd[i].accCmplDrop);
                        rowcontent.intIqrYards = NullSum(rowcontent.intIqrYards,NullSum(qbdd[i].yards,qbdd[i].dropYards));
                        rowcontent.intIqrTD = NullSum(rowcontent.intIqrTD,qbdd[i].tDandTDDrops);
                        rowcontent.intIqrInts = NullSum(rowcontent.intIqrInts,qbdd[i].intsDroppedInts);
                        break;
                    case 4:     // deep
                        rowcontent.deepcomp = NullSum(rowcontent.deepcomp,qbdd[i].comp);
                        rowcontent.deepiqr = qbdd[i].depthIQR; // no need to check null since direct move
                        rowcontent.deepIqrAttempts = NullSum(rowcontent.deepIqrAttempts,qbdd[i].iqrAttempts);
                        rowcontent.deepIqrCompletions = NullSum(rowcontent.deepIqrCompletions,qbdd[i].accCmplDrop);
                        rowcontent.deepIqrYards = NullSum(rowcontent.deepIqrYards,NullSum(qbdd[i].yards,qbdd[i].dropYards));
                        rowcontent.deepIqrTD = NullSum(rowcontent.deepIqrTD,qbdd[i].tDandTDDrops);
                        rowcontent.deepIqrInts = NullSum(rowcontent.deepIqrInts,qbdd[i].intsDroppedInts);
                        break;
                    default:
                        rowcontent.behindloscomp = null;
                        rowcontent.behindlosiqr = null;
                        rowcontent.behindlosIqrAttempts = null;
                        rowcontent.behindlosIqrCompletions = null;
                        rowcontent.behindlosIqrYards = null;
                        rowcontent.behindlosIqrTD = null;
                        rowcontent.behindlosIqrInts = null;
                        rowcontent.shortcomp = null;
                        rowcontent.shortiqr = null;
                        rowcontent.shortIqrAttempts = null;
                        rowcontent.shortIqrCompletions = null;
                        rowcontent.shortIqrYards = null;
                        rowcontent.shortIqrTD = null;
                        rowcontent.shortIqrInts = null;
                        rowcontent.intcomp = null;
                        rowcontent.intiqr = null;
                        rowcontent.intIqrAttempts = null;
                        rowcontent.intIqrCompletions = null;
                        rowcontent.intIqrYards = null;
                        rowcontent.intIqrTD = null;
                        rowcontent.intIqrInts = null;
                        rowcontent.deepcomp = null;
                        rowcontent.deepiqr = null;
                        rowcontent.deepIqrAttempts = null;
                        rowcontent.deepIqrCompletions = null;
                        rowcontent.deepIqrYards = null;
                        rowcontent.deepIqrTD = null;
                        rowcontent.deepIqrInts = null;
                        break;
                }
                switch (NullSum(NullMult(qbdd[i].y,10),qbdd[i].x)) {   // y = depth, x = direction
                    case 12:    // Behind LOS, Left Middle
                    case 13:    // Behind LOS, Middle
                    case 14:    // Behind LOS, Right Middle
                        rowcontent.behindlosmidatt = NullSum(rowcontent.behindlosmidatt,qbdd[i].att);
                        break;
                    case 22:    // Short (0-9), Left Middle
                    case 23:    // Short (0-9), Middle
                    case 24:    // Short (0-9), Right Middle
                        rowcontent.shortmidatt = NullSum(rowcontent.shortmidatt,qbdd[i].att);
                        break;
                    case 32:    // Intermediate (10-19), Left Middle
                    case 33:    // Intermediate (10-19), Middle
                    case 34:    // Intermediate (10-19), Right Middle
                        rowcontent.intmidatt = NullSum(rowcontent.intmidatt,qbdd[i].att);
                        break;
                    case 42:    // Deep (20+), Left Middle
                    case 43:    // Deep (20+), Middle
                    case 44:    // Deep (20+), Right Middle
                        rowcontent.deepmidatt = NullSum(rowcontent.deepmidatt,qbdd[i].att);
                        break;
                    case 11:    // Behind LOS, Left Outside
                        rowcontent.behindlosleftatt = NullSum(rowcontent.behindlosleftatt,qbdd[i].att);
                        break;
                    case 15:    // Behind LOS, Right Outside
                        rowcontent.behindlosrightatt = NullSum(rowcontent.behindlosrightatt,qbdd[i].att);
                        break;
                    case 21:    // Short (0-9), Left Outside
                        rowcontent.shortleftatt = NullSum(rowcontent.shortleftatt,qbdd[i].att);
                        break;
                    case 25:    // Short (0-9), Right Outside'
                        rowcontent.shortrightatt = NullSum(rowcontent.shortrightatt,qbdd[i].att);
                        break;
                    case 31:    // Intermediate (10-19), Left Outside
                        rowcontent.intleftatt = NullSum(rowcontent.intleftatt,qbdd[i].att);
                        break;
                    case 35:    // Intermediate (10-19), Right Outside
                        rowcontent.intrightatt = NullSum(rowcontent.intrightatt,qbdd[i].att);
                        break;
                    case 41:    // Deep (20+), Left Outside
                        rowcontent.deepleftatt = NullSum(rowcontent.deepleftatt,qbdd[i].att);
                        break;
                    case 45:    // Deep (20+), Right Outside
                        rowcontent.deeprightatt = NullSum(rowcontent.deeprightatt,qbdd[i].att);
                        break;
                    default:
                        rowcontent.behindlosmidatt = null;
                        rowcontent.shortmidatt = null;
                        rowcontent.intmidatt = null;
                        rowcontent.deepmidatt = null;
                        rowcontent.behindlosleftatt = null;
                        rowcontent.behindlosrightatt = null;
                        rowcontent.shortleftatt = null;
                        rowcontent.shortrightatt = null;
                        rowcontent.intleftatt = null;
                        rowcontent.intrightatt = null;
                        rowcontent.deepleftatt = null;
                        rowcontent.deeprightatt = null;
                        break;
                }
            }
            this.GenerateSeasonRow(rowcontent,this.totals, this.isLeaderboards);
        }
        if (!this.isLeaderboards){
            this.GenerateTotalRow(this.totals);
        }
    }
    
    GenerateSeasonRow(rowcontent, totals, isLeaderboards) {
        // sum directional attempts to corresponding depth attempts
        rowcontent.behindlosatt =
            NullSum(rowcontent.behindlosleftatt, NullSum(rowcontent.behindlosmidatt, rowcontent.behindlosrightatt));
        rowcontent.shortatt =
            NullSum(rowcontent.shortleftatt, NullSum(rowcontent.shortmidatt, rowcontent.shortrightatt));
        rowcontent.intatt =
            NullSum(rowcontent.intleftatt, NullSum(rowcontent.intmidatt, rowcontent.intrightatt));
        rowcontent.deepatt =
            NullSum(rowcontent.deepleftatt, NullSum(rowcontent.deepmidatt, rowcontent.deeprightatt));
        // calculate directional percentages
        rowcontent.behindlosleftpct = NullDiv(rowcontent.behindlosleftatt, rowcontent.behindlosatt) * 100;
        rowcontent.behindlosmidpct = NullDiv(rowcontent.behindlosmidatt, rowcontent.behindlosatt) * 100;
        rowcontent.behindlosrightpct = NullDiv(rowcontent.behindlosrightatt, rowcontent.behindlosatt) * 100;
        rowcontent.shortleftpct = NullDiv(rowcontent.shortleftatt, rowcontent.shortatt) * 100;
        rowcontent.shortmidpct = NullDiv(rowcontent.shortmidatt, rowcontent.shortatt) * 100;
        rowcontent.shortrightpct = NullDiv(rowcontent.shortrightatt, rowcontent.shortatt) * 100;
        rowcontent.intleftpct = NullDiv(rowcontent.intleftatt, rowcontent.intatt) * 100;
        rowcontent.intmidpct = NullDiv(rowcontent.intmidatt, rowcontent.intatt) * 100;
        rowcontent.intrightpct = NullDiv(rowcontent.intrightatt, rowcontent.intatt) * 100;
        rowcontent.deepleftpct = NullDiv(rowcontent.deepleftatt, rowcontent.deepatt) * 100;
        rowcontent.deepmidpct = NullDiv(rowcontent.deepmidatt, rowcontent.deepatt) * 100;
        rowcontent.deeprightpct = NullDiv(rowcontent.deeprightatt, rowcontent.deepatt) * 100;
        // generate a season row
        if (!this.isLeaderboards || rowcontent.behindlosmidatt +
            rowcontent.shortmidatt +
            rowcontent.intmidatt +
            rowcontent.deepmidatt +
            rowcontent.behindlosleftatt +
            rowcontent.behindlosrightatt +
            rowcontent.shortleftatt +
            rowcontent.shortrightatt +
            rowcontent.intleftatt +
            rowcontent.intrightatt +
            rowcontent.deepleftatt +
            rowcontent.deeprightatt >= this.minFilter) {
            this.data.push(rowcontent);
        }
        // add seasonal stats to totals
        if (!isLeaderboards) {
            this.AddSeasonToTotals(rowcontent, totals);
        }
    }

    GenerateTotalRow(totals) {
        let rowcontent = {
            season: 'Total',
            playerName: '',
            playerId: '',
            positionName: '',
            team: '',
            behindlosatt: 0,
            behindloscomp: 0,
            behindlosiqr: 0,
            behindlosIqrAttempts: 0,
            behindlosIqrCompletions: 0,
            behindlosIqrYards: 0,
            behindlosIqrTD: 0,
            behindlosIqrInts: 0,
            behindlosleftatt: 0,
            behindlosmidatt: 0,
            behindlosrightatt: 0,
            shortatt: 0,
            shortcomp: 0,
            shortiqr: 0,
            shortIqrAttempts: 0,
            shortIqrCompletions: 0,
            shortIqrYards: 0,
            shortIqrTD: 0,
            shortIqrInts: 0,
            shortleftatt: 0,
            shortmidatt: 0,
            shortrightatt: 0,
            intatt: 0,
            intcomp: 0,
            intiqr: 0,
            intIqrAttempts: 0,
            intIqrCompletions: 0,
            intIqrYards: 0,
            intIqrTD: 0,
            intIqrInts: 0,
            intleftatt: 0,
            intmidatt: 0,
            intrightatt: 0,
            deepatt: 0,
            deepcomp: 0,
            deepiqr: 0,
            deepIqrAttempts: 0,
            deepIqrCompletions: 0,
            deepIqrYards: 0,
            deepIqrTD: 0,
            deepIqrInts: 0,
            deepleftatt: 0,
            deepmidatt: 0,
            deeprightatt: 0
        }
        // sum directional attempt totals to corresponding rowcontent fields
        rowcontent.behindlosatt =
            NullSum(totals.behindlosleftatt, NullSum(totals.behindlosmidatt, totals.behindlosrightatt));
        rowcontent.shortatt =
            NullSum(totals.shortleftatt, NullSum(totals.shortmidatt, totals.shortrightatt));
        rowcontent.intatt =
            NullSum(totals.intleftatt, NullSum(totals.intmidatt, totals.intrightatt));
        rowcontent.deepatt =
            NullSum(totals.deepleftatt, NullSum(totals.deepmidatt, totals.deeprightatt));
        // move directional completion totals to corresponding rowcontent fields
        rowcontent.behindloscomp = totals.behindloscomp;
        rowcontent.shortcomp = totals.shortcomp;
        rowcontent.intcomp = totals.intcomp;
        rowcontent.deepcomp = totals.deepcomp;
        // calculate total iqr values into corresponding rowcontent fields
        rowcontent.behindlosiqr =
            CalcPlayerRating(
                totals.behindlosIqrAttempts,
                totals.behindlosIqrCompletions,
                totals.behindlosIqrYards,
                totals.behindlosIqrTD,
                totals.behindlosIqrInts
            );
        rowcontent.shortiqr =
            CalcPlayerRating(
                totals.shortIqrAttempts,
                totals.shortIqrCompletions,
                totals.shortIqrYards,
                totals.shortIqrTD,
                totals.shortIqrInts
            );
        rowcontent.intiqr =
            CalcPlayerRating(
                totals.intIqrAttempts,
                totals.intIqrCompletions,
                totals.intIqrYards,
                totals.intIqrTD,
                totals.intIqrInts
            );
        rowcontent.deepiqr =
            CalcPlayerRating(
                totals.deepIqrAttempts,
                totals.deepIqrCompletions,
                totals.deepIqrYards,
                totals.deepIqrTD,
                totals.deepIqrInts
            );
        // calculate directional percentages
        rowcontent.behindlosleftpct = NullDiv(totals.behindlosleftatt, totals.behindlosatt) * 100;
        rowcontent.behindlosmidpct = NullDiv(totals.behindlosmidatt, totals.behindlosatt) * 100;
        rowcontent.behindlosrightpct = NullDiv(totals.behindlosrightatt, totals.behindlosatt) * 100;
        rowcontent.shortleftpct = NullDiv(totals.shortleftatt, totals.shortatt) * 100;
        rowcontent.shortmidpct = NullDiv(totals.shortmidatt, totals.shortatt) * 100;
        rowcontent.shortrightpct = NullDiv(totals.shortrightatt, totals.shortatt) * 100;
        rowcontent.intleftpct = NullDiv(totals.intleftatt, totals.intatt) * 100;
        rowcontent.intmidpct = NullDiv(totals.intmidatt, totals.intatt) * 100;
        rowcontent.intrightpct = NullDiv(totals.intrightatt, totals.intatt) * 100;
        rowcontent.deepleftpct = NullDiv(totals.deepleftatt, totals.deepatt) * 100;
        rowcontent.deepmidpct = NullDiv(totals.deepmidatt, totals.deepatt) * 100;
        rowcontent.deeprightpct = NullDiv(totals.deeprightatt, totals.deepatt) * 100;
        // generate total row
        this.data.push(rowcontent);
    }

    AddSeasonToTotals(rowcontent,totals) {
        // totals for behind los depth
        totals.behindlosatt = NullSum(totals.behindlosatt,rowcontent.behindlosatt);
        totals.behindloscomp = NullSum(totals.behindloscomp,rowcontent.behindloscomp);
        totals.behindlosIqrAttempts = NullSum(totals.behindlosIqrAttempts,rowcontent.behindlosIqrAttempts);
        totals.behindlosIqrCompletions = NullSum(totals.behindlosIqrCompletions,rowcontent.behindlosIqrCompletions);
        totals.behindlosIqrYards = NullSum(totals.behindlosIqrYards,rowcontent.behindlosIqrYards);
        totals.behindlosIqrTD = NullSum(totals.behindlosIqrTD,rowcontent.behindlosIqrTD);
        totals.behindlosIqrInts = NullSum(totals.behindlosIqrInts,rowcontent.behindlosIqrInts);
        totals.behindlosleftatt = NullSum(totals.behindlosleftatt,rowcontent.behindlosleftatt);
        totals.behindlosmidatt = NullSum(totals.behindlosmidatt,rowcontent.behindlosmidatt);
        totals.behindlosrightatt = NullSum(totals.behindlosrightatt,rowcontent.behindlosrightatt);
        // totals for short depth
        totals.shortatt = NullSum(totals.shortatt,rowcontent.shortatt);
        totals.shortcomp = NullSum(totals.shortcomp,rowcontent.shortcomp);
        totals.shortIqrAttempts = NullSum(totals.shortIqrAttempts,rowcontent.shortIqrAttempts);
        totals.shortIqrCompletions = NullSum(totals.shortIqrCompletions,rowcontent.shortIqrCompletions);
        totals.shortIqrYards = NullSum(totals.shortIqrYards,rowcontent.shortIqrYards);
        totals.shortIqrTD = NullSum(totals.shortIqrTD,rowcontent.shortIqrTD);
        totals.shortIqrInts = NullSum(totals.shortIqrInts,rowcontent.shortIqrInts);
        totals.shortleftatt = NullSum(totals.shortleftatt,rowcontent.shortleftatt);
        totals.shortmidatt = NullSum(totals.shortmidatt,rowcontent.shortmidatt);
        totals.shortrightatt = NullSum(totals.shortrightatt,rowcontent.shortrightatt);
        // totals for intermediate depth
        totals.intatt = NullSum(totals.intatt,rowcontent.intatt);
        totals.intcomp = NullSum(totals.intcomp,rowcontent.intcomp);
        totals.intIqrAttempts = NullSum(totals.intIqrAttempts,rowcontent.intIqrAttempts);
        totals.intIqrCompletions = NullSum(totals.intIqrCompletions,rowcontent.intIqrCompletions);
        totals.intIqrYards = NullSum(totals.intIqrYards,rowcontent.intIqrYards);
        totals.intIqrTD = NullSum(totals.intIqrTD,rowcontent.intIqrTD);
        totals.intIqrInts = NullSum(totals.intIqrInts,rowcontent.intIqrInts);
        totals.intleftatt = NullSum(totals.intleftatt,rowcontent.intleftatt);
        totals.intmidatt = NullSum(totals.intmidatt,rowcontent.intmidatt);
        totals.intrightatt = NullSum(totals.intrightatt,rowcontent.intrightatt);
        // totals for deep depth
        totals.deepatt = NullSum(totals.deepatt,rowcontent.deepatt);
        totals.deepcomp = NullSum(totals.deepcomp,rowcontent.deepcomp);
        totals.deepIqrAttempts = NullSum(totals.deepIqrAttempts,rowcontent.deepIqrAttempts);
        totals.deepIqrCompletions = NullSum(totals.deepIqrCompletions,rowcontent.deepIqrCompletions);
        totals.deepIqrYards = NullSum(totals.deepIqrYards,rowcontent.deepIqrYards);
        totals.deepIqrTD = NullSum(totals.deepIqrTD,rowcontent.deepIqrTD);
        totals.deepIqrInts = NullSum(totals.deepIqrInts,rowcontent.deepIqrInts);
        totals.deepleftatt = NullSum(totals.deepleftatt,rowcontent.deepleftatt);
        totals.deepmidatt = NullSum(totals.deepmidatt,rowcontent.deepmidatt);
        totals.deeprightatt = NullSum(totals.deeprightatt,rowcontent.deeprightatt);
        return;
    }
        
    componentDidMount() {
        this.ConsolidateData();
        let pagination = 'local';
        let paginationSize = 50;
        if (!this.isLeaderboards){
            //this.SumData();
            pagination = null;
            paginationSize = 0;
        }
        this.tabulator = new Tabulator(this.el, {
            data: this.data,
            reactiveData: true,
            columns: this.cols,
            layout: "fitColumns",  // added by Ruben 7/25/19
            rowFormatter:function(row){
                var data = row.getData();
                if (data.season === 'Total'){
                    row.getElement().style.borderTop = "2px solid #6f88a8";
                    row.getElement().style.borderBottom = "2px solid #6f88a8";
                }
            },
            pagination: pagination,
            paginationSize: paginationSize,
            initialSort: this.initSort,
            resizableColumns: false,
            selectable: false
        });
    }

    componentDidUpdate() {
        this.tabulator.replaceData(this.data);
    }

    onSubmit(e, minFilter){
        this.minFilter = minFilter;
        this.ConsolidateData();
        this.forceUpdate();
    }

    render() {
        return (
            <React.Fragment>
                {
                    this.isLeaderboards &&
                    <div className="shadowdiv" id="filtersDiv">
                        <Filters minFilter={this.minFilter} onSubmit={this.onSubmit} label={"Pass Attempts"} />
                        <div ref={el => (this.el = el)} />
                    </div>
                }
                {
                    !this.isLeaderboards &&
                    <div className="shadowdiv">
                        <div id="table-title">Passing Effectiveness by Distance</div>
                        <div ref={el => (this.el = el)} />
                    </div>
                }
            </React.Fragment>
        );
    }
}

export default TableQBPassingAccuracyTabulator;