import React from 'react';
import {CalcPlayerRating, NullSum, NullBypassSum, NullDiv, Tst2015, DispFunc} from '../common/CommonFunctions';
import { advReceiving } from '../common/TableColumnWidths';
import Tabulator from 'tabulator-tables';
import "tabulator-tables/dist/css/tabulator.min.css";
import Filters from '../common/Filters';

class TableQBSummaryTabulator extends React.Component {
    constructor(props) {
        super(props);
        this.receivingstats = this.props.receivingstats;
        this.isLeaderboards = this.props.isLeaderboards;
        this.minFilter = 1;
        this.data = [];
        let bioCols = null;
        let sortable = false;
        this.initSort = null;
        this.colNum = this.isLeaderboards ? 0 : 2;

        this.onSubmit = this.onSubmit.bind(this);

        // Custom sort function for players page so Total stays at the bottom of the table.
        let sortFunc = (a, b, aRow, bRow, column, dir, sorterParams) => {
            var aData = aRow.getData();
            var bData = bRow.getData();
            if (aData.season === 'Total'){
                if (dir === 'desc'){
                    return -1;
                }
                else if (dir === 'asc'){
                    return 1
                }
            }
            else if (bData.season === 'Total'){
                if (dir === 'desc'){
                    return 1;
                }
                else if (dir === 'asc'){
                    return -1;
                }            
            }
            else {
                return a-b;
            }
        };

        // Set first group of columns
        // If this is a leaderboards page include player name, remove custom sorter and set initial sort to proper column.
        if (this.isLeaderboards){
            sortable = true;
            bioCols = [
                { 
                    title: "Player", field: "player", cssClass: "single-row-header textColumn", 
                    width: `${advReceiving[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                    formatter: "link", formatterParams:{
                        labelField: "player", 
                        urlPrefix:"/players/", 
                        urlField: "playerId"
                    } 
                },
                { 
                    title: "Pos", field: "positionName", cssClass: "single-row-header textColumn",
                    width: `${advReceiving[this.colNum++][this.isLeaderboards ? 0 : 1]}`
                },
                { 
                    title: "Year", field: "season", cssClass: "single-row-header textColumn",
                    width: `${advReceiving[this.colNum++][this.isLeaderboards ? 0 : 1]}`
                },
                { 
                    title: "Team", field: "team", cssClass: "single-row-header textColumn break", 
                    width: `${advReceiving[this.colNum++][this.isLeaderboards ? 0 : 1]}`
                }
            ];
            this.sorterFunc = null;
            this.initSort = [
                { column: "eps", dir: "desc" }
            ];
        }
        // Else don't include player name and set initial sort to season.
        else{
            bioCols = [
                { 
                    title: "Year", field: "season", cssClass: "textColumn",
                    width: `${advReceiving[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                    headerSort: sortable, sorter: sortFunc 
                },
                { 
                    title: "Team", field: "team", cssClass: "textColumn break", 
                    width: `${advReceiving[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                    headerSort: sortable, sorter: sortFunc
                }
            ];
            this.initSort = [
                { column: "season", dir: "asc" }
            ];
        }
        // Set Remaining Columns
        this.cols = [
            { title: "",
                columns: bioCols,
                cssClass: "break",
            },
            {
                title: "Targets",
                columns:[
                    { 
                        title: "All", field: "targets", cssClass: "single-row-header", 
                        headerSortStartingDir:"desc", headerSort: sortable, sorter: sortFunc, 
                        width: `${advReceiving[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                        formatter: function(cell, formatterParams, onRendered){
                            return DispFunc(cell, formatterParams, onRendered, 0, true, false);
                        }
                    },
                    { 
                        title: "Rec", field: "recs", cssClass: "single-row-header", 
                        headerSortStartingDir:"desc", headerSort: sortable, sorter: sortFunc, 
                        width: `${advReceiving[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                        formatter: function(cell, formatterParams, onRendered){
                            return DispFunc(cell, formatterParams, onRendered, 0, true, false);
                        }
                    },
                    { 
                        title: "Rec%", field: "compPct", cssClass: "single-row-header break", 
                        headerSortStartingDir:"desc", headerSort: sortable, sorter: sortFunc, 
                        width: `${advReceiving[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                        formatter: function(cell, formatterParams, onRendered){
                            return DispFunc(cell, formatterParams, onRendered, 1, false, false);
                        }
                    },
                    { 
                        title: "Share%", field: "targetShare", cssClass: "single-row-header", 
                        headerSortStartingDir:"desc", headerSort: sortable, sorter: sortFunc, 
                        width: `${advReceiving[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                        formatter: function(cell, formatterParams, onRendered){
                            return DispFunc(cell, formatterParams, onRendered, 1, false, false);
                        }
                    },
                    {
                        title: "Intended Air Yards", field: "intendedAirYards", cssClass: "single-row-header",
                        headerSortStartingDir: "desc", headerSort: sortable, sorter: sortFunc,
                        titleFormatter: "textarea",
                        width: `${advReceiving[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                        formatter: function (cell, formatterParams, onRendered) {
                            return DispFunc(cell, formatterParams, onRendered, 0, true, false);
                        }
                    },
                    { 
                        title: "Avg Depth", field: "avgTgtDepth", cssClass: "single-row-header break", 
                        headerSortStartingDir:"desc", headerSort: sortable, sorter: sortFunc, 
                        titleFormatter: "textarea",
                        width: `${advReceiving[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                        formatter: function(cell, formatterParams, onRendered){
                            return DispFunc(cell, formatterParams, onRendered, 1, false, false);
                        }
                    }
                ],
            },
            {
                title: "Catchable",
                columns:[
                    { 
                        title: "All", field: "catchableTgts", cssClass: "single-row-header", 
                        width: `${advReceiving[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                        headerSortStartingDir:"desc", headerSort: sortable, sorter: sortFunc, 
                        formatter: function(cell, formatterParams, onRendered){
                            return DispFunc(cell, formatterParams, onRendered, 0, true, false);
                        }
                    },
                    { 
                        title: "Catch%", field: "catchablePct", cssClass: "single-row-header break", 
                        width: `${advReceiving[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                        headerSortStartingDir:"desc", headerSort: sortable, sorter: sortFunc, 
                        formatter: function(cell, formatterParams, onRendered){
                            return DispFunc(cell, formatterParams, onRendered, 1, false, false);
                        }
                    }
                ],
            },
            { 
                title: "Complete Air Yards", field: "completedAirYards", cssClass: "single-row-header", 
                headerSortStartingDir:"desc", headerSort: sortable, sorter: sortFunc, 
                titleFormatter:"textarea",
                width: `${advReceiving[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                formatter: function(cell, formatterParams, onRendered){
                    return DispFunc(cell, formatterParams, onRendered, 0, true, false);
                }
            },
            { 
                title: "Yds After Catch", field: "yardsAfterCatch", cssClass: "single-row-header",
                headerSortStartingDir:"desc", headerSort: sortable, sorter: sortFunc, 
                titleFormatter:"textarea",
                width: `${advReceiving[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                formatter: function(cell, formatterParams, onRendered){
                    return DispFunc(cell, formatterParams, onRendered, 0, true, false);
                }
            },
            { 
                title: "YAC Per Rec", field: "ydsAftCatchPerReceive", cssClass: "single-row-header break", 
                headerSortStartingDir:"desc", headerSort: sortable, sorter: sortFunc, 
                titleFormatter:"textarea",
                width: `${advReceiving[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                formatter: function(cell, formatterParams, onRendered){
                    return DispFunc(cell, formatterParams, onRendered, 1, false, false);
                }
            },
            { 
                title: "Drops", field: "drops", cssClass: "single-row-header", 
                headerSortStartingDir:"desc", headerSort: sortable, sorter: sortFunc, 
                titleFormatter:"textarea",
                width: `${advReceiving[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                formatter: function(cell, formatterParams, onRendered){
                    return DispFunc(cell, formatterParams, onRendered, 0, false, false);
                }
            },
            { 
                title: "Drop%", field: "dropPct", cssClass: "single-row-header break", 
                headerSortStartingDir:"desc", headerSort: sortable, sorter: sortFunc, 
                titleFormatter:"textarea",
                width: `${advReceiving[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                formatter: function(cell, formatterParams, onRendered){
                    return DispFunc(cell, formatterParams, onRendered, 1, false, false);
                }
            },
            { 
                title: "First Downs", field: "firstDowns", cssClass: "single-row-header", 
                headerSortStartingDir:"desc", headerSort: sortable, sorter: sortFunc, 
                titleFormatter:"textarea", 
                width: `${advReceiving[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                formatter: function(cell, formatterParams, onRendered){
                    return DispFunc(cell, formatterParams, onRendered, 0, false, false);
                }
            },
            { 
                title: "First Down%", field: "firstDownPct", cssClass: "single-row-header break", 
                headerSortStartingDir:"desc", headerSort: sortable, sorter: sortFunc, 
                titleFormatter:"textarea",
                width: `${advReceiving[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                formatter: function(cell, formatterParams, onRendered){
                    return DispFunc(cell, formatterParams, onRendered, 1, false, false);
                }
            },
            { 
                title: "Receiver Rating", field: "receiverRating", cssClass: "single-row-header break", 
                headerSortStartingDir:"desc", headerSort: sortable, sorter: sortFunc, 
                titleFormatter:"textarea",
                width: `${advReceiving[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                formatter: function(cell, formatterParams, onRendered){
                    return DispFunc(cell, formatterParams, onRendered, 1, false, false);
                }
            },
            { 
                title: "Recv Points Earned", field: "eps", cssClass: "single-row-header", 
                headerSortStartingDir:"desc", headerSort: sortable, sorter: sortFunc, 
                titleFormatter:"textarea",
                width: `${advReceiving[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                formatter: function(cell, formatterParams, onRendered){
                    return DispFunc(cell, formatterParams, onRendered, 0, false, false);
                }
            }
        ];
        
        this.el = React.createRef();
        this.tabulator = null;

    }

    // Consolidate the data into one data array to be used for tabulator(called on component mount)
    ConsolidateData(){
        let data = [];
        let player = '';
        let playerId = 0;
        let positionName = '';
        let season = 0;
        let team = '';
        let targets = 0;
        let recs = 0;
        let compPct = 0;
        let teamTargets = 0;
        let targetShare = 0;
        let avgTgtDepth = 0;
        let catchableTgts = 0;
        let catchablePct = 0;
        let completedAirYards = 0;
        let intendedAirYards = 0;
        let yardsAfterCatch = 0;
        let ydsAftCatchPerReceive = 0;
        let drops = 0;
        let dropPct = 0;
        let firstDowns = 0;
        let firstDownPct = 0;
        let yards = 0;
        let td = 0;
        let int = 0;
        let receiverRating = 0;
        let eps = 0;
        let receivingstats = this.receivingstats;
        if (receivingstats.length !== 0){
            for (var i=0; i<receivingstats.length; i++) {
                if (!this.isLeaderboards || receivingstats[i].targets >= this.minFilter){
                    player = receivingstats[i].player;
                    playerId = receivingstats[i].playerId;
                    positionName = receivingstats[i].position;
                    season = receivingstats[i].season;
                    team = receivingstats[i].team;
                    targets = receivingstats[i].targets;
                    recs = receivingstats[i].recs;
                    compPct = compPct = NullDiv(receivingstats[i].recs,receivingstats[i].targets)*100;
                    teamTargets = receivingstats[i].teamTargets;
                    targetShare = NullDiv(receivingstats[i].targets,receivingstats[i].teamTargets)*100;
//                    avgTgtDepth = NullDiv(receivingstats[i].airYards, receivingstats[i].recs);
                    intendedAirYards = receivingstats[i].intendedAirYards;
                    avgTgtDepth = NullDiv(receivingstats[i].intendedAirYards, receivingstats[i].targets);
                    catchableTgts = receivingstats[i].catchableTgts;
                    catchablePct = receivingstats[i].catchablePct*100;
                    completedAirYards = receivingstats[i].completedAirYards;
                    yardsAfterCatch = receivingstats[i].yardsAfterCatch;
                    ydsAftCatchPerReceive = NullDiv(receivingstats[i].yardsAfterCatch,receivingstats[i].recs);
                    drops = receivingstats[i].drops;
                    dropPct = NullDiv(receivingstats[i].drops,receivingstats[i].catchableTgts)*100;
                    firstDowns = receivingstats[i].firstDowns;
                    firstDownPct = receivingstats[i].firstDownPct*100;
                    yards = receivingstats[i].yards;
                    td = receivingstats[i].td;
                    int = receivingstats[i].interceptions;
                    receiverRating = receivingstats[i].receiverRating;
                    eps = Tst2015(this.receivingstats[i].season, this.receivingstats[i].eps);
                    data.push({
                        player,
                        playerId,
                        positionName,
                        season,
                        team,
                        targets,
                        recs,
                        compPct,
                        teamTargets,
                        targetShare,
                        avgTgtDepth,
                        catchableTgts,
                        catchablePct,
                        completedAirYards,
                        intendedAirYards,
                        yardsAfterCatch,
                        ydsAftCatchPerReceive,
                        drops,
                        dropPct,
                        firstDowns,
                        firstDownPct,
                        yards,
                        td,
                        int,
                        receiverRating,
                        eps
                    });
                }
            }
            this.data = data;
        }
    }

    // Add totals row to data.(called on component mount if this is the players page.)
    SumData() {
        let season = 'Total';
        let team = '';
        let targets = 0;
        let recs = 0;
        let targetShare = 0;
        let teamTargets = 0;
        let catchableTgts = 0;
        let completedAirYards = 0;
        let intendedAirYards = 0;
        let yardsAfterCatch = 0;
        let drops = 0;
        let firstDowns = 0;
        let compPct = 0;
        let avgTgtDepth = 0;
        let catchablePct = 0;
        let ydsAftCatchPerReceive = 0;
        let dropPct = 0;
        let firstDownPct = 0;
        let eps = 0;
        let yards = 0;  //not displayed. for IQR calculation only
        let td = 0;     //not displayed. for IQR calculation only
        let int = 0;    //not displayed. for IQR calculation only
        if (this.data.length !== 0){
            for (var i = 0; i < this.data.length; i++) {
                targets = NullSum(targets,this.data[i].targets);
                teamTargets = NullSum(teamTargets,this.data[i].teamTargets);
                recs = NullSum(recs,this.data[i].recs);
                catchableTgts = NullSum(catchableTgts,this.data[i].catchableTgts);
                completedAirYards = NullSum(completedAirYards, this.data[i].completedAirYards);
                intendedAirYards = NullSum(intendedAirYards, this.data[i].intendedAirYards);
                yardsAfterCatch = NullSum(yardsAfterCatch,this.data[i].yardsAfterCatch);
                drops = NullSum(drops,this.data[i].drops);
                firstDowns = NullSum(firstDowns,this.data[i].firstDowns);
                yards = NullSum(yards,this.data[i].yards);
                td = NullSum(td,this.data[i].td);
                int = NullSum(int,this.data[i].int);
                eps = NullBypassSum(eps,Tst2015(this.data[i].season,this.data[i].eps));
            }
            compPct = NullDiv(recs,targets)*100;
//            avgTgtDepth = NullDiv(airYards, recs);
            avgTgtDepth = NullDiv(intendedAirYards, targets);
            catchablePct = NullDiv(recs,catchableTgts)*100;
            ydsAftCatchPerReceive = NullDiv(yardsAfterCatch,recs);
            dropPct = NullDiv(drops,catchableTgts)*100;
            firstDownPct = NullDiv(firstDowns,recs)*100;
            targetShare = NullDiv(targets,teamTargets)*100;
            let receiverRating = CalcPlayerRating(targets,recs,yards,td,int);
            this.data.push({
                season,
                team,
                targets,
                recs,
                compPct,
                targetShare,
                avgTgtDepth,
                catchableTgts,
                catchablePct,
                completedAirYards,
                intendedAirYards,
                yardsAfterCatch,
                ydsAftCatchPerReceive,
                drops,
                dropPct,
                firstDowns,
                firstDownPct,
                receiverRating,
                eps
            });
        }
    }
    
    componentDidMount() {
        this.ConsolidateData();
        let pagination = 'local';
        let paginationSize = 50;
        if (!this.isLeaderboards){
            this.SumData();
            pagination = null;
            paginationSize = 0;
        }
        this.tabulator = new Tabulator(this.el, {
            data: this.data,
            reactiveData: true,
            columns: this.cols,
            layout: "fitColumns",  // added by Ruben 7/25/19
            rowFormatter:function(row){
                var data = row.getData();
                if (data.season === 'Total'){
                    row.getElement().style.borderTop = "2px solid #6f88a8";
                    row.getElement().style.borderBottom = "2px solid #6f88a8";
                }
            },
            pagination: pagination,
            paginationSize: paginationSize,
            initialSort: this.initSort,
            resizableColumns: false,
            selectable: false
        });
    }

    componentDidUpdate() {
        this.tabulator.replaceData(this.data);
    }

    onSubmit(e, minFilter){
        this.minFilter = minFilter;
        this.ConsolidateData();
        this.forceUpdate();
    }

    render() {
        return (
            <React.Fragment>
                {
                    this.isLeaderboards &&
                    <div className="shadowdiv" id="filtersDiv">
                        <Filters minFilter={this.minFilter} onSubmit={this.onSubmit} label={"Targets"} />
                        <div ref={el => (this.el = el)} />
                    </div>
                }
                {
                    !this.isLeaderboards &&
                    <div className="shadowdiv">
                        <div id="table-title">Advanced Receiving</div>
                        <div ref={el => (this.el = el)} />
                    </div>
                }
            </React.Fragment>
        );
    }
}

export default TableQBSummaryTabulator;