import {NullTst, Tst2015, NullBypassSum, GameResult} from '../common/CommonFunctions';

const GameLogDefenseBuildData = (d,pd,pr,rd,krt,prt) => {
    // This function consolidates defense stats into an array of objects by season/team.
    //
    // PARAMETERS
    //    pd is an array of passdefense stats with one object per season'
    //    pr is an array of passrush stats with one object per season'
    //    rd is an array of rushdefense stats with one object per season.
    // CONSOLIDATION PROCESS
    // Data consolidation from the three types of data is accomplished by building an
    // array in three steps. The first step adds one object for each passdefense object
    // populating the corresponding fields of the new object. The second step checks for
    // existings object from step one that match year and team of each passrush object
    // If there is a match, appropriate fields are populated or accumulated, otherwise,
    // a new object is created. Step three continues in the same way as step two but for
    // rushdefense data.
    //
    // check if there are no stats
    if (d.length === 0 &&
        pd.length === 0 &&
        pr.length === 0 &&
        rd.length === 0 &&
        krt.length === 0 &&
        prt.length === 0) {
        return([]);
    }
    // initialize variables to be used in generating new consolidated defense objects
    let havepassdefense = false;
    let havepassrush = false;
    let haverushdefense = false;
    let havedefense = false;
    let havekickret = false;
    let havepuntret = false;
    let sortKey = 0;
    let season = 0;
    let playerName = '';
    let playerId = 0;
    let positionName = '';
    let team = '';
    let matchup = '';
    let oppTeam = '';
    let gameWeek = 0;
    let games = 0;
    let gamesStarted = 0;
    let gameDate = '';
    let gameSeasonDateSSSSYYYYMMDD = '';
    let gameResult = '';
    let playeriddf = 0;
    let playeridpd = 0;
    let playeridpr = 0;
    let playeridrd = 0;
    let assistedTackles = 0;
    let avgTackleDepth = 0;
    let batDowns = 0;
    let breakups = 0;
    let brokenTackles = 0;
    let brokenTacklePct = 0;
    let catchableTgts = 0;
    let completionPct = 0;
    let completions = 0;
    let defensed = 0;
    let deflected = 0;
    let deservedCatchPct = 0;
    let droppedInts = 0;
    let drops = 0;
    let forcedFumbles = 0;
    let hitPct = 0;
    let hits = 0;
    let hurries = 0;
    let hurryPct = 0;
    let interceptions = 0;
    let knockdownPct = 0;
    let knockdowns = 0;
    let passesBatted = 0;
    let passesBattedPct = 0;
    let passRushes = 0;
    let passRushPct = 0;
    let passRushSacks = 0;
    let passSnaps = 0;
    let passYards = 0;
    let passYardsPerTgt = 0;
    let pointsSavedPassDef = 0;
    let pointsSavedPassRush = 0
    let pointsSavedRushDef = 0;
    let pressurePct = 0;
    let pressures = 0;
    let rushSnaps = 0;
    let rushTackles = 0;
    let rushYards = 0;
    let sackPct = 0;
    let sacks = 0;
    let soloTackles = 0;
    let tackleForLoss = 0;
    let tackles = 0;
    let targets = 0;
    let td = 0;
    let totCatchable = 0;
    let pntretAttempts = 0;
    let pntretYds = 0;
    let pntretYdsPerAtt = 0;
    let pntretTD = 0;
    let kickretAttempts = 0;
    let kickretYds = 0;
    let kickretYdsPerAtt = 0;
    let kickretTD = 0;
    // initialize array to hold new consolidated defense objects
    var consolidatedData = [];
    // Consolidation step 1 - build one new consolidated object per defense object
    var i = 0;
    if (d.length !== 0){
        for (i=0; i<d.length; i++) {
            // populate corresponding passdefense fields
            havedefense = true;
            havepassdefense = false;
            havepassrush = false;
            haverushdefense = false;
            havekickret = false;
            havepuntret = false;
            sortKey = ((9999 - d[i].season) * 1000000) + (d[i].season * 100) + (d[i].week);
            season = d[i].season;
            playerName = d[i].playerName;
            playerId = d[i].playerId;
            positionName = d[i].positionName;
            team = d[i].teamName;
            matchup = d[i].teamName.trim() + ' ' + d[i].oppTeamName.trim();
            games = 1;
            gameDate = d[i].gameDate;
            gameSeasonDateSSSSYYYYMMDD = d[i].season.toString() + d[i].gameDate.substring(0, 10);
            gameResult = GameResult(d[i].teamScore, d[i].oppTeamScore);
            gameWeek = d[i].week;
            gamesStarted = d[i].starts;
            oppTeam = d[i].oppTeamName;
            assistedTackles = d[i].assistedTackles;
            batDowns = d[i].passBatDowns;
            defensed = d[i].passesDefensed;
            deflected = d[i].deflections;
            breakups = NullBypassSum(d[i].passBatDowns, NullBypassSum(d[i].passesDefensed, d[i].deflections));
            droppedInts = d[i].droppedInts;
            forcedFumbles = d[i].forcedFumbles;
            hits = d[i].hitPasser;
            hurries = d[i].hurries;
            interceptions = d[i].ints;
            knockdowns = d[i].passerKnockDowns;
            playeriddf = d[i].playerId;
            soloTackles = d[i].soloTackles;
            tackles = d[i].totalTackles;
            // add new consolidated object to the array
            consolidatedData.push({
                sortKey,
                season,
                playerName,
                playerId,
                positionName,
                team,
                matchup,
                games,
                gameDate,
                gameSeasonDateSSSSYYYYMMDD,
                gameResult,
                gameWeek,
                gamesStarted,
                oppTeam,
                havepassdefense,
                havepassrush,
                haverushdefense,
                havedefense,
                havekickret,
                havepuntret,
                assistedTackles,
                avgTackleDepth,
                catchableTgts,
                batDowns,
                breakups,
                brokenTackles,
                brokenTacklePct,
                completionPct,
                completions,
                defensed,
                deflected,
                deservedCatchPct,
                drops,
                droppedInts,
                forcedFumbles,
                hitPct,
                hits,
                hurries,
                hurryPct,
                interceptions,
                knockdownPct,
                knockdowns,
                passesBatted,
                passesBattedPct,
                passRushes,
                passRushPct,
                passRushSacks,
                passSnaps,
                passYards,
                passYardsPerTgt,
                playeriddf,
                playeridpd,
                playeridpr,
                playeridrd,
                pointsSavedPassDef,
                pointsSavedPassRush,
                pointsSavedRushDef,
                pressurePct,
                pressures,
                rushSnaps,
                rushTackles,
                rushYards,
                sackPct,
                sacks,
                soloTackles,
                tackleForLoss,
                tackles,
                targets,
                td,
                totCatchable,
                pntretAttempts,
                pntretYds,
                pntretYdsPerAtt,
                pntretTD,
                kickretAttempts,
                kickretYds,
                kickretYdsPerAtt,
                kickretTD
            });
        }
    }
    // Consolidation step 2 - build new or updated existing consolidated object per passdefense object
    let j = -1;
    if (pd.length !==0) {
        // TableDefenseListData("PASSDEFENSE",pd);
        for (i=0; i<pd.length; i++) {
            for (j = consolidatedData.length - 1; j > -1; j--) {
                if (consolidatedData[j].gameDate === pd[i].gameDate) {
                    break;
                }
            }
            if (j > -1){
                consolidatedData[j].havepassdefense = true;
//                consolidatedData[j].breakups = pd[i].totalBreakups; // 9/8/19 RA - doesn't always match the sum of breakups. Will calculate programatically
                consolidatedData[j].brokenTackles = pd[i].tackleBroken;
                consolidatedData[j].catchableTgts = pd[i].catchableTgts;
                consolidatedData[j].completionPct = pd[i].compPct;
                consolidatedData[j].completions = pd[i].completions;
                consolidatedData[j].deservedCatchPct = Tst2015(pd[i].season, pd[i].deservedCatchRateAllowed);
                consolidatedData[j].drops = pd[i].drops;
                consolidatedData[j].interceptions = pd[i].ints;
                consolidatedData[j].passYards = pd[i].yards;
                consolidatedData[j].passYardsPerTgt = pd[i].yardsPerTarget;
                consolidatedData[j].playeridpd = pd[i].playerId;
                consolidatedData[j].pointsSavedPassDef = pd[i].eps;
                consolidatedData[j].tackleForLoss = pd[i].tfl;
                consolidatedData[j].gamesStarted = pd[i].starts; // overriding value on defense endpoint which is incorrect
                consolidatedData[j].targets = pd[i].targets;
                consolidatedData[j].td = pd[i].td;
                consolidatedData[j].totCatchable = pd[i].totCatchable;
            }
            else{
                // populate corresponding passdefense fields
                havedefense = false;
                havepassdefense = true;
                havepassrush = false;
                haverushdefense = false;
                havekickret = false;
                havepuntret = false;
                sortKey = ((9999 - pd[i].season) * 1000000) + (pd[i].season * 100) + (pd[i].week);
                season = pd[i].season;
                playerName = pd[i].playerName;
                playerId = pd[i].playerId;
                positionName = pd[i].positionName;
                team = pd[i].teamName;
                matchup = pd[i].teamName.trim() + ' ' + pd[i].oppTeamName.trim();
                games = 1;
                gameDate = pd[i].gameDate;
                gameSeasonDateSSSSYYYYMMDD = pd[i].season.toString() + pd[i].gameDate.substring(0, 10);
                gameResult = GameResult(pd[i].teamScore, pd[i].oppTeamScore);
                gameWeek = pd[i].week;
                gamesStarted = pd[i].starts;
                oppTeam = pd[i].oppTeamName;
                assistedTackles = 0;
                batDowns = 0;
//                breakups = pd[i].totalBreakups;  // 9/8/19 RA - will calculate programatically
                brokenTackles = pd[i].tackleBroken;
                // brokenTacklePct = pd[i].brokenTacklePct;
                catchableTgts = pd[i].catchableTgts;
                completionPct = pd[i].compPct;
                completions = pd[i].completions;
                defensed = 0;
                deflected = 0;
                deservedCatchPct = pd[i].deservedCatchRateAllowed;
                drops = pd[i].drops;
                droppedInts = 0;
                forcedFumbles = 0; // pd[i].forcedFumbles;
                interceptions = pd[i].ints;
                passYards = pd[i].yards;
                passYardsPerTgt = pd[i].yardsPerTarget;
                playeriddf = 0;
                playeridpd = pd[i].playerId;
                playeridpr = 0;
                playeridrd = 0;
                pointsSavedPassDef = pd[i].eps;
                pointsSavedPassRush = 0;
                pointsSavedRushDef = 0;
                positionName = pd[i].positionName;
                soloTackles = 0;
                tackles = 0;
                tackleForLoss = pd[i].tfl;
                targets = pd[i].targets;
                td = pd[i].td;
                totCatchable = pd[i].totCatchable;
                pntretAttempts =0;
                pntretYds =0;
                pntretYdsPerAtt =0;
                pntretTD =0;
                kickretAttempts = 0;
                kickretYds = 0;
                kickretYdsPerAtt = 0;
                kickretTD = 0;
            // add new consolidated object to the array
                consolidatedData.push({
                    sortKey,
                    season,
                    playerName,
                    playerId,
                    positionName,
                    team,
                    matchup,
                    games,
                    gameDate,
                    gameSeasonDateSSSSYYYYMMDD,
                    gameResult,
                    gameWeek,
                    gamesStarted,
                    oppTeam,
                    havepassdefense,
                    havepassrush,
                    haverushdefense,
                    havedefense,
                    havekickret,
                    havepuntret,
                    assistedTackles,
                    avgTackleDepth,
                    catchableTgts,
                    batDowns,
                    breakups,
                    brokenTackles,
                    brokenTacklePct,
                    completionPct,
                    completions,
                    defensed,
                    deflected,
                    deservedCatchPct,
                    drops,
                    droppedInts,
                    forcedFumbles,
                    hitPct,
                    hits,
                    hurries,
                    hurryPct,
                    interceptions,
                    knockdownPct,
                    knockdowns,
                    passesBatted,
                    passesBattedPct,
                    passRushes,
                    passRushPct,
                    passRushSacks,
                    passSnaps,
                    passYards,
                    passYardsPerTgt,
                    playeriddf,
                    playeridpd,
                    playeridpr,
                    playeridrd,
                    pointsSavedPassDef,
                    pointsSavedPassRush,
                    pointsSavedRushDef,
                    pressurePct,
                    pressures,
                    rushSnaps,
                    rushTackles,
                    rushYards,
                    sackPct,
                    sacks,
                    soloTackles,
                    tackleForLoss,
                    tackles,
                    targets,
                    td,
                    totCatchable,
                    pntretAttempts,
                    pntretYds,
                    pntretYdsPerAtt,
                    pntretTD,
                    kickretAttempts,
                    kickretYds,
                    kickretYdsPerAtt,
                    kickretTD
                });
            }
        }
    }
    // Consolidation step 3 - build new or updated existing consolidated object per passrush object
    j = -1;
    if (pr.length !== 0) {
        for (i=0; i<pr.length; i++) {
            // search for object that matches passrush season (and team when the field is added to the api)
            for (j = consolidatedData.length - 1; j > -1; j--) {
                if (consolidatedData[j].gameDate === pr[i].gameDate) {
                    break;
                }
            }
            if (j > -1) {
                // if matching object is found update(=)/accumulate(+=) fields as appropriate
                consolidatedData[j].havepassrush = true;
                consolidatedData[j].passesBatted = pr[i].passesBatted;
                consolidatedData[j].passRushes = pr[i].passRushes;
                consolidatedData[j].passRushPct = pr[i].passSnaps ? (NullTst(pr[i].passRushes)/pr[i].passSnaps)*100 : 0;
                consolidatedData[j].passRushSacks = pr[i].combinedSacks;
                consolidatedData[j].passSnaps = pr[i].passSnaps;
                consolidatedData[j].playeridpr = pr[i].playerId;
                consolidatedData[j].pointsSavedPassRush = pr[i].eps;
                consolidatedData[j].pressurePct = pr[i].pressurePct*100;
                consolidatedData[j].pressures = pr[i].pressures;
                consolidatedData[j].sackPct = pr[i].sackPct*100;
                consolidatedData[j].sacks = pr[i].combinedSacks;
                consolidatedData[j].season = pr[i].season;
                // consolidatedData[j].tackles += pr[i].tackles; // according to Trish, passrush tackles duplicates tackles in other endpoints
                if (pr[i].passRushes) {
                    consolidatedData[j].hitPct = (pr[i].hits/pr[i].passRushes)*100;
                    consolidatedData[j].hurryPct = (pr[i].hurriedPasser/pr[i].passRushes)*100;
                    consolidatedData[j].knockdownPct = (pr[i].knockDownPasser/pr[i].passRushes)*100;
                    consolidatedData[j].passesBattedPct = (pr[i].passesBatted/pr[i].passRushes)*100;
                } else {
                    consolidatedData[j].hitPct = 0;
                    consolidatedData[j].hurryPct = 0;
                    consolidatedData[j].knockdownPct = 0;
                    consolidatedData[j].passesBattedPct = 0;
                }
            } else {
                // if no match, populate corresponding passrush fields and initialize the rest then add object to array
                havedefense = false;
                havepassdefense = false;
                havepassrush = true;
                haverushdefense = false;
                havekickret = false;
                havepuntret = false;
                sortKey = ((9999 - pr[i].season) * 1000000) + (pr[i].season * 100) + (pr[i].week);
                season = pr[i].season;
                playerName = pr[i].playerName;
                playerId = pr[i].playerId;
                positionName = pr[i].positionName;
                team = pr[i].teamName;
                matchup = pr[i].teamName.trim() + ' ' + pr[i].oppTeamName.trim();
                games = 1;
                gameDate = pr[i].gameDate;
                gameSeasonDateSSSSYYYYMMDD = pr[i].season.toString() + pr[i].gameDate.substring(0, 10);
                gameResult = GameResult(pr[i].teamScore, pr[i].oppTeamScore);
                gameWeek = pr[i].week;
                gamesStarted = pr[i].starts;
                oppTeam = pr[i].oppTeamName;
                assistedTackles = 0;
                avgTackleDepth = 0;
                batDowns = 0;
                breakups = 0;
                brokenTackles = 0;
                brokenTacklePct = 0;
                catchableTgts = 0;
                completionPct = 0;
                completions = 0;
                defensed = 0;
                deflected = 0;
                deservedCatchPct = 0;
                drops = 0;
                droppedInts = 0;
                forcedFumbles = 0; // pr[i].ff;
                hits = 0;
                hurries = 0;
                interceptions = 0;
                knockdowns = 0;
                passesBatted = pr[i].passesBatted;
                passRushes = pr[i].passRushes;
                passRushPct = pr[i].passSnaps ? (NullTst(pr[i].passRushes)/pr[i].passSnaps)*100 : 0;
                passRushSacks = pr[i].combinedSacks;
                passSnaps = pr[i].passSnaps;
                passYards = 0;
                passYardsPerTgt = 0;
                playeriddf = 0;
                playeridpd = pr[i].playerId;
                playeridpr = pr[i].playerId;
                playeridrd = 0;
                pointsSavedPassDef = 0;
                pointsSavedPassRush = pr[i].eps;
                pointsSavedRushDef = 0;
                pressurePct = pr[i].pressurePct*100;
                pressures = Tst2015(pr[i].season,pr[i].pressures);
                rushSnaps = 0;
                rushTackles = 0;
                rushYards = 0;
                sackPct = pr[i].sackPct*100;
                sacks = pr[i].combinedSacks;
                soloTackles = 0;
                tackleForLoss = 0;
                // tackles = pr[i].tackles;  // according to Trish, passrush tackles duplicates tackles in other endpoints
                tackles = 0;
                targets = 0;
                td = 0;
                totCatchable = 0;
                if (passRushes) {
                    hitPct = (pr[i].hits/pr[i].passRushes)*100;
                    hurryPct = (pr[i].hurriedPasser/pr[i].passRushes)*100;
                    knockdownPct = (pr[i].knockDownPasser/pr[i].passRushes)*100;
                    passesBattedPct = (pr[i].passesBatted/pr[i].passRushes)*100;
                } else {
                    hitPct = 0;
                    hurryPct = 0;
                    knockdownPct = 0;
                    passesBattedPct = 0;
                }
                pntretAttempts =0;
                pntretYds =0;
                pntretYdsPerAtt =0;
                pntretTD =0;
                kickretAttempts = 0;
                kickretYds = 0;
                kickretYdsPerAtt = 0;
                kickretTD = 0;
            // add new consolidated object to the array
                consolidatedData.push({
                    sortKey,
                    season,
                    playerName,
                    playerId,
                    positionName,
                    team,
                    matchup,
                    games,
                    gameDate,
                    gameSeasonDateSSSSYYYYMMDD,
                    gameResult,
                    gameWeek,
                    gamesStarted,
                    oppTeam,
                    havepassdefense,
                    havepassrush,
                    haverushdefense,
                    havedefense,
                    havekickret,
                    havepuntret,
                    assistedTackles,
                    avgTackleDepth,
                    catchableTgts,
                    batDowns,
                    breakups,
                    brokenTackles,
                    brokenTacklePct,
                    completionPct,
                    completions,
                    defensed,
                    deflected,
                    deservedCatchPct,
                    drops,
                    droppedInts,
                    forcedFumbles,
                    hitPct,
                    hits,
                    hurries,
                    hurryPct,
                    interceptions,
                    knockdownPct,
                    knockdowns,
                    passesBatted,
                    passesBattedPct,
                    passRushes,
                    passRushPct,
                    passRushSacks,
                    passSnaps,
                    passYards,
                    passYardsPerTgt,
                    playeriddf,
                    playeridpd,
                    playeridpr,
                    playeridrd,
                    pointsSavedPassDef,
                    pointsSavedPassRush,
                    pointsSavedRushDef,
                    pressurePct,
                    pressures,
                    rushSnaps,
                    rushTackles,
                    rushYards,
                    sackPct,
                    sacks,
                    soloTackles,
                    tackleForLoss,
                    tackles,
                    targets,
                    td,
                    totCatchable,
                    pntretAttempts,
                    pntretYds,
                    pntretYdsPerAtt,
                    pntretTD,
                    kickretAttempts,
                    kickretYds,
                    kickretYdsPerAtt,
                    kickretTD
                });
            }
        }
    }   
    // Consolidation step 4 - build new or updated existing consolidated object per rushdefense object
    if (rd.length !==0) {
        for (i=0; i<rd.length; i++) {
            // search for object that matches rushdefense season/team
            for (j = consolidatedData.length - 1; j > -1; j--) {
                if (consolidatedData[j].gameDate === rd[i].gameDate) {
                    break;
                }
            }
            // if matching object is found update(=)/accumulate(+=) fields as appropriate
            // if (rd[i].games) {
                if (j > -1) {
                    consolidatedData[j].haverushdefense = true;
                    consolidatedData[j].avgTackleDepth = rd[i].avgTackleDepth;
                    consolidatedData[j].brokenTackles += rd[i].tackleBroken;
                    consolidatedData[j].playeridrd = rd[i].playerId;
                    consolidatedData[j].pointsSavedRushDef = rd[i].eps;
                    consolidatedData[j].rushSnaps = rd[i].rushSnaps;
                    consolidatedData[j].rushTackles = rd[i].combinedTackles;
                    consolidatedData[j].rushYards = rd[i].totalYards;
                    if (consolidatedData[j].tackleForLoss !== null) {
                        consolidatedData[j].tackleForLoss += NullTst(rd[i].tfl); // remove *2 when backend fixed
                    } else {
                        consolidatedData[j].tackleForLoss = (rd[i].tfl); // remove *2 when backend fixed
                    }
                // if no match, populate corresponding rushdefense fields and initialize the rest then add object to array
                } else {
                    havepassdefense = false;
                    havepassrush = false;
                    haverushdefense = true;
                    havedefense = false;
                    havekickret = false;
                    havepuntret = false;
                    sortKey = ((9999 - rd[i].season) * 1000000) + (rd[i].season * 100) + (rd[i].week);
                    season = rd[i].season;
                    playerName = rd[i].playerName;
                    playerId = rd[i].playerId;
                    positionName = rd[i].positionName;
                    team = rd[i].teamName;
                    matchup = rd[i].teamName.trim() + ' ' + rd[i].oppTeamName.trim();
                    games = 1;
                    gameDate = rd[i].gameDate;
                    gameSeasonDateSSSSYYYYMMDD = rd[i].season.toString() + rd[i].gameDate.substring(0, 10);
                    gameResult = GameResult(rd[i].teamScore, rd[i].oppTeamScore);
                    gameWeek = rd[i].week;
                    gamesStarted = rd[i].starts;
                    oppTeam = rd[i].oppTeamName;
                    assistedTackles = 0;
                    avgTackleDepth = rd[i].avgTackleDepth;
                    batDowns = 0;
                    breakups = 0;
                    brokenTackles = rd[i].tackleBroken;
                    catchableTgts = 0;
                    completionPct = 0;
                    completions = 0;
                    defensed = 0;
                    deflected = 0;
                    deservedCatchPct = 0;
                    drops = 0;
                    droppedInts = 0;
                    forcedFumbles = 0; // rd[i].ff;
                    hitPct = 0;
                    hits = 0;
                    hurries = 0;
                    hurryPct = 0;
                    interceptions = 0;
                    knockdownPct = 0;
                    knockdowns = 0;
                    passesBatted = 0;
                    passesBattedPct = 0;
                    passRushes = 0;
                    passRushPct = 0;
                    passRushSacks = 0;
                    passSnaps = 0;
                    passYards = 0;
                    passYardsPerTgt = 0;
                    playeriddf = 0;
                    playeridpd = 0;
                    playeridpr = 0;
                    playeridrd = rd[i].playerId;
                    pointsSavedPassDef = 0;
                    pointsSavedPassRush = 0;
                    pointsSavedRushDef = rd[i].eps;
                    pressurePct = 0;
                    pressures = 0;
                    rushSnaps = rd[i].rushSnaps;
                    rushTackles = rd[i].combinedTackles;
                    rushYards = rd[i].totalYards;
                    sackPct = 0;
                    sacks = 0;
                    soloTackles = 0;
                    tackleForLoss = rd[i].tfl;
                    tackles = rd[i].combinedTackles;
                    targets = 0;
                    td = 0;
                    catchableTgts = 0;
                    pntretAttempts =0;
                    pntretYds =0;
                    pntretYdsPerAtt =0;
                    pntretTD =0;
                    kickretAttempts = 0;
                    kickretYds = 0;
                    kickretYdsPerAtt = 0;
                    kickretTD = 0;
                    // add new consolidated object to the array
                    consolidatedData.push({
                        sortKey,
                        season,
                        playerName,
                        playerId,
                        positionName,
                        team,
                        matchup,
                        games,
                        gameDate,
                        gameSeasonDateSSSSYYYYMMDD,
                        gameResult,
                        gameWeek,
                        gamesStarted,
                        oppTeam,
                        havepassdefense,
                        havepassrush,
                        haverushdefense,
                        havedefense,
                        havekickret,
                        havepuntret,
                        assistedTackles,
                        avgTackleDepth,
                        catchableTgts,
                        batDowns,
                        breakups,
                        brokenTackles,
                        brokenTacklePct,
                        completionPct,
                        completions,
                        defensed,
                        deflected,
                        deservedCatchPct,
                        drops,
                        droppedInts,
                        forcedFumbles,
                        hitPct,
                        hits,
                        hurries,
                        hurryPct,
                        interceptions,
                        knockdownPct,
                        knockdowns,
                        passesBatted,
                        passesBattedPct,
                        passRushes,
                        passRushPct,
                        passRushSacks,
                        passSnaps,
                        passYards,
                        passYardsPerTgt,
                        playeriddf,
                        playeridpd,
                        playeridpr,
                        playeridrd,
                        pointsSavedPassDef,
                        pointsSavedPassRush,
                        pointsSavedRushDef,
                        pressurePct,
                        pressures,
                        rushSnaps,
                        rushTackles,
                        rushYards,
                        sackPct,
                        sacks,
                        soloTackles,
                        tackleForLoss,
                        tackles,
                        targets,
                        td,
                        totCatchable,
                        pntretAttempts,
                        pntretYds,
                        pntretYdsPerAtt,
                        pntretTD,
                        kickretAttempts,
                        kickretYds,
                        kickretYdsPerAtt,
                        kickretTD
                    });
                }
            // }
        }
    }
    // Consolidation step 5 - build new or updated existing consolidated object per kick return object
    if (krt.length !==0) {
        for (i=0; i<krt.length; i++) {
            // search for object that matches rushdefense season/team
            for (j = consolidatedData.length - 1; j > -1; j--) {
                if (consolidatedData[j].gameDate === krt[i].gameDate) {
                    break;
                }
            }
            // if matching object is found update(=)/accumulate(+=) fields as appropriate
                if (j > -1) {
                    consolidatedData[j].kickretAttempts = krt[i].attempts;
                    consolidatedData[j].kickretYds = krt[i].yards;
                    consolidatedData[j].kickretYdsPerAtt = krt[i].attempts ? krt[i].yards/krt[i].attempts : 0;
                    consolidatedData[j].kickretTD = krt[i].tDs;
                // if no match, populate corresponding rushdefense fields and initialize the rest then add object to array
                } else {
                    havepassdefense = false;
                    havepassrush = false;
                    haverushdefense = true;
                    havedefense = false;
                    havekickret = false;
                    havepuntret = false;
                    sortKey = ((9999 - krt[i].season) * 1000000) + (krt[i].season * 100) + (krt[i].week);
                    season = krt[i].season;
                    playerName = krt[i].playerName;
                    playerId = krt[i].playerId;
                    positionName = krt[i].positionName;
                    team = krt[i].teamName;
                    matchup = krt[i].teamName.trim() + ' ' + krt[i].oppTeamName.trim();
                    games = 1;
                    gameDate = krt[i].gameDate;
                    gameSeasonDateSSSSYYYYMMDD = krt[i].season.toString() + krt[i].gameDate.substring(0, 10);
                    gameResult = GameResult(krt[i].teamScore, krt[i].oppTeamScore);
                    gameWeek = krt[i].week;
                    gamesStarted = krt[i].starts;
                    oppTeam = krt[i].oppTeamName;
                    assistedTackles = 0;
                    avgTackleDepth = 0;
                    batDowns = 0;
                    breakups = 0;
                    brokenTackles = 0;
                    catchableTgts = 0;
                    completionPct = 0;
                    completions = 0;
                    defensed = 0;
                    deflected = 0;
                    deservedCatchPct = 0;
                    drops = 0;
                    droppedInts = 0;
                    forcedFumbles = 0;
                    hitPct = 0;
                    hits = 0;
                    hurries = 0;
                    hurryPct = 0;
                    interceptions = 0;
                    knockdownPct = 0;
                    knockdowns = 0;
                    passesBatted = 0;
                    passesBattedPct = 0;
                    passRushes = 0;
                    passRushPct = 0;
                    passRushSacks = 0;
                    passSnaps = 0;
                    passYards = 0;
                    passYardsPerTgt = 0;
                    playeriddf = 0;
                    playeridpd = 0;
                    playeridpr = 0;
                    playeridrd = krt[i].playerId;
                    pointsSavedPassDef = 0;
                    pointsSavedPassRush = 0;
                    pointsSavedRushDef = 0;
                    pressurePct = 0;
                    pressures = 0;
                    rushSnaps = 0;
                    rushTackles = 0;
                    rushYards = 0;
                    sackPct = 0;
                    sacks = 0;
                    season = krt[i].season;
                    soloTackles = 0;
                    tackleForLoss = 0;
                    tackles = 0;
                    targets = 0;
                    td = 0;
                    catchableTgts = 0;
                    pntretAttempts = 0;
                    pntretYds = 0;
                    pntretYdsPerAtt = 0;
                    pntretTD = 0;
                    kickretAttempts = krt[i].attempts;
                    kickretYds = krt[i].yards;
                    kickretYdsPerAtt = krt[i].attempts ? krt[i].yards/krt[i].attempts : 0;
                    kickretTD = krt[i].tDs;
                // add new consolidated object to the array
                    consolidatedData.push({
                        sortKey,
                        season,
                        playerName,
                        playerId,
                        positionName,
                        team,
                        matchup,
                        games,
                        gameDate,
                        gameSeasonDateSSSSYYYYMMDD,
                        gameResult,
                        gameWeek,
                        gamesStarted,
                        oppTeam,
                        havepassdefense,
                        havepassrush,
                        haverushdefense,
                        havedefense,
                        havekickret,
                        havepuntret,
                        assistedTackles,
                        avgTackleDepth,
                        catchableTgts,
                        batDowns,
                        breakups,
                        brokenTackles,
                        brokenTacklePct,
                        completionPct,
                        completions,
                        defensed,
                        deflected,
                        deservedCatchPct,
                        drops,
                        droppedInts,
                        forcedFumbles,
                        hitPct,
                        hits,
                        hurries,
                        hurryPct,
                        interceptions,
                        knockdownPct,
                        knockdowns,
                        passesBatted,
                        passesBattedPct,
                        passRushes,
                        passRushPct,
                        passRushSacks,
                        passSnaps,
                        passYards,
                        passYardsPerTgt,
                        playeriddf,
                        playeridpd,
                        playeridpr,
                        playeridrd,
                        pointsSavedPassDef,
                        pointsSavedPassRush,
                        pointsSavedRushDef,
                        pressurePct,
                        pressures,
                        rushSnaps,
                        rushTackles,
                        rushYards,
                        sackPct,
                        sacks,
                        soloTackles,
                        tackleForLoss,
                        tackles,
                        targets,
                        td,
                        totCatchable,
                        pntretAttempts,
                        pntretYds,
                        pntretYdsPerAtt,
                        pntretTD,
                        kickretAttempts,
                        kickretYds,
                        kickretYdsPerAtt,
                        kickretTD
                    });
                }
            // }
        }
    }
    // Consolidation step 6 - build new or updated existing consolidated object per punt return object
    if (prt.length !==0) {
        for (i=0; i<prt.length; i++) {
            // search for object that matches rushdefense season/team
            for (j = consolidatedData.length - 1; j > -1; j--) {
                if (consolidatedData[j].gameDate === prt[i].gameDate) {
                    break;
                }
            }
            // if matching object is found update(=)/accumulate(+=) fields as appropriate
                if (j > -1) {
                    consolidatedData[j].pntretAttempts = prt[i].attempts;
                    consolidatedData[j].pntretYds = prt[i].yards;
                    consolidatedData[j].pntretYdsPerAtt = prt[i].attempts ? prt[i].yards/prt[i].attempts : 0;
                    consolidatedData[j].pntretTD = prt[i].tDs;
                // if no match, populate corresponding rushdefense fields and initialize the rest then add object to array
                } else {
                    havepassdefense = false;
                    havepassrush = false;
                    haverushdefense = true;
                    havedefense = false;
                    havekickret = false;
                    havepuntret = false;
                    sortKey = ((9999 - prt[i].season) * 1000000) + (prt[i].season * 100) + (prt[i].week);
                    season = prt[i].season;
                    playerName = prt[i].playerName;
                    playerId = prt[i].playerId;
                    positionName = prt[i].positionName;
                    team = prt[i].teamName;
                    matchup = prt[i].teamName.trim() + ' ' + prt[i].oppTeamName.trim();
                    games = 1;
                    gameDate = prt[i].gameDate;
                    gameSeasonDateSSSSYYYYMMDD = prt[i].season.toString() + prt[i].gameDate.substring(0, 10);
                    gameResult = GameResult(prt[i].teamScore, prt[i].oppTeamScore);
                    gameWeek = prt[i].week;
                    gamesStarted = prt[i].starts;
                    oppTeam = prt[i].oppTeamName;
                    assistedTackles = 0;
                    avgTackleDepth = 0;
                    batDowns = 0;
                    breakups = 0;
                    brokenTackles = 0;
                    catchableTgts = 0;
                    completionPct = 0;
                    completions = 0;
                    defensed = 0;
                    deflected = 0;
                    deservedCatchPct = 0;
                    drops = 0;
                    droppedInts = 0;
                    forcedFumbles = 0;
                    hitPct = 0;
                    hits = 0;
                    hurries = 0;
                    hurryPct = 0;
                    interceptions = 0;
                    knockdownPct = 0;
                    knockdowns = 0;
                    passesBatted = 0;
                    passesBattedPct = 0;
                    passRushes = 0;
                    passRushPct = 0;
                    passRushSacks = 0;
                    passSnaps = 0;
                    passYards = 0;
                    passYardsPerTgt = 0;
                    playeriddf = 0;
                    playeridpd = 0;
                    playeridpr = 0;
                    playeridrd = prt[i].playerId;
                    pointsSavedPassDef = 0;
                    pointsSavedPassRush = 0;
                    pointsSavedRushDef = 0;
                    pressurePct = 0;
                    pressures = 0;
                    rushSnaps = 0;
                    rushTackles = 0;
                    rushYards = 0;
                    sackPct = 0;
                    sacks = 0;
                    soloTackles = 0;
                    tackleForLoss = 0;
                    tackles = 0;
                    targets = 0;
                    td = 0;
                    catchableTgts = 0;
                    pntretAttempts = prt[i].attempts;
                    pntretYds = prt[i].yards;
                    pntretYdsPerAtt = prt[i].attempts ? prt[i].yards/prt[i].attempts : 0;
                    pntretTD = prt[i].tDs;
                    kickretAttempts = 0;
                    kickretYds = 0;
                    kickretYdsPerAtt = 0;
                    kickretTD = 0;
                // add new consolidated object to the array
                    consolidatedData.push({
                        sortKey,
                        season,
                        playerName,
                        playerId,
                        positionName,
                        team,
                        matchup,
                        games,
                        gameDate,
                        gameSeasonDateSSSSYYYYMMDD,
                        gameResult,
                        gameWeek,
                        gamesStarted,
                        oppTeam,
                        havepassdefense,
                        havepassrush,
                        haverushdefense,
                        havedefense,
                        havekickret,
                        havepuntret,
                        assistedTackles,
                        avgTackleDepth,
                        catchableTgts,
                        batDowns,
                        breakups,
                        brokenTackles,
                        brokenTacklePct,
                        completionPct,
                        completions,
                        defensed,
                        deflected,
                        deservedCatchPct,
                        drops,
                        droppedInts,
                        forcedFumbles,
                        hitPct,
                        hits,
                        hurries,
                        hurryPct,
                        interceptions,
                        knockdownPct,
                        knockdowns,
                        passesBatted,
                        passesBattedPct,
                        passRushes,
                        passRushPct,
                        passRushSacks,
                        passSnaps,
                        passYards,
                        passYardsPerTgt,
                        playeriddf,
                        playeridpd,
                        playeridpr,
                        playeridrd,
                        pointsSavedPassDef,
                        pointsSavedPassRush,
                        pointsSavedRushDef,
                        pressurePct,
                        pressures,
                        rushSnaps,
                        rushTackles,
                        rushYards,
                        sackPct,
                        sacks,
                        soloTackles,
                        tackleForLoss,
                        tackles,
                        targets,
                        td,
                        totCatchable,
                        pntretAttempts,
                        pntretYds,
                        pntretYdsPerAtt,
                        pntretTD,
                        kickretAttempts,
                        kickretYds,
                        kickretYdsPerAtt,
                        kickretTD
                    });
                }
            // }
        }
    }
    // sort consolidated objects by season
    //make final adjustments as needed
    if (consolidatedData.length !== 0) {
        consolidatedData.sort((a, b) => (a.sortKey > b.sortKey) ? 1 : -1);
        for (i = 0; i < consolidatedData.length; i++) {
            if (consolidatedData[i].season === 2015) {
                //fields displayed in LB and DT summary that are partially or fully sourced from passrush are not valid in 2015 except sacks
                consolidatedData[i].passesBatted = null;
                consolidatedData[i].hurries = null
                consolidatedData[i].hits = null;
                consolidatedData[i].knockdowns = null;
                consolidatedData[i].pressures = null;
            }
        }
    }
    return(consolidatedData); // return the result array
}

export default GameLogDefenseBuildData;