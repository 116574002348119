import React, { useState } from 'react';
import TableDefenseSummaryTabulator from '../pageDefense/TableDefenseSummaryTabulator';
import TableDefenseAdvancedDefenseTabulator from '../pageDefense/TableDefenseAdvancedDefenseTabulator';
import TableDefensePassRushTabulator from '../pageDefense/TableDefensePassRushTabulator';

const DefenseSection = (d) => {
    const [startSeason, setStartSeason] = useState(d.filters.startSeason);
    const [endSeason, setEndSeason] = useState(d.filters.endSeason);
    const [tabName, setTabName] = useState("summary");

    let fromSeasons = [];
    let toSeasons = [];
    for(var i = new Date().getFullYear(); i >= 2015; i--){
        if (i.toString() === d.filters.startSeason){
            fromSeasons.push(<option value={i} selected='selected'>{i}</option>)
        }
        else{
            fromSeasons.push(<option value={i}>{i}</option>)
        } 
        if (i.toString() === d.filters.endSeason){
            toSeasons.push(<option value={i} selected='selected'>{i}</option>)
        }
        else{
            toSeasons.push(<option value={i}>{i}</option>)
        }
    }

    return (
        <React.Fragment>
            <div className="form-group row" id="yearRangePanel">
                <div className="col-sm-3"></div>
                <label htmlFor="fromSeason" className="col-sm-1 col-form-label-sm">From Yr: </label>
                <select className="form-control form-control-sm col-sm-1" name="fromSeason" id="fromSeason" onChange={(event) => setStartSeason(event.target.value)}>
                    {fromSeasons}
                </select>
                <label htmlFor="toSeason" className="col-sm-1 col-form-label-sm">&nbsp;&nbsp;&nbsp;&nbsp;To Yr: </label>
                <select className="form-control form-control-sm col-sm-1" name="toSeason" id="toSeason" onChange={(event) => setEndSeason(event.target.value)}>
                    {toSeasons}
                </select>
                <div className="col-sm-1"></div>
                <button type="submit" className="btn btn-primary" onClick={(e) => d.onSubmit(e, startSeason, endSeason)}>Submit</button>
            </div>

            <div className="shadowdiv">
                <ul className="nav nav-tabs" id="positionSubsections" role="tablist">
                    <li className="nav-item">
                        <a className="nav-link active" id="summary-tab" data-toggle="tab" href="#summary" role="tab" aria-controls="summary" aria-selected="true" onClick={() => setTabName("summary")}>Summary</a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" id="advdefense-tab" data-toggle="tab" href="#advdefense" role="tab" aria-controls="advdefense" aria-selected="false" onClick={() => setTabName("advdefense")}>Adv. Defense</a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" id="passrush-tab" data-toggle="tab" href="#passrush" role="tab" aria-controls="passrush" aria-selected="false" onClick={() => setTabName("passrush")}>Pass Rush</a>
                    </li>
                </ul>
            </div>

            <div onClick={(e) => d.reScroll()}>
                {
                    tabName === "summary" &&
                    <TableDefenseSummaryTabulator defensestats={d.defensestats} isLeaderboards={true} />
                }
                            
                {
                    tabName === "advdefense" &&
                    <TableDefenseAdvancedDefenseTabulator defensestats={d.defensestats} isLeaderboards={true} />
                }
                                
                {
                    tabName === "passrush" &&
                    <TableDefensePassRushTabulator defensestats={d.defensestats} isLeaderboards={true} />                        
                }
            </div>       
        </React.Fragment>
    );
}

export default DefenseSection;
