import React from 'react';
import { NullSum, NullBypassSum, NullMax, NullDiv, Tst2015, DispFunc, GameResult} from '../common/CommonFunctions';
import { rbfbGameSummary } from '../common/TableColumnWidths';
import Tabulator from 'tabulator-tables';
import "tabulator-tables/dist/css/tabulator.min.css";

class GameLogWRTERBFBSummaryTabulator extends React.Component {
    constructor(props) {
        super(props);
        this.rushingstats = this.props.rushingstats;
        this.receivingstats = this.props.receivingstats;
        this.puntretstats = this.props.puntretstats;
        this.kickretstats = this.props.kickretstats;
        this.blockingstats = this.props.blockingstats;
        this.data = [];
        let gameInfoCols = null;
        this.colNum = 0;
        this.maxPlayerSeason = ''

        // Set game information columns
        gameInfoCols = [
            {
                title: "Date", field: "gameSeasonDateSSSSYYYYMMDD", cssClass: "single-row-header textColumn",
                titleFormatter: "html", headerSort: false,
                formatter: function (cell, formatterParams, onRendered) {
                    return (cell.getValue().substr(9, 1) === 'T' ? 'Total' : parseInt(cell.getValue().substr(9, 2)) + '/' + parseInt(cell.getValue().substr(12, 2)));
                },
                width: `${rbfbGameSummary[this.colNum++]}`
            },
            { 
                title: "Wk", field: "gameWeek", cssClass: "single-row-header small-column-center",
                titleFormatter: "html", headerSort: false,
                width: `${rbfbGameSummary[this.colNum++]}`,
//              formatter: function (cell, formatterParams, onRendered) {
//                 return cell = 99 ? '' : cell;
//              }
            },
            { 
                title: "Teams", field: "matchup", cssClass: "single-row-header textColumn",
                titleFormatter: "html", headerSort: false,
                width: `${rbfbGameSummary[this.colNum++]}`
            },  
            {
                title: "Score", field: "gameResult", cssClass: "single-row-header break textColumn",
                titleFormatter: "html", headerSort: false,
                width: `${rbfbGameSummary[this.colNum++]}`
            },
            {
                title: "G", field: "games", cssClass: "single-row-header small-column",
                titleFormatter: "html", headerSort: false,
                width: `${rbfbGameSummary[this.colNum++]}`
            },
            {
                title: "GS", field: "gamesStarted", cssClass: "single-row-header break small-column",
                titleFormatter: "html", headerSort: false,
                width: `${rbfbGameSummary[this.colNum++]}`
            }        
        ];

        // Set Remaining Columns
        this.cols = [
            {
                title: "",
                columns: gameInfoCols
            },
            {
                title: "Rushing",
                columns: [
                    {
                        title: "Att", field: "rushAttempts", cssClass: "single-row-header",
                        width: `${rbfbGameSummary[this.colNum++]}`,
                        headerSortStartingDir: "desc", headerSort: false,
                    },
                    {
                        title: "Yds", field: "rushYds", cssClass: "single-row-header",
                        headerSortStartingDir: "desc", headerSort: false,
                        width: `${rbfbGameSummary[this.colNum++]}`,
                        formatter: "money", formatterParams: {
                            thousand: ',',
                            precision: 0
                        }
                    },
                    {
                        title: "Avg", field: "rushYdsPerAttempt", cssClass: "single-row-header",
                        headerSortStartingDir: "desc", headerSort: false,
                        width: `${rbfbGameSummary[this.colNum++]}`,
                        formatter: "money", formatterParams: {
                            precision: 1
                        }
                    },
                    {
                        title: "Lg", field: "rushLong", cssClass: "single-row-header",
                        width: `${rbfbGameSummary[this.colNum++]}`,
                        headerSortStartingDir: "desc", headerSort: false
                    },
                    {
                        title: "TD", field: "rushTD", cssClass: "single-row-header break",
                        width: `${rbfbGameSummary[this.colNum++]}`,
                        headerSortStartingDir: "desc", headerSort: false
                    },
                    // {    // remove per Matt gauntlet
                    //     title: "Y/G", field: "rushYdsPerGame", cssClass: "single-row-header break",
                    //     headerSortStartingDir: "desc", headerSort: false,
                    //     width: `${rbfbGameSummary[this.colNum++]}`,
                    //     formatter: "money", formatterParams: {
                    //         precision: 1
                    //     }
                    // },
                ],
            },
            {
                title: "Receiving",
                columns: [
                    {
                        title: "Tgts", field: "rcvTargets", cssClass: "single-row-header",
                        width: `${rbfbGameSummary[this.colNum++]}`,
                        headerSortStartingDir: "desc", headerSort: false
                    },
                    {
                        title: "Rec", field: "receives", cssClass: "single-row-header",
                        width: `${rbfbGameSummary[this.colNum++]}`,
                        headerSortStartingDir: "desc", headerSort: false
                    },
                    {
                        title: "Yds", field: "rcvYds", cssClass: "single-row-header",
                        width: `${rbfbGameSummary[this.colNum++]}`,
                        headerSortStartingDir: "desc", headerSort: false,
                        formatter: "money", formatterParams: {
                            thousand: ',',
                            precision: 0
                        }
                    },
                    {
                        title: "Avg", field: "rcvYdsPerRcv", cssClass: "single-row-header",
                        width: `${rbfbGameSummary[this.colNum++]}`,
                        headerSortStartingDir: "desc", headerSort: false,
                        formatter: "money", formatterParams: {
                            precision: 1
                        }
                    },
                    {
                        title: "Lg", field: "rcvLong", cssClass: "single-row-header",
                        width: `${rbfbGameSummary[this.colNum++]}`,
                        headerSortStartingDir: "desc", headerSort: false
                    },
                    {
                        title: "TD", field: "rcvTD", cssClass: "single-row-header break",
                        width: `${rbfbGameSummary[this.colNum++]}`,
                        headerSortStartingDir: "desc", headerSort: false
                    },
                    // {    // remove per Matt gauntlet
                    //     title: "Y/G", field: "rcvYdsPerGame", cssClass: "single-row-header break",
                    //     width: `${rbfbGameSummary[this.colNum++]}`,
                    //     headerSortStartingDir: "desc", headerSort: false,
                    //     formatter: "money", formatterParams: {
                    //         precision: 1
                    //     }
                    // }
                ],
            },
            {
                title: "Punt Returns",
                columns: [
                    {
                        title: "Num", field: "pntretAttempts", cssClass: "single-row-header small-column",
                        width: `${rbfbGameSummary[this.colNum++]}`,
                        headerSortStartingDir: "desc", headerSort: false
                    },
                    {
                        title: "Yds", field: "pntretYards", cssClass: "single-row-header",
                        width: `${rbfbGameSummary[this.colNum++]}`,
                        headerSortStartingDir: "desc", headerSort: false,
                        formatter: "money", formatterParams: {
                            thousand: ',',
                            precision: 0
                        }
                    },
                    {
                        title: "Avg", field: "pntretYdsPerAtt", cssClass: "single-row-header",
                        width: `${rbfbGameSummary[this.colNum++]}`,
                        headerSortStartingDir: "desc", headerSort: false,
                        formatter: "money", formatterParams: {
                            precision: 1
                        }
                    },
                    {
                        title: "TD", field: "pntretTD", cssClass: "single-row-header break small-column",
                        width: `${rbfbGameSummary[this.colNum++]}`,
                        headerSortStartingDir: "desc", headerSort: false
                    }
                ],
            },
            {
                title: "Kickoff Returns",
                columns: [
                    {
                        title: "Num", field: "kickretAttempts", cssClass: "single-row-header small-column",
                        width: `${rbfbGameSummary[this.colNum++]}`,
                        headerSortStartingDir: "desc", headerSort: false
                    },
                    {
                        title: "Yds", field: "kickretYards", cssClass: "single-row-header",
                        width: `${rbfbGameSummary[this.colNum++]}`,
                        headerSortStartingDir: "desc", headerSort: false,
                        formatter: "money", formatterParams: {
                            thousand: ',',
                            precision: 0
                        }
                    },
                    {
                        title: "Avg", field: "kickretYdsPerAtt", cssClass: "single-row-header",
                        width: `${rbfbGameSummary[this.colNum++]}`,
                        headerSortStartingDir: "desc", headerSort: false,
                        formatter: "money", formatterParams: {
                            precision: 1
                        }
                    },
                    {
                        title: "TD", field: "kickretTD", cssClass: "single-row-header break small-column",
                        width: `${rbfbGameSummary[this.colNum++]}`,
                        headerSortStartingDir: "desc", headerSort: false
                    },
                ],
            },
            {
                title: "Fumbles",
                columns: [
                    {
                        title: "Fum", field: "sumFumbles", cssClass: "single-row-header small-column",
                        width: `${rbfbGameSummary[this.colNum++]}`,
                        headerSortStartingDir: "desc", headerSort: false
                    },
                    {
                        title: "Lost", field: "sumFumblesLost", cssClass: "single-row-header break small-column",
                        width: `${rbfbGameSummary[this.colNum++]}`,
                        headerSortStartingDir: "desc", headerSort: false
                    }
                ],
            },
            {
                title: "Tot Pts<br/>Earned", field: "sumPointsEarned", cssClass: "single-row-header",
                headerSortStartingDir: "desc", headerSort: false,
                width: `${rbfbGameSummary[this.colNum++]}`,
                formatter: function (cell, formatterParams, onRendered) {
                    return DispFunc(cell, formatterParams, onRendered, 0, false, false);
                }
            }
        ];
        this.el = React.createRef();
        this.tabulator = null;
    }

    // Consolidate game data into one data array to be used for tabulator (called on component mount)
    ConsolidateData() {
        // console.log('CONSOLIDATE GAMELOG DATA');
        let rushingstats = this.rushingstats;
        let receivingstats = this.receivingstats;
        let puntretstats = this.puntretstats;
        let kickretstats = this.kickretstats;
        let blockingstats = this.blockingstats; // only used for TE position
        let sortKey = 0;
        let season = 0;
        let playerName = '';
        let playerId = 0;
        let positionName = '';
        let team = '';
        let matchup = '';
        let oppTeam = '';
        let gameWeek = 0;
        let games = 0;
        let gameDate = '';
        let gameSeasonDateSSSSYYYYMMDD = '';
        let gameResult = '';
        let gamesStarted = 0;
        let rushAttempts = 0;
        let rushYds = 0;
        let rushYdsPerAttempt = 0;
        let rushLong = 0;
        let rushTD = 0;
        let rushYdsPerGame = 0;
        let receives = 0;
        let rcvYds = 0;
        let rcvYdsPerRcv = 0;
        let rcvLong = 0;
        let rcvTargets = 0;
        let rcvTD = 0;
        let rcvYdsPerGame = 0;
        let pntretAttempts = 0;
        let pntretYards = 0;
        let pntretYdsPerAtt = 0;
        let pntretTD = 0;
        let kickretAttempts = 0;
        let kickretYards = 0;
        let kickretYdsPerAtt = 0;
        let kickretTD = 0;
        let sumFumbles = 0;
        let sumFumblesLost = 0;
        let sumPointsEarned = 0;
    
        var rowdata = [];
        var i = 0;
        if (receivingstats.length !==0) {
            for (i=0; i<receivingstats.length; i++) {
                sortKey = (9999 - receivingstats[i].season) * 1000000 + receivingstats[i].season * 100 + receivingstats[i].week; //XXXXYYYYWW
                season = receivingstats[i].season;
                playerName = receivingstats[i].playerName;
                playerId = receivingstats[i].playerId;
                positionName = receivingstats[i].positionName;
                team = receivingstats[i].teamName;
                matchup = receivingstats[i].teamName.trim() + ' ' + receivingstats[i].oppTeamName.trim();
                games = 1;
                gameDate = receivingstats[i].gameDate;
                gameSeasonDateSSSSYYYYMMDD = receivingstats[i].season.toString() + receivingstats[i].gameDate.substring(0, 10);
                gameResult = GameResult(receivingstats[i].teamScore, receivingstats[i].oppTeamScore);
                gameWeek = receivingstats[i].week;
                gamesStarted = receivingstats[i].starts;
                oppTeam = receivingstats[i].oppTeamName;
                rushAttempts = 0;
                rushYds = 0;
                rushYdsPerAttempt = 0;
                rushLong = 0;
                rushTD = 0;
                rushYdsPerGame = 0;
                receives = receivingstats[i].recs;
                rcvYds = receivingstats[i].yards;
                rcvYdsPerRcv = receivingstats[i].yardsPerRec;
                rcvYdsPerGame = receivingstats[i].yards;
                rcvLong = receivingstats[i].long;
                rcvTargets = receivingstats[i].targets;
                rcvTD = receivingstats[i].td;
                pntretAttempts = 0;
                pntretYards = 0;
                pntretYdsPerAtt = 0;
                pntretTD = 0;   
                kickretAttempts = 0;
                kickretYards = 0;
                kickretYdsPerAtt = 0;
                kickretTD = 0;
                sumFumbles = receivingstats[i].fumbles;
                sumFumblesLost = receivingstats[i].fumblesLost;
                sumPointsEarned = Tst2015(receivingstats[i].season,receivingstats[i].eps);
                // consolelogruben(receivingstats[i].playerId, 'Receiving EPS', receivingstats[i].eps);
                rowdata.push({
                    sortKey,
                    season,
                    playerName,
                    playerId,
                    positionName,
                    team,
                    matchup,
                    games,
                    gameDate,
                    gameSeasonDateSSSSYYYYMMDD,
                    gameResult,
                    gameWeek,
                    gamesStarted,
                    oppTeam,
                    rushAttempts,
                    rushYds,
                    rushYdsPerAttempt,
                    rushLong,
                    rushTD,
                    rushYdsPerGame,
                    receives,
                    rcvYds,
                    rcvYdsPerRcv,
                    rcvLong,
                    rcvTargets,
                    rcvTD,
                    rcvYdsPerGame,
                    pntretAttempts,
                    pntretYards,
                    pntretYdsPerAtt,
                    pntretTD,
                    kickretAttempts,
                    kickretYards,
                    kickretYdsPerAtt,
                    kickretTD,
                    sumFumbles,
                    sumFumblesLost,
                    sumPointsEarned
                });
            }
        }
        let j = -1;
        if (rushingstats.length !==0) {
            for (i=0; i<rushingstats.length; i++) {
                for (j = rowdata.length - 1; j > -1; j--) {
                    if (rowdata[j].gameDate === rushingstats[i].gameDate) {
                        break;
                    }
                }
                if (j > -1) {
                    rowdata[j].rushAttempts = rushingstats[i].att;
                    rowdata[j].rushYds = rushingstats[i].yards;
                    rowdata[j].rushYdsPerAttempt = rushingstats[i].yardsPerAtt;
                    rowdata[j].rushLong = rushingstats[i].long;
                    rowdata[j].rushTD = rushingstats[i].td;
                    rowdata[j].rushYdsPerGame = rushingstats[i].yards;
                    rowdata[j].sumFumbles = NullSum(rowdata[j].sumFumbles, rushingstats[i].fumbles);
                    rowdata[j].sumFumblesLost = NullSum(rowdata[j].sumFumblesLost, rushingstats[i].fumblesLost);
                    rowdata[j].sumPointsEarned = NullBypassSum(rowdata[j].sumPointsEarned, Tst2015(rushingstats[i].season, rushingstats[i].eps));
                    // consolelogruben(rushingstats[i].playerId, 'Rushing EPSa', rushingstats[i].eps);
                } else {
                    sortKey = (9999 - rushingstats[i].season) * 1000000 + rushingstats[i].season * 100 + rushingstats[i].week; //XXXXYYYYWW
                    season = rushingstats[i].season;
                    playerName = rushingstats[i].playerName;
                    playerId = rushingstats[i].playerId;
                    positionName = rushingstats[i].positionName;
                    team = rushingstats[i].teamName;
                    matchup = rushingstats[i].teamName.trim() + ' ' + rushingstats[i].oppTeamName.trim();
                    games = 1;
                    gameDate = rushingstats[i].gameDate;
                    gameSeasonDateSSSSYYYYMMDD = rushingstats[i].season.toString() + rushingstats[i].gameDate.substring(0, 10);
                    gameResult = GameResult(rushingstats[i].teamScore, rushingstats[i].oppTeamScore);
                    gameWeek = rushingstats[i].week;
                    gamesStarted = rushingstats[i].starts;
                    oppTeam = rushingstats[i].oppTeamName;
                    rushAttempts = rushingstats[i].att;
                    rushYds = rushingstats[i].yards;
                    rushYdsPerAttempt = rushingstats[i].yardsPerAtt;
                    rushLong = rushingstats[i].long;
                    rushTD = rushingstats[i].td;
                    rushYdsPerGame = rushingstats[i].yardsPerG;
                    receives = 0;
                    rcvYds = 0;
                    rcvYdsPerRcv = 0;
                    rcvLong = 0;
                    rcvTargets = 0;
                    rcvTD = 0;
                    rcvYdsPerGame = 0;
                    pntretAttempts = 0;
                    pntretYards = 0;
                    pntretYdsPerAtt = 0;
                    pntretTD = 0;
                    kickretAttempts = 0;
                    kickretYards = 0;
                    kickretYdsPerAtt = 0;
                    kickretTD = 0;
                    sumFumbles = rushingstats[i].fumbles;
                    sumFumblesLost = rushingstats[i].fumblesLost;
                    sumPointsEarned = Tst2015(rushingstats[i].season, rushingstats[i].eps);
                    // consolelogruben(rushingstats[i].playerId, 'Rushing EPSb', rushingstats[i].eps);
                    rowdata.push({
                        sortKey,
                        season,
                        playerName,
                        playerId,
                        positionName,
                        team,
                        matchup,
                        games,
                        gameDate,
                        gameSeasonDateSSSSYYYYMMDD,
                        gameResult,
                        gameWeek,
                        gamesStarted,
                        oppTeam,
                        rushAttempts,
                        rushYds,
                        rushYdsPerAttempt,
                        rushLong,
                        rushTD,
                        rushYdsPerGame,
                        receives,
                        rcvYds,
                        rcvYdsPerRcv,
                        rcvLong,
                        rcvTargets,
                        rcvTD,
                        rcvYdsPerGame,
                        pntretAttempts,
                        pntretYards,
                        pntretYdsPerAtt,
                        pntretTD,
                        kickretAttempts,
                        kickretYards,
                        kickretYdsPerAtt,
                        kickretTD,
                        sumFumbles,
                        sumFumblesLost,
                        sumPointsEarned
                    });
                }
            }
        }
        if (puntretstats.length !==0) {
            for (i=0; i<puntretstats.length; i++) {
                for (j = rowdata.length - 1; j > -1; j--) {
                    if (rowdata[j].gameDate === puntretstats[i].gameDate) {
                        break;
                    }
                }
                if (j > -1) {
                    rowdata[j].pntretAttempts = puntretstats[i].attempts;
                    rowdata[j].pntretYards = puntretstats[i].yards;
                    rowdata[j].pntretYdsPerAtt = puntretstats[i].attempts ? puntretstats[i].yards/puntretstats[i].attempts : 0;
                    rowdata[j].pntretTD = puntretstats[i].tDs;
                    rowdata[j].sumTDs = NullSum(rowdata[j].sumTDs,puntretstats[i].tDs);
                    rowdata[j].sumFumbles = NullSum(rowdata[j].sumFumbles,puntretstats[i].fumbles);
                    rowdata[j].sumFumblesLost = NullSum(rowdata[j].sumFumblesLost,puntretstats[i].fumblesLost);
                } else {
                    sortKey = (9999 - puntretstats[i].season) * 1000000 + puntretstats[i].season * 100 + puntretstats[i].week; //XXXXYYYYWW
                    season = puntretstats[i].season;
                    playerName = puntretstats[i].playerName;
                    playerId = puntretstats[i].playerId;
                    positionName = puntretstats[i].positionName;
                    team = puntretstats[i].teamName;
                    matchup = puntretstats[i].teamName.trim() + ' ' + puntretstats[i].oppTeamName.trim();
                    games = 1;
                    gameDate = puntretstats[i].gameDate;
                    gameSeasonDateSSSSYYYYMMDD = puntretstats[i].season.toString() + puntretstats[i].gameDate.substring(0, 10);
                    gameResult = GameResult(puntretstats[i].teamScore, puntretstats[i].oppTeamScore);
                    gameWeek = puntretstats[i].week;
                    gamesStarted = puntretstats[i].starts;
                    oppTeam = puntretstats[i].oppTeamName;
                    rushAttempts = 0;
                    rushYds = 0;
                    rushYdsPerAttempt = 0;
                    rushLong = 0;
                    rushTD = 0;
                    rushYdsPerGame = 0;
                    receives = 0;
                    rcvYds = 0;
                    rcvYdsPerRcv = 0;
                    rcvLong = 0;
                    rcvTargets = 0;
                    rcvTD = 0;
                    rcvYdsPerGame = 0;
                    pntretAttempts = puntretstats[i].attempts;
                    pntretYards = puntretstats[i].yards;
                    pntretYdsPerAtt = NullDiv(puntretstats[i].yards,puntretstats[i].attempts);
                    pntretTD = puntretstats[i].tDs;
                    kickretAttempts = 0;
                    kickretYards = 0;
                    kickretYdsPerAtt = 0;
                    kickretTD = 0;
                    sumFumbles = puntretstats[i].fumbles;
                    sumFumblesLost = puntretstats[i].fumblesLost;
                    sumPointsEarned = 0;
                    rowdata.push({
                        sortKey,
                        season,
                        playerName,
                        playerId,
                        positionName,
                        team,
                        matchup,
                        games,
                        gameDate,
                        gameSeasonDateSSSSYYYYMMDD,
                        gameResult,
                        gameWeek,
                        gamesStarted,
                        oppTeam,
                        rushAttempts,
                        rushYds,
                        rushYdsPerAttempt,
                        rushLong,
                        rushTD,
                        rushYdsPerGame,
                        receives,
                        rcvYds,
                        rcvYdsPerRcv,
                        rcvLong,
                        rcvTargets,
                        rcvTD,
                        rcvYdsPerGame,
                        pntretAttempts,
                        pntretYards,
                        pntretYdsPerAtt,
                        pntretTD,
                        kickretAttempts,
                        kickretYards,
                        kickretYdsPerAtt,
                        kickretTD,
                        sumFumbles,
                        sumFumblesLost,
                        sumPointsEarned
                    });
                }
            }
        }
        if (kickretstats.length !==0) {
            for (i=0; i<kickretstats.length; i++) {
                for (j = rowdata.length - 1; j > -1; j--) {
                    if (rowdata[j].gameDate === kickretstats[i].gameDate) {
                        break;
                    }
                }
                if (j > -1) {
                    rowdata[j].kickretAttempts = kickretstats[i].attempts;
                    rowdata[j].kickretYards = kickretstats[i].yards;
                    rowdata[j].kickretYdsPerAtt = NullDiv(kickretstats[i].yards, kickretstats[i].attempts);
                    rowdata[j].kickretTD = kickretstats[i].tDs;
                    rowdata[j].sumTDs = NullSum(rowdata[j].sumTDs,kickretstats[i].tDs);
                    rowdata[j].sumFumbles = NullSum(rowdata[j].sumFumbles,kickretstats[i].fumbles);
                    rowdata[j].sumFumblesLost = NullSum(rowdata[j].sumFumblesLost,kickretstats[i].fumblesLost);
                } else {
                    sortKey = (9999 - kickretstats[i].season) * 1000000 + kickretstats[i].season * 100 + kickretstats[i].week; //XXXXYYYYWW
                    season = kickretstats[i].season;
                    playerName = kickretstats[i].playerName;
                    playerId = kickretstats[i].playerId;
                    positionName = kickretstats[i].positionName;
                    team = kickretstats[i].teamName;
                    matchup = kickretstats[i].teamName.trim() + ' ' + kickretstats[i].oppTeamName.trim();
                    games = 1;
                    gameDate = kickretstats[i].gameDate;
                    gameSeasonDateSSSSYYYYMMDD = kickretstats[i].season.toString() + kickretstats[i].gameDate.substring(0, 10);
                    gameResult = GameResult(kickretstats[i].teamScore, kickretstats[i].oppTeamScore);
                    gameWeek = kickretstats[i].week;
                    gamesStarted = kickretstats[i].starts;
                    oppTeam = kickretstats[i].oppTeamName;
                    rushAttempts = 0;
                    rushYds = 0;
                    rushYdsPerAttempt = 0;
                    rushLong = 0;
                    rushTD = 0;
                    rushYdsPerGame = 0;
                    receives = 0;
                    rcvYds = 0;
                    rcvYdsPerRcv = 0;
                    rcvLong = 0;
                    rcvTargets = 0;
                    rcvTD = 0;
                    rcvYdsPerGame = 0;
                    pntretAttempts = 0;
                    pntretYards = 0;
                    pntretYdsPerAtt = 0;
                    pntretTD = 0;
                    kickretAttempts = kickretstats[i].attempts;
                    kickretYards = kickretstats[i].yards;
                    kickretYdsPerAtt = NullDiv(kickretYards, kickretAttempts);
                    kickretTD = kickretstats[i].tDs;
                    sumFumbles = kickretstats[i].fumbles;
                    sumFumblesLost = kickretstats[i].fumblesLost;
                    sumPointsEarned = 0;
                    rowdata.push({
                        sortKey,
                        season,
                        playerName,
                        playerId,
                        positionName,
                        team,
                        matchup,
                        games,
                        gameDate,
                        gameSeasonDateSSSSYYYYMMDD,
                        gameResult,
                        gameWeek,
                        gamesStarted,
                        oppTeam,
                        rushAttempts,
                        rushYds,
                        rushYdsPerAttempt,
                        rushLong,
                        rushTD,
                        rushYdsPerGame,
                        receives,
                        rcvYds,
                        rcvYdsPerRcv,
                        rcvLong,
                        rcvTargets,
                        rcvTD,
                        rcvYdsPerGame,
                        pntretAttempts,
                        pntretYards,
                        pntretYdsPerAtt,
                        pntretTD,
                        kickretAttempts,
                        kickretYards,
                        kickretYdsPerAtt,
                        kickretTD,
                        sumFumbles,
                        sumFumblesLost,
                        sumPointsEarned
                    });
                }
            }
        }
        if (blockingstats.length !==0) {
            for (i=0; i<blockingstats.length; i++) {
                for (j = rowdata.length - 1; j > -1; j--) {
                    if (rowdata[j].gameDate === blockingstats[i].gameDate) {
                        break;
                    }
                }
                if (j > -1) {
                    rowdata[j].sumPointsEarned =
                        NullBypassSum(rowdata[j].sumPointsEarned, Tst2015(blockingstats[i].season, blockingstats[i].passBlockEPS));
                        // consolelogruben(blockingstats[i].playerId, 'PassBlock EPS', blockingstats[i].passBlockEPS);
                        rowdata[j].sumPointsEarned =
                        NullBypassSum(rowdata[j].sumPointsEarned, Tst2015(blockingstats[i].season, blockingstats[i].runBlockEPS));
                        // consolelogruben(blockingstats[i].playerId, 'RunBlock EPS', blockingstats[i].runBlockEPS);
                } else if(!this.isLeaderboards) {
                    sortKey = (9999 - blockingstats[i].season) * 1000000 + blockingstats[i].season * 100 + blockingstats[i].week; //XXXXYYYYWW
                    season = blockingstats[i].season;
                    playerName = blockingstats[i].playerName;
                    playerId = blockingstats[i].playerId;
                    positionName = blockingstats[i].positionName;
                    team = blockingstats[i].teamName;
                    matchup = blockingstats[i].teamName.trim() + ' ' + blockingstats[i].oppTeamName.trim();
                    games = 1;
                    gameDate = blockingstats[i].gameDate;
                    gameSeasonDateSSSSYYYYMMDD = blockingstats[i].season.toString() + blockingstats[i].gameDate.substring(0, 10);
                    gameResult = GameResult(blockingstats[i].teamScore, blockingstats[i].oppTeamScore);
                    gameWeek = blockingstats[i].week;
                    gamesStarted = blockingstats[i].starts;
                    oppTeam = blockingstats[i].oppTeamName;
                    rushAttempts = 0;
                    rushYds = 0;
                    rushYdsPerAttempt = 0;
                    rushLong = 0;
                    rushTD = 0;
                    rushYdsPerGame = 0;
                    receives = 0;
                    rcvYds = 0;
                    rcvYdsPerRcv = 0;
                    rcvLong = 0;
                    rcvTargets = 0;
                    rcvTD = 0;
                    rcvYdsPerGame = 0;
                    pntretAttempts = 0;
                    pntretYards = 0;
                    pntretYdsPerAtt = 0;
                    pntretTD = 0;
                    kickretAttempts = 0;
                    kickretYards = 0;
                    kickretYdsPerAtt = 0;
                    kickretTD = 0;
                    sumFumbles = 0;
                    sumFumblesLost = 0;
                    sumPointsEarned =
                        NullBypassSum(
                            Tst2015(blockingstats[i].season, blockingstats[i].passBlockEPS),
                            Tst2015(blockingstats[i].season, blockingstats[i].runBlockEPS)
                        );
                        // consolelogruben(blockingstats[i].playerId, 'PassBlock EPS', blockingstats[i].passBlockEPS);
                        // consolelogruben(blockingstats[i].playerId, 'RunBlock EPS', blockingstats[i].runBlockEPS);
                    rowdata.push({
                        sortKey,
                        season,
                        playerName,
                        playerId,
                        positionName,
                        team,
                        matchup,
                        games,
                        gameDate,
                        gameSeasonDateSSSSYYYYMMDD,
                        gameResult,
                        gameWeek,
                        gamesStarted,
                        oppTeam,
                        rushAttempts,
                        rushYds,
                        rushYdsPerAttempt,
                        rushLong,
                        rushTD,
                        rushYdsPerGame,
                        receives,
                        rcvYds,
                        rcvYdsPerRcv,
                        rcvLong,
                        rcvTargets,
                        rcvTD,
                        rcvYdsPerGame,
                        pntretAttempts,
                        pntretYards,
                        pntretYdsPerAtt,
                        pntretTD,
                        kickretAttempts,
                        kickretYards,
                        kickretYdsPerAtt,
                        kickretTD,
                        sumFumbles,
                        sumFumblesLost,
                        sumPointsEarned
                    });
                }
            }
        }
        if (rowdata.length !== 0) {
            rowdata.sort((a, b) => a.sortKey > b.sortKey ? 1 : -1); // orders rows by sortkey
            this.SumData(rowdata); // insert seasonal totals
        }
        this.data = rowdata;
    }

    SumData(d) {
        let sortKey = 0;
        let season = d[0].season;
        let playerName = '';
        let playerId = '';
        let positionName = '';
        let team = '';
        let matchup = '';
        let games = 0;
        let gameDate = '';
        let gameSeasonDateSSSSYYYYMMDD = '';
        let gameResult = '';
        let gameWeek = '';
        let gamesStarted = 0;
        let oppTeam = ''
        let rushAttempts = 0;
        let rushYds = 0;
        let rushYdsPerAttempt = 0;
        let rushLong = 0;
        let rushTD = 0;
        let rushYdsPerGame = 0;
        let receives = 0;
        let rcvYds = 0;
        let rcvYdsPerRcv = 0;
        let rcvLong = 0;
        let rcvTargets = 0;
        let rcvTD = 0;
        let rcvYdsPerGame = 0;
        let pntretAttempts = 0;
        let pntretYards = 0;
        let pntretYdsPerAtt = 0;
        let pntretTD = 0;
        let kickretAttempts = 0;
        let kickretYards = 0;
        let kickretYdsPerAtt = 0;
        let kickretTD = 0;
        let sumFumbles = 0;
        let sumFumblesLost = 0;
        let sumPointsEarned = 0;
        let rowcount = d.length;
        for (var i = 0; i < rowcount; i++) {
            if (season !== d[i].season) {
                sortKey = (9999 - season) * 1000000 + season * 100 + 99; //XXXXYYYY99
                gameSeasonDateSSSSYYYYMMDD = season.toString() + season.toString() + " Total";
                rushYdsPerAttempt = NullDiv(rushYds, rushAttempts);
                rushYdsPerGame = NullDiv(rushYds, games);
                rcvYdsPerRcv = NullDiv(rcvYds, receives);
                rcvYdsPerGame = NullDiv(rcvYds, games);
                kickretYdsPerAtt = NullDiv(kickretYards, kickretAttempts);
                pntretYdsPerAtt = NullDiv(pntretYards, pntretAttempts);
                d.push({
                    sortKey,
                    season,
                    playerName,
                    playerId,
                    positionName,
                    team,
                    matchup,
                    games,
                    gameDate,
                    gameSeasonDateSSSSYYYYMMDD,
                    gameResult,
                    gameWeek,
                    gamesStarted,
                    oppTeam,
                    rushAttempts,
                    rushYds,
                    rushYdsPerAttempt,
                    rushLong,
                    rushTD,
                    rushYdsPerGame,
                    receives,
                    rcvYds,
                    rcvYdsPerRcv,
                    rcvLong,
                    rcvTargets,
                    rcvTD,
                    rcvYdsPerGame,
                    pntretAttempts,
                    pntretYards,
                    pntretYdsPerAtt,
                    pntretTD,
                    kickretAttempts,
                    kickretYards,
                    kickretYdsPerAtt,
                    kickretTD,
                    sumFumbles,
                    sumFumblesLost,
                    sumPointsEarned
                });
                season = d[i].season;
                playerName = '';
                playerId = '';
                positionName = '';
                team = '';
                matchup = '';
                games = 0;
                gameDate = '';
                gameSeasonDateSSSSYYYYMMDD = '';
                gameResult = '';
                gameWeek = '';
                gamesStarted = 0;
                oppTeam = ''
                rushAttempts = 0;
                rushYds = 0;
                rushYdsPerAttempt = 0;
                rushLong = 0;
                rushTD = 0;
                rushYdsPerGame = 0;
                receives = 0;
                rcvYds = 0;
                rcvYdsPerRcv = 0;
                rcvLong = 0;
                rcvTargets = 0;
                rcvTD = 0;
                rcvYdsPerGame = 0;
                pntretAttempts = 0;
                pntretYards = 0;
                pntretYdsPerAtt = 0;
                pntretTD = 0;
                kickretAttempts = 0;
                kickretYards = 0;
                kickretYdsPerAtt = 0;
                kickretTD = 0;
                sumFumbles = 0;
                sumFumblesLost = 0;
                sumPointsEarned = 0;
            }
            games = NullSum(games, d[i].games);
            gamesStarted = NullSum(gamesStarted,d[i].gamesStarted);
            rushAttempts = NullSum(rushAttempts,d[i].rushAttempts);
            rushLong = NullMax(rushLong,d[i].rushLong);
            rushTD = NullSum(rushTD,d[i].rushTD);
            rushYds = NullSum(rushYds,d[i].rushYds);
            receives = NullSum(receives,d[i].receives);
            rcvYds = NullSum(rcvYds,d[i].rcvYds);
            rcvLong = NullMax(rcvLong,d[i].rcvLong);
            rcvTargets = NullSum(rcvTargets,d[i].rcvTargets)
            rcvTD = NullSum(rcvTD,d[i].rcvTD);
            pntretAttempts = NullSum(pntretAttempts,d[i].pntretAttempts);
            pntretYards = NullSum(pntretYards,d[i].pntretYards);
            pntretTD = NullSum(pntretTD,d[i].pntretTD);
            kickretAttempts = NullSum(kickretAttempts,d[i].kickretAttempts);
            kickretYards = NullSum(kickretYards, d[i].kickretYards);
            kickretTD = NullSum(kickretTD, d[i].kickretTD);
            sumFumbles = NullSum(sumFumbles,d[i].sumFumbles);
            sumFumblesLost = NullSum(sumFumblesLost,d[i].sumFumblesLost);
            sumPointsEarned = NullBypassSum(sumPointsEarned,Tst2015(d[i].season,d[i].sumPointsEarned));
            // consolelogruben(d[i].playerId, 'SUMMING', d[i].sumPointsEarned)
        }
        sortKey = (9999 - season) * 1000000 + season * 100 + 99; //XXXXYYYY99
        gameSeasonDateSSSSYYYYMMDD = season.toString() + season.toString() + " Total";
        rushYdsPerAttempt = NullDiv(rushYds,rushAttempts);
        rushYdsPerGame = NullDiv(rushYds,games);
        rcvYdsPerRcv = NullDiv(rcvYds,receives);
        rcvYdsPerGame = NullDiv(rcvYds,games);
        kickretYdsPerAtt = NullDiv(kickretYards,kickretAttempts);
        pntretYdsPerAtt = NullDiv(pntretYards,pntretAttempts);
        d.push({
            sortKey,
            season,
            playerName,
            playerId,
            positionName,
            team,
            matchup,
            games,
            gameDate,
            gameSeasonDateSSSSYYYYMMDD,
            gameResult,
            gameWeek,
            gamesStarted,
            oppTeam,
            rushAttempts,
            rushYds,
            rushYdsPerAttempt,
            rushLong,
            rushTD,
            rushYdsPerGame,
            receives,
            rcvYds,
            rcvYdsPerRcv,
            rcvLong,
            rcvTargets,
            rcvTD,
            rcvYdsPerGame,
            pntretAttempts,
            pntretYards,
            pntretYdsPerAtt,
            pntretTD,
            kickretAttempts,
            kickretYards,
            kickretYdsPerAtt,
            kickretTD,
            sumFumbles,
            sumFumblesLost,
            sumPointsEarned
        });
        d.sort((a, b) => a.sortKey > b.sortKey ? 1 : -1); // corrects for new season total rows
    }
    
    componentDidMount() {
        this.ConsolidateData();
        let pagination = 'local';
        let paginationSize = 50;
        let maxPlayerSeason = this.data.length ? this.data[0].season : 0;
        pagination = null;
        paginationSize = 0;
        this.tabulator = new Tabulator(this.el, {
            data: this.data,
            reactiveData: true,
            columns: this.cols,
            layout: "fitColumns",  // added by Ruben 7/25/19
            rowFormatter: function (row) {
                var data = row.getData();
                if (data.gameSeasonDateSSSSYYYYMMDD.substr(9, 5) === 'Total') {
                    row.getElement().style.borderTop = "2px solid #6f88a8";
                    row.getElement().style.borderBottom = "2px solid #6f88a8";
                }
            },
            pagination: pagination,
            paginationSize: paginationSize,
            groupBy: function (row) {
                return row.gameSeasonDateSSSSYYYYMMDD.substr(0, 4);
            },
            groupHeader: function (groupKey, rowcount, data, group) {
                return groupKey + ' Season <span style="color:SlateBlue">&#9660</span>';
            },
            groupStartOpen: function (groupKey, rowcount, data, group) {
                return groupKey === maxPlayerSeason.toString();
            },
            groupToggleElement: "header",
            resizableColumns: false,
            selectable: false
        });
    }

    componentDidUpdate() {
        this.tabulator.replaceData(this.data);
    }

    render() {
        return (
            <React.Fragment>
                <div className="shadowdiv">
                    <div id="table-title">Summary</div>
                    <div ref={el => (this.el = el)} />
                </div>
            </React.Fragment>
        );
    }
}

function consolelogruben(playerId, text, value) {
    //console.log('playerid =', playerId)
    if (playerId === 3938) {
        console.log(playerId, text, value)
    }
}

export default GameLogWRTERBFBSummaryTabulator;