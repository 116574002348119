import React from 'react';
import { GetAge, DispTxt, FormatNumSuffixed } from '../common/CommonFunctions.js';

const PlayerBio = (parm) => {
    let data = parm.data;
    let i = data.length - 1;
    let byear = '--';
    let bmonth = '--';
    let bday = '--'
    let playername = '--'
    let position = '--'
    let jersey = '--';
    let team = '--';
    let height = '-- --';
    let weight = '--';
    let age = '--';
    let birthdate = '--';
    let draftRound = '--';
    let draftPick = '--';
    let draftYear = '--';
    let college = '--';
    let draftInfo = 'undrafted';
    if (i !== -1) {
        if (data[i].birthDate !== null) {
            byear = data[i].birthDate.substr(0,4);
            bmonth = data[i].birthDate.substr(5,2);
            bday = data[i].birthDate.substr(8,2);
        }
        playername = DispTxt(data[i].fullName);
        position = DispTxt(data[i].positionName);
        let cutOffDate = new Date();
        cutOffDate.setMonth(cutOffDate.getMonth() - 3);
        if (data[i].status && data[i].season === cutOffDate.getFullYear()) {
            team = DispTxt(data[i].team);
            jersey = '#'+DispTxt(data[i].jersey);
        } else {
            team = '';
            jersey = '';
        }
        if (data[i].height !== null) {
            height =
                parseInt(data[i].height/12).toString()+"'"+
                (data[i].height%12).toString()+'"';
        } else {
            height = '--';
        }
        if (data[i].weight !== null) {
            weight = data[i].weight.toString();
        } else {
            weight = '---'
        }
        if (byear === '--') {
            age = '--';
            birthdate = '--/--/--';
        } else {
            age = GetAge(byear,bmonth,bday);
            birthdate = bmonth+"/"+bday+"/"+byear;
        }
        if (data[i].draftRound !== null) {
            draftRound = FormatNumSuffixed(data[i].draftRound);
            draftPick = FormatNumSuffixed(data[i].draftPick);
            draftYear = data[i].draftYear.toString();
            draftInfo = draftRound + " round (" + draftPick + " pick) in "+draftYear;
        }
        college = DispTxt(data[i].college);
    }
    return(
        <React.Fragment>
            <div className="bio">
                <h4><b>{playername}</b>&nbsp;&nbsp;{position} {jersey} {team}</h4>
                <h5>{height}, {weight}&nbsp;&nbsp;&nbsp;Age {age}&nbsp;&nbsp;&nbsp;Born {birthdate}</h5>
                <h5>Drafted: {draftInfo}&nbsp;&nbsp;&nbsp;College: {college}</h5>
            </div>
        </React.Fragment>
    );
}

export default PlayerBio;