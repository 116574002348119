import React, { Component } from 'react';
import GameLogQBSummaryTabulator from './components/pageQB/GameLogQBSummaryTabulator';
import GameLogOLSummaryTabulator from './components/pageOL/GameLogOLSummaryTabulator';
import GameLogWRTERBFBSummaryTabulator from './components/pageRBWR/GameLogWRTERBFBSummaryTabulator';
import GameLogDefenseSummaryTabulator from './components/pageDefense/GameLogDefenseSummaryTabulator';
import GameLogDefenseBuildData from './components/pageDefense/GameLogDefenseBuildData';
import GameLogKPKickingTabulator from './components/pageKP/GameLogKPKickingTabulator';
import GameLogKPPuntingTabulator from './components/pageKP/GameLogKPPuntingTabulator';
import PlayerBio from './components/common/PlayerBio';
import DropDown from './components/common/DropDown';
import {fetch as fetchPolyfill} from 'whatwg-fetch';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
//import TableDefenseBuildData from './components/pageDefense/TableDefenseBuildData';
let InstrumentationKey = "c6c148de-7a33-4f4e-81d1-f0522c53f12f";
let appInsights = new ApplicationInsights({config: {
    instrumentationKey: InstrumentationKey,
    maxBatchInterval: 0
}});
appInsights.loadAppInsights();

//////////////////////////////////////////////////////
//           PolyFill stuff                         //
//////////////////////////////////////////////////////
if (!window.fetch) {
    window.fetch = fetchPolyfill;
}

if (!Array.prototype.find) {
    // eslint-disable-next-line
    Object.defineProperty(Array.prototype, 'find', {
      value: function(predicate) {
       // 1. Let O be ? ToObject(this value).
        if (this == null) {
          throw new TypeError('"this" is null or not defined');
        }
  
        var o = Object(this);
  
        // 2. Let len be ? ToLength(? Get(O, "length")).
        var len = o.length >>> 0;
  
        // 3. If IsCallable(predicate) is false, throw a TypeError exception.
        if (typeof predicate !== 'function') {
          throw new TypeError('predicate must be a function');
        }
  
        // 4. If thisArg was supplied, let T be thisArg; else let T be undefined.
        var thisArg = arguments[1];
  
        // 5. Let k be 0.
        var k = 0;
  
        // 6. Repeat, while k < len
        while (k < len) {
          // a. Let Pk be ! ToString(k).
          // b. Let kValue be ? Get(O, Pk).
          // c. Let testResult be ToBoolean(? Call(predicate, T, « kValue, k, O »)).
          // d. If testResult is true, return kValue.
          var kValue = o[k];
          if (predicate.call(thisArg, kValue, k, o)) {
            return kValue;
          }
          // e. Increase k by 1.
          k++;
        }
  
        // 7. Return undefined.
        return undefined;
      }
    });
  }
//////////////////////////////////////////////////////
//           End PolyFill stuff                     //
//////////////////////////////////////////////////////

class PlayerGameLogs extends Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
            searchUserInput: '',                    // user input from autocomplete. triggers selection list updates
            selectedPlayerID: ''                    // set to true when API calls complete for a given player. triggers page update
        }
        this.version = '2022-09-13a';
        this.alldependenciescomplete = false;
        this.loadingmessage = 'Loading...';
        this.passingstats =[];                      // passing data retrieved from data API 
        this.rushingstats=[];                       // rushing data retrieved from data API
        this.accuracystats=[];                      // accuracy data retrieved from data API
        this.receivingstats=[];                     // receiving data retrieved from data API
        this.defensestats = [];                     // defense data retrieved from data API
        this.havedefense = false;
        this.passdefensestats = [];                 // passdefense data retrieved from data API
        this.havepassdefense = false;
        this.passrushstats = [];                    // passrush data retrieved from data API
        this.havepassrush = false;
        this.rushdefensestats = [];                 // rushdefense data retrieved from data API
        this.haverushdefense = false;
        this.kickingstats=[];                       // kicking data retrieved from data API
        this.kickretstats = [];                     // kickret data retrieved from data API
        this.havekickret = false;
        this.puntingstats=[];                       // punting data retrieved from data API
        this.puntretstats = [];                     // puntret data retrieved from data API
        this.havepuntret = false;
        this.blockingstats=[];                      // blocking data retrieved from data API
        this.globalstats=[];                        // global stats regardless of position - currently not in use
        this.defenseData=[];                        // array to consolidate defense data - unique to defense due to condiional layout
        this.token = '';                            // token value retrieved from authorization API and used for data API
        this.playerSelectionList = [];              // all NFL players to be listed in the Autocomplete dropdown
        this.asyncAPIcount = 0;                     // number of API calls completed for a given player position
        this.asyncAPIcallstocomplete = 0;           // number of API call to be completed for a given player position
        this.playername = '(No player selected)';   // player name to be displayed on page
        this.playerposition = '';                   // indicates which stats to be displayed. also displayed on page. 
        this.playerid = 0;                          // populated on autocomplete choice and used to retrieve data
        this.usererrormessage = '';                 // error message to be displayed on the page in place of stats
        this.pagelayout = '';                       // indicate which type of page to be rendered
        this.scrollY = 0;
        this.targetPage = 'playergamelogs';
        //console.log("0 - Initialize PlayerGameLogs");

        this.reScroll = this.reScroll.bind(this);

        if (this.props.match.params.id && !isNaN(this.props.match.params.id)){
            this.playerid = this.props.match.params.id; //id of player in route
        }
        else{
            this.playerid = 5048;
        }
    }

    // called by React when the page initially renders. It invokes a chain
    // of three API calls. Note that the page has already rendered at this
    // point so the API calls occur in the background after the user sees
    // the page. It should complete before the user begins typing in the
    // input box. Any delay would be experienced by the user as a delay in
    // dropdown being displayed as the user types into the player selection
    // input box. If this chain fails the dropdown will not work and the
    // page must be reloaded/refreshed by the user so that the intial API
    // calls are reinvoked.
    //
    // possible improvement: display error message and action on the page upon API failures
    //
    componentDidMount() {
        //console.log("0 - componentDidMount");
        window.addEventListener('scroll', this.handleScroll.bind(this));
        this.getSecurityToken();
        //this.loadNFLPlayers();
    }

    componentWillUnmount(){
        window.removeEventListener('scroll', this.handleScroll.bind(this));
    }

    // There are three API calls that must complete in sequence when this page loads:
    //
    // 1) obtain token from security API
    // 2) use token to access the data API
    // 3) load NFL players to an array for user selection
    //
    // API chaining is accomplished by each API routine invoking the next on successfull completion:
    // 1) getSecurityToken --> 2) accessNFLdata --> 3) loadNFLplayers
    //
    getSecurityToken = () => {
        //console.log("0 - getSecurityToken");
        this.executeHTTPRequestPost(
            'https://auth.sportsinfosolutions.com/connect/token',
            (response) => {
                this.token = response.access_token;
                this.getGlobalStats();
                
                //this.accessNFLdata();
            },
            (error) => {
                console.log("getSecurityToken",error);
            }
        )
    }
    
    // Gain access to NFL data API using the token obtained from the secutiry API
    getAllPlayers = (event) => {
        //console.log("0 - getAllPlayers");
        const reqUrl = 'https://api.sportsinfosolutions.com/api/v1/nfl/players/allplayerswithteamnew';
        this.executeHTTPRequest(
            reqUrl,
            'get',
            (response) => {
                this.playerSelectionList = response;
                //this.displayFeaturedPlayer();       // generate report with featured player
                this.asyncAPImonitor(this.playerSelectionList);
            },
            (error) => {
                console.log("getAllPlayers",error);
            }
        )
    }

    displayFeaturedPlayer = () => {
        //console.log("0 - getFeaturedPlayer");
        // this.playerid = 5048;
        // this.getGlobalStats()
        if (this.playerid !== 0){
            var player = this.playerSelectionList.filter((p) =>{
                if (p.playerId.toString() === this.playerid){
                    return p;
                }
                return null;
            });
            this.handlePlayerSelected(' ',{playerId: player[0].playerId, positionCategory: player[0].positionCategory, firstName: player[0].firstName, lastName: player[0].lastName})
        }
        else{
            this.handlePlayerSelected(' ',{playerId: 5048, positionCategory: "QB", firstName: "Patrick", lastName: "Mahomes"}) // load default player
        }
    }

    // Load NFL players from a json file on sisdatahub
    loadNFLPlayers = () => {
        fetch('http://sismetrics.com/players.json').then(function(response) {
	        return response.json();
        }).then(json => {
            this.playerSelectionList = json;
        }).catch(err => {
            console.log('loadNFLPlayers - problem fetching player list:',err.message);
        });
    }

    // get passing stats by game from NFL data API
    getPassingStats = (event) => {
        //console.log("0 - getPassingStats");
        const requesturl = `https://api.sportsinfosolutions.com/api/v1/nfl/seasons/players/passingbygame?playerId=${this.playerid}`;
        this.executeHTTPRequest(
            requesturl,
            'get',
            (response) => {
                this.passingstats = response.data;
                this.asyncAPImonitor(this.passingstats);
            },
            (error) => {
                console.log("getPassingStats",error);
            }
        )
    }

    // get rushing stats by game from NFL API
    getRushingStats = () => {
        //console.log("0 - getRushingStats");
        const requesturl = `https://api.sportsinfosolutions.com/api/v1/nfl/seasons/players/rushingbygame?playerId=${this.playerid}`;
        this.executeHTTPRequest(
            requesturl,
            'get',
            (response) => {
                this.rushingstats = response.data;
                this.asyncAPImonitor(this.rushingstats);
            },
            (error) => {
                console.log("getRushingStats",error);
            }
        )
    }

    // get accuracy stats by game from NFL API
    getAccuracyStats = () => {
        const requesturl = `https://api.sportsinfosolutions.com/api/v1/nfl/seasons/players/qb_ddByGame?playerId=${this.playerid}&startSeason=2015&endSeason=${(new Date()).getFullYear()}`;
        this.executeHTTPRequest(
            requesturl,
            'get',
            (response) => {
                this.accuracystats = response.data;
                this.asyncAPImonitor(this.accuracystats);
            },
            (error) => {
                console.log("getAccuracyStats",error);
            }
        )
    }

    // get receiving stats  by game from NFL API
    getReceivingStats = () => {
        const requesturl = `https://api.sportsinfosolutions.com/api/v1/nfl/seasons/players/receivingbygame?playerId=${this.playerid}`;
        this.executeHTTPRequest(
            requesturl,
            'get',
            (response) => {
                this.receivingstats = response.data;
                this.asyncAPImonitor(this.receivingstats);
            },
            (error) => {
                console.log("getReceivingStats",error);
            }
        )
    }

    // get blocking stats  by game from NFL API
    getBlockingStats = () => {
        //console.log("0 - getBlockingStats");
        const requesturl = `https://api.sportsinfosolutions.com/api/v1/nfl/seasons/players/blockingbygame?playerId=${this.playerid}`;
        this.executeHTTPRequest(
            requesturl,
            'get',
            (response) => {
                this.blockingstats = response.data;
                this.asyncAPImonitor(this.blockingstats);
            },
            (error) => {
                console.log("getBlockingStats",error);
            }
        )
    }

    // get puntret stats  by game from NFL API
    getPuntRetStats = () => {
        //console.log("0 - getPuntRetStats");
        const requesturl = `https://api.sportsinfosolutions.com/api/v1/nfl/seasons/players/puntreturningbygame?playerId=${this.playerid}`;
        this.executeHTTPRequest(
            requesturl,
            'get',
            (response) => {
                this.puntretstats = response.data;
                this.asyncAPImonitor(this.puntretstats);
            },
            (error) => {
                console.log("getPuntRetStats",error);
            }
        )
    }

    // get kickret stats  by game from NFL API
    getKickRetStats = () => {
        //console.log("0 - getKickRetStats");
        const requesturl = `https://api.sportsinfosolutions.com/api/v1/nfl/seasons/players/kickreturningbygame?playerId=${this.playerid}`;
        this.executeHTTPRequest(
            requesturl,
            'get',
            (response) => {
                this.kickretstats = response.data;
                this.asyncAPImonitor(this.kickretstats);
            },
            (error) => {
                console.log("getKickRetStats",error);
            }
        )
    }

    // get punting stats  by game from NFL API
    getPuntingStats = () => {
        const requesturl = `https://api.sportsinfosolutions.com/api/v1/nfl/seasons/players/puntingbygame?playerId=${this.playerid}`;
        this.executeHTTPRequest(
            requesturl,
            'get',
            (response) => {
                this.puntingstats = response.data;
                this.asyncAPImonitor(this.puntingstats);
            },
            (error) => {
                console.log("getPuntingStats",error);
            }
        )
    }

    // get kicking stats  by game from NFL API
    getKickingStats = () => {
        const requesturl = `https://api.sportsinfosolutions.com/api/v1/nfl/seasons/players/kickingbygame?playerId=${this.playerid}`;
        this.executeHTTPRequest(
            requesturl,
            'get',
            (response) => {
                this.kickingstats = response.data;
                this.asyncAPImonitor(this.kickingstats);
            },
            (error) => {
                console.log("getKickingStats",error);
            }
        )
    }

    // get passdefense stats  by game from NFL API
    getPassDefenseStats = () => {
        const requesturl = `https://api.sportsinfosolutions.com/api/v1/nfl/seasons/players/passdefensebygame?playerId=${this.playerid}`;
        this.executeHTTPRequest(
            requesturl,
            'get',
            (response) => {
                this.passdefensestats = response.data;
                this.asyncAPImonitor(this.passdefensestats);
            },
            (error) => {
                console.log("getPassDefenseStats",error);
            }
        )
    }

    // get passrush stats  by game from NFL API
    getPassRushStats = () => {
        const requesturl = `https://api.sportsinfosolutions.com/api/v1/nfl/seasons/players/passrushbygame?playerId=${this.playerid}`;
        this.executeHTTPRequest(
            requesturl,
            'get',
            (response) => {
                this.passrushstats = response.data;
//                let i = 0;
//                for (i = 0; i < this.passrushstats.length; i++) {
//                    if (this.passrushstats[i].season === 2015) {
//                        this.passrushstats.splice(i, 1);
//                        i--;
//                    }
//                }
                this.asyncAPImonitor(this.passrushstats);
            },
            (error) => {
                console.log("getPassRushStats",error);
            }
        )
    }

    // get rushdefense stats  by game from NFL API
    getRushDefenseStats = () => {
        const requesturl = `https://api.sportsinfosolutions.com/api/v1/nfl/seasons/players/rushdefensebygame?playerId=${this.playerid}`;
        this.executeHTTPRequest(
            requesturl,
            'get',
            (response) => {
                this.rushdefensestats = response.data;
                this.asyncAPImonitor(this.rushdefensestats);
            },
            (error) => {
                console.log("getRushDefenseStats",error);
            }
        )
    }

    // get defense stats  by game from NFL API
    getDefenseStats = () => {
        const requesturl = `https://api.sportsinfosolutions.com/api/v1/nfl/seasons/players/defensebygame?playerId=${this.playerid}`;
        this.executeHTTPRequest(
            requesturl,
            'get',
            (response) => {
                this.defensestats = response.data;
                this.asyncAPImonitor(this.defensestats);
            },
            (error) => {
                console.log("getDefenseStats",error);
            }
        )
    }

    // get global stats and player bio info
    getGlobalStats = () => {
        //console.log("0 - getGlobalStats");
        const requesturl = `https://api.sportsinfosolutions.com/api/v1/nfl/players/globalinfo?PlayerId=${this.playerid}`;
        this.executeHTTPRequest(
            requesturl,
            'get',
            (response) => {
                this.globalstats = response.data;
                this.handlePlayerSelected();
                //this.asyncAPImonitor(this.globalstats);
                // this.asyncAPImonitor(this.defensestats);
            },
            (error) => {
                console.log("getGlobalStats",error);
            }
        )
    }

    // general purpose fetch method called by each API related method
    executeHTTPRequest = (url, method, callback, failback) => {
        fetch(
            url, {
            method: method,
            headers: {
                Accept: 'application/json, text/plain, */*',
                    'Content-Type': 'application/json',
                Authorization: `Bearer ${this.token}`
            },
        })
        .then((response) => {
            let json = response.json();
            if(response.ok) {
                if (callback) {
                    json.then((result) => {
                        callback(result);
                    });
                }
            } else {
              return json.then(Promise.reject.bind(Promise));
            }
        })
        .catch((error) => {
            if (failback) {
                failback(error);
            }
        });
    }

    executeHTTPRequestPost = (url, callback, failback) => {
        var details = {'client_id': 'FootballApp@sportsinfosolutions.com',
        'client_secret': 'xPc14+wU2zHtydg12AgglRCkL+ALNlX/miH2JJHp2/k=',
        'grant_type': 'client_credentials',
        'scope': 'sisapi'};

        var formBody = [];
        for (var property in details){
            var encodedKey = encodeURIComponent(property);
            var encodedValue = encodeURIComponent(details[property]);
            formBody.push(encodedKey + "=" + encodedValue);
        }
        formBody = formBody.join("&");

        fetch(
            url, {
                method: "POST",
                cache: "no-cache",
                headers: {'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'},
                body: formBody,  
        })
        .then((response) => {
            let json = response.json();
            if (response.ok) {
                if (callback) {
                    json.then((result) => {
                        callback(result);
                    });
                }
            } else {
                return json.then(Promise.reject.bind(Promise));
            }
        })
        .catch((error) => {
            if (failback) {
                failback(error);
            }
        });
    }

    // Replacement for Promise.all to ensure that parallel async
    // dependencies complete before triggering a render by updating
    // state.
    //
    // Prior to launching parallel async tasks, set dependencycount
    // to zero and dependenciestocomplete to the number of async tasks
    // to be complete. Each task must call this function upon successful
    // completion. When dependencycount is equal to dependenciestocomplete,
    // state will be updated and dependencycount and dependenciestocomplete
    // are reset to zero.
    //
    asyncAPImonitor = (d) => {
        this.asyncAPIcount += 1;
        this.asyncAPIrows += d.length;
        if (this.asyncAPIcount === this.asyncAPIcallstocomplete) {
            // this.GetPlayerBioInfo()
            this.alldependenciescomplete = true;
            this.asyncAPIcount = 0;
            this.asyncAPIcallstocomplete = 0;
            this.usererrormessage = '';
            this.loadingmessage = '';
            this.setState({
                selectedPlayerId: this.playerid
            });
        } 
    }

    // this method is invoked when the user selects a player
    // from the autocomplete list. it uses the player position
    // to set the number of dependencies to be monitored and
    // launch the appropriate async NFL API calls.
    //
    handlePlayerSelected = (value) => {
        //this.playerid = this.playerId;                     // used in the NFL API calls
        let globalStats = this.globalstats[this.globalstats.length - 1];
        this.playername = globalStats.fullName; //selection.firstName+" "+selection.lastName;    // displayed on the page
        this.playerposition = globalStats.positionName; // selection.positionCategory;       // displayed on page and used in the switch below
        //console.log("0 - handlePlayerSelected, Player =", this.playerposition, this.playername);
        this.setCookie();
        this.usererrormessage = '';
        this.alldependenciescomplete = false;                   // reset the async API completion indicator to false
        this.loadingmessage = '"Loading...';                    // displayed on initial page load and whenever react triggers a refresh while player data is being retrieved
        this.asyncAPIcount = 0;                                 // reset the number of async API calls completed
        this.asyncAPIrows = 0;                                  // reset the number of async API rows fetched
        this.forceUpdate();
        //this.asyncAPIcallstocomplete = 1;                       // reset the number of async API calls completed
        //this.getGlobalStats();                                  // retrieve player bio info
        switch (this.playerposition) {                          // evalute the value of player position
            case 'QB':                                          // when quarterback
                this.pagelayout = this.playerposition;          // indicate the QB page format
                this.asyncAPIcallstocomplete += 3;              // set the number of async API calls to launch
                this.getAllPlayers();
                this.getPassingStats();                         //***************************************
                this.getRushingStats();                         //** lanch async API calls for QB data **
                //this.getAccuracyStats();                        //***************************************
                break;
            case 'DE':                                          // when defensive end or    (Defense)
            case 'DT':                                          // when defensive tackle or (Defense)
            case 'LB':                                          // when line backer or      (Defense)
               this.pagelayout = 'Defense';                    // indicate the Defenst page format for positions DE, DT, LB, CB, S
                this.asyncAPIcallstocomplete += 5;              // set the number of async API calls to launch
                this.getAllPlayers();
                this.getDefenseStats();
                this.getPassDefenseStats();                     //*****************************************************
                this.getPassRushStats();                        //** launch async API calls for DE, DT, LB, CB and S **
                this.getRushDefenseStats();                     //*****************************************************
                this.puntretstats = [];
                this.kickretstats = [];
                break;
            case 'CB':                                          // when cornerback or       (Defense)
            case 'S':                                           // when safety              (Defense)
                this.pagelayout = 'Defense';                    // indicate the Defenst page format for positions DE, DT, LB, CB, S
                this.asyncAPIcallstocomplete += 7;              // set the number of async API calls to launch
                this.getAllPlayers();
                this.getDefenseStats();
                this.getPassDefenseStats();                     //*****************************************************
                this.getPassRushStats();                        //** launch async API calls for DE, DT, LB, CB and S **
                this.getRushDefenseStats();                     //*****************************************************
                this.getPuntRetStats();                         //******************************************
                this.getKickRetStats();                         //******************************************
                break;
            case 'TE':                                          // when tight end or        (Offensive Line)
                this.pagelayout = 'Receiver';                   // indicate the receiver page format for positions TE
                this.asyncAPIcallstocomplete += 6;              // set the number of async API calls to launch
                this.getAllPlayers();
                this.getBlockingStats();                        //***********************************************
                this.getRushingStats();                         //******************************************
                this.getReceivingStats();                       //** launch async API calls for WR and RB **
                this.getPuntRetStats();                         //******************************************
                this.getKickRetStats();                         //******************************************
                break;
            case 'RB':                                          // when running back or
            case 'FB':                                          // when running back or
            case 'WR':                                          // when wide receiver
                this.pagelayout = 'Receiver';                   // indicate the receiver format (same data but different page format)
                this.asyncAPIcallstocomplete += 6;              // set the number of async API calls to launch
                this.blockingstats = [];                        // RB, FB and WR do not use blocking stats
                this.getAllPlayers();
                this.getBlockingStats();                        //***********************************************
                this.getRushingStats();                         //******************************************
                this.getReceivingStats();                       //** launch async API calls for WR and RB **
                this.getPuntRetStats();                         //******************************************
                this.getKickRetStats();                         //******************************************
                break;
            case 'T':                                           // when tackle or           (Offensive Line)
            case 'G':                                           // when guard or            (Offensive Line)
            case 'C':                                           // when center              (Offensive Line)
                this.pagelayout = 'OL';                         // indicate the OL page format for positions TE, T, G, or C
                this.asyncAPIcallstocomplete += 3;              // set the number of async API calls to launch
                this.getAllPlayers();
                this.getBlockingStats();                        //***********************************************
                this.getRushingStats();                         //** launch async API calls for TE, T, G and C **
                break;
            case 'K':                                           // when kicker or           (Kicking)
            case 'P':                                           // when punter              (Kicking)
                this.pagelayout = this.playerposition;          // indicate the K or PB page format (same data but different page format)
                this.asyncAPIcallstocomplete += 5;              // set the number of async API calls to launch
                this.getAllPlayers();
                this.getKickingStats();                         //****************************************
                this.getPuntingStats();                         //** launch async API calls for K and P **
                this.getPassingStats();                         //****************************************
                this.getRushingStats();                         //****************************************
                break;
            default:                                            // when anything else
////                this.usererrormessage = '(' + this.playerposition + ' not supported at this time)';
                console.log("handlePlayerSelected - invalid position:", this.playerposition);
        }
    }

    setCookie(){
        document.cookie = 'playerId=' + this.playerid + '; path=/;';
        document.cookie = 'playerPos=' + this.playerposition + '; path=/;';
    }

    handleScroll(){
        this.scrollY = window.scrollY;
    }
    
    reScroll(){
        window.scrollTo(0, this.scrollY);
    }

    // What is rendered out to the page
    render() {
        //console.log("0 - Render")
        if (this.pagelayout === 'Defense' && this.alldependenciescomplete) {
            this.defenseData = [];
            this.defenseData = GameLogDefenseBuildData(this.defensestats, this.passdefensestats, this.passrushstats, this.rushdefensestats, this.kickretstats, this.puntretstats);
            this.havepassdefense = false;
            this.haverushdefense = false;
            this.havepassrush = false;
            this.defenseData.forEach(season => {
                if (season.havedefense) { this.havedefense = true; }
                if (season.havepassdefense) { this.havepassdefense = true; }
                if (season.haverushdefense) { this.haverushdefense = true; }
                if (season.havepassrush) { this.havepassrush = true; }
                if (season.havekickret) { this.havekickret = true; }
                if (season.havepuntret) { this.havepuntret = true; }
            })
        }
        let playerLink = '/players/' + this.playerid;
        let playerGameLogLink = '/playergamelogs/' + this.playerid;
        let leaderboardsLink = '/leaderboards/' + this.playerposition;
        let glossaryLink = '/glossary/';
        return (
            <div className="App">
                <div className="container">
                    <div className="row page-header">
                        <div className='col-md-12'><br /></div>
                        <div className="col-md-6">
                            <a href="http://www.sisdatahub.com">
                                <img src="/images/logo.png" alt="logo" height="70" width="166" />
                            </a>
                        </div>
                        <div  className="col-md-6">
                            <div id="player-autocomplete-wrapper">
                                <p><font color="MediumSeaGreen"><b>Player Search:</b></font></p>
                                {
                                    this.playerSelectionList &&
                                    <DropDown players={this.playerSelectionList} targetPage={this.targetPage} />
                                }
                                <i><font size="2">last, first name (pos, team #jersey)</font></i>
                            </div>
                        </div>
                        <div className='col-md-12'><br /></div>
                        <div style={{backgroundColor: 'red', textAlign: 'center' }} className="col-md-12"><a href="http://pro.sisdatahub.com">
                            <b style={{color: 'white'}}>Go PRO! Click here for more details!</b></a>
                        </div>

                        <div id="hero1" className="col-md-12">SIS DataHub</div>
                        <ul className="col-md-12 nav nav-tabs" id="myTab" role="tablist">
                            <li className="nav-item">
                                <a className="nav-link" href={playerLink}>PLAYERS</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link active" href={playerGameLogLink}>GAME LOGS</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href={leaderboardsLink}>LEADERBOARDS</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href={glossaryLink}>GLOSSARY</a>
                            </li>
                        </ul>
                        <div style={{clear: 'both'}}></div>
                    </div>

                    <div className="container" onClick={this.reScroll}>
                        <h3> </h3>
                        {
                            this.alldependenciescomplete &&
                                <PlayerBio data={this.globalstats} />
                        }
                        {
                            !this.alldependenciescomplete &&
                            <i className='fa fa-spinner fa-spin loading-icon'></i>
                        }

                        {
                            this.pagelayout === 'QB' && this.alldependenciescomplete && this.asyncAPIrows !== 0 && this.asyncAPIrows !== this.accuracystats.length &&
                            <React.Fragment>
                                <GameLogQBSummaryTabulator passingstats={this.passingstats} rushingstats={this.rushingstats} />
                            </React.Fragment>
                        }
                        {
                            this.pagelayout === 'OL' && this.alldependenciescomplete && this.blockingstats.length &&
                            <React.Fragment>
                                <GameLogOLSummaryTabulator blockingstats={this.blockingstats} />
                            </React.Fragment>
                        }
                        {
                            this.pagelayout === 'Receiver' && this.alldependenciescomplete && this.asyncAPIrows !== 0 &&
                            <React.Fragment>
                                <GameLogWRTERBFBSummaryTabulator rushingstats={this.rushingstats} receivingstats={this.receivingstats} puntretstats={this.puntretstats} kickretstats={this.kickretstats} blockingstats={this.blockingstats} />
                            </React.Fragment>
                        }
                        {
                            this.pagelayout === 'K' && this.alldependenciescomplete && this.kickingstats.length !== 0 &&
                            <React.Fragment>
                                <GameLogKPKickingTabulator kickingstats={this.kickingstats} />
                            </React.Fragment>
                        }
                        {
                            this.pagelayout === 'P' && this.alldependenciescomplete && this.puntingstats.length !== 0 &&
                            <React.Fragment>
                                <GameLogKPPuntingTabulator puntingstats={this.puntingstats} />
                            </React.Fragment>
                        }
                        {
                            this.pagelayout === 'Defense' && this.alldependenciescomplete && this.defenseData.length !== 0 && (this.havedefense || this.havepassdefense || this.haverushdefense || this.havepassrush || this.havepuntret || this.havekickret) &&
                            <React.Fragment>
                                <GameLogDefenseSummaryTabulator defensestats={this.defenseData} isLeaderboards={false} />
                            </React.Fragment>
                        }
                        {
                            this.pagelayout !== 'QB' && this.pagelayout !== 'OL' && this.pagelayout !== 'Receiver' && this.pagelayout !== 'Defense' && this.pagelayout !== 'K' && this.pagelayout !== 'P' && this.alldependenciescomplete &&
                            <React.Fragment>
                                <div>
                                    <br />
                                    <br />
                                    <h4>*** Position no supported at this time. Coming soon ***</h4>
                                </div>
                            </React.Fragment>
                        }

                    </div>
                    <br/>
                    <br/>
                    <p><font size="1">v. {this.version}</font></p>
                </div>
            </div>
        )
    }
}

export default PlayerGameLogs;