import React, { Component } from 'react';
import DropDown from './components/common/DropDown';

class Glossary extends Component {
    constructor(props, context) {
        super(props, context);
        this.version = '2022-09-13a';
        this.state = {
            searchUserInput: '',                    // user input from autocomplete. triggers selection list updates
            selectedPlayerID: '',                   // set to true when API calls complete for a given player. triggers page update
            searchGlossaryKey: ''                   // used to filter the display of glossary items
        }
//        this.onGlossaryFilterChange = this.onGlossaryFilterChange.bind(this);
        this.playerSelectionList = [];              // all NFL players to be listed in the Autocomplete dropdown
        this.token = '';                            // token value retrieved from authorization API and used for data API
        this.playerId = 0;
        this.playerCategory = 'QB';
        let cookies = document.cookie.split(';');
        for (var i=0; i < cookies.length; i++){
            let c = cookies[i];
            while(c.substring(0, 1) === ' ') c = c.substring(1, c.length);
            if (c.indexOf('playerId') === 0 && !isNaN(c.substring(9, c.length))) {
                this.playerId = c.substring(9, c.length);
            } else if (c.indexOf('playerPos') === 0) {
                this.playerCategory = c.substring(10, c.length);
            }
        }
        let key = 0;
        this.glossaryEntries = [
            [key++,"Passing",""],
            [key++,"Air-Yards%:","Percent of passing yards gained through Air-Yards."],
            [key++,"AirYards or Air-Yards:","The number of yards the ball travels downfield from the line of scrimmage to where the wide receiver catches the ball."],
            [key++,"Average Throw Depth:","Average depth of each of the quarterback's pass attempts."],
            [key++,"Behind Line of Scrimmage:","Throws where the target depth was behind the line of scrimmage."],
            [key++,"Catchable:","Number of passes that were catchable, according to SIS Video Scouts."],
            [key++,"Catchable%:","Percent of passes that were catchable, according to SIS Video Scouts."],
            [key++,"Deep:","Throws where the target depth was at least 20 yards."],
            [key++,"EPA (Pro only):","Expected Points Added; the total change in the offense's Expected Points that came on passes thrown by the player."],
            [key++,"Int%:","Percent of passes resulting in interception."],
            [key++,"Intermediate:","Throws where the target depth was between 10 and 19 yards."],
            [key++,"IQR:","Sports Info Solutions' proprietary quarterback metric builds on the traditional Passer Rating formula by considering the value of a quarterback independent of results outside of his control such as dropped passes, dropped interceptions, throwaways, etc."],
            [key++,"Left - Attempt Pct:","Throws to the left of the hashmarks."],
            [key++,"Lg:","Longest pass."],
            [key++,"Mid - Attempt Pct:","Throws between the hashmarks."],
            [key++,"On-Tgt or On Target:","The number of times a quarterback's throw is deemed catchable by the wide receiver, regardless of whether or not the receiver catches the pass."],
            [key++,"On-Tgt% or On Target%:","The number of on-target/catchable throws a quarterback makes divided by the total number of pass attempts."],
            [key++,"Points Earned or Passing Points Earned:","The total of a player's EPA responsibility on passes using the Total Points system that distributes credit among all players on the field for a given play. For passers, this includes accounting for offensive line play, sacks, off-target passes, dropped passes, and dropped interceptions."],
            [key++,"Positive% (Pro only):","Positive Play Percentage; the percentage of passes thrown by the player that resulted in a positive EPA (i.e. a successful play for the offense)."],
            [key++,"Rating or QB Rating:","Traditional Passer Rating."],
            [key++,"Right - Attempt Pct:","Throws to the right of the hashmarks."],
            [key++,"Sack%:","Percent of dropbacks resulting in a sack."],
            [key++,"Short:","Throws where the target depth was between 0 and 9 yards."],
            [key++,"TD%:","Percent of passes resulting in touchdown."],
            [key++,"Y/A or Yds/Att:","Yards per pass attempt."],
            [key++,"Y/G:","Passing yards per game played."],
            [key++,"Rushing",""],
            [key++,"1st Down% (Pro only):","The percentage of rushes in which the rusher achieves a first down."],
            [key++,"Brkn Tkls or Broken Tackles:","The number of times a defender was in position to make a tackle and made contact with the ball carrier, but failed to bring them down."],
            [key++,"BT/A (Renamed from 'Brkn Tkl%') or Broken Tackles Pct.:","The total number of broken tackles on rushes divided by the number of rush attempts."],
            [key++,"EPA (Pro only):","Expected Points Added; the total change in the offense's Expected Points that came on carries by the player."],
            [key++,"Inside:","Runs where the intended direction was between the center and guard (excluding kneeldowns)."],
            [key++,"Lg:","Longest run."],
            [key++,"Off Tackle:","Runs where the intended direction was between the guard and the tackle."],
            [key++,"Outside:","Runs where the intended direction was outside the tackles."],
            [key++,"Points Earned or Rushing Points Earned:","The total of a player's EPA responsibility on rushes using the Total Points system that distributes credit among all players on the field for a given play. For rushers, this includes accounting for offensive line play, run direction, broken tackles, and fumbles."],
            [key++,"Positive% (Pro only):","Positive Play Percentage; the percentage of rushes by the player that resulted in a positive EPA (i.e. a successful play for the offense)."],
            [key++,"Y/A or Rushing Avg:","Yards per rush attempt."],
            [key++,"Y/G:","Rushing yards per game played."],
            [key++,"YAC/Att or Yards After Contact Avg.:","Yards after contact per rush attempt."],
            [key++,"YAContact or Yards After Contact:","The amount of additional yards the ball carrier achieves after first contact is made by a defender."],
            [key++,"Receiving",""],
            [key++,"1st Down% or First Down%:","The percentage of receptions in which the receiver achieves a first down."],
            [key++,"AirYards:","The number of yards the ball travels downfield from the line of scrimmage to where the wide receiver catches the ball."],
            [key++,"Avg Depth:","Average depth of each reception relative to the line of scrimmage."],
            [key++,"Brkn Tkls:","The number of times a defender was in position to make a tackle and made contact with the ball carrier after a reception, but failed to bring them down."],
            [key++,"Catchable - All:","Total Number of catchable throws targeting a receiver."],
            [key++,"Catchable:","Pass attempts that the receiver was capable of catching."],
            [key++,"Comp% or Rec%:","The percentage of passes thrown to the receiver that result in a catch."],
            [key++,"Drop %:","Drops/catchable targets."],
            [key++,"EPA (Pro only):","Expected Points Added; the total change in the offense's Expected Points that came on passes thrown to the player."],
            [key++,"Lg:","Longest reception."],
            [key++,"On-Tgt Catch % or Catch%:","Total receptions divided by the number of catchable targets."],
            [key++,"Points Earned or Receiving Points Earned:","The total of a player's EPA responsibility on targets using the Total Points system that distributes credit among all players on the field for a given play. For receivers, this includes accounting for offensive line play, off-target passes, dropped passes, and broken tackles."],
            [key++,"Positive% (Pro only):","Positive Play Percentage; the percentage of passes thrown to the player that resulted in a positive EPA (i.e. a successful play for the offense)."],
            [key++,"Rec. Rtg or Receiver Rating:","The quarterback's traditional Passer Rating on throws when targeting the receiver."],
            [key++,"Share%:","aka Target Share, the Percent of the team's targets directed toward a specific player."],
            [key++,"Y/G:","Receiving yards per game played."],
            [key++,"Y/R or Receiving Avg.:","Yards per reception."],
            [key++,"Y/Tgt (Pro only):","Receiving yards per pass attempt in which the receiver is targeted, including incompletions."],
            [key++,"YAC or Yds After Catch:","Yards after catch, the amount of additional yards the ball carrier is a."],
            [key++,"YAC Per Rec:","Avg. yards after catch per reception."],
            [key++,"YAContact:","The amount of additional receiving yards the ball carrier achieves after first contact is made by a defender."],
            [key++,"Blocking",""],
            [key++,"AdjBB% (Pro only):","Strength of schedule Adjusted Blown Block Rate. Takes the extent to which a player's defensive opponents had forced blown blocks in their other matchups above those offenses' averages, and uses that as a strength-of-schedule adjustment for Blown Block Rate."],
            [key++,"BB or Total Blown Blocks:","A blown block, any time a blocker does not successfully block the defender they attempted to engage with and, as a result, gives the defender an opportunity to negatively affect the play."],
            [key++,"BB% or Blown Blocks Pct:","Blown Block Rate, the percentage of blocking snaps that the player had a blown block."],
            [key++,"Blown Blocks - Pass:","Number of blown blocks on a passing play."],
            [key++,"Blown Blocks - Rush:","Number of blown blocks on a rushing play."],
            [key++,"Bounce Rate (Pro only):","Bounces per run-blocking snap."],
            [key++,"Bounces (Pro only):","The number of times the player was run blocking and the rusher did not go through the designed gap."],
            [key++,"Designed Run Pct. (Pro only):","The percentage of run-blocking snaps that the player was on the field where the design of the play was for the rusher to run through their gap."],
            [key++,"Designed Runs (Pro only):","The number of times a rush attempt was designed to go through the blocker's gap."],
            [key++,"EPA (Pro only):","Expected Points Added; the total change in the offense's Expected Points that came on plays in which the player was blocking."],
            [key++,"Opp Sacks - Pct:","Percent of sacks allowed to the opponent on passing snaps."],
            [key++,"Opp Sacks - Total:","Number of sacks allowed to the opponent."],
            [key++,"Opp Stuffs - Pct:","Percent of run plays where the ballcarrier was stopped behind the line of scrimmage."],
            [key++,"Opp Stuffs - Total:","Number of run plays where the ballcarrier was stopped behind the line of scrimmage."],
            [key++,"Passing Snaps:","Number of passing snaps player was used as a blocker."],
            [key++,"Points Earned (Pro only):","The total of a player's EPA responsibility while blocking using the Total Points system that distributes credit among all players on the field for a given play. For blockers, this includes accounting for blown blocks, yards before contact on running plays, and performance given the defenders in the box."],
            [key++,"Positive% (Pro only):","Positive Play Percentage; the percentage of plays in which the player was blocking that resulted in a positive EPA (i.e. a successful play for the offense)."],
            [key++,"Total Run Snaps or Rushing Snaps:","The number of run-blocking snaps on which the player was blocking."],
            [key++,"Pass Defense",""],
            [key++,"Catchable (Pro only):","Pass attempts that the receiver was capable of catching when the player was the primary defender."],
            [key++,"Cmp:","Number of completions allowed by a player while in pass coverage as the primary defender."],
            [key++,"Cmp%:","Percent of passes targeted at a player in pass coverage that resulted in a completion."],
            [key++,"Cov. Snaps (Pro only):","The number of pass snaps on which the player was in coverage."],
            [key++,"Deserved Catch %:","The percentage of targets as the primary defender that the receiver either caught or dropped the ball when the pass was catchable."],
            [key++,"Int Drops:","Number of easily catchable interception opportunities that did not result in an interception."],
            [key++,"Pass Batted or Passes Batted:","The number of times the defender knocked a pass down at the line of scrimmage by a defender rendering the pass entirely uncatchable."],
            [key++,"Pass Breakups:","Number of times a player physically prevented a receiver from making the catch."],
            [key++,"Pass Def. or Defensed:","Passes defensed; the number of times the defender broke up a throw at the catch point. This can be done by either knocking the ball away or hitting the receiver as the ball reaches them to disrupt the catch."],
            [key++,"Pass Defl. or Deflected:","Passes deflected; the number of times the defender made contact with a pass either at the line of scrimmage or in the secondary, but without entirely stopping the pass from moving downfield."],
            [key++,"Points Saved - Pass Defense:","The total of a player's EPA responsibility while in coverage using the Total Points system that distributes credit among all players on the field for a given play (with positive numbers being good). For pass defenders, this includes accounting for pass rush, broken tackles, dropped interceptions, turnovers, and turnover returns."],
            [key++,"Positive% (Pro only):","Positive Play Percentage; the percentage of plays with the player in coverage that resulted in a positive EPA (i.e. a successful play for the offense)."],
            [key++,"QBR Against (Pro only):","Traditional Passer Rating allowed as the primary defender EPA."],
            [key++,"Targeted:","Number of times a player was targeted by a pass while in pass coverage as the primary defender."],
            [key++,"TD Allowed:","Number of touchdowns allowed on passes targeting a defensive player in pass coverage."],
            [key++,"Y/A (renamed from 'YPA') or Yds/Trgt:","Yards per pass attempt allowed as the primary pass defender."],
            [key++,"Y/G (Renamed from 'YPG') (Pro only):","Yards allowed as the primary pass defender per game played."],
            [key++,"Pass Rushing",""],
            [key++,"Ast. Sacks (Pro only):","Assisted sacks; the number of times the defender helped sack the QB with the assistance of another defender."],
            [key++,"Comb. Sacks or Sacks:","Combined sacks; the number of solo sacks added to half of the amount of assisted sacks."],
            [key++,"EPA (Pro only):","Expected Points Added; the total change in the offense's Expected Points that came when the player was rushing the passer."],
            [key++,"FF:","Forced fumbles."],
            [key++,"FR (renamed from 'RF') (Pro only):","Fumbles recovered."],
            [key++,"Hit%:","Percent of pass rushes that resulted in a QB hit."],
            [key++,"Hits:","Any meaningful contact (more than a light brush) made by the defender on the quarterback before or as he releases the football."],
            [key++,"Hurries:","When a defender gets close to the quarterback and forces him to rush a throw before he may have otherwise thrown it."],
            [key++,"Hurry%:","Percent of pass rushes that resulted in a QB hurry."],
            [key++,"Knockdown%:","Percent of pass rushes that resulted in a QB knockdown."],
            [key++,"Knockdowns:","Whenever a quarterback gets knocked down to the ground by a defender."],
            [key++,"Pass Defl. (Pro only):","Passes deflected; the number of times the defender made contact with a pass either at the line of scrimmage or in the secondary, but without entirely stopping the pass from moving downfield."],
            [key++,"Pass Rushes:","The number of pass snaps on which the player was rushing the passer."],
            [key++,"Pass Snaps:","The number of pass snaps on which the player was on the field."],
            [key++,"Passes Batted:","The number of times the defender knocked a pass down at the line of scrimmage by a defender rendering the pass entirely uncatchable."],
            [key++,"Passes Batted%:","Percent of pass rushes that resulted in a pass batted."],
            [key++,"Points Saved - Pass Rush:","The total of a player's EPA responsibility while rushing the passer using the Total Points system that distributes credit among all players on the field for a given play (with positive numbers being good). For pass rushers, this includes accounting for sacks, blown blocks forced, turnovers, turnover returns, and other disruptions at the line of scrimmage."],
            [key++,"Positive% (Pro only):","Positive Play Percentage; the percentage of plays with the player rushing the passer that resulted in a positive EPA (i.e. a successful play for the offense)."],
            [key++,"Pressure%:","Percent of pass rushes that resulted in a QB pressure."],
            [key++,"Pressures:","Any play in which there is a quarterback hurry, hit, or knockdown."],
            [key++,"Rush%:","Percent of pass snaps the player rushed the passer."],
            [key++,"Sack%:","Percent of pass rushes that resulted in a sack."],
            [key++,"Solo Sacks (Pro only):","Sacks in which the defender brought down the quarterback by himself."],
            [key++,"Unblocked Sacks (Pro only):","A sack in which the rusher was able to reach the quarterback without anyone blocking them."],
            [key++,"Run Defense",""],
            [key++,"Ast. Tkl:","Assisted tackles; tackles by the defender in which they brought down the ball carrier with the assistance of another defender."],
            [key++,"Avg Tackle Depth:","Avg. distance from line of scrimmage on tackles in yards (positive value is beyond the line of scrimmage, negative value is behind the line of scrimmage)."],
            [key++,"Broken + Missed:","Total Number of broken and missed tackles by a player."],
            [key++,"Broken + Missed%:","Percent of tackle opportunities that resulted in a broken or missed tackle."],
            [key++,"Broken Tkl Yds (Pro only):","The amount of extra yards gained as a result of broken tackles."],
            [key++,"Broken Tkl (Pro only):","The number of times the defender was in position to make a tackle and made contact with the ball carrier, but failed to bring them down."],
            [key++,"Comb. Tkl or Tackles:","Combined tackles; the number of solo tackles added to the number of assisted tackles."],
            [key++,"EPA (Pro only):","Expected Points Added; the total change in the offense's Expected Points that came on run plays when the player was on the field."],
            [key++,"FF:","Forced fumbles."],
            [key++,"FR TD (Pro only):","The number of times a player recovered a fumble and returned it for a touchdown."],
            [key++,"FR (Pro only):","Fumbles recovered."],
            [key++,"Missed Tackle Rate or Broken + Missed%:","The percentage of attempted tackles that result in a player failing to bring down the ball carrier when they are in position to do so."],
            [key++,"Points Saved - Run Defense:","The total of a player's EPA responsibility on run plays using the Total Points system that distributes credit among all players on the field for a given play (with positive numbers being good). For run defense, that includes accounting for defenders in the box, blown blocks forced, broken tackles, turnovers, and turnover returns."],
            [key++,"Positive% (Pro only):","Positive Play Percentage; the percentage of run plays with the player on the field that resulted in a positive EPA (i.e. a successful play for the offense)."],
            [key++,"Rush Snaps:","The number of running plays on which the defender was on the field."],
            [key++,"Solo Tkl:","Solo tackles."],
            [key++,"TFL:","Tackles for a loss."],
            [key++,"Tkl Short of 1st (Pro only):","Number of times a player tackled a runner before they could achieve a first down on the play."],
            [key++,"Tkl/G (Pro only):","The amount of tackles a defender averages per game."],
            [key++,"Punting",""],
            [key++,"Blocks:","Blocked punts that do not cross the line of scrimmage."],
            [key++,"FCs or Fair Catches:","Fair catches; punts in which the receiving player called for a fair catch."],
            [key++,"Net Avg.:","Total punt yards minus return yards and touchback yards equals the Net total. Net total divided by the number of punts (not including Blocks) gives the Net Avg."],
            [key++,"Partial Blocks:","Punts that are deflected but still cross the line of scrimmage."],
            [key++,"TBs or Touchbacks:","Touchbacks; punts that resulted in a touchback."]
        ];
        this.filteredGlossary = this.glossaryEntries
    }

    componentDidMount() {
        this.getSecurityToken();
    }

    // There are three API calls that must complete in sequence when this page loads:
    //
    // 1) obtain token from security API
    // 2) use token to access the data API
    // 3) load NFL players to an array for user selection
    //
    // API chaining is accomplished by each API routine invoking the next on successfull completion:
    // 1) getSecurityToken --> 2) accessNFLdata --> 3) loadNFLplayers
    //
    getSecurityToken = () => {
        this.executeHTTPRequestPost(
            'https://auth.sportsinfosolutions.com/connect/token',
            (response) => {
                this.token = response.access_token;
                this.getAllPlayers();
            },
            (error) => {
                console.log(error);
            }
        )
    }

    // Gain access to NFL data API using the token obtained from the secutiry API
    getAllPlayers = (event) => {
        const reqUrl = 'https://api.sportsinfosolutions.com/api/v1/nfl/players/allplayerswithteamnew';
        this.executeHTTPRequest(
            reqUrl,
            'get',
            (response) => {
                this.playerSelectionList = response;
                this.forceUpdate()
            },
            (error) => {
                console.log(error);
            }
        )
    }

    // general purpose fetch method called by each API related method
    executeHTTPRequest = (url, method, callback, failback) => {
        fetch(
            url, {
                method: method,
                headers: {
                    Accept: 'application/json, text/plain, */*',
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${this.token}`
                },
            })
            .then((response) => {
                let json = response.json();
                if (response.ok) {
                    if (callback) {
                        json.then((result) => {
                            callback(result);
                        });
                    }
                } else {
                    return json.then(Promise.reject.bind(Promise));
                }
            })
            .catch((error) => {
                if (failback) {
                    failback(error);
                }
            });
    }

    executeHTTPRequestPost = (url, callback, failback) => {
        var details = {'client_id': 'FootballApp@sportsinfosolutions.com',
        'client_secret': 'xPc14+wU2zHtydg12AgglRCkL+ALNlX/miH2JJHp2/k=',
        'grant_type': 'client_credentials',
        'scope': 'sisapi'};

        var formBody = [];
        for (var property in details){
            var encodedKey = encodeURIComponent(property);
            var encodedValue = encodeURIComponent(details[property]);
            formBody.push(encodedKey + "=" + encodedValue);
        }
        formBody = formBody.join("&");

        fetch(
            url, {
                method: "POST",
                cache: "no-cache",
                headers: {'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'},
                body: formBody,
        })
        .then((response) => {
            let json = response.json();
            if (response.ok) {
                if (callback) {
                    json.then((result) => {
                        callback(result);
                    });
                }
            } else {
                return json.then(Promise.reject.bind(Promise));
            }
        })
        .catch((error) => {
            if (failback) {
                failback(error);
            }
        });
    }

    filterPlayers = (ids, data) => {
        return data.filter(d => ids.indexOf(d.playerId) > -1);
    }

    onGlossaryFilterChange = e => {
        let keep = false;
        this.filteredGlossary = this.glossaryEntries.filter( ([key, heading, definition]) => {
            if (definition === "" || e.target.value === "") {
                keep = true;
            } else if (heading.toLowerCase().search(e.target.value.toLowerCase()) > -1) {
                keep= true;
            } else if (definition.toLowerCase().search(e.target.value.toLowerCase()) > -1) {
                keep = true;
            } else {
                keep = false;
            }
            return keep;
        });
        this.setState({searchGlossaryKey: e.target.value});
    }

    render() {
        let playerLink = '/players/' + this.playerId;
        let playerGameLogLink = '/playergamelogs/' + this.playerId;
        let leaderboardLink = '/leaderboards/' + this.playerCategory;
        let glossaryLink = '/glossary/';
        return (
            <div className="App">
                <div className="container">
                    <div className="row page-header">
                        <div className='col-md-12'><br /></div>
                        <div className="col-md-6">
                            <a href="http://sisdatahub.com">
                                <img src="/images/logo.png" alt="logo" height="70" width="166" />
                            </a>
                        </div>
                        <div className="col-md-6">
                            <div id="player-autocomplete-wrapper">
                                <p><font color="MediumSeaGreen"><b>Player Search:</b></font></p>
                                {
                                    this.playerSelectionList &&
                                    <DropDown players={this.playerSelectionList} />
                                }
                                <i><font size="2">last, first name (pos, team #jersey)</font></i>
                            </div>
                        </div>
                        <div className='col-md-12'><br /></div>
                        <div style={{ backgroundColor: 'red', textAlign: 'center' }} className="col-md-12"><a href="http://pro.sisdatahub.com">
                            <b style={{ color: 'white' }}>Go PRO! Click here for more details!</b></a>
                        </div>
                        
                        <div id="hero1" className="col-md-12">SIS DataHub</div>
                        <ul className="nav nav-tabs col-md-12" role="tablist">
                            <li className="nav-item">
                                <a className="nav-link" href={playerLink}>PLAYERS</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href={playerGameLogLink}>GAME LOGS</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href={leaderboardLink}>LEADERBOARDS</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link active" href={glossaryLink}>GLOSSARY</a>
                            </li>
                        </ul>
                    </div>

                    <div className="row">
                        <div className="col-md-12">
                            <div className="shadowdiv" id="table-title">Glossary</div>
                            <div className="glossarySearchBar shadowdiv">
                                <input type="text" onChange={this.onGlossaryFilterChange.bind(this)} placeholder="Search for..." title="Type in a search term"></input>
                            </div>
                            <div id="glossary_items">
                                {this.filteredGlossary.map( (g) => (
                                    (g[2] && <p key={g[0]}><b>{g[1]}</b> {g[2]}</p>) || (<h4 key={g[0]}>{g[1]}</h4>)
                                ))}
                            </div>    
                        </div>    
                    </div>
                    <br />
                    <br />
                    <p><font size='1'> v. {this.version}</font></p>
                </div>
            </div >

        )
    }
}

export default Glossary;




