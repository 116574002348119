import React, { useState } from 'react';
import TableKPKickingTabulator from '../pageKP/TableKPKickingTabulator';

const KickingSection = (d) => {
    const [startSeason, setStartSeason] = useState(d.filters.startSeason);
    const [endSeason, setEndSeason] = useState(d.filters.endSeason);
    const [tabName, setTabName] = useState("kicking");

    let fromSeasons = [];
    let toSeasons = [];
    for(var i = new Date().getFullYear(); i >= 2015; i--){
        if (i.toString() === d.filters.startSeason){
            fromSeasons.push(<option value={i} selected='selected'>{i}</option>)
        }
        else{
            fromSeasons.push(<option value={i}>{i}</option>)
        } 
        if (i.toString() === d.filters.endSeason){
            toSeasons.push(<option value={i} selected='selected'>{i}</option>)
        }
        else{
            toSeasons.push(<option value={i}>{i}</option>)
        }
    }

    return (
        <React.Fragment>
            <div className="form-group row" id="yearRangePanel">
                <div className="col-sm-3"></div>
                <label htmlFor="fromSeason" className="col-sm-1 col-form-label-sm">Year: </label>
                <select className="form-control form-control-sm col-sm-1" name="fromSeason" id="fromSeason" onChange={(event) => setStartSeason(event.target.value)}>
                    {fromSeasons}
                </select>
                <label htmlFor="toSeason" className="col-sm-1 col-form-label-sm">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;To: </label>
                <select className="form-control form-control-sm col-sm-1" name="toSeason" id="toSeason" onChange={(event) => setEndSeason(event.target.value)}>
                    {toSeasons}
                </select>
                <div className="col-sm-1"></div>
                <button type="submit" className="btn btn-primary" onClick={(e) => d.onSubmit(e, startSeason, endSeason)}>Submit</button>
            </div>

            <div className="shadowdiv">
                <ul className="nav nav-tabs" id="positionSubsections" role="tablist">
                    <li className="nav-item">
                        <a className="nav-link active" id="kicking-tab" data-toggle="tab" href="#kicking" role="tab" aria-controls="kicking" aria-selected="true"  onClick={() => setTabName("kicking")}>Kicking</a>
                    </li>
                </ul>
            </div>

            <div onClick={(e) => d.reScroll()}>
                {
                    tabName === "kicking" &&
                    <TableKPKickingTabulator kickingstats={d.kickingstats} globalstats={d.globalstats} isLeaderboards={true} />
                }
            </div>
        </React.Fragment>
    );
}

export default KickingSection;
