import React from 'react';
import {NullSum, NullBypassSum, NullDiv, NullMax, DispFunc} from '../common/CommonFunctions';
import { kicking } from '../common/TableColumnWidths';
import Tabulator from 'tabulator-tables';
import "tabulator-tables/dist/css/tabulator.min.css";
import Filters from '../common/Filters';

class TableKPKickingTabulator extends React.Component {
    constructor(props) {
        super(props);
        this.kickingstats = this.props.kickingstats;
        this.isLeaderboards = this.props.isLeaderboards;
        this.minFilter = 1;
        this.data = [];
        let bioCols = null;
        let sortable = false;
        this.initSort = null;
        this.colNum = this.isLeaderboards ? 0 : 1;

        this.onSubmit = this.onSubmit.bind(this);

         // Custom sort function for players page so Total stays at the bottom of the table.
         let sortFunc = (a, b, aRow, bRow, column, dir, sorterParams) => {
            var aData = aRow.getData();
            var bData = bRow.getData();
            if (aData.season === 'Total'){
                if (dir === 'desc'){
                    return -1;
                }
                else if (dir === 'asc'){
                    return 1
                }
            }
            else if (bData.season === 'Total'){
                if (dir === 'desc'){
                    return 1;
                }
                else if (dir === 'asc'){
                    return -1;
                }            
            }
            else {
                return a-b;
            }
        };

        // Set first group of columns
        // If this is a leaderboards page include player name, remove custom sorter and set initial sort to proper column.
        if (this.isLeaderboards){
            sortable = true;
            bioCols = [
                {
                    title: "Player", field: "player", cssClass: "single-row-header textColumn",
                    headerSort: sortable,
                    width: `${kicking[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                    formatter: "link", formatterParams:{
                        labelField: "player",
                        urlPrefix:"/players/",
                        urlField: "playerId"
                    }
                },
                //{ title: "Pos", field: "positionName", cssClass: "textColumn", headerSort: sortable, sorter: sortFunc },
                {
                    title: "Year", field: "season", cssClass: "single-row-header textColumn",
                    width: `${kicking[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                    headerSort: sortable
                },
                {
                    title: "Team", field: "team", cssClass: "single-row-header textColumn",
                    width: `${kicking[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                    headerSort: sortable
                },
                {
                    title: "G", field: "games", cssClass: "single-row-header small-column break",
                    width: `${kicking[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                    headerSortStartingDir: "desc", headerSort: sortable
                }
    
            ];
            this.sorterFunc = null;
            this.initSort = [
                { column: "PointsEarned", dir: "desc" }
            ];
        }
        // Else don't include player name and set initial sort to season.
        else{
            bioCols = [
                {
                    title: "Year", field: "season", cssClass: "single-row-header textColumn",
                    width: `${kicking[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                    headerSort: sortable, sorter: sortFunc
                },
                {
                    title: "Team", field: "team", cssClass: "single-row-header textColumn",
                    width: `${kicking[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                    headerSort: sortable, sorter: sortFunc
                }, 
                {
                    title: "G", field: "games", cssClass: "single-row-header small-column break",
                    width: `${kicking[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                    headerSortStartingDir: "desc", headerSort: sortable, sorter: sortFunc
                },      
           ];
            this.initSort = [
                { column: "season", dir: "asc" }
            ];
        }
        // Set Remaining Columns
        this.cols = [
            { title: "",
                columns: bioCols
            },
            {
                title: "Overall",
                columns:[
                    {
                        title: "Made", field: "overallMade", cssClass: "single-row-header",
                        headerSortStartingDir: "desc", headerSort: sortable, sorter: sortFunc,
                        width: `${kicking[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                        formatter: function(cell, formatterParams, onRendered){
                            return DispFunc(cell, formatterParams, onRendered, 0, false, false);
                        }
                    },
                    {
                        title: "Att", field: "overallAttempts", cssClass: "single-row-header",
                        headerSortStartingDir: "desc", headerSort: sortable, sorter: sortFunc,
                        width: `${kicking[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                        formatter: function(cell, formatterParams, onRendered){
                            return DispFunc(cell, formatterParams, onRendered, 0, false, false);
                        }
                    },
                    {
                        title: "Pct", field: "overallPct", cssClass: "single-row-header",
                        headerSortStartingDir: "desc", headerSort: sortable, sorter: sortFunc,
                        width: `${kicking[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                        formatter: function(cell, formatterParams, onRendered){
                            return DispFunc(cell, formatterParams, onRendered, 1, false, false);
                        }
                    },
                    {
                        title: "Long", field: "overallLong", cssClass: "single-row-header",
                        headerSortStartingDir: "desc", headerSort: sortable, sorter: sortFunc,
                        width: `${kicking[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                        formatter: function(cell, formatterParams, onRendered){
                            return DispFunc(cell, formatterParams, onRendered, 0, false, false);
                        }
                    },
                    {
                        title: "Pts", field: "overallPoints", cssClass: "single-row-header break",
                        headerSortStartingDir: "desc", headerSort: sortable, sorter: sortFunc,
                        width: `${kicking[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                        formatter: function(cell, formatterParams, onRendered){
                            return DispFunc(cell, formatterParams, onRendered, 0, false, false);
                        }
                    },
                ],
            },
            {
                title: "1-29 Yds",
                columns:[ 
                    {
                        title: "Made", field: "fg1to29Made", cssClass: "single-row-header",
                        headerSortStartingDir: "desc", headerSort: sortable, sorter: sortFunc,
                        width: `${kicking[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                        formatter: function(cell, formatterParams, onRendered){
                            return DispFunc(cell, formatterParams, onRendered, 0, false, false);
                        }
                    },
                    {
                        title: "Att", field: "fg1to29Attempts", cssClass: "single-row-header",
                        headerSortStartingDir: "desc", headerSort: sortable, sorter: sortFunc,
                        width: `${kicking[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                        formatter: function(cell, formatterParams, onRendered){
                            return DispFunc(cell, formatterParams, onRendered, 0, false, false);
                        }
                    },
                    {
                        title: "Pct", field: "fg1to29Pct", cssClass: "single-row-header break",
                        headerSortStartingDir: "desc", headerSort: sortable, sorter: sortFunc,
                        width: `${kicking[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                        formatter: function(cell, formatterParams, onRendered){
                            return DispFunc(cell, formatterParams, onRendered, 1, false, false);
                        }
                    }
                ],
            },
            {
                title: "30-39 Yds",
                columns:[ 
                    {
                        title: "Made", field: "fg30to39Made", cssClass: "single-row-header",
                        headerSortStartingDir: "desc", headerSort: sortable, sorter: sortFunc,
                        width: `${kicking[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                        formatter: function(cell, formatterParams, onRendered){
                            return DispFunc(cell, formatterParams, onRendered, 0, false, false);
                        }
                    },
                    {
                        title: "Att", field: "fg30to39Attempts", cssClass: "single-row-header",
                        headerSortStartingDir: "desc", headerSort: sortable, sorter: sortFunc,
                        width: `${kicking[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                        formatter: function(cell, formatterParams, onRendered){
                            return DispFunc(cell, formatterParams, onRendered, 0, false, false);
                        }
                    },
                    {
                        title: "Pct", field: "fg30to39Pct", cssClass: "single-row-header break",
                        headerSortStartingDir: "desc", headerSort: sortable, sorter: sortFunc,
                        width: `${kicking[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                        formatter: function(cell, formatterParams, onRendered){
                            return DispFunc(cell, formatterParams, onRendered, 1, false, false);
                        }
                    }
                ]
            },
            {
                title: "40-49 Yds",
                columns: [
                    {
                        title: "Made", field: "fg40to49Made", cssClass: "single-row-header",
                        headerSortStartingDir: "desc", headerSort: sortable, sorter: sortFunc,
                        width: `${kicking[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                        formatter: function(cell, formatterParams, onRendered){
                            return DispFunc(cell, formatterParams, onRendered, 0, false, false);
                        }
                    },
                    { 
                        title: "Att", field: "fg40to49Attempts", cssClass: "single-row-header",
                        headerSortStartingDir: "desc", headerSort: sortable, sorter: sortFunc,
                        width: `${kicking[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                        formatter: function(cell, formatterParams, onRendered){
                            return DispFunc(cell, formatterParams, onRendered, 0, false, false);
                        }
                    },
                    { 
                        title: "Pct", field: "fg40to49Pct", cssClass: "single-row-header break",
                        headerSortStartingDir: "desc", headerSort: sortable, sorter: sortFunc,
                        width: `${kicking[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                        formatter: function(cell, formatterParams, onRendered){
                            return DispFunc(cell, formatterParams, onRendered, 1, false, false);
                        }
                    },
                ]
            },
            {
                title: "50+ Yds",
                columns: [
                    { 
                        title: "Made", field: "fg50plusMade", cssClass: "single-row-header",
                        headerSortStartingDir: "desc", headerSort: sortable, sorter: sortFunc,
                        width: `${kicking[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                        formatter: function(cell, formatterParams, onRendered){
                            return DispFunc(cell, formatterParams, onRendered, 0, false, false);
                        }
                    },
                    { 
                        title: "Att", field: "fg50plusAttempts", cssClass: "single-row-header",
                        width: `${kicking[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                        headerSortStartingDir: "desc", headerSort: sortable, sorter: sortFunc,
                        formatter: function(cell, formatterParams, onRendered){
                            return DispFunc(cell, formatterParams, onRendered, 0, false, false);
                        }
                    },
                    { 
                        title: "Pct", field: "fg50plusPct", cssClass: "single-row-header break",
                        width: `${kicking[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                        headerSortStartingDir: "desc", headerSort: sortable, sorter: sortFunc,
                        formatter: function(cell, formatterParams, onRendered){
                            return DispFunc(cell, formatterParams, onRendered, 1, false, false);
                        }
                    },
                ]
            },
            {
                title: "PAT",
                columns: [
                    { 
                        title: "Made", field: "patMade", cssClass: "single-row-header",
                        headerSortStartingDir: "desc", headerSort: sortable, sorter: sortFunc,
                        width: `${kicking[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                        formatter: function(cell, formatterParams, onRendered){
                            return DispFunc(cell, formatterParams, onRendered, 0, false, false);
                        }
                    },
                    { 
                        title: "Att", field: "patAttempts", cssClass: "single-row-header",
                        headerSortStartingDir: "desc", headerSort: sortable, sorter: sortFunc,
                        width: `${kicking[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                        formatter: function(cell, formatterParams, onRendered){
                            return DispFunc(cell, formatterParams, onRendered, 0, false, false);
                        }
                    },
                    { 
                        title: "Pct", field: "patPct", cssClass: "single-row-header break",
                        headerSortStartingDir: "desc", headerSort: sortable, sorter: sortFunc,
                        width: `${kicking[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                        formatter: function(cell, formatterParams, onRendered){
                            return DispFunc(cell, formatterParams, onRendered, 1, false, false);
                        }
                    },
                ],
            },
            {
                title: "Tot Points Earned", field: "PointsEarned",
                headerSortStartingDir: "desc", headerSort: sortable, sorter: sortFunc,
                titleFormatter:"textarea",
                width: `${kicking[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                formatter: function(cell, formatterParams, onRendered){
                    return DispFunc(cell, formatterParams, onRendered, 1, false, false);
                }
            },
        ]
        
        this.el = React.createRef();
        this.tabulator = null;
    }

    // Consolidate the data into one data array to be used for tabulator(called on component mount)
    ConsolidateData(){
        let data = [];
        let kickingstats = this.kickingstats;
        let player = '';
        let playerId = 0;
        let positionName = '';
        let team = '';
        let season = 0;
        let games = 0;
        let overallMade = 0;
        let overallAttempts = 0;
        let overallPct = 0;
        let overallLong = 0;
        let overallPoints = 0;
        let fg1to29Made = 0;
        let fg1to29Attempts = 0;
        let fg1to29Pct = 0;
        let fg30to39Made = 0;
        let fg30to39Attempts = 0;
        let fg30to39Pct = 0;
        let fg40to49Made = 0;
        let fg40to49Attempts = 0;
        let fg40to49Pct = 0;
        let fg50plusMade = 0;
        let fg50plusAttempts = 0;
        let fg50plusPct = 0;
        let patMade = 0;
        let patAttempts = 0;
        let patPct = 0;
        let PointsEarned = 0;

        // create rowdata[] object for each row in kickingstats data
        if(kickingstats.length !==0) {
            for (var i = 0; i < kickingstats.length; i++) {
                if (!this.isLeaderboards || kickingstats[i].fieldGoalAtt >= this.minFilter){
                    games = this.kickingstats[i].g;
                    season =this.kickingstats[i].season;
                    team = this.kickingstats[i].teamName;
                    player =this.kickingstats[i].playerName;
                    playerId = this.kickingstats[i].playerId;
                    positionName = this.kickingstats[i].positionName;
                    overallMade = this.kickingstats[i].fieldGoalMakes;
                    overallAttempts = this.kickingstats[i].fieldGoalAtt;
                    overallPoints = (this.kickingstats[i].fieldGoalMakes * 3) + this.kickingstats[i].extraPointMakes;;
                    overallLong = this.kickingstats[i].long;
                    fg1to29Made = NullSum(this.kickingstats[i].fgMade1to19,this.kickingstats[i].fgMade20to29);
                    fg1to29Attempts = NullSum(this.kickingstats[i].fgAtt1to19,this.kickingstats[i].fgAtt20to29);
                    fg30to39Made = this.kickingstats[i].fgMade30to39;
                    fg30to39Attempts = this.kickingstats[i].fgAtt30to39;
                    fg40to49Made = this.kickingstats[i].fgMade40to49;
                    fg40to49Attempts =this.kickingstats[i].fgAtt40to49;
                    fg50plusMade = this.kickingstats[i].fgMade50Plus;
                    fg50plusAttempts = this.kickingstats[i].fgAtt50Plus;
                    patMade = this.kickingstats[i].extraPointMakes;
                    patAttempts = this.kickingstats[i].extraPointAtt;
                    overallPct = NullDiv(overallMade,overallAttempts)*100;
                    fg1to29Pct = NullDiv(fg1to29Made,fg1to29Attempts)*100;
                    fg30to39Pct = NullDiv(fg30to39Made,fg30to39Attempts)*100;
                    fg40to49Pct = NullDiv(fg40to49Made,fg40to49Attempts)*100;
                    fg50plusPct = NullDiv(fg50plusMade,fg50plusAttempts)*100;
                    patPct = NullDiv(patMade,patAttempts)*100;
                    PointsEarned = this.kickingstats[i].eps;
                    data.push({
                        player,
                        playerId,
                        positionName,
                        season,
                        team,
                        games,
                        overallMade,
                        overallAttempts,
                        overallPct,
                        overallLong,
                        overallPoints,
                        fg1to29Made,
                        fg1to29Attempts,
                        fg1to29Pct,
                        fg30to39Made,
                        fg30to39Attempts,
                        fg30to39Pct,
                        fg40to49Made,
                        fg40to49Attempts,
                        fg40to49Pct,
                        fg50plusMade,
                        fg50plusAttempts,
                        fg50plusPct,
                        patMade,
                        patAttempts,
                        patPct,
                        PointsEarned
                    });
                }
            }
        }
        this.data = data;
    }

    SumData(){
        let player = '';
        let playerId = '';
        let positionName = '';
        let season = 'Total';
        let team = '';
        let games = 0;
        let overallMade = 0;
        let overallAttempts = 0;
        let overallPct = 0;
        let overallLong = 0;
        let overallPoints = 0;
        let fg1to29Made = 0;
        let fg1to29Attempts = 0;
        let fg1to29Pct = 0;
        let fg30to39Made = 0;
        let fg30to39Attempts = 0;
        let fg30to39Pct = 0;
        let fg40to49Made = 0;
        let fg40to49Attempts = 0;
        let fg40to49Pct = 0;
        let fg50plusMade = 0;
        let fg50plusAttempts = 0;
        let fg50plusPct = 0;
        let patMade = 0;
        let patAttempts = 0;
        let patPct = 0;
        let PointsEarned = 0;
        if (this.data.length !== 0){
            for (var i = 0; i < this.data.length; i++) {
                games = NullSum(games, this.data[i].games);
                overallMade = NullSum(overallMade,this.data[i].overallMade);
                overallAttempts = NullSum(overallAttempts,this.data[i].overallAttempts);
                overallPoints = NullSum(overallPoints,(this.kickingstats[i].fieldGoalMakes * 3));
                overallLong = NullMax(overallLong,this.data[i].overallLong);
                fg1to29Made = NullSum(fg1to29Made,this.data[i].fg1to29Made);
                fg1to29Attempts = NullSum(fg1to29Attempts,this.data[i].fg1to29Attempts);
                fg30to39Made = NullSum(fg30to39Made,this.data[i].fg30to39Made);
                fg30to39Attempts = NullSum(fg30to39Attempts,this.data[i].fg30to39Attempts);
                fg40to49Made = NullSum(fg40to49Made,this.data[i].fg40to49Made);
                fg40to49Attempts = NullSum(fg40to49Attempts,this.data[i].fg40to49Attempts);
                fg50plusMade = NullSum(fg50plusMade,this.data[i].fg50plusMade);
                fg50plusAttempts = NullSum(fg50plusAttempts,this.data[i].fg50plusAttempts);
                patMade = NullSum(patMade,this.data[i].patMade);
                patAttempts = NullSum(patAttempts, this.kickingstats[i].extraPointAtt);
                PointsEarned = NullBypassSum(PointsEarned, this.kickingstats[i].eps);
            }
            overallPct = NullDiv(overallMade,overallAttempts)*100;
            fg1to29Pct = NullDiv(fg1to29Made,fg1to29Attempts)*100;
            fg30to39Pct = NullDiv(fg30to39Made,fg30to39Attempts)*100;
            fg40to49Pct = NullDiv(fg40to49Made,fg40to49Attempts)*100;
            fg50plusPct = NullDiv(fg50plusMade,fg50plusAttempts)*100;
            patPct = NullDiv(patMade,patAttempts)*100;
    
            this.data.push({
                player,
                playerId,
                positionName,
                season,
                team,
                games,
                overallMade,
                overallAttempts,
                overallPct,
                overallLong,
                overallPoints,
                fg1to29Made,
                fg1to29Attempts,
                fg1to29Pct,
                fg30to39Made,
                fg30to39Attempts,
                fg30to39Pct,
                fg40to49Made,
                fg40to49Attempts,
                fg40to49Pct,
                fg50plusMade,
                fg50plusAttempts,
                fg50plusPct,
                patMade,
                patAttempts,
                patPct,
                PointsEarned
            });
        }
    }
    
    componentDidMount() {
        this.ConsolidateData();
        let pagination = 'local';
        let paginationSize = 50;
        if (!this.isLeaderboards){
            this.SumData();
            pagination = null;
            paginationSize = 0;
        }
        this.tabulator = new Tabulator(this.el, {
            data: this.data,
            reactiveData: true,
            columns: this.cols,
            //layout: "fitColumns",  // added by Ruben 7/25/19
            colMinWidth: 33,
            rowFormatter:function(row){
                var data = row.getData();
                if (data.season === 'Total'){
                    row.getElement().style.borderTop = "2px solid #6f88a8";
                    row.getElement().style.borderBottom = "2px solid #6f88a8";
                }
            },
            pagination: pagination,
            paginationSize: paginationSize,
            initialSort: this.initSort,
            resizableColumns: false,
            selectable: false
        });
    }

    componentDidUpdate() {
        this.tabulator.replaceData(this.data);
    }

    onSubmit(e, minFilter){
        this.minFilter = minFilter;
        this.ConsolidateData();
        this.forceUpdate();
    }

    render() {
        return (
            <React.Fragment>
                {
                    this.isLeaderboards &&
                    <div className="shadowdiv" id="filtersDiv">
                        <Filters minFilter={this.minFilter} onSubmit={this.onSubmit} label={"FG Attempts"} />
                        <div ref={el => (this.el = el)} />
                    </div>
                }
                {
                    !this.isLeaderboards &&
                    <div className="shadowdiv">
                        <div id="table-title">Kicking</div>
                        <div ref={el => (this.el = el)} />
                    </div>
                }
            </React.Fragment>
        );
    }
}
export default TableKPKickingTabulator;
