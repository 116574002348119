import React from 'react';
import { BrowserRouter, Route } from 'react-router-dom';
import Players from './Players';
import PlayerGameLogs from './PlayerGameLogs';
import LeaderBoards from './Leaderboards';
import Glossary from './Glossary';
import BrowserRedirect from './BrowserRedirect'

function Router() {
    const isIE2 = /*@cc_on!@*/false || !!document.documentMode;
    if (isIE2 === false) {
        return (
            <BrowserRouter>
                <Route path="/" exact component={Players} />
                <Route path="/leaderboards" exact component={LeaderBoards} />
                <Route path="/leaderboards/:cat" component={LeaderBoards} />
                <Route path="/players" exact component={Players} />
                <Route path="/players/:id" component={Players} />
                <Route path="/playergamelogs" exact component={PlayerGameLogs} />
                <Route path="/playergamelogs/:id" component={PlayerGameLogs} />
                <Route path="/glossary" component={Glossary} />
            </BrowserRouter>
        )
    } else {
        return (
            <BrowserRouter>
                <Route path="/" exact component={BrowserRedirect} />
                <Route path="/leaderboards" exact component={BrowserRedirect} />
                <Route path="/leaderboards/:cat" component={BrowserRedirect} />
                <Route path="/players" exact component={BrowserRedirect} />
                <Route path="/players/:id" component={BrowserRedirect} />
                <Route path="/playergamelogs" exact component={BrowserRedirect} />
                <Route path="/playergamelogs/:id" component={BrowserRedirect} />
                <Route path="/glossary" component={BrowserRedirect} />
            </BrowserRouter>
        )
    }
}

export default Router;