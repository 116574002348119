import React from 'react';
import {NullSum, NullBypassSum, NullDiv, Tst2015, DispFunc} from '../common/CommonFunctions';
import { dedtGameSummary, lbGameSummary, cbsGameSummary } from '../common/TableColumnWidths';
import Tabulator from 'tabulator-tables';
import "tabulator-tables/dist/css/tabulator.min.css";

class GameLogDefenseSummaryTabulator extends React.Component {
    constructor(props) {
        super(props);
        this.defensestats = this.props.defensestats;
        this.data = [];
        this.gameInfoCols = null;
        this.colNum = 0;
        this.maxPlayerSeason = ''

        // Set game information columns
        this.gameInfoCols = [
            {
                title: "Date", field: "gameSeasonDateSSSSYYYYMMDD", cssClass: "single-row-header textColumn",
                titleFormatter: "html", headerSort: false,
                width: `${dedtGameSummary[this.colNum++]}`,
                formatter: function (cell, formatterParams, onRendered) {
                    return (cell.getValue().substr(9, 1) === 'T' ? 'Total' : parseInt(cell.getValue().substr(9, 2)) + '/' + parseInt(cell.getValue().substr(12, 2)));
                }
            },
            {
                title: "Wk", field: "gameWeek", cssClass: "single-row-header small-column-center",
                titleFormatter: "html", headerSort: false,
                width: `${dedtGameSummary[this.colNum++]}`,
            },
            {
                title: "Teams", field: "matchup", cssClass: "single-row-header textColumn",
                titleFormatter: "html", headerSort: false,
                width: `${dedtGameSummary[this.colNum++]}`
            },
            {
                title: "Score", field: "gameResult", cssClass: "single-row-header break textColumn",
                titleFormatter: "html", headerSort: false,
                width: `${dedtGameSummary[this.colNum++]}`
            },
            {
                title: "G", field: "games", cssClass: "single-row-header small-column",
                titleFormatter: "html", headerSort: false,
                width: `${dedtGameSummary[this.colNum++]}`
            },
            {
                title: "GS", field: "gamesStarted", cssClass: "single-row-header break small-column",
                titleFormatter: "html", headerSort: false,
                width: `${dedtGameSummary[this.colNum++]}`
            }        
        ];
        this.el = React.createRef();
        this.tabulator = null;
    }

    // Consolidate the data into one data array to be used for tabulator(called on component mount)
    ConsolidateData(){
        let defensestats = this.defensestats;
        let sortKey = 0;
        let season = 0;
        let playerName = '';
        let playerId = 0;
        let positionName = '';
        let team = '';
        let matchup = '';
        let oppTeam = '';
        let gameWeek = 0;
        let games = 0;
        let gameDate = '';
        let gameSeasonDateSSSSYYYYMMDD = '';
        let gameResult = '';
        let gamesStarted = 0;
        let targets = 0;
        let completions = 0;
        let completionPct = 0;
        let passYards = 0;
        let passYardsPerTgt = 0;
        let td = 0;
        let breakups = 0;
        let interceptions = 0;
        let droppedInts = 0;
        let tackles = 0;
        let tackleForLoss = 0;
        let forcedFumbles = 0;
        let pointsSaved = 0;
        let sacks = 0;
        let pressures = 0;
        let hurries = 0;
        let hits = 0;
        let knockdowns = 0;
        let passesBatted = 0;
        let pntretAttempts = 0;
        let pntretYds = 0;
        let pntretYdsPerAtt = 0;
        let pntretTD = 0;
        let kickretAttempts = 0;
        let kickretYds = 0;
        let kickretYdsPerAtt = 0;
        let kickretTD = 0;
        let rowdata = [];
        if (defensestats.length !== 0){
            positionName = defensestats[0].positionName;
            for (var i = 0; i < defensestats.length; i++) {
                sortKey = defensestats[i].sortKey;
                season = defensestats[i].season;
                playerName = defensestats[i].playerName;
                playerId = defensestats[i].playerId;
                team = defensestats[i].teamName;
                matchup = defensestats[i].matchup;
                games = defensestats[i].games;
                gameDate = defensestats[i].gameDate;
                gameSeasonDateSSSSYYYYMMDD = defensestats[i].gameSeasonDateSSSSYYYYMMDD;
                gameResult = defensestats[i].gameResult;
                gameWeek = defensestats[i].gameWeek;
                gamesStarted = defensestats[i].gamesStarted;
                targets = defensestats[i].targets;
                completions = defensestats[i].completions;
                completionPct = defensestats[i].completionPct;
                passYards = defensestats[i].passYards;
                passYardsPerTgt = defensestats[i].passYardsPerTgt;
                td = defensestats[i].td;
                breakups = defensestats[i].breakups;
                interceptions = defensestats[i].interceptions;
                droppedInts = defensestats[i].droppedInts;
                tackles = defensestats[i].tackles;
                tackleForLoss = defensestats[i].tackleForLoss;
                forcedFumbles = defensestats[i].forcedFumbles;
                sacks = defensestats[i].sacks;
                pressures = defensestats[i].pressures;
                hurries = defensestats[i].hurries;
                hits = defensestats[i].hits;
                knockdowns = defensestats[i].knockdowns;
                passesBatted = defensestats[i].passesBatted;
                pntretAttempts = defensestats[i].pntretAttempts;
                pntretYds = defensestats[i].pntretYds;
                pntretYdsPerAtt = defensestats[i].pntretYdsPerAtt;
                pntretTD = defensestats[i].pntretTD;
                kickretAttempts = defensestats[i].kickretAttempts;
                kickretYds = defensestats[i].kickretYds;
                kickretYdsPerAtt = defensestats[i].kickretYdsPerAtt;
                kickretTD = defensestats[i].kickretTD;
                pointsSaved = Tst2015(defensestats[i].season, NullSum(defensestats[i].pointsSavedPassDef, NullSum(defensestats[i].pointsSavedPassRush, defensestats[i].pointsSavedRushDef)))
                rowdata.push({
                    sortKey,
                    season,
                    playerName,
                    playerId,
                    positionName,
                    team,
                    matchup,
                    games,
                    gameDate,
                    gameSeasonDateSSSSYYYYMMDD,
                    gameResult,
                    gameWeek,
                    gamesStarted,
                    oppTeam,
                    targets,
                    completions,
                    completionPct,
                    passYards,
                    passYardsPerTgt,
                    td,
                    breakups,
                    interceptions,
                    droppedInts,
                    tackles,
                    tackleForLoss,
                    forcedFumbles,
                    pointsSaved,
                    sacks,
                    pressures,
                    hurries,
                    hits,
                    knockdowns,
                    passesBatted,
                    pntretAttempts,
                    pntretYds,
                    pntretYdsPerAtt,
                    pntretTD,
                    kickretAttempts,
                    kickretYds,
                    kickretYdsPerAtt,
                    kickretTD
                });
            }
        }
        if (rowdata.length !== 0) {
            rowdata.sort((a, b) => a.sortKey > b.sortKey ? 1 : -1); // orders rows by sortkey
            this.SumData(rowdata); // insert seasonal totals
        }
        this.data = rowdata;
    }

    // Add totals row to data.(called on component mount if this is the players page.)
    SumData(d) {
        let sortKey = 0;
        let season = d[0].season;
        let playerName = '';
        let playerId = '';
        let positionName = '';
        let team = '';
        let matchup = '';
        let games = 0;
        let gameDate = '';
        let gameSeasonDateSSSSYYYYMMDD = '';
        let gameResult = '';
        let gameWeek = '';
        let gamesStarted = 0;
        let oppTeam = ''
        let assistedTackles = 0;
        let batDowns = 0;
        let breakups = 0;
        let brokenTackles = 0;
        let completionPct = 0;
        let completions = 0;
        let defensed = 0;
        let deflected = 0;
        let droppedInts = 0;
        let forcedFumbles = 0;
        let hits = 0;
        let hurries = 0;
        let interceptions = 0;
        let knockdowns = 0;
        let passesBatted = 0;
        let passRushes = 0;
        let passRushSacks = 0;
        let passSnaps = 0;
        let pointsSaved = 0;
        let pressures = 0;
        let rushsnaps = 0;
        let sacks = 0;
        let soloTackles = 0;
        let starts = 0;
        let tackleForLoss = 0;
        let tackles = 0;
        let targets = 0;
        let td = 0;
        let passYards = 0;
        let passYardsPerTgt = 0;
        let pntretAttempts = 0;
        let pntretYds = 0;
        let pntretYdsPerAtt = 0;
        let pntretTD = 0;
        let kickretAttempts = 0;
        let kickretYds = 0;
        let kickretYdsPerAtt = 0;
        let kickretTD = 0;
        let rowcount = d.length;
        for (var i = 0; i < rowcount; i++) {
            if (season !== d[i].season) {
                sortKey = (9999 - season) * 1000000 + season * 100 + 99; //XXXXYYYY99
                gameSeasonDateSSSSYYYYMMDD = season.toString() + season.toString() + " Total";
                completionPct = NullDiv(completions, targets) * 100;
                passYardsPerTgt = NullDiv(passYards, targets);
                pntretYdsPerAtt = NullDiv(pntretYds, pntretAttempts);
                kickretYdsPerAtt = NullDiv(kickretYds, kickretAttempts);
                d.push({
                    sortKey,
                    season,
                    playerName,
                    playerId,
                    positionName,
                    team,
                    matchup,
                    games,
                    gameDate,
                    gameSeasonDateSSSSYYYYMMDD,
                    gameResult,
                    gameWeek,
                    gamesStarted,
                    oppTeam,
                    targets,
                    completions,
                    completionPct,
                    passYards,
                    passYardsPerTgt,
                    td,
                    breakups,
                    interceptions,
                    droppedInts,
                    tackles,
                    tackleForLoss,
                    forcedFumbles,
                    pointsSaved,
                    sacks,
                    pressures,
                    hurries,
                    hits,
                    knockdowns,
                    passesBatted,
                    pntretAttempts,
                    pntretYds,
                    pntretYdsPerAtt,
                    pntretTD,
                    kickretAttempts,
                    kickretYds,
                    kickretYdsPerAtt,
                    kickretTD
                });
                season = d[i].season;
                playerName = '';
                playerId = '';
                positionName = '';
                team = '';
                matchup = '';
                games = 0;
                gameDate = '';
                gameSeasonDateSSSSYYYYMMDD = '';
                gameResult = '';
                gameWeek = '';
                gamesStarted = 0;
                oppTeam = ''
                assistedTackles = 0;
                batDowns = 0;
                breakups = 0;
                brokenTackles = 0;
                completionPct = 0;
                completions = 0;
                defensed = 0;
                deflected = 0;
                droppedInts = 0;
                forcedFumbles = 0;
                games = 0;
                hits = 0;
                hurries = 0;
                interceptions = 0;
                knockdowns = 0;
                passesBatted = 0;
                passRushes = 0;
                passRushSacks = 0;
                passSnaps = 0;
                pointsSaved = 0;
                pressures = 0;
                rushsnaps = 0;
                sacks = 0;
                soloTackles = 0;
                starts = 0;
                tackleForLoss = 0;
                tackles = 0;
                targets = 0;
                td = 0;
                passYards = 0;
                passYardsPerTgt = 0;
                pntretAttempts = 0;
                pntretYds = 0;
                pntretYdsPerAtt = 0;
                pntretTD = 0;
                kickretAttempts = 0;
                kickretYds = 0;
                kickretYdsPerAtt = 0;
                kickretTD = 0;
            }
            gamesStarted = NullSum(gamesStarted, d[i].gamesStarted);
            assistedTackles = NullBypassSum(assistedTackles,d[i].assistedTackles);
            batDowns = NullBypassSum(batDowns,d[i].batDowns);
            breakups = NullBypassSum(breakups,d[i].breakups);
            brokenTackles = NullBypassSum(brokenTackles,d[i].brokenTackles);
            completions = NullBypassSum(completions,d[i].completions);
            defensed = NullBypassSum(defensed,d[i].defensed);
            deflected = NullBypassSum(deflected,d[i].deflected);
            droppedInts = NullBypassSum(droppedInts,d[i].droppedInts);
            forcedFumbles = NullBypassSum(forcedFumbles,d[i].forcedFumbles);
            games = NullBypassSum(games,d[i].games);
            hits = NullBypassSum(hits,d[i].hits);
            hurries = NullBypassSum(hurries,d[i].hurries);
            interceptions = NullBypassSum(interceptions,d[i].interceptions);
            knockdowns = NullBypassSum(knockdowns,d[i].knockdowns);
            passesBatted = NullBypassSum(passesBatted,d[i].passesBatted);
            passRushes = NullBypassSum(passRushes,d[i].passRushes);
            passRushSacks = NullBypassSum(passRushSacks,d[i].passRushSacks);
            passSnaps = NullBypassSum(passSnaps,d[i].passSnaps);
            pointsSaved = NullBypassSum(pointsSaved, Tst2015(d[i].season, d[i].pointsSaved));
            pressures = NullBypassSum(pressures,d[i].pressures);
            rushsnaps = NullBypassSum(rushsnaps,d[i].rushsnaps);
            sacks = NullBypassSum(sacks,d[i].sacks);
            soloTackles = NullBypassSum(soloTackles,d[i].soloTackles);
            starts = NullBypassSum(starts,d[i].starts);
            tackleForLoss = NullBypassSum(tackleForLoss,d[i].tackleForLoss);
            tackles = NullBypassSum(tackles,d[i].tackles);
            targets = NullBypassSum(targets,d[i].targets);
            td = NullBypassSum(td,d[i].td);
            passYards = NullBypassSum(passYards,d[i].passYards);
            pntretAttempts = NullBypassSum(pntretAttempts, d[i].pntretAttempts);
            pntretYds = NullBypassSum(pntretYds, d[i].pntretYds);
            pntretTD = NullBypassSum(pntretTD, d[i].pntretTD);
            kickretAttempts = NullBypassSum(kickretAttempts, d[i].kickretAttempts);
            kickretYds = NullBypassSum(kickretYds, d[i].kickretYds);
            kickretTD = NullBypassSum(kickretTD, d[i].kickretTD);
        }
        sortKey = (9999 - season) * 1000000 + season * 100 + 99; //XXXXYYYY99
        gameSeasonDateSSSSYYYYMMDD = season.toString() + season.toString() + " Total";
        completionPct = NullDiv(completions,targets)*100;
        passYardsPerTgt = NullDiv(passYards,targets);
        pntretYdsPerAtt = NullDiv(pntretYds,pntretAttempts);
        kickretYdsPerAtt = NullDiv(kickretYds,kickretAttempts);
        d.push({
            sortKey,
            season,
            playerName,
            playerId,
            positionName,
            team,
            matchup,
            games,
            gameDate,
            gameSeasonDateSSSSYYYYMMDD,
            gameResult,
            gameWeek,
            gamesStarted,
            oppTeam,
            targets,
            completions,
            completionPct,
            passYards,
            passYardsPerTgt,
            td,
            breakups,
            interceptions,
            droppedInts,
            tackles,
            tackleForLoss,
            forcedFumbles,
            pointsSaved,
            sacks,
            pressures,
            hurries,
            hits,
            knockdowns,
            passesBatted,
            pntretAttempts,
            pntretYds,
            pntretYdsPerAtt,
            pntretTD,
            kickretAttempts,
            kickretYds,
            kickretYdsPerAtt,
            kickretTD
        });
        d.sort((a, b) => a.sortKey > b.sortKey ? 1 : -1); // corrects for new season total rows
    }
    
    componentDidMount() {
        this.ConsolidateData();
        let pagination = 'local';
        let paginationSize = 50;
        let maxPlayerSeason = this.data.length ? this.data[0].season : 0;
        pagination = null;
        paginationSize = 0;
        if (this.defensestats[0]) {
            switch (this.defensestats[0].positionName){
                case 'CB':
                case 'S':
                    this.cols = [
                        {
                            title: "",
                            columns: this.gameInfoCols,
                        },
                        {
                            title: "Coverage",
                            columns: [
                                {
                                    title: "Tgtd", field:"targets", cssClass: "single-row-header",
                                    headerSortStartingDir: "desc", headerSort: false,
                                    width: `${cbsGameSummary[this.colNum++]}`,
                                    formatter: function(cell, formatterParams, onRendered){
                                        return DispFunc(cell, formatterParams, onRendered, 0, false, false);
                                    }
                                },
                                {
                                    title: "Cmp", field: "completions", cssClass: "single-row-header small-column",
                                    headerSortStartingDir: "desc", headerSort: false,
                                    width: `${cbsGameSummary[this.colNum++]}`,
                                    formatter: function(cell, formatterParams, onRendered){
                                        return DispFunc(cell, formatterParams, onRendered, 0, false, false);
                                    }
                                },
                                {
                                    title: "Cmp%", field: "completionPct", cssClass: "single-row-header",
                                    headerSortStartingDir: "desc", headerSort: false,
                                    width: `${cbsGameSummary[this.colNum++]}`,
                                    formatter: function(cell, formatterParams, onRendered){
                                        return DispFunc(cell, formatterParams, onRendered, 1, false, false);
                                    }
                                },
                                {
                                    title: "Yards", field: "passYards", cssClass: "single-row-header",
                                    headerSortStartingDir: "desc", headerSort: false,
                                    width: `${cbsGameSummary[this.colNum++]}`,
                                    formatter: function(cell, formatterParams, onRendered){
                                        return DispFunc(cell, formatterParams, onRendered, 0, true, false);
                                    }
                                },
                                {
                                    title: "Yds/ Target", field: "passYardsPerTgt", cssClass: "single-row-header",
                                    headerSortStartingDir: "desc", headerSort: false,
                                    titleFormatter: "textarea",
                                    width: `${cbsGameSummary[this.colNum++]}`,
                                    formatter: function(cell, formatterParams, onRendered){
                                        return DispFunc(cell, formatterParams, onRendered, 1, false, false);
                                    }
                                },
                                {
                                    title: "TD Allwd", field: "td", titleFormatter: "textarea", cssClass: "single-row-header small-column",
                                    headerSortStartingDir: "desc", headerSort: false,
                                    width: `${cbsGameSummary[this.colNum++]}`,
                                    formatter: function(cell, formatterParams, onRendered){
                                        return DispFunc(cell, formatterParams, onRendered, 0, false, false);
                                    }
                                },
                                {
                                    title: "Pass Brkps", field: "breakups", cssClass: "single-row-header",
                                    headerSortStartingDir: "desc", headerSort: false,
                                    titleFormatter: "textarea",
                                    width: `${cbsGameSummary[this.colNum++]}`,
                                    formatter: function(cell, formatterParams, onRendered){
                                        return DispFunc(cell, formatterParams, onRendered, 0, false, false);
                                    }
                                },
                                {
                                    title: "Int", field: "interceptions", cssClass: "single-row-header small-column",
                                    headerSortStartingDir: "desc", headerSort: false,
                                    width: `${cbsGameSummary[this.colNum++]}`,
                                    formatter: function(cell, formatterParams, onRendered){
                                        return DispFunc(cell, formatterParams, onRendered, 0, false, false);
                                    }
                                },
                                {
                                    title: "Int Drops", field: "droppedInts", cssClass: "single-row-header break",
                                    headerSortStartingDir: "desc", headerSort: false,
                                    titleFormatter: "textarea",
                                    width: `${cbsGameSummary[this.colNum++]}`,
                                    formatter: function(cell, formatterParams, onRendered){
                                        return DispFunc(cell, formatterParams, onRendered, 0, false, false);
                                    }
                                }
                            ]
                        },
                        {
                            title: "Tkls (exc spl tms)",
                            columns:[
                                {
                                    title: "Tackles", field: "tackles",
                                    headerSortStartingDir: "desc", headerSort: false,
                                    cssClass: "single-row-header",
                                    width: `${cbsGameSummary[this.colNum++]}`,
                                    formatter: function(cell, formatterParams, onRendered){
                                        return DispFunc(cell, formatterParams, onRendered, 0, false, false);
                                    }
                                },
                                {
                                    title: "TFL", field: "tackleForLoss",
                                    headerSortStartingDir: "desc", headerSort: false,
                                    cssClass: "single-row-header small-column",
                                    width: `${cbsGameSummary[this.colNum++]}`,
                                    formatter: function(cell, formatterParams, onRendered){
                                        return DispFunc(cell, formatterParams, onRendered, 0, false, false);
                                    }
                                },
                                {
                                    title: "FF", field: "forcedFumbles", cssClass: "single-row-header small-column break",
                                    headerSortStartingDir: "desc", headerSort: false,
                                    width: `${cbsGameSummary[this.colNum++]}`,
                                    formatter: function(cell, formatterParams, onRendered){
                                        return DispFunc(cell, formatterParams, onRendered, 0, false, false);
                                    }
                                }        
                            ],
                        },
                        {
                            title: "Punt Returns",
                            columns: [
                                {
                                    title: "Num", field: "pntretAttempts", cssClass: "single-row-header small-column",
                                    width: `${cbsGameSummary[this.colNum++]}`,
                                    headerSortStartingDir: "desc", headerSort: false
                                },
                                {
                                    title: "Yards", field: "pntretYds", cssClass: "single-row-header",
                                    width: `${cbsGameSummary[this.colNum++]}`,
                                    headerSortStartingDir: "desc", headerSort: false,
                                    formatter: "money", formatterParams: {
                                        thousand: ',',
                                        precision: 0
                                    }
                                },
                                {
                                    title: "Avg", field: "pntretYdsPerAtt", cssClass: "single-row-header",
                                    width: `${cbsGameSummary[this.colNum++]}`,
                                    headerSortStartingDir: "desc", headerSort: false,
                                    formatter: "money", formatterParams: {
                                        precision: 1
                                    }
                                },
                                {
                                    title: "TD", field: "pntretTD", cssClass: "single-row-header break small-column",
                                    width: `${cbsGameSummary[this.colNum++]}`,
                                    headerSortStartingDir: "desc", headerSort: false
                                }
                            ],
                        },
                        {
                            title: "Kickoff Returns",
                            columns: [
                                {
                                    title: "Num", field: "kickretAttempts", cssClass: "single-row-header small-column",
                                    width: `${cbsGameSummary[this.colNum++]}`,
                                    headerSortStartingDir: "desc", headerSort: false
                                },
                                {
                                    title: "Yards", field: "kickretYds", cssClass: "single-row-header",
                                    width: `${cbsGameSummary[this.colNum++]}`,
                                    headerSortStartingDir: "desc", headerSort: false,
                                    formatter: "money", formatterParams: {
                                        thousand: ',',
                                        precision: 0
                                    }
                                },
                                {
                                    title: "Avg", field: "kickretYdsPerAtt", cssClass: "single-row-header",
                                    width: `${cbsGameSummary[this.colNum++]}`,
                                    headerSortStartingDir: "desc", headerSort: false,
                                    formatter: "money", formatterParams: {
                                        precision: 1
                                    }
                                },
                                {
                                    title: "TD", field: "kickretTD", cssClass: "single-row-header break small-column",
                                    width: `${cbsGameSummary[this.colNum++]}`,
                                    headerSortStartingDir: "desc", headerSort: false
                                },
                            ],
                        },
                                    {
                            title: "Tot Pts Saved", field: "pointsSaved", cssClass: "single-row-header",
                            headerSortStartingDir: "desc", headerSort: false,
                            titleFormatter:"textarea",
                            width: `${cbsGameSummary[this.colNum++]}`,
                            formatter: function(cell, formatterParams, onRendered){
                                return DispFunc(cell, formatterParams, onRendered, 0, false, false);
                            }
                        }
                    ];
                    break;
                case 'LB':
                    this.cols = [
                        {
                            title: "",
                            columns: this.gameInfoCols,
                        },
                        {
                            title: "Tackling (excl. special teams)",
                            columns:[
                                {
                                    title: "Tackles", field: "tackles", cssClass: "single-row-header",
                                    headerSortStartingDir: "desc", headerSort: false,
                                    width: `${lbGameSummary[this.colNum++]}`,
                                    formatter: function(cell, formatterParams, onRendered){
                                        return DispFunc(cell, formatterParams, onRendered, 0, false, false);
                                    }
                                },
                                {
                                    title: "TFL", field: "tackleForLoss", cssClass: "single-row-header",
                                    headerSortStartingDir: "desc", headerSort: false,
                                    width: `${lbGameSummary[this.colNum++]}`,
                                    formatter: function(cell, formatterParams, onRendered){
                                        return DispFunc(cell, formatterParams, onRendered, 0, false, false);
                                    }
                                },
                                {
                                    title: "FF", field: "forcedFumbles", cssClass: "single-row-header break",
                                    headerSortStartingDir: "desc", headerSort: false,
                                    width: `${lbGameSummary[this.colNum++]}`,
                                    formatter: function(cell, formatterParams, onRendered){
                                        return DispFunc(cell, formatterParams, onRendered, 0, false, false);
                                    }
                                }        
                            ],
                        },
                        {
                            title: "Pass Rush",
                            columns:[
                                {
                                    title: "Sacks", field: "sacks", cssClass: "single-row-header",
                                    headerSortStartingDir: "desc", headerSort: false,
                                    width: `${lbGameSummary[this.colNum++]}`,
                                    formatter: function(cell, formatterParams, onRendered){
                                        return DispFunc(cell, formatterParams, onRendered, 1, false, false);
                                    }
                                },
                                {
                                    title: "Pressures", field: "pressures", cssClass: "single-row-header break",
                                    headerSortStartingDir: "desc", headerSort: false,
                                    width: `${lbGameSummary[this.colNum++]}`,
                                    formatter: function(cell, formatterParams, onRendered){
                                        return DispFunc(cell, formatterParams, onRendered, 0, false, false);
                                    }
                                }
                            ],
                        },
                        {
                            title: "Coverage",
                            columns: [
                                {
                                    title: "Targeted", field:"targets", cssClass: "single-row-header",
                                    headerSortStartingDir: "desc", headerSort: false,
                                    width: `${lbGameSummary[this.colNum++]}`,
                                    formatter: function(cell, formatterParams, onRendered){
                                        return DispFunc(cell, formatterParams, onRendered, 0, false, false);
                                    }
                                },
                                {
                                    title: "Cmp", field:"completions", cssClass: "single-row-header",
                                    headerSortStartingDir: "desc", headerSort: false,
                                    width: `${lbGameSummary[this.colNum++]}`,
                                    formatter: function(cell, formatterParams, onRendered){
                                        return DispFunc(cell, formatterParams, onRendered, 0, false, false);
                                    }
                                },
                                {
                                    title: "Cmp%", field:"completionPct", cssClass: "single-row-header",
                                    headerSortStartingDir: "desc", headerSort: false,
                                    width: `${lbGameSummary[this.colNum++]}`,
                                    formatter: function(cell, formatterParams, onRendered){
                                        return DispFunc(cell, formatterParams, onRendered, 1, false, false);
                                    }
                                },
                                {
                                    title: "Yards", field:"passYards", cssClass: "single-row-header",
                                    headerSortStartingDir: "desc", headerSort: false,
                                    width: `${lbGameSummary[this.colNum++]}`,
                                    formatter: function(cell, formatterParams, onRendered){
                                        return DispFunc(cell, formatterParams, onRendered, 0, false, false);
                                    }
                                },
                                {
                                    title: "Yds/Trgt", field:"passYardsPerTgt", cssClass: "single-row-header",
                                    headerSortStartingDir: "desc", headerSort: false,
                                    width: `${lbGameSummary[this.colNum++]}`,
                                    formatter: function(cell, formatterParams, onRendered){
                                        return DispFunc(cell, formatterParams, onRendered, 1, false, false);
                                    }
                                },
                                {
                                    title: "Int", field:"interceptions", cssClass: "single-row-header break",
                                    headerSortStartingDir: "desc", headerSort: false,
                                    width: `${lbGameSummary[this.colNum++]}`,
                                    formatter: function(cell, formatterParams, onRendered){
                                        return DispFunc(cell, formatterParams, onRendered, 0, false, false);
                                    }
                                },
                            ]
                        },
                        {
                            title: "Tot Points Saved", field: "pointsSaved",
                            headerSortStartingDir: "desc", headerSort: false,
                            titleFormatter:"textarea",
                            width: `${lbGameSummary[this.colNum++]}`,
                            formatter: function(cell, formatterParams, onRendered){
                                return DispFunc(cell, formatterParams, onRendered, 0, false, false);
                            }
                        }
                    ];
                    break;
                default: // DE, DT
                    this.cols = [
                        {
                            title: "",
                            columns: this.gameInfoCols,
                        },
                        {
                            title: "Tackling (excl. special teams)",
                            columns:[
                                {
                                    title: "Tackles", field: "tackles", cssClass: "single-row-header",
                                    headerSortStartingDir: "desc", headerSort: false,
                                    width: `${dedtGameSummary[this.colNum++]}`,
                                    formatter: function(cell, formatterParams, onRendered){
                                        return DispFunc(cell, formatterParams, onRendered, 0, false, false);
                                    }
                                },
                                {
                                    title: "TFL", field: "tackleForLoss", cssClass: "single-row-header",
                                    headerSortStartingDir: "desc", headerSort: false,
                                    width: `${dedtGameSummary[this.colNum++]}`,
                                    formatter: function(cell, formatterParams, onRendered){
                                        return DispFunc(cell, formatterParams, onRendered, 0, false, false);
                                    }
                                },
                                {
                                    title: "FF", field: "forcedFumbles", cssClass: "single-row-header break",
                                    headerSortStartingDir: "desc", headerSort: false,
                                    width: `${dedtGameSummary[this.colNum++]}`,
                                    formatter: function(cell, formatterParams, onRendered){
                                        return DispFunc(cell, formatterParams, onRendered, 0, false, false);
                                    }
                                }        
                            ],
                        },
                        {
                            title: "Pass Rush",
                            columns:[
                                {
                                    title: "Sacks", field: "sacks",cssClass: "single-row-header",
                                    headerSortStartingDir: "desc", headerSort: false, 
                                    width: `${dedtGameSummary[this.colNum++]}`,
                                    formatter: function(cell, formatterParams, onRendered){
                                        return DispFunc(cell, formatterParams, onRendered, 1, false, false);
                                    }
                                },
                                {
                                    title: "Hurries", field: "hurries",cssClass: "single-row-header",
                                    headerSortStartingDir: "desc", headerSort: false,
                                    width: `${dedtGameSummary[this.colNum++]}`,
                                    formatter: function(cell, formatterParams, onRendered){
                                        return DispFunc(cell, formatterParams, onRendered, 0, false, false);
                                    }
                                },
                                {
                                    title: "Hits", field: "hits",cssClass: "single-row-header",
                                    headerSortStartingDir: "desc", headerSort: false,
                                    width: `${dedtGameSummary[this.colNum++]}`,
                                    formatter: function(cell, formatterParams, onRendered){
                                        return DispFunc(cell, formatterParams, onRendered, 0, false, false);
                                    }
                                },
                                {
                                    title: "Knockdowns", field: "knockdowns",cssClass: "single-row-header",
                                    headerSortStartingDir: "desc", headerSort: false,
                                    width: `${dedtGameSummary[this.colNum++]}`,
                                    formatter: function(cell, formatterParams, onRendered){
                                        return DispFunc(cell, formatterParams, onRendered, 0, false, false);
                                    }
                                },
                                {
                                    title: "Pressures", field: "pressures",cssClass: "single-row-header",
                                    headerSortStartingDir: "desc", headerSort: false,
                                    width: `${dedtGameSummary[this.colNum++]}`,
                                    formatter: function(cell, formatterParams, onRendered){
                                        return DispFunc(cell, formatterParams, onRendered, 0, false, false);
                                    }
                                },
                                {
                                    title: "Passes Batted", field: "passesBatted", cssClass: "single-row-header break",
                                    headerSortStartingDir: "desc", headerSort: false,
                                    width: `${dedtGameSummary[this.colNum++]}`,
                                    formatter: function(cell, formatterParams, onRendered){
                                        return DispFunc(cell, formatterParams, onRendered, 0, false, false);
                                    }
                                }
                            ],
                        },
                        {
                            title: "Tot Points Saved", field: "pointsSaved",
                            headerSortStartingDir: "desc", headerSort: false,
                            titleFormatter:"textarea",
                            width: `${dedtGameSummary[this.colNum++]}`,
                            formatter: function(cell, formatterParams, onRendered){
                                return DispFunc(cell, formatterParams, onRendered, 0, false, false);
                            }
                        }
                    ];
                    break;
            }
            this.tabulator = new Tabulator(this.el, {
                data: this.data,
                reactiveData: true,
                columns: this.cols,
                layout: "fitColumns",  // added by Ruben 7/25/19
                rowFormatter: function (row) {
                    var data = row.getData();
                    if (data.gameSeasonDateSSSSYYYYMMDD.substr(9, 5) === 'Total') {
                        row.getElement().style.borderTop = "2px solid #6f88a8";
                        row.getElement().style.borderBottom = "2px solid #6f88a8";
                    }
                },
                pagination: pagination,
                paginationSize: paginationSize,
                groupBy: function (row) {
                    return row.gameSeasonDateSSSSYYYYMMDD.substr(0, 4);
                },
                groupHeader: function (groupKey, rowcount, data, group) {
                    return groupKey + ' Season <span style="color:SlateBlue">&#9660</span>';
                },
                groupStartOpen: function (groupKey, rowcount, data, group) {
                    return groupKey === maxPlayerSeason.toString();
                },
                groupToggleElement: "header",
                resizableColumns: false,
                selectable: false
            });
        }
        
    }

    componentDidUpdate() {
        this.tabulator.replaceData(this.data);
    }

    render() {
        return (
            <React.Fragment>
                <div className="shadowdiv">
                    <div id="table-title">Summary</div>
                    <div ref={el => (this.el = el)} />
                </div>
            </React.Fragment>
        );
    }
}

export default GameLogDefenseSummaryTabulator;
