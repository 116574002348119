import React, { useState } from 'react';
import TableRBWRSummaryTabulator from '../pageRBWR/TableRBWRSummaryTabulator';
import TableRBWRAdvancedRushingTabulator from '../pageRBWR/TableRBWRAdvancedRushingTabulator';
import TableRBWRAdvancedReceivingTabulator from '../pageRBWR/TableRBWRAdvancedReceivingTabulator';

const WRRBSection = (d) => {
    const [startSeason, setStartSeason] = useState(d.filters.startSeason);
    const [endSeason, setEndSeason] = useState(d.filters.endSeason);
    const [tabName, setTabName] = useState("summary");

    let fromSeasons = [];
    let toSeasons = [];
    for(var i = new Date().getFullYear(); i >= 2015; i--){
        if (i.toString() === d.filters.startSeason){
            fromSeasons.push(<option value={i} selected='selected'>{i}</option>)
        }
        else{
            fromSeasons.push(<option value={i}>{i}</option>)
        } 
        if (i.toString() === d.filters.endSeason){
            toSeasons.push(<option value={i} selected='selected'>{i}</option>)
        }
        else{
            toSeasons.push(<option value={i}>{i}</option>)
        }
    }

    return (
        <React.Fragment>
            <div className="form-group row" id="yearRangePanel">
                <div className="col-sm-3"></div>
                <label htmlFor="fromSeason" className="col-sm-1 col-form-label-sm">Year: </label>
                <select className="form-control form-control-sm col-sm-1" name="fromSeason" id="fromSeason" onChange={(event) => setStartSeason(event.target.value)}>
                    {fromSeasons}
                </select>
                <label htmlFor="toSeason" className="col-sm-1 col-form-label-sm">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;To: </label>
                <select className="form-control form-control-sm col-sm-1" name="toSeason" id="toSeason" onChange={(event) => setEndSeason(event.target.value)}>
                    {toSeasons}
                </select>
                <div className="col-sm-1"></div>
                <button type="submit" className="btn btn-primary" onClick={(e) => d.onSubmit(e, startSeason, endSeason)}>Submit</button>
            </div>

            <div className="shadowdiv">
                <ul class="nav nav-tabs" id="positionSubsections" role="tablist">
                    <li class="nav-item">
                        <a class="nav-link active" id="summary-tab" data-toggle="tab" href="#summary" role="tab" aria-controls="summary" aria-selected="true" onClick={() => setTabName("summary")}>Summary</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" id="advrushing-tab" data-toggle="tab" href="#advrushing" role="tab" aria-controls="advrushing" aria-selected="false" onClick={() => setTabName("advrushing")}>Adv. Rushing</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" id="advreceiving-tab" data-toggle="tab" href="#advreceiving" role="tab" aria-controls="advreceiving" aria-selected="false" onClick={() => setTabName("advreceiving")}>Adv. Receiving</a>
                    </li>
                </ul>
            </div>
            
            <div onClick={(e) => d.reScroll()}>
                {
                    tabName === "summary" &&
                    <TableRBWRSummaryTabulator rushingstats={d.rushingstats} receivingstats={d.receivingstats} puntretstats={d.puntretstats} kickretstats={d.kickretstats} blockingstats={d.blockingstats} isLeaderboards={true} />   
                }
                {
                    tabName === "advrushing" &&
                    <TableRBWRAdvancedRushingTabulator rushingstats={d.rushingstats} isLeaderboards={true} />
                }       
                {
                    tabName === "advreceiving" &&
                    <TableRBWRAdvancedReceivingTabulator receivingstats={d.receivingstats} isLeaderboards={true} />
                }
            </div>
        </React.Fragment>
    );
}

export default WRRBSection;
