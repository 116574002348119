import React from 'react';
import {NullSum, NullDiv, DispFunc} from '../common/CommonFunctions';
import { advPunting } from '../common/TableColumnWidths';
import Tabulator from 'tabulator-tables';
import "tabulator-tables/dist/css/tabulator.min.css";
import Filters from '../common/Filters';

class TableKPAdvancedPuntingTabulator extends React.Component {
    constructor(props) {
        super(props);
        this.puntingstats = this.props.puntingstats;
        this.rushingstats = this.props.rushingstats;
        this.passingstats = this.props.passingstats;
        this.playerlist = this.props.playerlist;
        this.isLeaderboards = this.props.isLeaderboards;
        this.minFilter = 1;
        this.data = [];
        let bioCols = null;
        this.initSort = null;
        let sortable = false;
        this.colNum = this.isLeaderboards ? 0 : 1;

        this.onSubmit = this.onSubmit.bind(this);

        // Custom sort function for players page so Total stays at the bottom of the table.
        let sortFunc = (a, b, aRow, bRow, column, dir, sorterParams) => {
            var aData = aRow.getData();
            var bData = bRow.getData();
            if (aData.season === 'Total'){
                if (dir === 'desc'){
                    return -1;
                }
                else if (dir === 'asc'){
                    return 1
                }
            }
            else if (bData.season === 'Total'){
                if (dir === 'desc'){
                    return 1;
                }
                else if (dir === 'asc'){
                    return -1;
                }            
            }
            else {
                return a-b;
            }
        };

        // Set first group of columns
        // If this is a leaderboards page include player name, remove custom sorter and set initial sort to proper column.
        if (this.isLeaderboards){
            sortable = true;
            bioCols = [
                {
                    title: "Player", field: "player", cssClass: "textColumn",
                    headerSort: sortable,
                    width: `${advPunting[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                    formatter: "link", formatterParams:{
                        labelField: "player",
                        urlPrefix:"/players/",
                        urlField: "playerId"
                    }
                },
                //{ title: "Pos", field: "positionName", cssClass: "textColumn", headerSort: sortable, sorter: sortFunc },
                {
                    title: "Year", field: "season", cssClass: "textColumn",
                    width: `${advPunting[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                    headerSort: sortable
                },
                {
                    title: "Team", field: "team", cssClass: "textColumn break",
                    width: `${advPunting[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                    headerSort: sortable
                },
    
            ];
            this.sorterFunc = null;
            this.initSort = [
                { column: "returnPct", dir: "asc" }
            ];
        }
        // Else don't include player name and set initial sort to season.
        else{
            bioCols = [
                {
                    title: "Year", field: "season", cssClass: "textColumn",
                    width: `${advPunting[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                    headerSort: sortable, sorter: sortFunc
                },
                {
                    title: "Team", field: "team", cssClass: "textColumn break",
                    width: `${advPunting[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                    headerSort: sortable, sorter: sortFunc
                },       
           ];
            this.initSort = [
                { column: "season", dir: "asc" }
            ];
        }
        // Set Remaining Columns
        this.cols = [
            {
                title: "",
                columns: bioCols
            },
            {
                title: "Fair Catch%", field: "fairCatchPct", cssClass: "single-row-header",
                headerSortStartingDir: "desc", headerSort: sortable, sorter: sortFunc,
                titleFormatter:"textarea",
                width: `${advPunting[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                formatter: function(cell, formatterParams, onRendered){
                    return DispFunc(cell, formatterParams, onRendered, 1, false, false);
                }
            },
            {
                title: "Return%", field: "returnPct", cssClass: "single-row-header",
                headerSortStartingDir: "desc", headerSort: sortable, sorter: sortFunc,
                width: `${advPunting[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                formatter: function(cell, formatterParams, onRendered){
                    return DispFunc(cell, formatterParams, onRendered, 1, false, false);
                }
            },
            {
                title: "Inside 20%", field: "puntIn20Pct", cssClass: "single-row-header",
                headerSortStartingDir: "desc", headerSort: sortable, sorter: sortFunc,
                width: `${advPunting[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                formatter: function(cell, formatterParams, onRendered){
                    return DispFunc(cell, formatterParams, onRendered, 1, false, false);
                }
            },
            {
                title: "Touch-back%", field: "touchBackPct", cssClass: "single-row-header break",
                headerSortStartingDir: "desc", headerSort: sortable, sorter: sortFunc,
                titleFormatter:"textarea",
                width: `${advPunting[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                formatter: function(cell, formatterParams, onRendered){
                    return DispFunc(cell, formatterParams, onRendered, 1, false, false);
                }
            },
            {
                title: "Punts",
                columns:[
                    {
                        title: "Inside 10 ", field: "puntIn10",cssClass: "single-row-header",
                        headerSortStartingDir: "desc", headerSort: sortable, sorter: sortFunc,
                        titleFormatter:"textarea",
                        width: `${advPunting[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                        formatter: function(cell, formatterParams, onRendered){
                            return DispFunc(cell, formatterParams, onRendered, 0, false, false);
                        }
                    },
                    {
                        title: "Inside 10%", field: "puntIn10Pct", cssClass: "break",
                        headerSortStartingDir: "desc", headerSort: sortable, sorter: sortFunc,
                        titleFormatter:"textarea",
                        width: `${advPunting[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                        formatter: function(cell, formatterParams, onRendered){
                            return DispFunc(cell, formatterParams, onRendered, 1, false, false);
                        }
                    }
                ]
            },
            {
                title: "Rushing",
                columns:[
                    {
                        title: "Att", field: "rushAttempts",
                        headerSortStartingDir: "desc", headerSort: sortable, sorter: sortFunc,
                        width: `${advPunting[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                        formatter: function(cell, formatterParams, onRendered){
                            return DispFunc(cell, formatterParams, onRendered, 0, false, false);
                        }
                    },
                    {
                        title: "Yards", field: "rushYds", cssClass: "break",
                        headerSortStartingDir: "desc", headerSort: sortable, sorter: sortFunc,
                        width: `${advPunting[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                        formatter: function(cell, formatterParams, onRendered){
                            return DispFunc(cell, formatterParams, onRendered, 0, false, false);
                        }
                    },
                ],
            },
            {
                title: "Passing",
                columns:[
                    {
                        title: "Att", field: "passAttempts",
                        headerSortStartingDir: "desc", headerSort: sortable, sorter: sortFunc,
                        width: `${advPunting[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                        formatter: function(cell, formatterParams, onRendered){
                            return DispFunc(cell, formatterParams, onRendered, 0, false, false);
                        }
                    },
                    {
                        title: "Cmp", field: "passCompletions",
                        headerSortStartingDir: "desc", headerSort: sortable, sorter: sortFunc,
                        width: `${advPunting[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                        formatter: function(cell, formatterParams, onRendered){
                            return DispFunc(cell, formatterParams, onRendered, 0, false, false);
                        }
                    },
                    {
                        title: "Yards", field: "passYds",
                        headerSortStartingDir: "desc", headerSort: sortable, sorter: sortFunc,
                        width: `${advPunting[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                        formatter: function(cell, formatterParams, onRendered){
                            return DispFunc(cell, formatterParams, onRendered, 0, false, false);
                        }
                    },
                    {
                        title: "TD", field: "passTD",
                        headerSortStartingDir: "desc", headerSort: sortable, sorter: sortFunc,
                        width: `${advPunting[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                        formatter: function(cell, formatterParams, onRendered){
                            return DispFunc(cell, formatterParams, onRendered, 0, false, false);
                        }
                    },
                    {
                        title: "Int", field: "passInt",
                        headerSortStartingDir: "desc", headerSort: sortable, sorter: sortFunc,
                        width: `${advPunting[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                        formatter: function(cell, formatterParams, onRendered){
                            return DispFunc(cell, formatterParams, onRendered, 0, false, false);
                        }
                    }        
                ],
            },
            
        ]
        
        this.el = React.createRef();
        this.tabulator = null;
    }


    // Consolidate the data into one data array to be used for tabulator(called on component mount)
    ConsolidateData() {
    
        // declare and initialize detail data fields
        // these fields will be used to create objects for each season/team pair
        let puntingstats = this.puntingstats;
        let passingstats = this.passingstats;
        let rushingstats = this.rushingstats;
        let player = '';
        let playerId = 0;
        let positionName = '';
        let season = 0;
        let team = '';
        let games = 0;
        let punts = 0;
        let fCs = 0;
        let returns = 0;
        let puntIn20 = 0;
        let puntIn20Pct = 0;
        let puntIn10 = 0;
        let touchBacks = 0;
        let rushAttempts = 0;
        let rushYds = 0;
        let passAttempts = 0;
        let passCompletions = 0;
        let passYds = 0;
        let passTD = 0;
        let passInt = 0;
        let fairCatchPct = 0;
        let returnPct = 0;
        let touchBackPct= 0;
        let puntIn10Pct = 0;

        // define array to hold detail data objects for each season/team pair
        var rowdata = [];
        var i = 0;
        // create rowdata[] object for each row in passingstats data
        if (puntingstats.length !==0) {
            for (i=0; i<puntingstats.length; i++) {
                if (!this.isLeaderboards || puntingstats[i].punts >= this.minFilter){
                    player = puntingstats[i].player;
                    playerId = puntingstats[i].playerId;
                    positionName = puntingstats[i].positionName;
                    season = puntingstats[i].season;
                    team = puntingstats[i].team;
                    games = puntingstats[i].games;
                    punts = puntingstats[i].punts;
                    fCs = puntingstats[i].fCs;
                    returns = puntingstats[i].puntsReturned;
                    puntIn20 = puntingstats[i].puntsInside20;
                    puntIn20Pct = puntingstats[i].puntsInSide20Pct;
                    puntIn10 = puntingstats[i].puntsInside10;
                    touchBacks = puntingstats[i].touchBacks;
                    rushAttempts = 0;
                    rushYds = 0;
                    passAttempts = 0;
                    passCompletions = 0;
                    passYds = 0;
                    passTD = 0;
                    passInt = 0;
                    fairCatchPct = NullDiv(fCs,punts)*100;
                    returnPct = NullDiv(returns,punts)*100;
                    puntIn20Pct = NullDiv(puntIn20,punts)*100;
                    touchBackPct = NullDiv(touchBacks,punts)*100;
                    puntIn10Pct = NullDiv(puntIn10,punts)*100;
    
                    rowdata.push({
                        player,
                        playerId,
                        positionName,
                        season,
                        team,
                        games,
                        punts,
                        fCs,
                        returns,
                        puntIn20,
                        puntIn20Pct,
                        puntIn10,
                        touchBacks,
                        rushAttempts,
                        rushYds,
                        passAttempts,
                        passCompletions,
                        passYds,
                        passTD,
                        passInt,
                        fairCatchPct,
                        returnPct,
                        touchBackPct,
                        puntIn10Pct
                    });
                }
            }
        }
        // for each rushingstats row that matches rowdata[] by season/team pair, populate the corresponding fields.
        // if the season/team pair is not found create a new rowdata object and populate the appropriate.
        let j = -1;
        if (rushingstats.length !==0) {
            for (i=0; i<rushingstats.length; i++) {
                for (j = rowdata.length - 1; j > -1; j--) {
                    if (rowdata[j].season === rushingstats[i].season && rowdata[j].team === rushingstats[i].team && rowdata[j].playerId === rushingstats[i].playerId) {
                        break;
                    }
                }
                if (j > -1) {
                    rowdata[j].rushAttempts = rushingstats[i].att;
                    rowdata[j].rushYds = rushingstats[i].yards;
                } else if(!this.isLeaderboards) {
                    player = rushingstats[i].player;
                    playerId = rushingstats[i].playerId
                    season = rushingstats[i].season;
                    team = rushingstats[i].team;
                    games = rushingstats[i].g;
                    punts = 0;
                    fCs = 0;
                    returns = 0;
                    puntIn20 = 0;
                    puntIn20Pct = 0;
                    puntIn10 = 0;
                    touchBacks = 0;
                    rushAttempts = rushingstats[i].att;
                    rushYds = rushingstats[i].yards;
                    passAttempts = 0;
                    passCompletions = 0;
                    passYds = 0;
                    passTD = 0;
                    passInt = 0;
                    fairCatchPct = NullDiv(fCs,punts);
                    returnPct = NullDiv(returns,punts);
                    puntIn20Pct = NullDiv(puntIn20,punts);
                    touchBackPct = NullDiv(touchBacks,punts);
                    puntIn10Pct = NullDiv(puntIn10,punts);
                    rowdata.push({
                        player,
                        playerId,
                        season,
                        team,
                        games,
                        punts,
                        fCs,
                        returns,
                        puntIn20,
                        puntIn20Pct,
                        puntIn10,
                        touchBacks,
                        rushAttempts,
                        rushYds,
                        passAttempts,
                        passCompletions,
                        passYds,
                        passTD,
                        passInt,
                        fairCatchPct,
                        returnPct,
                        touchBackPct,
                        puntIn10Pct
                    });
                }
            }
        }
        // for each passingstats row that matches rowdata[] by season/team pair, populate the corresponding fields.
        // if the season/team pair is not found create a new rowdata object and populate the appropriate.
        j = -1;
        if (passingstats.length !==0) {
            for (i=0; i<passingstats.length; i++) {
                for (j = rowdata.length - 1; j > -1; j--) {
                    if (rowdata[j].season === passingstats[i].season && rowdata[j].team === passingstats[i].teamName && rowdata[j].playerId === passingstats[i].playerId) {
                        break;
                    }
                }
                if (j > -1) {
                    rowdata[j].passAttempts = passingstats[i].attempts;
                    rowdata[j].passCompletions = passingstats[i].comp;
                    rowdata[j].passYds = passingstats[i].yards;
                    rowdata[j].passTD = passingstats[i].td;
                    rowdata[j].passInt = passingstats[i].int;
                } else if(!this.isLeaderboards) {
                    player = passingstats[i].player;
                    playerId = passingstats[i].playerId;
                    season = passingstats[i].season;
                    team = passingstats[i].team;
                    games = passingstats[i].g;
                    punts = 0;
                    fCs = 0;
                    returns = 0;
                    puntIn20 = 0;
                    puntIn20Pct = 0;
                    puntIn10 = 0;
                    touchBacks = 0;
                    rushAttempts = 0;
                    rushYds = 0;
                    passAttempts = passingstats[i].attempts;
                    passCompletions = passingstats[i].comp;
                    passYds = passingstats[i].yards;
                    passTD = passingstats[i].td;
                    passInt = passingstats[i].int;
                    fairCatchPct = NullDiv(fCs,punts);
                    returnPct = NullDiv(returns,punts);
                    puntIn20Pct = NullDiv(puntIn20,punts);
                    touchBackPct = NullDiv(touchBacks,punts);
                    puntIn10Pct = NullDiv(puntIn10,punts);
                    rowdata.push({
                        player,
                        playerId,
                        season,
                        team,
                        games,
                        punts,
                        fCs,
                        returns,
                        puntIn20,
                        puntIn20Pct,
                        puntIn10,
                        touchBacks,
                        rushAttempts,
                        rushYds,
                        passAttempts,
                        passCompletions,
                        passYds,
                        passTD,
                        passInt,
                        fairCatchPct,
                        returnPct,
                        touchBackPct,
                        puntIn10Pct
                    });
                }
            }
        }
        this.data = rowdata
    }

    // Add totals row to data.(called on component mount if this is the players page.)
    SumData() { 
        let season = 'Total';
        let team = '';
        let games = 0;
        let punts = 0;
        let fCs = 0;
        let returns = 0;
        let puntIn20 = 0;
        let puntIn10 = 0;
        let touchBacks = 0;
        let rushAttempts = 0;
        let rushYds = 0;
        let passAttempts = 0;
        let passCompletions = 0;
        let passYds = 0;
        let passTD = 0;
        let passInt = 0;
        let fairCatchPct = 0;
        let returnPct = 0;
        let touchBackPct = 0;
        let puntIn20Pct = 0;
        let puntIn10Pct = 0;
        if (this.data.length !== 0){
            for (var i=0; i<this.data.length; i++) {
                punts = NullSum(punts,this.data[i].punts);
                fCs = NullSum(fCs,this.data[i].fCs);
                returns = NullSum(returns,this.data[i].returns);
                puntIn20 = NullSum(puntIn20,this.data[i].puntIn20);
                puntIn10 = NullSum(puntIn10,this.data[i].puntIn10);
                touchBacks = NullSum(touchBacks,this.data[i].touchBacks);
                rushAttempts = NullSum(rushAttempts,this.data[i].rushAttempts);
                rushYds = NullSum(rushYds,this.data[i].rushYds);
                passAttempts = NullSum(passAttempts,this.data[i].passAttempts);
                passCompletions = NullSum(passCompletions,this.data[i].passCompletions);
                passYds = NullSum(passYds,this.data[i].passYds);
                passTD = NullSum(passTD,this.data[i].passTD)
                passInt = NullSum(passInt,this.data[i].passInt);
            }
            
            fairCatchPct = NullDiv(fCs,punts)*100;
            returnPct = NullDiv(returns,punts)*100;
            puntIn20Pct = NullDiv(puntIn20,punts)*100;
            touchBackPct = NullDiv(touchBacks,punts)*100;
            puntIn10Pct = NullDiv(puntIn10,punts)*100;
    
            this.data.push(
                {
                    season,
                    team,
                    games,
                    punts,
                    fCs,
                    returns,
                    puntIn20,
                    puntIn20Pct,
                    puntIn10,
                    touchBacks,
                    rushAttempts,
                    rushYds,
                    passAttempts,
                    passCompletions,
                    passYds,
                    passTD,
                    passInt,
                    fairCatchPct,
                    returnPct,
                    touchBackPct,
                    puntIn10Pct
                }
            );
        }
    } 
    
    componentDidMount() {
        this.ConsolidateData();
        let pagination = 'local';
        let paginationSize = 50;
        if (!this.isLeaderboards){
            this.SumData();
            pagination = null;
            paginationSize = 0;
        }
        this.tabulator = new Tabulator(this.el, {
            data: this.data,
            reactiveData: true,
            columns: this.cols,
            layout: "fitColumns",  // added by Ruben 7/25/19
            rowFormatter:function(row){
                var data = row.getData();
                if (data.season === 'Total'){
                    row.getElement().style.borderTop = "2px solid #6f88a8";
                    row.getElement().style.borderBottom = "2px solid #6f88a8";
                }
            },
            pagination: pagination,
            paginationSize: paginationSize,
            initialSort: this.initSort,
            resizableColumns: false,
            selectable: false
        });
    }

    componentDidUpdate() {
        this.tabulator.replaceData(this.data);
    }

    onSubmit(e, minFilter){
        this.minFilter = minFilter;
        this.ConsolidateData();
        this.forceUpdate();
    }

    render() {
        return (
            <React.Fragment>
                {
                    this.isLeaderboards &&
                    <div className="shadowdiv" id="filtersDiv">
                        <Filters minFilter={this.minFilter} onSubmit={this.onSubmit} label={"Punts"} />
                        <div ref={el => (this.el = el)} />
                    </div>
                }
                {
                    !this.isLeaderboards &&
                    <div className="shadowdiv">
                        <div id="table-title">Advanced Punting</div>
                        <div ref={el => (this.el = el)} />
                    </div>
                }
            </React.Fragment>
        );
    }
}

export default TableKPAdvancedPuntingTabulator;
