import React from 'react';
import { CalcPlayerRating, NullSum, NullBypassSum, NullMax, NullDiv, Tst2015, DispFunc, NullSub } from '../common/CommonFunctions';
import { qbSummary } from '../common/TableColumnWidths';
import Tabulator from 'tabulator-tables';
import "tabulator-tables/dist/css/tabulator.min.css";
import Filters from '../common/Filters';

class TableQBSummaryTabulator extends React.Component {
    constructor(props) {
        super(props);
        this.passingstats = this.props.passingstats;
        this.rushingstats = this.props.rushingstats;
        this.isLeaderboards = this.props.isLeaderboards;
        this.minFilter = 1;
        this.data = [];
        let bioCols = null;
        let sortable = false;
        this.initSort = null;
        this.colNum = this.isLeaderboards ? 0 : 1;

        this.onSubmit = this.onSubmit.bind(this);

        // Custom sort function for players page so Total stays at the bottom of the table.
        let sortFunc = (a, b, aRow, bRow, column, dir, sorterParams) => {
            var aData = aRow.getData();
            var bData = bRow.getData();
            if (aData.season === 'Total'){
                if (dir === 'desc'){
                    return -1;
                }
                else if (dir === 'asc'){
                    return 1
                }
            }
            else if (bData.season === 'Total'){
                if (dir === 'desc'){
                    return 1;
                }
                else if (dir === 'asc'){
                    return -1;
                }            
            }
            else {
                return a-b;
            }
        };

        // Set first group of columns
        // If this is a leaderboards page include player name, remove custom sorter and set initial sort to proper column.
        if (this.isLeaderboards) {
            sortable = true;
            bioCols = [
                {
                    title: "Player", field: "playerName", cssClass: "single-row-header textColumn",
                    titleFormatter: "html",
                    width: `${qbSummary[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                    formatter: "link", formatterParams:{
                        labelField: "playerName",
                        urlPrefix:"/players/",
                        urlField: "playerId"
                    }
                },
                { 
                    title: "Year", field: "season", cssClass: "single-row-header textColumn",
                    titleFormatter: "html",
                    width: `${qbSummary[this.colNum++][this.isLeaderboards ? 0 : 1]}`
                },
                { 
                    title: "Team", field: "team", cssClass: "single-row-header textColumn",
                    titleFormatter: "html",
                    width: `${qbSummary[this.colNum++][this.isLeaderboards ? 0 : 1]}`
                },
                { 
                    title: "G", field: "games", cssClass: "single-row-header small-column",
                    titleFormatter: "html",
                    headerSortStartingDir: "desc",
                    width: `${qbSummary[this.colNum++][this.isLeaderboards ? 0 : 1]}`
                },
                { 
                    title: "GS", field: "gamesStarted", cssClass: "single-row-header break small-column",
                    titleFormatter: "html",
                    headerSortStartingDir:"desc",
                    width: `${qbSummary[this.colNum++][this.isLeaderboards ? 0 : 1]}`
                }        
            ];
            this.sorterFunc = null;
            this.initSort = [
                { column: "sumPointsEarned", dir: "desc" }
            ];
        }
        // Else don't include player name and set initial sort to season.
        else{
            bioCols = [
                { 
                    title: "Year", field: "season", cssClass: "single-row-header textColumn",
                    titleFormatter: "html",
                    headerSort: false, sorter: sortFunc,
                    width: `${qbSummary[this.colNum++][this.isLeaderboards ? 0 : 1]}`
                },
                { 
                    title: "Team", field: "team", cssClass: "single-row-header textColumn",
                    titleFormatter: "html",
                    headerSort: false, sorter: sortFunc,
                    width: `${qbSummary[this.colNum++][this.isLeaderboards ? 0 : 1]}`
                },
                { 
                    title: "G", field: "games", cssClass: "single-row-header small-column",
                    titleFormatter: "html",
                    headerSortStartingDir:"desc", headerSort: sortable, sorter: sortFunc,
                    width: `${qbSummary[this.colNum++][this.isLeaderboards ? 0 : 1]}`
                },
                { 
                    title: "GS", field: "gamesStarted", cssClass: "single-row-header break small-column",
                    titleFormatter: "html",
                    headerSortStartingDir:"desc", headerSort: sortable, sorter: sortFunc,
                    width: `${qbSummary[this.colNum++][this.isLeaderboards ? 0 : 1]}`
                }        
            ];
            this.initSort = [
                { column: "season", dir: "asc" }
            ];
        }
        // Set Remaining Columns
        this.cols = [
            { title: "",
                columns: bioCols
            },
            {
                title: "Passing",
                columns:[
                    { 
                        title: "Att", field: "passAttempts", cssClass: "single-row-header",
                        titleFormatter: "html",
                        headerSortStartingDir:"desc", headerSort: sortable, sorter: sortFunc,
                        width: `${qbSummary[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                        formatter: function(cell, formatterParams, onRendered){
                            return DispFunc(cell, formatterParams, onRendered, 0, true, false);
                        }
                    },
                    { 
                        title: "Cmp", field: "passCompletions", cssClass: "single-row-header",
                        titleFormatter: "html",
                        headerSortStartingDir:"desc", headerSort: sortable, sorter: sortFunc,
                        width: `${qbSummary[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                        formatter: function(cell, formatterParams, onRendered){
                            return DispFunc(cell, formatterParams, onRendered, 0, true, false);
                        }
                    },
                    { 
                        title: "Pct", field: "passCompPct", cssClass: "single-row-header",
                        titleFormatter: "html",
                        headerSortStartingDir:"desc", headerSort: sortable, sorter: sortFunc,
                        width: `${qbSummary[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                        formatter: function(cell, formatterParams, onRendered){
                            return DispFunc(cell, formatterParams, onRendered, 1, false, false);
                        }
                    },
                    { 
                        title: "Yds", field: "passYards", cssClass: "single-row-header",
                        titleFormatter: "html",
                        headerSortStartingDir:"desc", headerSort: sortable, sorter: sortFunc,
                        width: `${qbSummary[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                        formatter: function(cell, formatterParams, onRendered){
                            return DispFunc(cell, formatterParams, onRendered, 0, true, false);
                        }
                    },
                    { 
                        title: "Yds/Att", field: "passYdsPerAttempt", cssClass: "single-row-header",
                        titleFormatter: "html",
                        headerSortStartingDir:"desc", headerSort: sortable, sorter: sortFunc,
                        width: `${qbSummary[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                        formatter: function(cell, formatterParams, onRendered){
                            return DispFunc(cell, formatterParams, onRendered, 1, false, false);
                        }
                    },
                    { 
                        title: "Y/G", field: "passYdsPerGame", cssClass: "single-row-header break",
                        titleFormatter: "html",
                        headerSortStartingDir:"desc", headerSort: sortable, sorter: sortFunc,
                        width: `${qbSummary[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                        formatter: function(cell, formatterParams, onRendered){
                            return DispFunc(cell, formatterParams, onRendered, 1, false, false);
                        }
                    },
                    {
                        title: "Lg", field: "passLong", cssClass: "single-row-header",
                        titleFormatter: "html",
                        headerSortStartingDir:"desc", headerSort: sortable, sorter: sortFunc,
                        width: `${qbSummary[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                        formatter: function(cell, formatterParams, onRendered){
                            return DispFunc(cell, formatterParams, onRendered, 0, false, false);
                        }
                    },
                    {
                        title: "TD", field: "passTD", cssClass: "single-row-header",
                        titleFormatter: "html",
                        headerSortStartingDir:"desc", headerSort: sortable, sorter: sortFunc,
                        width: `${qbSummary[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                        formatter: function(cell, formatterParams, onRendered){
                            return DispFunc(cell, formatterParams, onRendered, 0, false, false);
                        }
                    },
                    {
                        title: "Int", field: "passInt", cssClass: "single-row-header break",
                        titleFormatter: "html",
                        headerSortStartingDir:"desc", headerSort: sortable, sorter: sortFunc,
                        width: `${qbSummary[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                        formatter: function(cell, formatterParams, onRendered){
                            return DispFunc(cell, formatterParams, onRendered, 0, false, false);
                        }
                    }        
                ],
            },
            {
                title: "Rushing",
                columns:[
                    { 
                        title: "Att", field: "rushAttempts", cssClass: "single-row-header",
                        titleFormatter: "html",
                        headerSortStartingDir:"desc", headerSort: sortable, sorter: sortFunc,
                        width: `${qbSummary[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                        formatter: function(cell, formatterParams, onRendered){
                            return DispFunc(cell, formatterParams, onRendered, 0, false, false);
                        }
                    },
                    { 
                        title: "Yds", field: "rushYds", cssClass: "single-row-header",
                        titleFormatter: "html",
                        headerSortStartingDir:"desc", headerSort: sortable, sorter: sortFunc,
                        width: `${qbSummary[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                        formatter: function(cell, formatterParams, onRendered){
                            return DispFunc(cell, formatterParams, onRendered, 0, true, false);
                        }
                    },
                    { 
                        title: "Avg", field: "rushYdsPerAttempt", cssClass: "single-row-header",
                        titleFormatter: "html",
                        headerSortStartingDir:"desc", headerSort: sortable, sorter: sortFunc,
                        width: `${qbSummary[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                        formatter: function(cell, formatterParams, onRendered){
                            return DispFunc(cell, formatterParams, onRendered, 1, false, false);
                        }
                    },
                    { 
                        title: "Lg", field: "rushLong", cssClass: "single-row-header",
                        titleFormatter: "html",
                        headerSortStartingDir:"desc", headerSort: sortable, sorter: sortFunc,
                        width: `${qbSummary[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                        formatter: function(cell, formatterParams, onRendered){
                            return DispFunc(cell, formatterParams, onRendered, 0, false, false);
                        }
                    },
                    { 
                        title: "TD", field: "rushTD", cssClass: "single-row-header break",
                        titleFormatter: "html",
                        headerSortStartingDir:"desc", headerSort: sortable, sorter: sortFunc,
                        width: `${qbSummary[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                        formatter: function(cell, formatterParams, onRendered){
                            return DispFunc(cell, formatterParams, onRendered, 0, false, false);
                        }
                    }        
                ],
            },
            {
                title: "Fumbles",
                columns:[
                    { 
                        title: "Fum", field: "sumFumbles", cssClass: "single-row-header",
                        titleFormatter: "html",
                        headerSortStartingDir:"desc", headerSort: sortable, sorter: sortFunc,
                        width: `${qbSummary[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                        formatter: function(cell, formatterParams, onRendered){
                            return DispFunc(cell, formatterParams, onRendered, 0, false, false);
                        }
                    },
                    { 
                        title: "Lost", field: "sumFumblesLost", cssClass: "single-row-header break",
                        titleFormatter: "html",
                        headerSortStartingDir:"desc", headerSort: sortable, sorter: sortFunc,
                        width: `${qbSummary[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                        formatter: function(cell, formatterParams, onRendered){
                            return DispFunc(cell, formatterParams, onRendered, 0, false, false);
                        }
                    }        
                ],
            },
            {
                title: "",
                columns:[
                    { 
                        title: "QB Rating", field: "passQBRating", cssClass: "single-row-header",
                        titleFormatter: "html",
                        headerSortStartingDir:"desc", headerSort: sortable, sorter: sortFunc,
                        width: `${qbSummary[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                        formatter: function(cell, formatterParams, onRendered){
                            return DispFunc(cell, formatterParams, onRendered, 1, false, false);
                        }
                    },
                    { 
                        title: "IQR", field: "sumIQR", cssClass: "single-row-header break",
                        titleFormatter: "html",
                        headerSortStartingDir:"desc", headerSort: sortable, sorter: sortFunc,
                        width: `${qbSummary[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                        formatter: function(cell, formatterParams, onRendered){
                            return DispFunc(cell, formatterParams, onRendered, 1, false, false);
                        }
                    }
                ],
            },
            { 
                title: "Tot Points<br/>Earned", field: "sumPointsEarned",
                titleFormatter: "html",
                headerSortStartingDir:"desc", headerSort: sortable, sorter: sortFunc,
                width: `${qbSummary[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                formatter: function(cell, formatterParams, onRendered){
                    return DispFunc(cell, formatterParams, onRendered, 0, false, false);
                }
            }
        ];
        
        this.el = React.createRef();
        this.tabulator = null;

    }

    // Consolidate the data into one data array to be used for tabulator(called on component mount)
    ConsolidateData(){
        // no passing or rushing data
        if (this.passingstats.length === 0 && this.rushingstats.length === 0) {
            return('');
        }
        // declare and initialize detail data fields
        // these fields will be used to create objects for each season/team pair
        let passingstats = this.passingstats;
        let rushingstats = this.rushingstats;
        let season = 0;
        let playerName = '';
        let playerId = 0;
        let positionName = '';
        let team = '';
        let games = 0;
        let gamesStarted = 0;
        let passAttempts = 0;
        let passCompletions = 0;
        let passDrops = 0;
        let passCompPct = 0;
        let passYards = 0;
        let passDropYards = 0;
        let passYdsPerAttempt = 0;
        let passYdsPerGame = 0;
        let passLong = 0;
        let passTD = 0;
        let passThrowAways = 0;
        let passSpikes = 0;
        let passBatted = 0;
        let passMiscommunications = 0;
        let passAccurateCompletions = 0;
        let passDroppedTD = 0;
        let passInt = 0;
        let passDroppedInt = 0;
        let passQBRating = 0;
        let rushAttempts = 0;
        let rushYds = 0;
        let rushYdsPerAttempt = 0;
        let rushLong = 0;
        let rushTD = 0;
        let sumFumbles = 0;
        let sumFumblesLost = 0;
        let sumIQR = 0;
        let sumPointsEarned = 0;
        // define array to hold detail data objects for each season/team pair
        var rowdata = [];
        var i = 0;
        // create rowdata[] object for each row in passingstats data
        if (passingstats.length !==0) {
            for (i=0; i<passingstats.length; i++) {
                if (!this.isLeaderboards || passingstats[i].attempts >= this.minFilter){
                    season = passingstats[i].season;
                    playerName = passingstats[i].playerName;
                    playerId = passingstats[i].playerId;
                    positionName = passingstats[i].positionName;
                    team = passingstats[i].teamName;
                    games = passingstats[i].starts > passingstats[i].g ?
                        passingstats[i].starts :
                        passingstats[i].g;
                    gamesStarted = passingstats[i].starts;
                    passAttempts = passingstats[i].attempts;
                    passCompletions = passingstats[i].comp;
                    passDrops = passingstats[i].drops;
                    passCompPct = passingstats[i].compPerc;
                    passYards = passingstats[i].yards;
                    passDropYards = passingstats[i].dropYards;
                    passYdsPerAttempt = passingstats[i].yardsPerAtt;
                    passYdsPerGame = NullDiv(passYards,games);
                    passLong = passingstats[i].passLong;
                    passTD = passingstats[i].td;
                    passThrowAways = passingstats[i].throwAways;
                    passSpikes = passingstats[i].spikes;
                    passBatted = passingstats[i].passBatted;
                    passMiscommunications = passingstats[i].miscommunications;
                    passAccurateCompletions = passingstats[i].accurateCompletions;
                    passDroppedTD = passingstats[i].droppedTD;
                    passInt = passingstats[i].int;
                    passDroppedInt = passingstats[i].droppedInterceptions;
                    passQBRating = passingstats[i].qbRating;
                    rushAttempts = 0;
                    rushYds = 0;
                    rushYdsPerAttempt = 0;
                    rushLong = 0;
                    rushTD = 0;
                    sumFumbles = passingstats[i].fumbles;
                    sumFumblesLost = passingstats[i].fumblesLost;
                    sumIQR = passingstats[i].iqr
                    sumPointsEarned = Tst2015(passingstats[i].season,passingstats[i].eps);
                    rowdata.push({
                        season,
                        playerName,
                        playerId,
                        positionName,
                        team,
                        games,
                        gamesStarted,
                        passAttempts,
                        passCompletions,
                        passDrops,
                        passCompPct,
                        passYards,
                        passDropYards,
                        passYdsPerAttempt,
                        passYdsPerGame,
                        passLong,
                        passTD,
                        passThrowAways,
                        passSpikes,
                        passBatted,
                        passMiscommunications,
                        passAccurateCompletions,
                        passDroppedTD,
                        passInt,
                        passDroppedInt,
                        passQBRating,
                        rushAttempts,
                        rushYds,
                        rushYdsPerAttempt,
                        rushLong,
                        rushTD,
                        sumFumbles,
                        sumFumblesLost,
                        sumIQR,
                        sumPointsEarned
                    });
                }
            }
        }
        // for each rushingstats row that nmatch rowdata[] by season/team pair, populate the rushing stat fields
        // if the season/team pair is not found create a new rowdata object and populate the season, team, games
        // and rushign stat fields only
        let j = -1;
        if (rushingstats.length !==0) {
            for (i=0; i<rushingstats.length; i++) {
                for (j = rowdata.length - 1; j > -1; j--) {
                    if (rowdata[j].season === rushingstats[i].season && rowdata[j].team === rushingstats[i].team && rowdata[j].playerId === rushingstats[i].playerId) {
                        break;
                    }
                }
                // j = rowdata.indexOf(season => season === rushingstats[i].season);
                if (j > -1) {
                    rowdata[j].rushAttempts = rushingstats[i].att;
                    rowdata[j].rushYds = rushingstats[i].yards;
                    rowdata[j].rushYdsPerAttempt = rushingstats[i].yardsPerAtt;
                    rowdata[j].rushLong = rushingstats[i].long;
                    rowdata[j].rushTD = rushingstats[i].td;
                    rowdata[j].sumFumbles = NullSum(rowdata[j].sumFumbles,rushingstats[i].fumbles);
                    rowdata[j].sumFumblesLost = NullSum(rowdata[j].sumFumblesLost,rushingstats[i].fumblesLost);
                    rowdata[j].sumPointsEarned = NullBypassSum(rowdata[j].sumPointsEarned,Tst2015(rushingstats[i].season,rushingstats[i].eps));
                } else if(!this.isLeaderboards) {
                    season = rushingstats[i].season;
                    playerName = rushingstats[i].playerName;
                    playerId = rushingstats[i].playerId;
                    team = rushingstats[i].team;
                    games = rushingstats[i].starts > rushingstats[i].g ?
                        rushingstats[i].starts :
                        rushingstats[i].g;
                    gamesStarted = rushingstats[i].starts;
                    passAttempts = 0;
                    passCompletions = 0;
                    passDrops = 0;
                    passCompPct = 0;
                    passYards = 0;
                    passDropYards = 0;
                    passYdsPerAttempt = 0;
                    passYdsPerGame = 0;
                    passLong = 0;
                    passTD = 0;
                    passThrowAways = 0;
                    passSpikes = 0;
                    passBatted = 0;
                    passMiscommunications = 0;
                    passAccurateCompletions = 0;
                    passDroppedTD = 0;
                    passInt = 0;
                    passDroppedInt = 0;
                    passQBRating = 0;
                    rushAttempts = rushingstats[i].att;
                    rushYds = rushingstats[i].yards;
                    rushYdsPerAttempt = rushingstats[i].yardsPerAtt;
                    rushLong = rushingstats[i].long;
                    rushTD = rushingstats[i].td;
                    sumFumbles = rushingstats[i].fumbles;
                    sumFumblesLost = rushingstats[i].fumblesLost;
                    sumIQR = 0;
                    sumPointsEarned = Tst2015(rushingstats[i].season,rushingstats[i].eps);
                    rowdata.push({
                        season,
                        playerName,
                        playerId,
                        team,
                        games,
                        gamesStarted,
                        passAttempts,
                        passCompletions,
                        passDrops,
                        passCompPct,
                        passYards,
                        passDropYards,
                        passYdsPerAttempt,
                        passYdsPerGame,
                        passLong,
                        passTD,
                        passThrowAways,
                        passSpikes,
                        passBatted,
                        passMiscommunications,
                        passAccurateCompletions,
                        passDroppedTD,
                        passInt,
                        passDroppedInt,
                        passQBRating,
                        rushAttempts,
                        rushYds,
                        rushYdsPerAttempt,
                        rushLong,
                        rushTD,
                        sumFumbles,
                        sumFumblesLost,
                        sumIQR,
                        sumPointsEarned
                    });
                }
            }
        }
        this.data = rowdata;
    }

    // Add totals row to data.(called on component mount if this is the players page.)
    SumData() {
        let season = 'Total';
        let playerName = '';
        let playerId = '';
        let team = '';
        let games = 0;
        let gamesStarted = 0;
        let passAttempts = 0;
        let passCompletions = 0;
        let passCompPct = 0;
        let passYards = 0;
        let passYdsPerAttempt = 0;
        let passYdsPerGame = 0;
        let passLong = 0;
        let passTD = 0;
        let passInt = 0;
        let passQBRating = 0;
        let passDrops = 0;
        let passDropYards = 0;
        let passDroppedTD = 0;
        let passDroppedInt = 0;
        let sumIQR = 0;
        let rushAttempts = 0;
        let rushYds = 0;
        let rushYdsPerAttempt = 0;
        let rushLong = 0;
        let rushTD = 0;
        let sumFumbles = 0;
        let sumFumblesLost = 0;
        let sumPointsEarned = 0;
        let passThrowAways = 0;
        let passSpikes = 0;
        let passBatted = 0;
        let passMiscommunications = 0;
        let passAccurateCompletions = 0;
        if (this.data.length !== 0){
            for (var i = 0; i < this.data.length; i++) {
                games = NullBypassSum(games,this.data[i].games);
                gamesStarted = NullBypassSum(gamesStarted,this.data[i].gamesStarted);
                passAttempts = NullBypassSum(passAttempts,this.data[i].passAttempts);
                passCompletions = NullBypassSum(passCompletions,this.data[i].passCompletions);
                passYards = NullBypassSum(passYards,this.data[i].passYards);
                passDropYards = NullBypassSum(passDropYards,this.data[i].passDropYards);
                passLong = NullMax(passLong,this.data[i].passLong);
                passTD = NullBypassSum(passTD,this.data[i].passTD);
                passDroppedTD = NullBypassSum(passDroppedTD,this.data[i].passDroppedTD);
                passDrops = NullBypassSum(passDrops,this.data[i].passDrops);
                passInt = NullBypassSum(passInt,this.data[i].passInt);
                passDroppedInt = NullBypassSum(passDroppedInt,this.data[i].passDroppedInt);
                passThrowAways = NullBypassSum(passThrowAways,this.data[i].passThrowAways);
                passSpikes = NullBypassSum(passSpikes,this.data[i].passSpikes);
                passBatted = NullBypassSum(passBatted,this.data[i].passBatted);
                passMiscommunications = NullBypassSum(passMiscommunications,this.data[i].passMiscommunications);
                passAccurateCompletions = NullBypassSum(passAccurateCompletions,this.data[i].passAccurateCompletions);
                rushAttempts = NullBypassSum(rushAttempts,this.data[i].rushAttempts);
                rushYds = NullBypassSum(rushYds,this.data[i].rushYds);
                rushLong = NullMax(rushLong,this.data[i].rushLong);
                rushTD = NullBypassSum(rushTD,this.data[i].rushTD);
                sumFumbles = NullBypassSum(sumFumbles,this.data[i].sumFumbles);
                sumFumblesLost = NullBypassSum(sumFumblesLost,this.data[i].sumFumblesLost);
                sumPointsEarned = NullBypassSum(sumPointsEarned,Tst2015(this.data[i].season,this.data[i].sumPointsEarned));
            }
            if (passAttempts) {
                passCompPct = NullDiv(passCompletions,passAttempts)*100;
                passYdsPerAttempt = NullDiv(passYards,passAttempts);
                // QB Pass Rating
                passQBRating = CalcPlayerRating(passAttempts,passCompletions,passYards,passTD,passInt);
                let iqrAttempts = NullSub(passAttempts, NullSum(NullSum(passThrowAways,passSpikes),NullSum(passBatted,passMiscommunications)));
                let iqrComp = NullSum(passAccurateCompletions,passDrops);
                let iqrYards = NullSum(passYards,passDropYards);
                let iqrTD = NullSum(passTD,passDroppedTD);
                let iqrInt = NullSum(passInt,passDroppedInt);
                sumIQR = CalcPlayerRating(iqrAttempts, iqrComp, iqrYards,iqrTD,iqrInt);
            }
            rushYdsPerAttempt = NullDiv(rushYds,rushAttempts);
            passYdsPerGame = NullDiv(passYards,games);
            this.data.push({
                season,
                playerName,
                playerId,
                team,
                games,
                gamesStarted,
                passAttempts,
                passCompletions,
                passDrops,
                passCompPct,
                passYards,
                passDropYards,
                passYdsPerAttempt,
                passYdsPerGame,
                passLong,
                passTD,
                passThrowAways,
                passSpikes,
                passBatted,
                passMiscommunications,
                passAccurateCompletions,
                passDroppedTD,
                passInt,
                passDroppedInt,
                passQBRating,
                rushAttempts,
                rushYds,
                rushYdsPerAttempt,
                rushLong,
                rushTD,
                sumFumbles,
                sumFumblesLost,
                sumIQR,
                sumPointsEarned
            });
        }
    }
    
    componentDidMount() {
        this.ConsolidateData();
        let pagination = 'local';
        let paginationSize = 50;
        if (!this.isLeaderboards){
            this.SumData();
            pagination = null;
            paginationSize = 0;
        }
        this.tabulator = new Tabulator(this.el, {
            data: this.data,
            reactiveData: true,
            columns: this.cols,
            layout: "fitColumns",  // added by Ruben 7/25/19
            rowFormatter:function(row){
                var data = row.getData();
                if (data.season === 'Total'){
                    row.getElement().style.borderTop = "2px solid #6f88a8";
                    row.getElement().style.borderBottom = "2px solid #6f88a8";
                }
            },
            pagination: pagination,
            paginationSize: paginationSize,
//            resizableColumns: false,
//            selectable: false,
            initialSort: this.initSort
        });
    }

    componentDidUpdate() {
        this.tabulator.replaceData(this.data);
    }

    onSubmit(e, minFilter){
        this.minFilter = minFilter;
        this.ConsolidateData();
        this.forceUpdate();
    }

    render() {
        return (
            <React.Fragment>
                {
                    this.isLeaderboards &&
                    <div className="shadowdiv" id="filtersDiv">
                        <Filters minFilter={this.minFilter} onSubmit={this.onSubmit} label={"Pass Attempts"} />
                        <div ref={el => (this.el = el)} />
                    </div>
                }
                {
                    !this.isLeaderboards &&
                    <div className="shadowdiv">
                        <div id="table-title">Summary</div>
                        <div ref={el => (this.el = el)} />
                    </div>
                }
            </React.Fragment>
        );
    }
}

export default TableQBSummaryTabulator;