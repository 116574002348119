import React from 'react';
import {NullSum, NullBypassSum, NullMax, NullDiv, Tst2015, DispFunc} from '../common/CommonFunctions';
import { rbfbSummary } from '../common/TableColumnWidths';
import Tabulator from 'tabulator-tables';
import "tabulator-tables/dist/css/tabulator.min.css";
import Filters from '../common/Filters';

class TableWRTESummaryTabulator extends React.Component {
    constructor(props) {
        super(props);
        this.rushingstats = this.props.rushingstats;
        this.receivingstats = this.props.receivingstats;
        this.puntretstats = this.props.puntretstats;
        this.kickretstats = this.props.kickretstats;
        this.blockingstats = this.props.blockingstats;
        this.isLeaderboards = this.props.isLeaderboards;
        this.minFilter = 1;
        this.data = [];
        let bioCols = null;
        let sortable = false;
        this.initSort = null;
        this.colNum = this.isLeaderboards ? 0 : 2;

        this.onSubmit = this.onSubmit.bind(this);

        // Custom sort function for players page so Total stays at the bottom of the table.
        let sortFunc = (a, b, aRow, bRow, column, dir, sorterParams) => {
            var aData = aRow.getData();
            var bData = bRow.getData();
            if (aData.season === 'Total'){
                if (dir === 'desc'){
                    return -1;
                }
                else if (dir === 'asc'){
                    return 1
                }
            }
            else if (bData.season === 'Total'){
                if (dir === 'desc'){
                    return 1;
                }
                else if (dir === 'asc'){
                    return -1;
                }            
            }
            else {
                return a-b;
            }
        };

        // Set first group of columns
        // If this is a leaderboards page include player name, remove custom sorter and set initial sort to proper column.
        if (this.isLeaderboards) {
            sortable = true;
            bioCols = [
                {
                    title: "Player", field: "player", cssClass: "single-row-header textColumn",
                    width: `${rbfbSummary[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                    formatter: "link", formatterParams: {
                        labelField: "player",
                        urlPrefix: "/players/",
                        urlField: "playerId"
                    }
                },
                {
                    title: "Pos", field: "positionName", cssClass: "single-row-header player-position",
                    width: `${rbfbSummary[this.colNum++][this.isLeaderboards ? 0 : 1]}`
                },
                {
                    title: "Year", field: "season", cssClass: "single-row-header textColumn",
                    width: `${rbfbSummary[this.colNum++][this.isLeaderboards ? 0 : 1]}`
                },
                {
                    title: "Team", field: "team", cssClass: "single-row-header textColumn",
                    width: `${rbfbSummary[this.colNum++][this.isLeaderboards ? 0 : 1]}`
                },
                {
                    title: "G", field: "sumGames", cssClass: "single-row-header small-column",
                    width: `${rbfbSummary[this.colNum++][this.isLeaderboards ? 0 : 1]}`
                },
                {
                    title: "GS", field: "sumGamesStarted", cssClass: "single-row-header break small-column",
                    width: `${rbfbSummary[this.colNum++][this.isLeaderboards ? 0 : 1]}`
                }
            ];
            this.sorterFunc = null;
            this.initSort = [
                { column: "sumPointsEarned", dir: "desc" }
            ];
        }
        // Else don't include player name and set initial sort to season.
        else {
            bioCols = [
                {
                    title: "Year", field: "season", cssClass: "single-row-header textColumn",
                    width: `${rbfbSummary[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                    headerSort: sortable, sorter: sortFunc
                },
                {
                    title: "Team", field: "team", cssClass: "single-row-header textColumn",
                    width: `${rbfbSummary[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                    headerSort: sortable, sorter: sortFunc
                },
                {
                    title: "G", field: "sumGames", cssClass: "single-row-header small-column",
                    width: `${rbfbSummary[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                    headerSort: sortable, sorter: sortFunc
                },
                {
                    title: "GS", field: "sumGamesStarted", cssClass: "single-row-header break small-column",
                    width: `${rbfbSummary[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                    headerSort: sortable, sorter: sortFunc
                }
            ];
            this.initSort = [
                { column: "season", dir: "asc" }
            ];
        }
        // Set Remaining Columns
        this.cols = [
            {
                title: "",
                columns: bioCols
            },
            {
                title: "Rushing",
                columns: [
                    {
                        title: "Att", field: "rushAttempts", cssClass: "single-row-header small-column",
                        width: `${rbfbSummary[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                        headerSortStartingDir: "desc", headerSort: sortable, sorter: sortFunc
                    },
                    {
                        title: "Yds", field: "rushYds", cssClass: "single-row-header",
                        headerSortStartingDir: "desc", headerSort: sortable, sorter: sortFunc,
                        width: `${rbfbSummary[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                        formatter: "money", formatterParams: {
                            thousand: ',',
                            precision: 0
                        }
                    },
                    {
                        title: "Avg", field: "rushYdsPerAttempt", cssClass: "single-row-header",
                        headerSortStartingDir: "desc", headerSort: sortable, sorter: sortFunc,
                        width: `${rbfbSummary[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                        formatter: "money", formatterParams: {
                            precision: 1
                        }
                    },
                    {
                        title: "Lg", field: "rushLong", cssClass: "single-row-header small-column",
                        width: `${rbfbSummary[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                        headerSortStartingDir: "desc", headerSort: sortable, sorter: sortFunc
                    },
                    {
                        title: "TD", field: "rushTD", cssClass: "single-row-header small-column",
                        width: `${rbfbSummary[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                        headerSortStartingDir: "desc", headerSort: sortable, sorter: sortFunc
                    },
                    {
                        title: "Y/G", field: "rushYdsPerGame", cssClass: "single-row-header break",
                        headerSortStartingDir: "desc", headerSort: sortable, sorter: sortFunc,
                        width: `${rbfbSummary[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                        formatter: "money", formatterParams: {
                            precision: 1
                        }
                    },
                ],
            },
            {
                title: "Receiving",
                columns: [
                    {
                        title: "Rec", field: "receives", cssClass: "single-row-header small-column",
                        width: `${rbfbSummary[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                        headerSortStartingDir: "desc", headerSort: sortable, sorter: sortFunc
                    },
                    {
                        title: "Yds", field: "rcvYds", cssClass: "single-row-header",
                        width: `${rbfbSummary[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                        headerSortStartingDir: "desc", headerSort: sortable, sorter: sortFunc,
                        formatter: "money", formatterParams: {
                            thousand: ',',
                            precision: 0
                        }
                    },
                    {
                        title: "Avg", field: "rcvYdsPerRcv", cssClass: "single-row-header",
                        width: `${rbfbSummary[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                        headerSortStartingDir: "desc", headerSort: sortable, sorter: sortFunc,
                        formatter: "money", formatterParams: {
                            precision: 1
                        }
                    },
                    {
                        title: "Lg", field: "rcvLong", cssClass: "single-row-header small-column",
                        width: `${rbfbSummary[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                        headerSortStartingDir: "desc", headerSort: sortable, sorter: sortFunc
                    },
                    {
                        title: "TD", field: "rcvTD", cssClass: "single-row-header small-column",
                        width: `${rbfbSummary[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                        headerSortStartingDir: "desc", headerSort: sortable, sorter: sortFunc
                    },
                    {
                        title: "Y/G", field: "rcvYdsPerGame", cssClass: "single-row-header break",
                        width: `${rbfbSummary[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                        headerSortStartingDir: "desc", headerSort: sortable, sorter: sortFunc,
                        formatter: "money", formatterParams: {
                            precision: 1
                        }
                    }
                ],
            },
            {
                title: "Punt Returns",
                columns: [
                    {
                        title: "Num", field: "pntretAttempts", cssClass: "single-row-header small-column",
                        width: `${rbfbSummary[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                        headerSortStartingDir: "desc", headerSort: sortable, sorter: sortFunc
                    },
                    {
                        title: "Yds", field: "pntretYards", cssClass: "single-row-header",
                        width: `${rbfbSummary[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                        headerSortStartingDir: "desc", headerSort: sortable, sorter: sortFunc,
                        formatter: "money", formatterParams: {
                            thousand: ',',
                            precision: 0
                        }
                    },
                    {
                        title: "Avg", field: "pntretYdsPerAtt", cssClass: "single-row-header",
                        width: `${rbfbSummary[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                        headerSortStartingDir: "desc", headerSort: sortable, sorter: sortFunc,
                        formatter: "money", formatterParams: {
                            precision: 1
                        }
                    },
                    {
                        title: "TD", field: "pntretTD", cssClass: "single-row-header break small-column",
                        width: `${rbfbSummary[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                        headerSortStartingDir: "desc", headerSort: sortable, sorter: sortFunc
                    }
                ],
            },
            {
                title: "Kickoff Returns",
                columns: [
                    {
                        title: "Num", field: "kickretAttempts", cssClass: "single-row-header small-column",
                        width: `${rbfbSummary[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                        headerSortStartingDir: "desc", headerSort: sortable, sorter: sortFunc
                    },
                    {
                        title: "Yds", field: "kickretYards", cssClass: "single-row-header",
                        width: `${rbfbSummary[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                        headerSortStartingDir: "desc", headerSort: sortable, sorter: sortFunc,
                        formatter: "money", formatterParams: {
                            thousand: ',',
                            precision: 0
                        }
                    },
                    {
                        title: "Avg", field: "kickretYdsPerAtt", cssClass: "single-row-header",
                        width: `${rbfbSummary[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                        headerSortStartingDir: "desc", headerSort: sortable, sorter: sortFunc,
                        formatter: "money", formatterParams: {
                            precision: 1
                        }
                    },
                    {
                        title: "TD", field: "kickretTD", cssClass: "single-row-header break small-column",
                        width: `${rbfbSummary[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                        headerSortStartingDir: "desc", headerSort: sortable, sorter: sortFunc
                    },
                ],
            },
            {
                title: "Fumbles",
                columns: [
                    {
                        title: "Fum", field: "sumFumbles", cssClass: "single-row-header small-column",
                        width: `${rbfbSummary[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                        headerSortStartingDir: "desc", headerSort: sortable, sorter: sortFunc
                    },
                    {
                        title: "Lost", field: "sumFumblesLost", cssClass: "single-row-header break small-column",
                        width: `${rbfbSummary[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                        headerSortStartingDir: "desc", headerSort: sortable, sorter: sortFunc
                    }
                ],
            },
            {
                title: "Tot<br/>Points<br/>Earned", field: "sumPointsEarned", cssClass: "single-row-header",
                headerSortStartingDir: "desc", headerSort: sortable, sorter: sortFunc,
                width: `${rbfbSummary[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                formatter: function (cell, formatterParams, onRendered) {
                    return DispFunc(cell, formatterParams, onRendered, 0, false, false);
                }
            }
        ];
        
        this.el = React.createRef();
        this.tabulator = null;

    }

    // Consolidate the data into one data array to be used for tabulator(called on component mount)
    ConsolidateData() {
        // console.log("WRTE");
        let rushingstats = this.rushingstats;
        let receivingstats = this.receivingstats;
        let puntretstats = this.puntretstats;
        let kickretstats = this.kickretstats;
        let blockingstats = this.blockingstats; // only used for TE position
        let season = 0;
        let player = '';
        let playerId = 0;
        let positionName = '';
        let team = '';
        let sumGames = 0;
        let sumGamesStarted = 0;
        let rushAttempts = 0;
        let rushYds = 0;
        let rushYdsPerAttempt = 0;
        let rushLong = 0;
        let rushTD = 0;
        let rushYdsPerGame = 0;
        let receives = 0;
        let rcvYds = 0;
        let rcvYdsPerRcv = 0;
        let rcvLong = 0;
        let rcvTD = 0;
        let rcvYdsPerGame = 0;
        let pntretAttempts = 0;
        let pntretYards = 0;
        let pntretYdsPerAtt = 0;
        let pntretTD = 0;
        let kickretAttempts = 0;
        let kickretYards = 0;
        let kickretYdsPerAtt = 0;
        let kickretTD = 0;
        let sumFumbles = 0;
        let sumFumblesLost = 0;
        let sumPointsEarned = 0;
    
        var rowdata = [];
        var i = 0;
        if (receivingstats.length !==0) {
            for (i=0; i<receivingstats.length; i++) {
                if (!this.isLeaderboards || receivingstats[i].recs >= this.minFilter){
                    season = receivingstats[i].season;
                    player = receivingstats[i].player;
                    playerId = receivingstats[i].playerId;
                    positionName = receivingstats[i].position;
                    team = receivingstats[i].team;
                    sumGames =
                        receivingstats[i].starts > receivingstats[i].g ?
                            receivingstats[i].starts :
                            receivingstats[i].g;
                    sumGamesStarted = receivingstats[i].starts;
                    rushAttempts = 0;
                    rushYds = 0;
                    rushYdsPerAttempt = 0;
                    rushLong = 0;
                    rushTD = 0;
                    rushYdsPerGame = 0;
                    receives = receivingstats[i].recs;
                    rcvYds = receivingstats[i].yards;
                    rcvYdsPerRcv = receivingstats[i].yardsPerRec;
                    rcvYdsPerGame = receivingstats[i].yardsPerG;
                    rcvLong = receivingstats[i].long;
                    rcvTD = receivingstats[i].td;
                    pntretAttempts = 0;
                    pntretYards = 0;
                    pntretYdsPerAtt = 0;
                    pntretTD = 0;   
                    kickretAttempts = 0;
                    kickretYards = 0;
                    kickretYdsPerAtt = 0;
                    kickretTD = 0;
                    sumFumbles = receivingstats[i].fumbles;
                    sumFumblesLost = receivingstats[i].fumblesLost;
                    sumPointsEarned = Tst2015(receivingstats[i].season,receivingstats[i].eps);
                    // consolelogruben(receivingstats[i].playerId, 'Receiving EPS', receivingstats[i].eps);
                    // spe(receivingstats[i].playerId, 'SUMPOINTSEARNED =', sumPointsEarned);
                    rowdata.push({
                        season,
                        player,
                        playerId,
                        positionName,
                        team,
                        sumGames,
                        sumGamesStarted,
                        rushAttempts,
                        rushYds,
                        rushYdsPerAttempt,
                        rushLong,
                        rushTD,
                        rushYdsPerGame,
                        receives,
                        rcvYds,
                        rcvYdsPerRcv,
                        rcvLong,
                        rcvTD,
                        rcvYdsPerGame,
                        pntretAttempts,
                        pntretYards,
                        pntretYdsPerAtt,
                        pntretTD,
                        kickretAttempts,
                        kickretYards,
                        kickretYdsPerAtt,
                        kickretTD,
                        sumFumbles,
                        sumFumblesLost,
                        sumPointsEarned
                    });
                }
            }
        }
        let j = -1;
        if (rushingstats.length !==0) {
            for (i=0; i<rushingstats.length; i++) {
                for (j = rowdata.length - 1; j > -1; j--) {
                    if (rowdata[j].season === rushingstats[i].season && rowdata[j].team === rushingstats[i].team && rowdata[j].playerId === rushingstats[i].playerId) {
                        break;
                    }
                }
                if (j > -1){
                    rowdata[j].rushAttempts = rushingstats[i].att;
                    rowdata[j].rushYds = rushingstats[i].yards;
                    rowdata[j].rushYdsPerAttempt = rushingstats[i].yardsPerAtt;
                    rowdata[j].rushLong = rushingstats[i].long;
                    rowdata[j].rushTD = rushingstats[i].td;
                    rowdata[j].rushYdsPerGame = rushingstats[i].yardsPerG;
                    rowdata[j].sumFumbles = NullSum(rowdata[j].sumFumbles,rushingstats[i].fumbles);
                    rowdata[j].sumFumblesLost = NullSum(rowdata[j].sumFumblesLost,rushingstats[i].fumblesLost);
                    rowdata[j].sumPointsEarned = NullBypassSum(rowdata[j].sumPointsEarned,Tst2015(rushingstats[i].season,rushingstats[i].eps));
                    // consolelogruben(rushingstats[i].playerId, 'Rushing EPS', rushingstats[i].eps);
                    // spe(rushingstats[i].playerId, 'SUMPOINTSEARNED =', rowdata[j].sumPointsEarned);
                }
                else if (!this.isLeaderboards){
                    season = rushingstats[i].season;
                    team = rushingstats[i].team;
                    player = rushingstats[i].player;
                    playerId = rushingstats[i].playerId;
                    positionName = rushingstats[i].positionName;
                    sumGames = rushingstats[i].starts > rushingstats[i].g ?
                        rushingstats[i].starts :
                        rushingstats[i].g;
                                        sumGamesStarted = rushingstats[i].starts;
                    rushAttempts = rushingstats[i].att;
                    rushYds = rushingstats[i].yards;
                    rushYdsPerAttempt = rushingstats[i].yardsPerAtt;
                    rushLong = rushingstats[i].long;
                    rushTD = rushingstats[i].td;
                    rushYdsPerGame = rushingstats[i].yardsPerG;
                    receives = 0;
                    rcvYds = 0;
                    rcvYdsPerRcv = 0;
                    rcvLong = 0;
                    rcvTD = 0;
                    rcvYdsPerGame = 0;
                    pntretAttempts = 0;
                    pntretYards = 0;
                    pntretYdsPerAtt = 0;
                    pntretTD = 0;
                    kickretAttempts = 0;
                    kickretYards = 0;
                    kickretYdsPerAtt = 0;
                    kickretTD = 0;
                    sumFumbles = rushingstats[i].fumbles;
                    sumFumblesLost = rushingstats[i].fumblesLost;
                    sumPointsEarned = Tst2015(rushingstats[i].season,rushingstats[i].eps);
                    // consolelogruben(rushingstats[i].playerId, 'Rushing EPS', rushingstats[i].eps);
                    // spe(rushingstats[i].playerId, 'SUMPOINTSEARNED =', sumPointsEarned);
                    rowdata.push({
                        season,
                        player,
                        playerId,
                        positionName,
                        team,
                        sumGames,
                        sumGamesStarted,
                        rushAttempts,
                        rushYds,
                        rushYdsPerAttempt,
                        rushLong,
                        rushTD,
                        rushYdsPerGame,
                        receives,
                        rcvYds,
                        rcvYdsPerRcv,
                        rcvLong,
                        rcvTD,
                        rcvYdsPerGame,
                        pntretAttempts,
                        pntretYards,
                        pntretYdsPerAtt,
                        pntretTD,
                        kickretAttempts,
                        kickretYards,
                        kickretYdsPerAtt,
                        kickretTD,
                        sumFumbles,
                        sumFumblesLost,
                        sumPointsEarned
                    });
                }
            }
        }
        if (puntretstats.length !==0) {
            for (i=0; i<puntretstats.length; i++) {
                for (j = rowdata.length - 1; j > -1; j--) {
                    if (rowdata[j].season === puntretstats[i].season && rowdata[j].team === puntretstats[i].teamName && rowdata[j].playerId === puntretstats[i].playerId) {
                        break;
                    }
                }
                if (j > -1) {
                    rowdata[j].pntretAttempts = puntretstats[i].attempts;
                    rowdata[j].pntretYards = puntretstats[i].yards;
                    rowdata[j].pntretYdsPerAtt = puntretstats[i].attempts ? puntretstats[i].yards/puntretstats[i].attempts : 0;
                    rowdata[j].pntretTD = puntretstats[i].tDs;
                    rowdata[j].sumTDs = NullSum(rowdata[j].sumTDs,puntretstats[i].tDs);
                    rowdata[j].sumFumbles = NullSum(rowdata[j].sumFumbles,puntretstats[i].fumbles);
                    rowdata[j].sumFumblesLost = NullSum(rowdata[j].sumFumblesLost,puntretstats[i].fumblesLost);
//                    rowdata[j].sumPointsEarned = NullBypassSum(rowdata[j].sumPointsEarned,Tst2015(puntretstats[i].season, puntretstats[i].eps));   // no eps field available on putret
                } else if(!this.isLeaderboards) {
                    season = puntretstats[i].season;
                    player = puntretstats[i].player;
                    playerId = puntretstats[i].playerId;
                    team = puntretstats[i].teamName;
                    sumGames = puntretstats[i].games;
                    sumGamesStarted = 0;
                    rushAttempts = 0;
                    rushYds = 0;
                    rushYdsPerAttempt = 0;
                    rushLong = 0;
                    rushTD = 0;
                    rushYdsPerGame = 0;
                    receives = 0;
                    rcvYds = 0;
                    rcvYdsPerRcv = 0;
                    rcvLong = 0;
                    rcvTD = 0;
                    rcvYdsPerGame = 0;
                    pntretAttempts = puntretstats[i].attempts;
                    pntretYards = puntretstats[i].yards;
                    pntretYdsPerAtt = NullDiv(puntretstats[i].yards,puntretstats[i].attempts);
                    pntretTD = puntretstats[i].tDs;
                    kickretAttempts = 0;
                    kickretYards = 0;
                    kickretYdsPerAtt = 0;
                    kickretTD = 0;
                    sumFumbles = puntretstats[i].fumbles;
                    sumFumblesLost = puntretstats[i].fumblesLost;
//                    sumPointsEarned = Tst2015(puntretstats[i].season, puntretstats[i].eps);
                    rowdata.push({
                        season,
                        player,
                        playerId,
                        team,
                        sumGames,
                        sumGamesStarted,
                        rushAttempts,
                        rushYds,
                        rushYdsPerAttempt,
                        rushLong,
                        rushTD,
                        rushYdsPerGame,
                        receives,
                        rcvYds,
                        rcvYdsPerRcv,
                        rcvLong,
                        rcvTD,
                        rcvYdsPerGame,
                        pntretAttempts,
                        pntretYards,
                        pntretYdsPerAtt,
                        pntretTD,
                        kickretAttempts,
                        kickretYards,
                        kickretYdsPerAtt,
                        kickretTD,
                        sumFumbles,
                        sumFumblesLost,
                        sumPointsEarned
                    });
                }
            }
        }
        if (kickretstats.length !==0) {
            for (i=0; i<kickretstats.length; i++) {
                for (j = rowdata.length - 1; j > -1; j--) {
                    if (rowdata[j].season === kickretstats[i].season && rowdata[j].team === kickretstats[i].teamName && rowdata[j].playerId === kickretstats[i].playerId) {
                        break;
                    }
                }
                if (j > -1) {
                    rowdata[j].kickretAttempts = kickretstats[i].attempts;
                    rowdata[j].kickretYards = kickretstats[i].yards;
                    rowdata[j].kickretYdsPerAtt = NullDiv(kickretstats[i].yards, kickretstats[i].attempts);
                    rowdata[j].kickretTD = kickretstats[i].tDs;
                    rowdata[j].sumTDs = NullSum(rowdata[j].sumTDs,kickretstats[i].tDs);
                    rowdata[j].sumFumbles = NullSum(rowdata[j].sumFumbles,kickretstats[i].fumbles);
                    rowdata[j].sumFumblesLost = NullSum(rowdata[j].sumFumblesLost,kickretstats[i].fumblesLost);
// no such field                   rowdata[j].sumPointsEarned = NullBypassSum(rowdata[j].sumPointsEarned, Tst2015(kickretstats[i].season, kickretstats[i].eps));
                } else if(!this.isLeaderboards) {
                    season = kickretstats[i].season;
                    player = kickretstats[i].player;
                    playerId = kickretstats[i].playerId;
                    team = kickretstats[i].teamName;
                    sumGames = kickretstats[i].games;
                    sumGamesStarted = 0;
                    rushAttempts = 0;
                    rushYds = 0;
                    rushYdsPerAttempt = 0;
                    rushLong = 0;
                    rushTD = 0;
                    rushYdsPerGame = 0;
                    receives = 0;
                    rcvYds = 0;
                    rcvYdsPerRcv = 0;
                    rcvLong = 0;
                    rcvTD = 0;
                    rcvYdsPerGame = 0;
                    pntretAttempts = 0;
                    pntretYards = 0;
                    pntretYdsPerAtt = 0;
                    pntretTD = 0;
                    kickretAttempts = kickretstats[i].attempts;
                    kickretYards = kickretstats[i].yards;
                    kickretYdsPerAtt = NullDiv(kickretYards, kickretAttempts);
                    kickretTD = kickretstats[i].tDs;
                    sumFumbles = kickretstats[i].fumbles;
                    sumFumblesLost = kickretstats[i].fumblesLost;
// no such field                    sumPointsEarned = Tst2015(kickretstats[i].season, kickretstats[i].eps);
                    rowdata.push({
                        season,
                        player,
                        playerId,
                        team,
                        sumGames,
                        sumGamesStarted,
                        rushAttempts,
                        rushYds,
                        rushYdsPerAttempt,
                        rushLong,
                        rushTD,
                        rushYdsPerGame,
                        receives,
                        rcvYds,
                        rcvYdsPerRcv,
                        rcvLong,
                        rcvTD,
                        rcvYdsPerGame,
                        pntretAttempts,
                        pntretYards,
                        pntretYdsPerAtt,
                        pntretTD,
                        kickretAttempts,
                        kickretYards,
                        kickretYdsPerAtt,
                        kickretTD,
                        sumFumbles,
                        sumFumblesLost,
                        sumPointsEarned
                    });
                }
            }
        }
        if (blockingstats.length !==0) {
            for (i=0; i<blockingstats.length; i++) {
                for (j = rowdata.length - 1; j > -1; j--) {
                    if (rowdata[j].season === blockingstats[i].season && rowdata[j].team === blockingstats[i].team && rowdata[j].playerId === blockingstats[i].playerId) {
                        break;
                    }
                }
                if (j > -1) {
//                    console.log("rowdata[j].sumPointsEarned before:", rowdata[j].sumPointsEarned);
                    rowdata[j].sumPointsEarned =
                        NullBypassSum(rowdata[j].sumPointsEarned, Tst2015(blockingstats[i].season, blockingstats[i].passBlockEPS));
                    rowdata[j].sumPointsEarned =
                        NullBypassSum(rowdata[j].sumPointsEarned, Tst2015(blockingstats[i].season, blockingstats[i].runBlockEPS));
//                    console.log("rowdata[j].sumPointsEarned after:", rowdata[j].sumPointsEarned);
                        // consolelogruben(blockingstats[i].playerId, 'PassBlock EPS', blockingstats[i].passBlockEPS);
                        // consolelogruben(blockingstats[i].playerId, 'RunBlock EPS', blockingstats[i].runBlockEPS);
                        // spe(blockingstats[i].playerId, 'SUMPOINTSEARNED =', rowdata[j].sumPointsEarned);
                } else if(!this.isLeaderboards) {
                    season = blockingstats[i].season;
                    player = blockingstats[i].player;
                    playerId = blockingstats[i].playerId;
                    team = blockingstats[i].team;
                    sumGames = blockingstats[i].starts > blockingstats[i].games ?
                        blockingstats[i].starts :
                        blockingstats[i].games;
                    sumGamesStarted = blockingstats[i].starts;
                    rushAttempts = 0;
                    rushYds = 0;
                    rushYdsPerAttempt = 0;
                    rushLong = 0;
                    rushTD = 0;
                    rushYdsPerGame = 0;
                    receives = 0;
                    rcvYds = 0;
                    rcvYdsPerRcv = 0;
                    rcvLong = 0;
                    rcvTD = 0;
                    rcvYdsPerGame = 0;
                    pntretAttempts = 0;
                    pntretYards = 0;
                    pntretYdsPerAtt = 0;
                    pntretTD = 0;
                    kickretAttempts = 0;
                    kickretYards = 0;
                    kickretYdsPerAtt = 0;
                    kickretTD = 0;
                    sumFumbles = 0;
                    sumFumblesLost = 0;
                    sumPointsEarned =
                        NullBypassSum(
                            Tst2015(blockingstats[i].season, blockingstats[i].passBlockEPS),
                            Tst2015(blockingstats[i].season, blockingstats[i].runBlockEPS)
                        );
//                    console.log("sumPointsEarned new:", sumPointsEarned);
                    // consolelogruben(blockingstats[i].playerId, 'PassBlock EPS', blockingstats[i].passBlockEPS);
                    // consolelogruben(blockingstats[i].playerId, 'RunBlock EPS', blockingstats[i].runBlockEPS);
                    // spe(blockingstats[i].playerId, 'SUMPOINTSEARNED =', sumPointsEarned);
                    rowdata.push({
                        season,
                        player,
                        playerId,
                        team,
                        sumGames,
                        sumGamesStarted,
                        rushAttempts,
                        rushYds,
                        rushYdsPerAttempt,
                        rushLong,
                        rushTD,
                        rushYdsPerGame,
                        receives,
                        rcvYds,
                        rcvYdsPerRcv,
                        rcvLong,
                        rcvTD,
                        rcvYdsPerGame,
                        pntretAttempts,
                        pntretYards,
                        pntretYdsPerAtt,
                        pntretTD,
                        kickretAttempts,
                        kickretYards,
                        kickretYdsPerAtt,
                        kickretTD,
                        sumFumbles,
                        sumFumblesLost,
                        sumPointsEarned
                    });
                }
            }
        }
        this.data = rowdata;
    }

    // Add totals row to data.(called on component mount if this is the players page.)
    SumData() {
        let season = 'Total';
        let team = '';
        let sumGames = 0;
        let sumGamesStarted = 0;
        let rushAttempts = 0;
        let rushYds = 0;
        let rushYdsPerAttempt = 0;
        let rushLong = 0;
        let rushTD = 0;
        let rushYdsPerGame = 0;
        let receives = 0;
        let rcvYds = 0;
        let rcvYdsPerRcv = 0;
        let rcvLong = 0;
        let rcvTD = 0;
        let rcvYdsPerGame = 0;
        let pntretAttempts = 0;
        let pntretYards = 0;
        let pntretYdsPerAtt = 0;
        let pntretTD = 0;
        let kickretAttempts = 0;
        let kickretYards = 0;
        let kickretYdsPerAtt = 0;
        let kickretTD = 0;
        let sumFumbles = 0;
        let sumFumblesLost = 0;
        let sumPointsEarned = 0;
        if (this.data.length !== 0){
            for (var i = 0; i < this.data.length; i++) {
                sumGames = NullSum(sumGames,this.data[i].sumGames);
                sumGamesStarted = NullSum(sumGamesStarted,this.data[i].sumGamesStarted);
                rushAttempts = NullSum(rushAttempts,this.data[i].rushAttempts);
                rushLong = NullMax(rushLong,this.data[i].rushLong);
                rushTD = NullSum(rushTD,this.data[i].rushTD);
                rushYds = NullSum(rushYds,this.data[i].rushYds);
                receives = NullSum(receives,this.data[i].receives);
                rcvYds = NullSum(rcvYds,this.data[i].rcvYds);
                rcvLong = NullMax(rcvLong,this.data[i].rcvLong);
                rcvTD = NullSum(rcvTD,this.data[i].rcvTD);
                pntretAttempts = NullSum(pntretAttempts,this.data[i].pntretAttempts);
                pntretYards = NullSum(pntretYards,this.data[i].pntretYards);
                pntretTD = NullSum(pntretTD,this.data[i].pntretTD);
                kickretAttempts = NullSum(kickretAttempts,this.data[i].kickretAttempts);
                kickretYards = NullSum(kickretYards, this.data[i].kickretYards);
                kickretTD = NullSum(kickretTD, this.data[i].kickretTD);
                sumFumbles = NullSum(sumFumbles,this.data[i].sumFumbles);
                sumFumblesLost = NullSum(sumFumblesLost,this.data[i].sumFumblesLost);
                sumPointsEarned = NullBypassSum(sumPointsEarned,Tst2015(this.data[i].season,this.data[i].sumPointsEarned));
            }
            rushYdsPerAttempt = NullDiv(rushYds,rushAttempts);
            rushYdsPerGame = NullDiv(rushYds,sumGames);
            rcvYdsPerRcv = NullDiv(rcvYds,receives);
            rcvYdsPerGame = NullDiv(rcvYds,sumGames);
            kickretYdsPerAtt = NullDiv(kickretYards,kickretAttempts);
            pntretYdsPerAtt = NullDiv(pntretYards,pntretAttempts);
            this.data.push({
                season,
                team,
                sumGames,
                sumGamesStarted,
                rushAttempts,
                rushYds,
                rushYdsPerAttempt,
                rushLong,
                rushTD,
                rushYdsPerGame,
                receives,
                rcvYds,
                rcvYdsPerRcv,
                rcvLong,
                rcvTD,
                rcvYdsPerGame,
                pntretAttempts,
                pntretYards,
                pntretYdsPerAtt,
                pntretTD,
                kickretAttempts,
                kickretYards,
                kickretYdsPerAtt,
                kickretTD,
                sumFumbles,
                sumFumblesLost,
                sumPointsEarned
            });
        }
    }
    
    componentDidMount() {
        this.ConsolidateData();
        let pagination = 'local';
        let paginationSize = 50;
        if (!this.isLeaderboards){
            this.SumData();
            pagination = null;
            paginationSize = 0;
        }
        this.tabulator = new Tabulator(this.el, {
            data: this.data,
            reactiveData: true,
            columns: this.cols,
            layout: "fitColumns",  // added by Ruben 7/25/19
            rowFormatter:function(row){
                var data = row.getData();
                if (data.season === 'Total'){
                    row.getElement().style.borderTop = "2px solid #6f88a8";
                    row.getElement().style.borderBottom = "2px solid #6f88a8";
                }
            },
            pagination: pagination,
            paginationSize: paginationSize,
            initialSort: this.initSort,
            resizableColumns: false,
            selectable: false
        });
    }

    componentDidUpdate() {
        this.tabulator.replaceData(this.data);
    }

    onSubmit(e, minFilter){
        this.minFilter = minFilter;
        this.ConsolidateData();
        this.forceUpdate();
    }

    render() {
        return (
            <React.Fragment>
                {
                    this.isLeaderboards &&
                    <div className="shadowdiv" id="filtersDiv">
                        <Filters minFilter={this.minFilter} onSubmit={this.onSubmit} label={"Receptions"} />
                        <div ref={el => (this.el = el)} />
                    </div>
                }
                {
                    !this.isLeaderboards &&
                    <div className="shadowdiv">
                        <div id="table-title">Summary</div>
                        <div ref={el => (this.el = el)} />
                    </div>
                }
            </React.Fragment>
        );
    }
}

// function consolelogruben(playerId, text, value) {
//     //console.log('playerid =', playerId)
//     if (playerId === 4866) {
//         console.log(playerId, text, value)
//     }
// }

// function spe(playerId, text, value) {
//     if (playerId === 4866) {
//         console.log(text, value);
//     }
// }

export default TableWRTESummaryTabulator;