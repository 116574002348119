import React from 'react';
import {NullSum, NullBypassSum, NullDiv, DispFunc, Tst2015} from '../common/CommonFunctions';
import { advDefense } from '../common/TableColumnWidths';
import Tabulator from 'tabulator-tables';
import "tabulator-tables/dist/css/tabulator.min.css";
import Filters from '../common/Filters';

class TableQBSummaryTabulator extends React.Component {
    constructor(props) {
        super(props);
        this.defensestats = this.props.defensestats;
        this.isLeaderboards = this.props.isLeaderboards;
        this.minFilter = 1;
        this.data = [];
        let bioCols = null;
        let sortable = false;
        this.initSort = null;
        this.colNum = this.isLeaderboards ? 0 : 2;

        this.onSubmit = this.onSubmit.bind(this);

        // Custom sort function for players page so Total stays at the bottom of the table.
        let sortFunc = (a, b, aRow, bRow, column, dir, sorterParams) => {
            var aData = aRow.getData();
            var bData = bRow.getData();
            if (aData.season === 'Total'){
                if (dir === 'desc'){
                    return -1;
                }
                else if (dir === 'asc'){
                    return 1
                }
            }
            else if (bData.season === 'Total'){
                if (dir === 'desc'){
                    return 1;
                }
                else if (dir === 'asc'){
                    return -1;
                }            
            }
            else {
                return a-b;
            }
        };

        // Set first group of columns
        // If this is a leaderboards page include player name, remove custom sorter and set initial sort to proper column.
        if (this.isLeaderboards){
            sortable = true;
            bioCols = [
                {
                    title: "Player", field: "player", cssClass: "single-row-header textColumn",
                    width: `${advDefense[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                    formatter: "link", formatterParams:{
                        labelField: "player",
                        urlPrefix:"/players/",
                        urlField: "playerId"
                    }
                },
                {
                    title: "Pos", field: "positionName", cssClass: "single-row-header textColumn",
                    width: `${advDefense[this.colNum++][this.isLeaderboards ? 0 : 1]}`
                },
                {
                    title: "Year", field: "season", cssClass: "single-row-header textColumn",
                    width: `${advDefense[this.colNum++][this.isLeaderboards ? 0 : 1]}`
                },
                {
                    title: "Team", field: "team", cssClass: "single-row-header textColumn break",
                    width: `${advDefense[this.colNum++][this.isLeaderboards ? 0 : 1]}`
                }
            ];
            this.sorterFunc = null;
            this.initSort = [
                { column: "tackles", dir: "desc" }
            ];
        }
        // Else don't include player name and set initial sort to season.
        else{
            bioCols = [
                {
                    title: "Year", field: "season", cssClass: "single-row-header textColumn",
                    width: `${advDefense[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                    headerSort: sortable, sorter: sortFunc
                },
                {
                    title: "Team", field: "team",  cssClass: "single-row-header textColumn break",
                    width: `${advDefense[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                    headerSort: sortable, sorter: sortFunc
                }
            ];
            this.initSort = [
                { column: "season", dir: "asc" }
            ];
        }
        // Set Remaining Columns
        this.cols = [
            {
                title: "",
                columns: bioCols
            },
            {
                title: "Snaps",
                columns:[
                    {
                        title: "Pass", field: "passSnaps", cssClass: "single-row-header",
                        headerSortStartingDir: "desc", headerSort: sortable, sorter: sortFunc,
                        width: `${advDefense[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                        formatter: function(cell, formatterParams, onRendered){
                            return DispFunc(cell, formatterParams, onRendered, 0, true, false);
                        }
                    },
                    {
                        title: "Run", field: "rushSnaps", cssClass: "single-row-header break",
                        headerSortStartingDir: "desc", headerSort: sortable, sorter: sortFunc,
                        width: `${advDefense[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                        formatter: function(cell, formatterParams, onRendered){
                            return DispFunc(cell, formatterParams, onRendered, 0, false, false);
                        }
                    }
                ],
            },
            {
                title: "Deserved Catch%", field: "deservedCatchPct", cssClass: "single-row-header break",
                headerSortStartingDir: "desc", headerSort: sortable, sorter: sortFunc,
                titleFormatter:"textarea",
                width: `${advDefense[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                formatter: function(cell, formatterParams, onRendered){
                    return DispFunc(cell, formatterParams, onRendered, 1, false, false);
                }
            },
            {
                title: "Pass Breakups",
                columns:[
                    {
                        title: "Defensed", field: "defensed", cssClass: "single-row-header",
                        headerSortStartingDir: "desc", headerSort: sortable, sorter: sortFunc,
                        width: `${advDefense[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                        formatter: function(cell, formatterParams, onRendered){
                            return DispFunc(cell, formatterParams, onRendered, 0, false, false);
                        }
                    },
                    {
                        title: "Deflected", field: "deflected", cssClass: "single-row-header",
                        headerSortStartingDir: "desc", headerSort: sortable, sorter: sortFunc,
                        width: `${advDefense[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                        formatter: function(cell, formatterParams, onRendered){
                            return DispFunc(cell, formatterParams, onRendered, 0, false, false);
                        }
                    },
                    {
                        title: "Batted", field: "batDowns", cssClass: "single-row-header",
                        headerSortStartingDir: "desc", headerSort: sortable, sorter: sortFunc,
                        width: `${advDefense[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                        formatter: function(cell, formatterParams, onRendered){
                            return DispFunc(cell, formatterParams, onRendered, 0, false, false);
                        }
                    },
                    {
                        title: "Total", field: "breakups", cssClass: "single-row-header break",
                        headerSortStartingDir: "desc", headerSort: sortable, sorter: sortFunc,
                        width: `${advDefense[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                        formatter: function(cell, formatterParams, onRendered){
                            return DispFunc(cell, formatterParams, onRendered, 0, false, false);
                        }
                    }        
                ],
            },
            {
                title: "Tackling (excluding special teams)",
                columns:[
                    {
                        title: "Total", field: "tackles", cssClass: "single-row-header",
                        headerSortStartingDir: "desc", headerSort: sortable, sorter: sortFunc,
                        width: `${advDefense[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                        formatter: function(cell, formatterParams, onRendered){
                            return DispFunc(cell, formatterParams, onRendered, 0, false, false);
                        }
                    },
                    {
                        title: "Solo", field: "soloTackles", cssClass: "single-row-header",
                        headerSortStartingDir: "desc", headerSort: sortable, sorter: sortFunc,
                        width: `${advDefense[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                        formatter: function(cell, formatterParams, onRendered){
                            return DispFunc(cell, formatterParams, onRendered, 0, false, false);
                        }
                    },
                    {
                        title: "Ast", field: "assistedTackles", cssClass: "single-row-header",
                        headerSortStartingDir: "desc", headerSort: sortable, sorter: sortFunc,
                        width: `${advDefense[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                        formatter: function(cell, formatterParams, onRendered){
                            return DispFunc(cell, formatterParams, onRendered, 0, false, false);
                        }
                    },
                    {
                        title: "TFL", field: "tackleForLoss", cssClass: "single-row-header",
                        headerSortStartingDir: "desc", headerSort: sortable, sorter: sortFunc,
                        width: `${advDefense[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                        formatter: function(cell, formatterParams, onRendered){
                            return DispFunc(cell, formatterParams, onRendered, 0, false, false);
                        }
                    },
                    {
                        title: "Avg Tackle Depth", field: "avgTackleDepth", cssClass: "break",
                        headerSortStartingDir: "desc", headerSort: sortable, sorter: sortFunc,
                        titleFormatter:"textarea",
                        width: `${advDefense[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                        formatter: function(cell, formatterParams, onRendered){
                            return DispFunc(cell, formatterParams, onRendered, 1, false, false);
                        }
                    },
                    {
                        title: "Broken + Missed", field: "brokenTackles", titleFormatter:"textarea",
                        headerSortStartingDir: "desc", headerSort: sortable, sorter: sortFunc,
                        width: `${advDefense[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                        formatter: function(cell, formatterParams, onRendered){
                            return DispFunc(cell, formatterParams, onRendered, 0, false, false);
                        }
                    },
                    {
                        title: "Broken + Missed%", field: "brokenTacklePct", titleFormatter:"textarea",
                        headerSortStartingDir: "desc", headerSort: sortable, sorter: sortFunc,
                        width: `${advDefense[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                        formatter: function(cell, formatterParams, onRendered){
                            return DispFunc(cell, formatterParams, onRendered, 1, false, false);
                        }
                    }
                ],
            }
        ];
        
        this.el = React.createRef();
        this.tabulator = null;

    }

    // Consolidate the data into one data array to be used for tabulator(called on component mount)
    ConsolidateData(){
        let data = [];
        let player = '';
        let playerId = 0;
        let positionName = '';
        let season = 0;
        let team = '';
        let passSnaps = 0;
        let rushSnaps = 0;
        let deservedCatchPct = 0;
        let defensed = 0;
        let deflected = 0;
        let batDowns = 0;
        let breakups = 0;
        let tackles = 0;
        let soloTackles = 0;
        let assistedTackles = 0;
        let tackleForLoss = 0;
        let avgTackleDepth = 0;
        let brokenTackles = 0;
        let brokenTacklePct = 0;
        if (this.defensestats.length !== 0){
            for (var i = 0; i < this.defensestats.length; i++){
                if ((this.defensestats[i].havepassdefense || this.defensestats[i].haverushdefense) 
                        && (!this.isLeaderboards ||
                        NullBypassSum(this.defensestats[i].tackles, 0) >= this.minFilter)) {
//                        NullBypassSum(this.defensestats[i].passSnaps, this.defensestats[i].rushSnaps) >= this.minFilter)) {
                    player = this.defensestats[i].player;
                    playerId = this.defensestats[i].playeridpr || this.defensestats[i].playeridrd || this.defensestats[i].playeridpd || this.defensestats[i].playeriddf;
                    positionName = this.defensestats[i].positionName;
                    season = this.defensestats[i].season;
                    team = this.defensestats[i].team;
                    passSnaps = Tst2015(this.defensestats[i].season,this.defensestats[i].passSnaps);
                    rushSnaps = this.defensestats[i].rushSnaps;
                    deservedCatchPct = this.defensestats[i].season === 2015 ? null :
                        this.defensestats[i].deservedCatchPct * 100;
                    defensed = this.defensestats[i].defensed;
                    deflected = this.defensestats[i].deflected;
                    batDowns = this.defensestats[i].batDowns;
                    breakups = this.defensestats[i].breakups;
                    tackles = this.defensestats[i].tackles;
                    soloTackles = this.defensestats[i].soloTackles;
                    assistedTackles = this.defensestats[i].assistedTackles;
                    tackleForLoss = this.defensestats[i].tackleForLoss;
//                    avgTackleDepth = NullDiv(NullSum(this.defensestats[i].passYards, this.defensestats[i].rushYards), this.defensestats[i].tackles);  // replaced with line below
                    avgTackleDepth = NullDiv(this.defensestats[i].rushYards, this.defensestats[i].tackles); // 10-20-21 Ruben Agosto: removed passYards from calculation per Alex V.
                    brokenTackles = this.defensestats[i].brokenTackles;
                    brokenTacklePct = NullDiv(this.defensestats[i].brokenTackles,NullSum(this.defensestats[i].brokenTackles,this.defensestats[i].tackles))*100;
    
                    data.push(
                        {
                            player,
                            playerId,
                            positionName,
                            season,
                            team,
                            passSnaps,
                            rushSnaps,
                            deservedCatchPct,
                            defensed,
                            deflected,
                            batDowns,
                            breakups,
                            tackles,
                            soloTackles,
                            assistedTackles,
                            tackleForLoss,
                            avgTackleDepth,
                            brokenTackles,
                            brokenTacklePct
                        }
                    );
                }
            }
            this.data = data;
 //           console.log("this.data after details:", this.data);
        }
    }

    // Add totals row to data.(called on component mount if this is the players page.)
    SumData() {
        let season = 'Total';
        let team = '';
//        let playerId = '';
//        let player = '';
//        let positionName = '';
        let passSnaps = 0;
        let rushSnaps = 0;
        let rushYards = 0; // not displayed. only used in a calculation
        let passYards = 0; // not displayed. only used in a calculation
        let deservedCatchPct = 0;
        let defensed = 0;
        let deflected = 0;
        let batDowns = 0;
        let breakups = 0;
        // let rushTackles = 0; // not displayed. only used in a calculation
        let soloTackles = 0;
        let tackles = 0;
        let assistedTackles = 0;
        let tackleForLoss = 0;
        let avgTackleDepth = 0;
        let brokenTackles = 0;
        let brokenTacklePct = 0;
        let catchableTgts = 0;  // not displayed. only used in a calculation
        let completions = 0;  // not displayed. only used in a calculation
        let drops = 0;  // not displayed. only used in a calculation
        let totCatchable = 0;  // not displayed. only used in a calculation
        if (this.defensestats.length !== 0){
            for (var i=0; i<this.defensestats.length; i++) {
                passSnaps = NullBypassSum(passSnaps,this.defensestats[i].passSnaps);
                rushSnaps = NullBypassSum(rushSnaps,this.defensestats[i].rushSnaps);
                defensed = NullBypassSum(defensed,this.defensestats[i].defensed);
                deflected = NullBypassSum(deflected,this.defensestats[i].deflected);
                batDowns = NullBypassSum(batDowns,this.defensestats[i].batDowns);
                breakups = NullBypassSum(breakups,this.defensestats[i].breakups);
                tackles = NullBypassSum(tackles,this.defensestats[i].tackles);
                soloTackles = NullBypassSum(soloTackles,this.defensestats[i].soloTackles);
                assistedTackles = NullBypassSum(assistedTackles,this.defensestats[i].assistedTackles);
                tackleForLoss = NullBypassSum(tackleForLoss,this.defensestats[i].tackleForLoss);
                brokenTackles = NullBypassSum(brokenTackles,this.defensestats[i].brokenTackles);
                rushYards = NullBypassSum(rushYards,this.defensestats[i].rushYards);
                passYards = NullBypassSum(passYards, this.defensestats[i].passYards);
                if (this.defensestats[i].season > 2015) {
                    catchableTgts = NullBypassSum(catchableTgts, this.defensestats[i].catchableTgts);
                    completions = NullBypassSum(completions, this.defensestats[i].completions);
                    drops = NullBypassSum(drops, this.defensestats[i].drops);
                    totCatchable = NullBypassSum(totCatchable, this.defensestats[i].totCatchable);
                }
            }
            deservedCatchPct = NullDiv(NullSum(completions,drops),NullSum(catchableTgts,totCatchable))*100;
//            avgTackleDepth = NullDiv(NullSum(passYards, rushYards), tackles); // replaced calculation with line below
            avgTackleDepth = NullDiv(rushYards, tackles); // 10-20-21 Ruben Agosto: removed passYards from calculation per Alex V.
            brokenTacklePct = NullDiv(brokenTackles,NullSum(brokenTackles,tackles))*100;

            this.data.push(
                {
                    season,
                    team,
                    passSnaps,
                    rushSnaps,
                    deservedCatchPct,
                    defensed,
                    deflected,
                    batDowns,
                    breakups,
                    tackles,
                    soloTackles,
                    assistedTackles,
                    tackleForLoss,
                    avgTackleDepth,
                    brokenTackles,
                    brokenTacklePct
                }
            );
 //           console.log("this.data after summary:", this.data);
        }
    }
    
    componentDidMount() {
        this.ConsolidateData();
        let pagination = 'local';
        let paginationSize = 50;
        if (!this.isLeaderboards){
            this.SumData();
            pagination = null;
            paginationSize = 0;
        }
        this.tabulator = new Tabulator(this.el, {
            data: this.data,
            reactiveData: true,
            columns: this.cols,
            layout: "fitColumns",  // added by Ruben 7/25/19
            rowFormatter:function(row){
                var data = row.getData();
                if (data.season === 'Total'){
                    row.getElement().style.borderTop = "2px solid #6f88a8";
                    row.getElement().style.borderBottom = "2px solid #6f88a8";
                }
            },
            pagination: pagination,
            paginationSize: paginationSize,
            initialSort: this.initSort,
            resizableColumns: false,
            selectable: false
        });
    }

    componentDidUpdate() {
        this.tabulator.replaceData(this.data);
    }

    onSubmit(e, minFilter){
        this.minFilter = minFilter;
        this.ConsolidateData();
        this.forceUpdate();
    }

    render() {
        return (
            <React.Fragment>
                {
                    this.isLeaderboards &&
                    <div className="shadowdiv" id="filtersDiv">
                        <Filters minFilter={this.minFilter} onSubmit={this.onSubmit} label={"Tackles"} />
                        <div ref={el => (this.el = el)} />
                    </div>
                }
                {
                    !this.isLeaderboards &&
                    <div className="shadowdiv">
                        <div id="table-title">Advanced Defense</div>
                        <div ref={el => (this.el = el)} />
                    </div>
                }
            </React.Fragment>
        );
    }
}

export default TableQBSummaryTabulator;