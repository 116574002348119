import React, { Component } from 'react';

class BrowserRedirect extends Component {
    constructor(props, context) {
        super(props, context);
        this.version = '2019-10-17a';
    }

    render() {
        return (
            <div className="App">
                <div className="container">
                    <div className="row">
                        <a href="http://www.sisdatahub.com">
                            <br /><img src="/images/logo.png" alt="logo" height="70" width="166" />
                        </a>
                    </div>
                    <div><hr /></div>
                    <div>
                        <h2><br />Sorry. SISDatahub.com is not supported on Internet Explorer.</h2>
                        <h3>Consider using another browser like:</h3>
                        <ul>
                            <li><a href="https://www.microsoft.com/en-us/windows/microsoft-edge">Microsoft Edge</a></li>
                            <li><a href="https://www.mozilla.org">Mozilla Firefox</a></li>
                            <li><a href="https://www.google.com/chrome/browser">Google Chrome</a></li>
                        </ul>
                    </div>
                    <br />
                    <br />
                    <p><font size='1'> v. {this.version}</font></p>
                </div>
            </div >

        )
    }
}

export default BrowserRedirect;