import React from 'react';
import { CalcPlayerRating, NullSum, NullBypassSum, NullMax, NullDiv, Tst2015, DispFunc, NullSub, GameResult, GameDayFormat } from '../common/CommonFunctions';
import { qbGameSummary } from '../common/TableColumnWidths';
import Tabulator from 'tabulator-tables';
import "tabulator-tables/dist/css/tabulator.min.css";

class GameLogQBSummaryTabulator extends React.Component {
    constructor(props) {
        super(props);
        this.passingstats = this.props.passingstats;
        this.rushingstats = this.props.rushingstats;
        this.data = [];
        let gameInfoCols = null;
        this.colNum = 0;
        this.maxPlayerSeason = ''

        // Set game information columns
        gameInfoCols = [
            {
                title: "Date", field: "gameSeasonDateSSSSYYYYMMDD", cssClass: "single-row-header textColumn",
                titleFormatter: "html", headerSort: false,
                formatter: function (cell, formatterParams, onRendered) {
                    return (cell.getValue().substr(9, 1) === 'T' ? 'Total' : parseInt(cell.getValue().substr(9, 2)) + '/' + parseInt(cell.getValue().substr(12, 2)));
                },
                width: `${qbGameSummary[this.colNum++]}`
            },
            { 
                title: "Wk", field: "gameWeek", cssClass: "single-row-header small-column-center",
                titleFormatter: "html", headerSort: false,
                width: `${qbGameSummary[this.colNum++]}`,
//              formatter: function (cell, formatterParams, onRendered) {
//                 return cell = 99 ? '' : cell;
//              }
            },
            { 
                title: "Teams", field: "matchup", cssClass: "single-row-header textColumn",
                titleFormatter: "html", headerSort: false,
                width: `${qbGameSummary[this.colNum++]}`
            },  
            {
                title: "Score", field: "gameResult", cssClass: "single-row-header break textColumn",
                titleFormatter: "html", headerSort: false,
                width: `${qbGameSummary[this.colNum++]}`
            },
            {
                title: "G", field: "games", cssClass: "single-row-header small-column",
                titleFormatter: "html", headerSort: false,
                width: `${qbGameSummary[this.colNum++]}`
            },
            {
                title: "GS", field: "gamesStarted", cssClass: "single-row-header break small-column",
                titleFormatter: "html", headerSort: false,
                width: `${qbGameSummary[this.colNum++]}`
            }        
        ];

        // Set Remaining Columns
        this.cols = [
            { title: "",
                columns: gameInfoCols
            },
            {
                title: "Passing",
                columns:[
                    { 
                        title: "Att", field: "passAttempts", cssClass: "single-row-header",
                        titleFormatter: "html", headerSort: false,
                        width: `${qbGameSummary[this.colNum++]}`,
                        formatter: function(cell, formatterParams, onRendered){
                            return DispFunc(cell, formatterParams, onRendered, 0, true, false);
                        }
                    },
                    { 
                        title: "Cmp", field: "passCompletions", cssClass: "single-row-header",
                        titleFormatter: "html", headerSort: false,
                        width: `${qbGameSummary[this.colNum++]}`,
                        formatter: function(cell, formatterParams, onRendered){
                            return DispFunc(cell, formatterParams, onRendered, 0, true, false);
                        }
                    },
                    { 
                        title: "Pct", field: "passCompPct", cssClass: "single-row-header",
                        titleFormatter: "html", headerSort: false,
                        width: `${qbGameSummary[this.colNum++]}`,
                        formatter: function(cell, formatterParams, onRendered){
                            return DispFunc(cell, formatterParams, onRendered, 1, false, false);
                        }
                    },
                    { 
                        title: "Yds", field: "passYards", cssClass: "single-row-header",
                        titleFormatter: "html", headerSort: false,
                        width: `${qbGameSummary[this.colNum++]}`,
                        formatter: function(cell, formatterParams, onRendered){
                            return DispFunc(cell, formatterParams, onRendered, 0, true, false);
                        }
                    },
                    { 
                        title: "Y/A", field: "passYdsPerAttempt", cssClass: "single-row-header break",
                        titleFormatter: "html", headerSort: false,
                        width: `${qbGameSummary[this.colNum++]}`,
                        formatter: function(cell, formatterParams, onRendered){
                            return DispFunc(cell, formatterParams, onRendered, 1, false, false);
                        }
                    },
                    // {    // removed as requested by Matt during initial gauntlet
                    //     title: "Y/G", field: "passYdsPerGame", cssClass: "single-row-header break",
                    //     titleFormatter: "html", headerSort: false,
                    //     width: `${qbGameSummary[this.colNum++]}`,
                    //     formatter: function(cell, formatterParams, onRendered){
                    //         return DispFunc(cell, formatterParams, onRendered, 1, true, false);
                    //     }
                    // },
                    {
                        title: "Lg", field: "passLong", cssClass: "single-row-header",
                        titleFormatter: "html", headerSort: false,
                        width: `${qbGameSummary[this.colNum++]}`,
                        formatter: function(cell, formatterParams, onRendered){
                            return DispFunc(cell, formatterParams, onRendered, 0, false, false);
                        }
                    },
                    {
                        title: "TD", field: "passTD", cssClass: "single-row-header",
                        titleFormatter: "html", headerSort: false,
                        width: `${qbGameSummary[this.colNum++]}`,
                        formatter: function(cell, formatterParams, onRendered){
                            return DispFunc(cell, formatterParams, onRendered, 0, false, false);
                        }
                    },
                    {
                        title: "Int", field: "passInt", cssClass: "single-row-header break",
                        titleFormatter: "html", headerSort: false,
                        width: `${qbGameSummary[this.colNum++]}`,
                        formatter: function(cell, formatterParams, onRendered){
                            return DispFunc(cell, formatterParams, onRendered, 0, false, false);
                        }
                    }        
                ],
            },
            {
                title: "Rushing",
                columns:[
                    { 
                        title: "Att", field: "rushAttempts", cssClass: "single-row-header",
                        titleFormatter: "html", headerSort: false,
                        width: `${qbGameSummary[this.colNum++]}`,
                        formatter: function(cell, formatterParams, onRendered){
                            return DispFunc(cell, formatterParams, onRendered, 0, false, false);
                        }
                    },
                    { 
                        title: "Yds", field: "rushYds", cssClass: "single-row-header",
                        titleFormatter: "html", headerSort: false,
                        width: `${qbGameSummary[this.colNum++]}`,
                        formatter: function(cell, formatterParams, onRendered){
                            return DispFunc(cell, formatterParams, onRendered, 0, true, false);
                        }
                    },
                    { 
                        title: "Avg", field: "rushYdsPerAttempt", cssClass: "single-row-header",
                        titleFormatter: "html", headerSort: false,
                        width: `${qbGameSummary[this.colNum++]}`,
                        formatter: function(cell, formatterParams, onRendered){
                            return DispFunc(cell, formatterParams, onRendered, 1, false, false);
                        }
                    },
                    { 
                        title: "Lg", field: "rushLong", cssClass: "single-row-header",
                        titleFormatter: "html", headerSort: false,
                        width: `${qbGameSummary[this.colNum++]}`,
                        formatter: function(cell, formatterParams, onRendered){
                            return DispFunc(cell, formatterParams, onRendered, 0, false, false);
                        }
                    },
                    { 
                        title: "TD", field: "rushTD", cssClass: "single-row-header break",
                        titleFormatter: "html", headerSort: false,
                        width: `${qbGameSummary[this.colNum++]}`,
                        formatter: function(cell, formatterParams, onRendered){
                            return DispFunc(cell, formatterParams, onRendered, 0, false, false);
                        }
                    }        
                ],
            },
            {
                title: "Fumbles",
                columns:[
                    { 
                        title: "Fum", field: "sumFumbles", cssClass: "single-row-header",
                        titleFormatter: "html", headerSort: false,
                        width: `${qbGameSummary[this.colNum++]}`,
                        formatter: function(cell, formatterParams, onRendered){
                            return DispFunc(cell, formatterParams, onRendered, 0, false, false);
                        }
                    },
                    { 
                        title: "Lost", field: "sumFumblesLost", cssClass: "single-row-header break",
                        titleFormatter: "html", headerSort: false,
                        width: `${qbGameSummary[this.colNum++]}`,
                        formatter: function(cell, formatterParams, onRendered){
                            return DispFunc(cell, formatterParams, onRendered, 0, false, false);
                        }
                    }        
                ],
            },
            {
                title: "",
                columns:[
                    { 
                        title: "QB Rating", field: "passQBRating", cssClass: "single-row-header",
                        titleFormatter: "html", headerSort: false,
                        width: `${qbGameSummary[this.colNum++]}`,
                        formatter: function(cell, formatterParams, onRendered){
                            return DispFunc(cell, formatterParams, onRendered, 1, false, false);
                        }
                    },
                    { 
                        title: "IQR", field: "sumIQR", cssClass: "single-row-header break",
                        titleFormatter: "html", headerSort: false,
                        width: `${qbGameSummary[this.colNum++]}`,
                        formatter: function(cell, formatterParams, onRendered){
                            return DispFunc(cell, formatterParams, onRendered, 1, false, false);
                        }
                    }
                ],
            },
            { 
                title: "Tot Pts<br/>Earned", field: "sumPointsEarned",
                titleFormatter: "html", headerSort: false,
                width: `${qbGameSummary[this.colNum++]}`,
                formatter: function(cell, formatterParams, onRendered){
                    return DispFunc(cell, formatterParams, onRendered, 0, false, false);
                }
            }
        ];
        this.el = React.createRef();
        this.tabulator = null;
    }

    // Consolidate the data into one data array to be used for tabulator(called on component mount)
    ConsolidateData(){
        // no passing or rushing data
        if (this.passingstats.length === 0 && this.rushingstats.length === 0) {
            return('');
        }
        // declare and initialize detail data fields
        // these fields will be used to create objects for each game
        let passingstats = this.passingstats;
        let rushingstats = this.rushingstats;
        let sortKey = 0;
        let season = 0;
        let playerName = '';
        let playerId = 0;
        let positionName = '';
        let team = '';
        let matchup = '';
        let oppTeam = '';
        let gameWeek = 0;
        let games = 0;
        let gameDate = '';
        let gameSeasonDateSSSSYYYYMMDD = '';
        let gameResult = '';
        let gamesStarted = 0;
        let passAttempts = 0;
        let passCompletions = 0;
        let passDrops = 0;
        let passCompPct = 0;
        let passYards = 0;
        let passDropYards = 0;
        let passYdsPerAttempt = 0;
        let passYdsPerGame = 0;
        let passLong = 0;
        let passTD = 0;
        let passThrowAways = 0;
        let passSpikes = 0;
        let passBatted = 0;
        let passMiscommunications = 0;
        let passAccurateCompletions = 0;
        let passDroppedTD = 0;
        let passInt = 0;
        let passDroppedInt = 0;
        let passQBRating = 0;
        let rushAttempts = 0;
        let rushYds = 0;
        let rushYdsPerAttempt = 0;
        let rushLong = 0;
        let rushTD = 0;
        let sumFumbles = 0;
        let sumFumblesLost = 0;
        let sumIQR = 0;
        let sumPointsEarned = 0;
        // define array to hold detail data objects for each game
        var rowdata = [];
        var i = 0;
        // create rowdata[] object for each row in passingstats data
        if (passingstats.length !==0) {
            for (i = 0; i < passingstats.length; i++) {
                sortKey = (9999 - passingstats[i].season) * 1000000 + passingstats[i].season * 100 + passingstats[i].week; //XXXXYYYYWW
                season = passingstats[i].season;
                playerName = passingstats[i].playerName;
                playerId = passingstats[i].playerId;
                positionName = passingstats[i].positionName;
                team = passingstats[i].teamName;
                matchup = passingstats[i].teamName.trim() + ' ' + passingstats[i].oppTeamName.trim();
                games = 1;
                gameDate = passingstats[i].gameDate;
                gameSeasonDateSSSSYYYYMMDD = passingstats[i].season.toString() + passingstats[i].gameDate.substring(0, 10);
                gameResult = GameResult(passingstats[i].teamScore, passingstats[i].oppTeamScore);
                gameWeek = passingstats[i].week;
                gamesStarted = passingstats[i].starts;
                oppTeam = passingstats[i].oppTeamName;
                passAttempts = passingstats[i].attempts;
                passCompletions = passingstats[i].comp;
                passDrops = passingstats[i].drops;
                passCompPct = passingstats[i].compPerc;
                passYards = passingstats[i].yards;
                passDropYards = passingstats[i].dropYards;
                passYdsPerAttempt = passingstats[i].yardsPerAtt;
                passYdsPerGame = passingstats[i].yards;
                passLong = passingstats[i].passLong;
                passTD = passingstats[i].td;
                passThrowAways = passingstats[i].throwAways;
                passSpikes = passingstats[i].spikes;
                passBatted = passingstats[i].passBatted;
                passMiscommunications = passingstats[i].miscommunications;
                passAccurateCompletions = passingstats[i].accurateCompletions;
                passDroppedTD = passingstats[i].droppedTD;
                passInt = passingstats[i].int;
                passDroppedInt = passingstats[i].droppedInterceptions;
                passQBRating = passingstats[i].qbRating;
                rushAttempts = 0;
                rushYds = 0;
                rushYdsPerAttempt = 0;
                rushLong = 0;
                rushTD = 0;
                sumFumbles = passingstats[i].fumbles;
                sumFumblesLost = passingstats[i].fumblesLost;
                sumIQR = passingstats[i].iqr
                sumPointsEarned = Tst2015(passingstats[i].season,passingstats[i].eps);
                rowdata.push({
                    sortKey,
                    season,
                    playerName,
                    playerId,
                    positionName,
                    team,
                    matchup,
                    games,
                    gameDate,
                    gameSeasonDateSSSSYYYYMMDD,
                    gameResult,
                    gameWeek,
                    gamesStarted,
                    oppTeam,
                    passAttempts,
                    passCompletions,
                    passDrops,
                    passCompPct,
                    passYards,
                    passDropYards,
                    passYdsPerAttempt,
                    passYdsPerGame,
                    passLong,
                    passTD,
                    passThrowAways,
                    passSpikes,
                    passBatted,
                    passMiscommunications,
                    passAccurateCompletions,
                    passDroppedTD,
                    passInt,
                    passDroppedInt,
                    passQBRating,
                    rushAttempts,
                    rushYds,
                    rushYdsPerAttempt,
                    rushLong,
                    rushTD,
                    sumFumbles,
                    sumFumblesLost,
                    sumIQR,
                    sumPointsEarned
                });
            }
        }
        // for each rushingstats row that nmatch rowdata[] by gameDate, populate the rushing stat fields.
        // if the gameDate is not found, create a new rowdata object.
        let j = -1;
        if (rushingstats.length !==0) {
            for (i=0; i<rushingstats.length; i++) {
                for (j = rowdata.length - 1; j > -1; j--) {
                    if (rowdata[j].gameDate === rushingstats[i].gameDate) {
                        break;
                    }
                }
                if (j > -1) {
                    rowdata[j].rushAttempts = rushingstats[i].att;
                    rowdata[j].rushYds = rushingstats[i].yards;
                    rowdata[j].rushYdsPerAttempt = rushingstats[i].yardsPerAtt;
                    rowdata[j].rushLong = rushingstats[i].long;
                    rowdata[j].rushTD = rushingstats[i].td;
                    rowdata[j].sumFumbles = NullSum(rowdata[j].sumFumbles,rushingstats[i].fumbles);
                    rowdata[j].sumFumblesLost = NullSum(rowdata[j].sumFumblesLost,rushingstats[i].fumblesLost);
                    rowdata[j].sumPointsEarned = NullBypassSum(rowdata[j].sumPointsEarned,Tst2015(rushingstats[i].season,rushingstats[i].eps));
                } else {
                    sortKey = (9999 - rushingstats[i].season) * 1000000 + rushingstats[i].season * 100 + rushingstats[i].week; //XXXXYYYYWW
                    season = rushingstats[i].season;
                    playerName = rushingstats[i].playerName;
                    playerId = rushingstats[i].playerId;
                    team = rushingstats[i].teamName;
                    matchup = rushingstats[i].teamName + ' ' + rushingstats[i].oppTeamName;
                    positionName = rushingstats[i].positionName;
                    games = 1;
                    gameDate = rushingstats[i].gameDate;
                    gameSeasonDateSSSSYYYYMMDD = rushingstats[i].season.toString() + rushingstats[i].gameDate.substring(0, 10);
                    gameResult = GameResult(rushingstats[i].teamScore, rushingstats[i].oppTeamScore);
                    gameWeek = rushingstats[i].week;
                    gamesStarted = rushingstats[i].starts;
                    oppTeam = rushingstats[i].oppTeamName;
                    passAttempts = 0;
                    passCompletions = 0;
                    passDrops = 0;
                    passCompPct = 0;
                    passYards = 0;
                    passDropYards = 0;
                    passYdsPerAttempt = 0;
                    passYdsPerGame = 0;
                    passLong = 0;
                    passTD = 0;
                    passThrowAways = 0;
                    passSpikes = 0;
                    passBatted = 0;
                    passMiscommunications = 0;
                    passAccurateCompletions = 0;
                    passDroppedTD = 0;
                    passInt = 0;
                    passDroppedInt = 0;
                    passQBRating = 0;
                    rushAttempts = rushingstats[i].att;
                    rushYds = rushingstats[i].yards;
                    rushYdsPerAttempt = rushingstats[i].yardsPerAtt;
                    rushLong = rushingstats[i].long;
                    rushTD = rushingstats[i].td;
                    sumFumbles = rushingstats[i].fumbles;
                    sumFumblesLost = rushingstats[i].fumblesLost;
                    sumIQR = 0;
                    sumPointsEarned = Tst2015(rushingstats[i].season,rushingstats[i].eps);
                    rowdata.push({
                        sortKey,
                        season,
                        playerName,
                        playerId,
                        positionName,
                        team,
                        matchup,
                        games,
                        gameDate,
                        gameSeasonDateSSSSYYYYMMDD,
                        gameResult,
                        gameWeek,
                        gamesStarted,
                        oppTeam,
                        passAttempts,
                        passCompletions,
                        passDrops,
                        passCompPct,
                        passYards,
                        passDropYards,
                        passYdsPerAttempt,
                        passYdsPerGame,
                        passLong,
                        passTD,
                        passThrowAways,
                        passSpikes,
                        passBatted,
                        passMiscommunications,
                        passAccurateCompletions,
                        passDroppedTD,
                        passInt,
                        passDroppedInt,
                        passQBRating,
                        rushAttempts,
                        rushYds,
                        rushYdsPerAttempt,
                        rushLong,
                        rushTD,
                        sumFumbles,
                        sumFumblesLost,
                        sumIQR,
                        sumPointsEarned
                    });
                }
            }
        }
        if (rowdata.length !== 0) {
            rowdata.sort((a, b) => a.sortKey > b.sortKey ? 1 : -1); // orders rows by sortkey
            this.SumData(rowdata); // insert seasonal totals
        }
        this.data = rowdata
    }

    SumData(d) {
        let sortKey = 0;
        let season = d[0].season;
        let playerName = '';
        let playerId = '';
        let positionName = '';
        let team = '';
        let matchup = '';
        let games = 0;
        let gameDate = '';
        let gameSeasonDateSSSSYYYYMMDD = '';
        let gameResult = '';
        let gameWeek = '';
        let gamesStarted = 0;
        let oppTeam = ''
        let passAttempts = 0;
        let passCompletions = 0;
        let passCompPct = 0;
        let passYards = 0;
        let passYdsPerAttempt = 0;
        let passYdsPerGame = 0;
        let passLong = 0;
        let passTD = 0;
        let passInt = 0;
        let passQBRating = 0;
        let passDrops = 0;
        let passDropYards = 0;
        let passDroppedTD = 0;
        let passDroppedInt = 0;
        let sumIQR = 0;
        let rushAttempts = 0;
        let rushYds = 0;
        let rushYdsPerAttempt = 0;
        let rushLong = 0;
        let rushTD = 0;
        let sumFumbles = 0;
        let sumFumblesLost = 0;
        let sumPointsEarned = 0;
        let passThrowAways = 0;
        let passSpikes = 0;
        let passBatted = 0;
        let passMiscommunications = 0;
        let passAccurateCompletions = 0;
        let iqrAttempts = 0;
        let iqrComp = 0;
        let iqrYards = 0;
        let iqrTD = 0;
        let iqrInt = 0;
        let rowcount = d.length;
        for (var i = 0; i < rowcount; i++) {
            if (season !== d[i].season) {
                sortKey = (9999 - season) * 1000000 + season * 100 + 99; //XXXXYYYY99
                gameSeasonDateSSSSYYYYMMDD = season.toString() + season.toString() + " Total";
                if (passAttempts) {
                    passCompPct = NullDiv(passCompletions, passAttempts) * 100;
                    passYdsPerAttempt = NullDiv(passYards, passAttempts);
                    // QB Pass Rating
                    passQBRating = CalcPlayerRating(passAttempts, passCompletions, passYards, passTD, passInt);
                    iqrAttempts = NullSub(passAttempts, NullSum(NullSum(passThrowAways, passSpikes), NullSum(passBatted, passMiscommunications)));
                    iqrComp = NullSum(passAccurateCompletions, passDrops);
                    iqrYards = NullSum(passYards, passDropYards);
                    iqrTD = NullSum(passTD, passDroppedTD);
                    iqrInt = NullSum(passInt, passDroppedInt);
                    sumIQR = CalcPlayerRating(iqrAttempts, iqrComp, iqrYards, iqrTD, iqrInt);
                }
                rushYdsPerAttempt = NullDiv(rushYds, rushAttempts);
                passYdsPerGame = NullDiv(passYards, games);
                d.push({
                    sortKey,
                    season,
                    playerName,
                    playerId,
                    positionName,
                    team,
                    matchup,
                    games,
                    gameDate,
                    gameSeasonDateSSSSYYYYMMDD,
                    gameResult,
                    gameWeek,
                    gamesStarted,
                    oppTeam,
                    passAttempts,
                    passCompletions,
                    passDrops,
                    passCompPct,
                    passYards,
                    passDropYards,
                    passYdsPerAttempt,
                    passYdsPerGame,
                    passLong,
                    passTD,
                    passThrowAways,
                    passSpikes,
                    passBatted,
                    passMiscommunications,
                    passAccurateCompletions,
                    passDroppedTD,
                    passInt,
                    passDroppedInt,
                    passQBRating,
                    rushAttempts,
                    rushYds,
                    rushYdsPerAttempt,
                    rushLong,
                    rushTD,
                    sumFumbles,
                    sumFumblesLost,
                    sumIQR,
                    sumPointsEarned
                });
                season = d[i].season;
                playerName = '';
                playerId = '';
                positionName = '';
                team = '';
                matchup = '';
                games = 0;
                gameDate = '';
                gameSeasonDateSSSSYYYYMMDD ='';
                gameResult = '';
                gameWeek = '';
                gamesStarted = 0;
                oppTeam = ''
                passAttempts = 0;
                passCompletions = 0;
                passCompPct = 0;
                passYards = 0;
                passYdsPerAttempt = 0;
                passYdsPerGame = 0;
                passLong = 0;
                passTD = 0;
                passInt = 0;
                passQBRating = 0;
                passDrops = 0;
                passDropYards = 0;
                passDroppedTD = 0;
                passDroppedInt = 0;
                sumIQR = 0;
                rushAttempts = 0;
                rushYds = 0;
                rushYdsPerAttempt = 0;
                rushLong = 0;
                rushTD = 0;
                sumFumbles = 0;
                sumFumblesLost = 0;
                sumPointsEarned = 0;
                passThrowAways = 0;
                passSpikes = 0;
                passBatted = 0;
                passMiscommunications = 0;
                passAccurateCompletions = 0;
                iqrAttempts = 0;
                iqrComp = 0;
                iqrYards = 0;
                iqrTD = 0;
                iqrInt = 0;
            }
            games = NullBypassSum(games, d[i].games);
            gamesStarted = NullBypassSum(gamesStarted, d[i].gamesStarted);
            passAttempts = NullBypassSum(passAttempts, d[i].passAttempts);
            passCompletions = NullBypassSum(passCompletions, d[i].passCompletions);
            passYards = NullBypassSum(passYards, d[i].passYards);
            passDropYards = NullBypassSum(passDropYards, d[i].passDropYards);
            passLong = NullMax(passLong, d[i].passLong);
            passTD = NullBypassSum(passTD, d[i].passTD);
            passDroppedTD = NullBypassSum(passDroppedTD, d[i].passDroppedTD);
            passDrops = NullBypassSum(passDrops, d[i].passDrops);
            passInt = NullBypassSum(passInt, d[i].passInt);
            passDroppedInt = NullBypassSum(passDroppedInt, d[i].passDroppedInt);
            passThrowAways = NullBypassSum(passThrowAways, d[i].passThrowAways);
            passSpikes = NullBypassSum(passSpikes, d[i].passSpikes);
            passBatted = NullBypassSum(passBatted, d[i].passBatted);
            passMiscommunications = NullBypassSum(passMiscommunications, d[i].passMiscommunications);
            passAccurateCompletions = NullBypassSum(passAccurateCompletions, d[i].passAccurateCompletions);
            rushAttempts = NullBypassSum(rushAttempts, d[i].rushAttempts);
            rushYds = NullBypassSum(rushYds, d[i].rushYds);
            rushLong = NullMax(rushLong, d[i].rushLong);
            rushTD = NullBypassSum(rushTD, d[i].rushTD);
            sumFumbles = NullBypassSum(sumFumbles, d[i].sumFumbles);
            sumFumblesLost = NullBypassSum(sumFumblesLost, d[i].sumFumblesLost);
            sumPointsEarned = NullBypassSum(sumPointsEarned, Tst2015(d[i].season, d[i].sumPointsEarned));
        }
        sortKey = (9999 - season) * 1000000 + season * 100 + 99; //XXXXYYYY99
        gameSeasonDateSSSSYYYYMMDD = season.toString() + season.toString() + " Total";
        if (passAttempts) {
            passCompPct = NullDiv(passCompletions, passAttempts) * 100;
            passYdsPerAttempt = NullDiv(passYards, passAttempts);
            // QB Pass Rating
            passQBRating = CalcPlayerRating(passAttempts, passCompletions, passYards, passTD, passInt);
            iqrAttempts = NullSub(passAttempts, NullSum(NullSum(passThrowAways, passSpikes), NullSum(passBatted, passMiscommunications)));
            iqrComp = NullSum(passAccurateCompletions, passDrops);
            iqrYards = NullSum(passYards, passDropYards);
            iqrTD = NullSum(passTD, passDroppedTD);
            iqrInt = NullSum(passInt, passDroppedInt);
            sumIQR = CalcPlayerRating(iqrAttempts, iqrComp, iqrYards, iqrTD, iqrInt);
        }
        rushYdsPerAttempt = NullDiv(rushYds, rushAttempts);
        passYdsPerGame = NullDiv(passYards, games);
        d.push({
            sortKey,
            season,
            playerName,
            playerId,
            positionName,
            team,
            matchup,
            games,
            gameDate,
            gameSeasonDateSSSSYYYYMMDD,
            gameResult,
            gameWeek,
            gamesStarted,
            oppTeam,
            passAttempts,
            passCompletions,
            passDrops,
            passCompPct,
            passYards,
            passDropYards,
            passYdsPerAttempt,
            passYdsPerGame,
            passLong,
            passTD,
            passThrowAways,
            passSpikes,
            passBatted,
            passMiscommunications,
            passAccurateCompletions,
            passDroppedTD,
            passInt,
            passDroppedInt,
            passQBRating,
            rushAttempts,
            rushYds,
            rushYdsPerAttempt,
            rushLong,
            rushTD,
            sumFumbles,
            sumFumblesLost,
            sumIQR,
            sumPointsEarned
        });
        d.sort((a, b) => a.sortKey > b.sortKey ? 1 : -1); // corrects for new season total rows
    }

    componentDidMount() {
        this.ConsolidateData();
        let pagination = 'local';
        let paginationSize = 50;
//        let maxPlayerSeason = this.data.length ? this.data[this.data.length - 1].season : 0;
        let maxPlayerSeason = this.data.length ? this.data[0].season : 0;
        pagination = null;
        paginationSize = 0;
        this.tabulator = new Tabulator(this.el, {
            data: this.data,
            reactiveData: true,
            columns: this.cols,
            layout: "fitColumns",  // added by Ruben 7/25/19
            rowFormatter:function(row){
                var data = row.getData();
                if (data.gameSeasonDateSSSSYYYYMMDD.substr(9, 5) === 'Total'){
                    row.getElement().style.borderTop = "2px solid #6f88a8";
                    row.getElement().style.borderBottom = "2px solid #6f88a8";
                }
            },
            pagination: pagination,
            paginationSize: paginationSize,
            groupBy: function (row) {
                return row.gameSeasonDateSSSSYYYYMMDD.substr(0, 4);
            },
            groupHeader: function (groupKey, rowcount, data, group) {
                return groupKey + ' Season <span style="color:SlateBlue">&#9660</span>';
            },
            groupStartOpen: function (groupKey, rowcount, data, group) {
                return groupKey === maxPlayerSeason.toString();
            },
            groupToggleElement: "header",
            resizableColumns: false,
            selectable: false
        });
    }

    componentDidUpdate() {
        this.tabulator.replaceData(this.data);
    }

    render() {
        return (
            <React.Fragment>
                <div className="shadowdiv">
                    <div id="table-title">Summary</div>
                    <div ref={el => (this.el = el)} />
                </div>
            </React.Fragment>
        );
    }
}

export default GameLogQBSummaryTabulator;