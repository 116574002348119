import React from 'react';
import {NullBypassSum, NullDiv, NullMin, DispFunc} from '../common/CommonFunctions';
import { passRush } from '../common/TableColumnWidths';
import Tabulator from 'tabulator-tables';
import "tabulator-tables/dist/css/tabulator.min.css";
import Filters from '../common/Filters';

class TableQBSummaryTabulator extends React.Component {
    constructor(props) {
        super(props);
        this.defensestats = this.props.defensestats;
        this.isLeaderboards = this.props.isLeaderboards;
        this.minFilter = 1;
        this.data = [];
        let bioCols = null;
        let sortable = false;
        this.initSort = null;
        this.colNum = this.isLeaderboards ? 0 : 2;

        this.onSubmit = this.onSubmit.bind(this);

        // Custom sort function for players page so Total stays at the bottom of the table.
        let sortFunc = (a, b, aRow, bRow, column, dir, sorterParams) => {
            var aData = aRow.getData();
            var bData = bRow.getData();
            if (aData.season === 'Total'){
                if (dir === 'desc'){
                    return -1;
                }
                else if (dir === 'asc'){
                    return 1
                }
            }
            else if (bData.season === 'Total'){
                if (dir === 'desc'){
                    return 1;
                }
                else if (dir === 'asc'){
                    return -1;
                }            
            }
            else {
                return a-b;
            }
        };

        // Set first group of columns
        // If this is a leaderboards page include player name, remove custom sorter and set initial sort to proper column.
        if (this.isLeaderboards){
            sortable = true;
            bioCols = [
                {
                    title: "Player", field: "player", cssClass: "single-row-header textColumn",
                    width: `${passRush[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                    formatter: "link", formatterParams:{
                        labelField: "player",
                        urlPrefix:"/players/",
                        urlField: "playerId"
                    }
                },
                {
                    title: "Pos", field: "positionName", cssClass: "single-row-header textColumn",
                    width: `${passRush[this.colNum++][this.isLeaderboards ? 0 : 1]}`
                },
                { 
                    title: "Year", field: "season", cssClass: "single-row-header textColumn",
                    width: `${passRush[this.colNum++][this.isLeaderboards ? 0 : 1]}`
                },
                { 
                    title: "Team", field: "team", cssClass: "single-row-header textColumn break",
                    width: `${passRush[this.colNum++][this.isLeaderboards ? 0 : 1]}`
                }
            ];
            this.sorterFunc = null;
            this.initSort = [
                { column: "pressurePct", dir: "desc" }
            ];
        }
        // Else don't include player name and set initial sort to season.
        else{
            bioCols = [
                { 
                    title: "Year", field: "season", cssClass: "single-row-header textColumn",
                    width: `${passRush[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                    headerSort: sortable, sorter: sortFunc
                },
                { 
                    title: "Team", field: "team", cssClass: "single-row-header textColumn break",
                    width: `${passRush[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                    headerSort: sortable, sorter: sortFunc
                }
            ];
            this.initSort = [
                { column: "season", dir: "asc" }
            ];
        }
        // Set Remaining Columns
        this.cols = [
            { 
                title: "",
                columns: bioCols
            },
            {
                title: "Pass Rushes",
                columns:[
                    { 
                        title: "Total", field: "passRushes", cssClass: "single-row-header",
                        headerSortStartingDir: "desc", headerSort: sortable, sorter: sortFunc,
                        width: `${passRush[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                        formatter: function(cell, formatterParams, onRendered){
                            return DispFunc(cell, formatterParams, onRendered, 0, true, false);
                        }
                    },
                    { 
                        title: "Rush%", field: "passRushPct", cssClass: "single-row-header break",
                        headerSortStartingDir: "desc", headerSort: sortable, sorter: sortFunc,
                        width: `${passRush[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                        formatter: function(cell, formatterParams, onRendered){
                            return DispFunc(cell, formatterParams, onRendered, 1, false, false);
                        }
                    }
                ],
            },
            {
                title: "Pass Rush Totals",
                columns:[
                    { 
                        title: "Sacks", field: "passRushSacks", cssClass: "single-row-header",
                        headerSortStartingDir: "desc", headerSort: sortable, sorter: sortFunc,
                        width: `${passRush[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                        formatter: function(cell, formatterParams, onRendered){
                            return DispFunc(cell, formatterParams, onRendered, 1, false, false);
                        }
                    },
                    { 
                        title: "Hurries", field: "hurries", cssClass: "single-row-header",
                        headerSortStartingDir: "desc", headerSort: sortable, sorter: sortFunc,
                        width: `${passRush[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                        formatter: function(cell, formatterParams, onRendered){
                            return DispFunc(cell, formatterParams, onRendered, 0, false, false);
                        }
                    },
                    { 
                        title: "Hits", field: "hits", cssClass: "single-row-header",
                        headerSortStartingDir: "desc", headerSort: sortable, sorter: sortFunc,
                        width: `${passRush[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                        formatter: function(cell, formatterParams, onRendered){
                            return DispFunc(cell, formatterParams, onRendered, 0, false, false);
                        }
                    },
                    { 
                        title: "Knockdowns", field: "knockdowns", cssClass: "single-row-header",
                        width: `${passRush[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                        headerSortStartingDir: "desc", headerSort: sortable, sorter: sortFunc,
                        formatter: function(cell, formatterParams, onRendered){
                            return DispFunc(cell, formatterParams, onRendered, 0, false, false);
                        }
                    },
                    { 
                        title: "Pressures", field: "pressures", cssClass: "single-row-header",
                        headerSortStartingDir: "desc", headerSort: sortable, sorter: sortFunc,
                        width: `${passRush[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                        formatter: function(cell, formatterParams, onRendered){
                            return DispFunc(cell, formatterParams, onRendered, 0, false, false);
                        }
                    },
                    { 
                        title: "Passes Batted", field: "passesBatted", cssClass: "break",
                        titleFormatter:"textarea",
                        headerSortStartingDir: "desc", headerSort: sortable, sorter: sortFunc,
                        width: `${passRush[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                        formatter: function(cell, formatterParams, onRendered){
                            return DispFunc(cell, formatterParams, onRendered, 0, false, false);
                        }
                    }
                ],
            },
            {
                title: "Pass Rush Percentages",
                columns:[
                    { 
                        title: "Sack%", field: "sackPct", cssClass: "single-row-header",
                        headerSortStartingDir: "desc", headerSort: sortable, sorter: sortFunc,
                        width: `${passRush[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                        formatter: function(cell, formatterParams, onRendered){
                            return DispFunc(cell, formatterParams, onRendered, 1, false, false);
                        }
                    },
                    { 
                        title: "Hurry%", field: "hurryPct", cssClass: "single-row-header",
                        headerSortStartingDir: "desc", headerSort: sortable, sorter: sortFunc,
                        width: `${passRush[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                        formatter: function(cell, formatterParams, onRendered){
                            return DispFunc(cell, formatterParams, onRendered, 1, false, false);
                        }
                    },
                    { 
                        title: "Hit%", field: "hitPct", cssClass: "single-row-header",
                        headerSortStartingDir: "desc", headerSort: sortable, sorter: sortFunc,
                        width: `${passRush[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                        formatter: function(cell, formatterParams, onRendered){
                            return DispFunc(cell, formatterParams, onRendered, 1, false, false);
                        }
                    },
                    { 
                        title: "Knockdown%", field: "knockdownPct", cssClass: "single-row-header",
                        headerSortStartingDir: "desc", headerSort: sortable, sorter: sortFunc,
                        width: `${passRush[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                        formatter: function(cell, formatterParams, onRendered){
                            return DispFunc(cell, formatterParams, onRendered, 1, false, false);
                        }
                    },
                    { 
                        title: "Pressure%", field: "pressurePct", cssClass: "single-row-header",
                        headerSortStartingDir: "desc", headerSort: sortable, sorter: sortFunc,
                        width: `${passRush[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                        formatter: function(cell, formatterParams, onRendered){
                            return DispFunc(cell, formatterParams, onRendered, 1, false, false);
                        }
                    },
                    { 
                        title: "Passes Batted%", field: "passesBattedPct", titleFormatter:"textarea",
                        headerSortStartingDir: "desc", headerSort: sortable, sorter: sortFunc,
                        width: `${passRush[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                        formatter: function(cell, formatterParams, onRendered){
                            return DispFunc(cell, formatterParams, onRendered, 1, false, false);
                        }
                    },
                ],
            }
        ];
        
        this.el = React.createRef();
        this.tabulator = null;

    }

    // Consolidate the data into one data array to be used for tabulator(called on component mount)
    ConsolidateData(){
        let data = [];
        let player = '';
        let playerId = 0;
        let positionName = '';
        let season = 0;
        let team = '';
        let passRushes = 0;
        let passRushPct = 0;
        let passRushSacks = 0;
        let hurries = 0;
        let hits = 0;
        let knockdowns = 0;
        let pressures = 0;
        let passesBatted = 0;
        let sackPct = 0;
        let hurryPct = 0;
        let hitPct = 0;
        let knockdownPct = 0;
        let pressurePct = 0;
        let passesBattedPct = 0;
        if (this.defensestats.length !== 0){
            for (var i = 0; i < this.defensestats.length; i++) {
                if (this.defensestats[i].season !== 2015) {
                    if (!this.isLeaderboards || this.defensestats[i].passRushes >= this.minFilter) {
                        player = this.defensestats[i].player;
                        playerId = this.defensestats[i].playeridpr || this.defensestats[i].playeridrd || this.defensestats[i].playeridpd || this.defensestats[i].playeriddf;
                        positionName = this.defensestats[i].positionName;
                        season = this.defensestats[i].season;
                        team = this.defensestats[i].team;
                        passRushes = this.defensestats[i].passRushes;
                        passRushPct = this.defensestats[i].passRushPct;
                        passRushSacks = this.defensestats[i].passRushSacks;
                        hurries = this.defensestats[i].hurries;
                        hits = this.defensestats[i].hits;
                        knockdowns = this.defensestats[i].knockdowns;
                        pressures = this.defensestats[i].pressures;
                        passesBatted = this.defensestats[i].passesBatted;
                        sackPct = NullMin(this.defensestats[i].sackPct, 100);
                        hurryPct = NullMin(this.defensestats[i].hurryPct, 100);
                        hitPct = NullMin(this.defensestats[i].hitPct, 100);
                        knockdownPct = NullMin(this.defensestats[i].knockdownPct, 100);
                        pressurePct = NullMin(this.defensestats[i].pressurePct, 100);
                        passesBattedPct = NullMin(this.defensestats[i].passesBattedPct, 100);
                        // zero values to nulls
//                        passRushes = passRushes ? passRushes : null;
//                        passRushPct = passRushPct ? passRushPct : null;
//                        passRushSacks = passRushSacks ? passRushSacks : null;
//                        hurries = hurries ? hurries : null;
//                        hits = hits ? hits : null;
//                        knockdowns = knockdowns ? knockdowns : null;
//                        pressures = pressures ? pressures : null;
//                        passesBatted = passesBatted ? passesBatted : null;
//                        sackPct = sackPct ? sackPct : null;
//                        hurryPct = hurryPct ? hurryPct : null;
//                        hitPct = hitPct ? hitPct : null;
//                        knockdownPct = knockdownPct ? knockdownPct : null;
//                        pressurePct = pressurePct ? pressurePct : null;
//                        passesBattedPct = passesBattedPct ? passesBattedPct : null;
                        data.push(
                            {
                                player,
                                playerId,
                                positionName,
                                season,
                                team,
                                passRushes,
                                passRushPct,
                                passRushSacks,
                                hurries,
                                hits,
                                knockdowns,
                                pressures,
                                passesBatted,
                                sackPct,
                                hurryPct,
                                hitPct,
                                knockdownPct,
                                pressurePct,
                                passesBattedPct,
                            }
                        );
                    }
                }
            };
            this.data = data;
        }
    }

    // Add totals row to data.(called on component mount if this is the players page.)
    SumData() {
        let season = 'Total';
        let team = '';
        let hits = 0;
        let hurries = 0;
        let knockdowns = 0;
        let passesBatted = 0;
        let passRushes = 0;
        let passSnaps = 0;
        let passRushSacks = 0;
        let pressures = 0;
        let sacks = 0;
        let hitPct = 0;
        let hurryPct = 0;
        let knockdownPct = 0;
        let passesBattedPct = 0;
        let passRushPct = 0;
        let pressurePct = 0;
        let sackPct = 0;
        if (this.defensestats.length !== 0){
            for (var i = 0; i < this.defensestats.length; i++) {
                if (this.defensestats[i].season > 2015) {
                    hits = NullBypassSum(hits, this.defensestats[i].hits);
                    hurries = NullBypassSum(hurries, this.defensestats[i].hurries);
                    knockdowns = NullBypassSum(knockdowns, this.defensestats[i].knockdowns);
                    passesBatted = NullBypassSum(passesBatted, this.defensestats[i].passesBatted);
                    passRushes = NullBypassSum(passRushes, this.defensestats[i].passRushes);
                    passRushSacks = NullBypassSum(passRushSacks, this.defensestats[i].passRushSacks);
                    pressures = NullBypassSum(pressures, this.defensestats[i].pressures);
                    sacks = NullBypassSum(sacks, this.defensestats[i].sacks);
                    passSnaps = NullBypassSum(passSnaps, this.defensestats[i].passSnaps);
                }
            }
            if (passRushes) {
                hitPct = NullMin(NullDiv(hits,passRushes) * 100, 100);
                hurryPct = NullMin(NullDiv(hurries, passRushes) * 100, 100);
                knockdownPct = NullMin(NullDiv(knockdowns, passRushes) * 100, 100);
                passesBattedPct = NullMin(NullDiv(passesBatted, passRushes) * 100, 100);
                pressurePct = NullMin(NullDiv(pressures, passRushes) * 100, 100);
                sackPct = NullMin(NullDiv(sacks, passRushes) * 100, 100);
            }
            passRushPct = NullDiv(passRushes,passSnaps) * 100;
            this.data.push(
                {
                    season,
                    team,
                    passRushes,
                    passRushPct,
                    passRushSacks,
                    hurries,
                    hits,
                    knockdowns,
                    pressures,
                    passesBatted,
                    sackPct,
                    hurryPct,
                    hitPct,
                    knockdownPct,
                    pressurePct,
                    passesBattedPct,
                }
            );
        }
    }
    
    componentDidMount() {
        this.ConsolidateData();
        let pagination = 'local';
        let paginationSize = 50;
        if (!this.isLeaderboards){
            this.SumData();
            pagination = null;
            paginationSize = 0;
        }
        this.tabulator = new Tabulator(this.el, {
            data: this.data,
            reactiveData: true,
            columns: this.cols,
            layout: "fitColumns",  // added by Ruben 7/25/19
            rowFormatter:function(row){
                var data = row.getData();
                if (data.season === 'Total'){
                    row.getElement().style.borderTop = "2px solid #6f88a8";
                    row.getElement().style.borderBottom = "2px solid #6f88a8";
                }
            },
            pagination: pagination,
            paginationSize: paginationSize,
            initialSort: this.initSort,
            resizableColumns: false,
            selectable: false
        });
    }

    componentDidUpdate() {
        this.tabulator.replaceData(this.data);
    }

    onSubmit(e, minFilter){
        this.minFilter = minFilter;
        this.ConsolidateData();
        this.forceUpdate();
    }

    render() {
        return (
            <React.Fragment>
                {
                    this.isLeaderboards &&
                    <div className="shadowdiv" id="filtersDiv">
                        <Filters minFilter={this.minFilter} onSubmit={this.onSubmit} label={"Rushes"} />
                        <div ref={el => (this.el = el)} />
                    </div>
                }
                {
                    !this.isLeaderboards &&
                    <div className="shadowdiv">
                        <div id="table-title">Pass Rush</div>
                        <div ref={el => (this.el = el)} />
                    </div>
                }
            </React.Fragment>
        );
    }
}

export default TableQBSummaryTabulator;