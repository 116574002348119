import React, { useState, useEffect } from 'react';

function DropDown(props) {
    const [showList, setshowList] = useState('none');
    const [searchedPlayer, setsearchedPlayer] = useState('');
    const [playerList, setplayerList] = useState(null);
    let targetPage = props.targetPage ? props.targetPage : 'players';

    useEffect(() => {
        let list = [];
        let cutOffDate = new Date();
        cutOffDate.setMonth(cutOffDate.getMonth() - 3); // new teams not set until May in 2020 due to Covid-19
        props.players.forEach(p => {
                if ((p.lastName + ', ' + p.firstName + ' (' + p.positionCategory + ', ' +  p.teamName + ' #' + p.jersey + ')').toLowerCase().includes(searchedPlayer.toLowerCase())){
                    list.push(
                        <li key={p.playerId} className='autocomplete-item autocomplete' tabIndex='0' >
                            <a href={'/' + targetPage + '/' + p.playerId} className='autocomplete' style={{ display: 'block' }}>{p.lastName + ', ' + p.firstName + ' (' + p.positionCategory + ', ' + (p.season >= cutOffDate.getFullYear() ? p.teamName : 'not on team') + (p.season >= cutOffDate.getFullYear() ? ' #' + p.jersey : '') + ')'}</a>
                        </li>
                    )
                }
            }
        )
        setplayerList(list);
    }, [searchedPlayer, props.players, targetPage]);

    return(
        <React.Fragment>
            <div onFocus={() => setshowList('block')} onBlur=
                {
                    event => 
                    {
                        if(event.relatedTarget){
                            let att = '';
                            if(event.relatedTarget.attributes){
                                att = event.relatedTarget.getAttribute('class');
                            }
                            else{
                                att = event.relatedTarget.parentElement.getAttribute('class');
                            }
                            if(!att || !att.includes('autocomplete')){
                                setshowList('none');
                            }
                        }
                        else{
                            setshowList('none');
                        }
                        //if(!event.relatedTarget){setshowList('none')};
                    }
                }>
                <input value={searchedPlayer} onChange={(event) => setsearchedPlayer(event.target.value)}
                    style={{ padding: 0 }} className="autocomplete" />
                <ul className="col-sm-8 autocomplete-list autocomplete" style={{ display: showList }}>
                    {playerList}
                </ul>
            </div>
        </React.Fragment>
    );
}

export default DropDown;