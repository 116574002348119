import React from 'react';
import {NullSum, NullBypassSum, NullSub, NullDiv, Tst2015, DispFunc} from '../common/CommonFunctions';
import { runBlocking } from '../common/TableColumnWidths';
import Tabulator from 'tabulator-tables';
import "tabulator-tables/dist/css/tabulator.min.css";
import Filters from '../common/Filters';

class TableQBSummaryTabulator extends React.Component {
    constructor(props) {
        super(props);
        this.blockingstats = this.props.blockingstats;
        this.isLeaderboards = this.props.isLeaderboards;
        this.minFilter = 1;
        this.data = [];
        let bioCols = null;
        let sortable = false;
        this.initSort = null;
        this.colNum = this.isLeaderboards ? 0 : 2;

        this.onSubmit = this.onSubmit.bind(this);

        // Custom sort function for players page so Total stays at the bottom of the table.
        let sortFunc = (a, b, aRow, bRow, column, dir, sorterParams) => {
            var aData = aRow.getData();
            var bData = bRow.getData();
            if (aData.season === 'Total'){
                if (dir === 'desc'){
                    return -1;
                }
                else if (dir === 'asc'){
                    return 1
                }
            }
            else if (bData.season === 'Total'){
                if (dir === 'desc'){
                    return 1;
                }
                else if (dir === 'asc'){
                    return -1;
                }            
            }
            else {
                return a-b;
            }
        };

        // Set first group of columns
        // If this is a leaderboards page include player name, remove custom sorter and set initial sort to proper column.
        if (this.isLeaderboards){
            sortable = true;
            bioCols = [
                { 
                    title: "Player", field: "player", cssClass: "textColumn", 
                    width: `${runBlocking[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                    formatter: "link", formatterParams:{
                        labelField: "player", 
                        urlPrefix:"/players/", 
                        urlField: "playerId"
                    }
                },
                { 
                    title: "Pos", field: "positionName", cssClass: "textColumn",
                    width: `${runBlocking[this.colNum++][this.isLeaderboards ? 0 : 1]}`
                },
                { 
                    title: "Year", field: "season", cssClass: "textColumn",
                    width: `${runBlocking[this.colNum++][this.isLeaderboards ? 0 : 1]}`
                },
                { 
                    title: "Team", field: "team", cssClass: "textColumn break", 
                    width: `${runBlocking[this.colNum++][this.isLeaderboards ? 0 : 1]}`
                },
            ];
            this.sorterFunc = null;
            this.initSort = [
                { column: "pointsEarned", dir: "desc" }
            ];
        }
        // Else don't include player name and set initial sort to season.
        else{
            bioCols = [
                { 
                    title: "Year", field: "season", cssClass: "textColumn", 
                    width: `${runBlocking[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                    headerSort: sortable, sorter: sortFunc
                },
                { 
                    title: "Team", field: "team", cssClass: "textColumn break", 
                    width: `${runBlocking[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                    headerSort: sortable, sorter: sortFunc
                },
            ];
            this.initSort = [
                { column: "season", dir: "asc" }
            ];
        }
        // Set Remaining Columns
        this.cols = [
            { 
                title: "",
                columns: bioCols
            },
            { 
                title: "Rushing Snaps", field: "rushSnap", cssClass: "break", 
                width: `${runBlocking[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                headerSortStartingDir: "desc", headerSort: sortable, sorter: sortFunc, 
                titleFormatter:"textarea",
                formatter: function(cell, formatterParams, onRendered){
                    return DispFunc(cell, formatterParams, onRendered, 0, true, false);
                }
            },
            {
                title: "Blown Blocks",
                columns:[
                    { 
                        title: "Total", field: "bbRuns", 
                        headerSortStartingDir: "desc", headerSort: sortable, sorter: sortFunc,
                        width: `${runBlocking[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                        formatter: function(cell, formatterParams, onRendered){
                            return DispFunc(cell, formatterParams, onRendered, 0, false, false);
                        }
                    },
                    { 
                        title: "Pct", field: "bBperRushSnap", cssClass: "break", 
                        headerSortStartingDir: "desc", headerSort: sortable, sorter: sortFunc, 
                        width: `${runBlocking[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                        formatter: function(cell, formatterParams, onRendered){
                            return DispFunc(cell, formatterParams, onRendered, 1, false, false);
                        }
                    }
                ],
            },
            {
                title: "Penalties",
                columns:[
                    { 
                        title: "Total", field: "rushPenalties", 
                        headerSortStartingDir: "desc", headerSort: sortable, sorter: sortFunc,
                        width: `${runBlocking[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                        formatter: function(cell, formatterParams, onRendered){
                            return DispFunc(cell, formatterParams, onRendered, 0, false, false);
                        }
                    },
                    { 
                        title: "Holding", field: "rushHolds", 
                        headerSortStartingDir: "desc", headerSort: sortable, sorter: sortFunc,
                        width: `${runBlocking[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                        formatter: function(cell, formatterParams, onRendered){
                            return DispFunc(cell, formatterParams, onRendered, 0, false, false);
                        }
                    },
                    { 
                        title: "Other", field: "otherPenalties", 
                        headerSortStartingDir: "desc", headerSort: sortable, sorter: sortFunc,
                        width: `${runBlocking[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                        formatter: function(cell, formatterParams, onRendered){
                            return DispFunc(cell, formatterParams, onRendered, 0, false, false);
                        }
                    },
                    { 
                        title: "Pct", field: "rushPenaltyPct", cssClass: "break", 
                        headerSortStartingDir: "desc", headerSort: sortable, sorter: sortFunc, 
                        width: `${runBlocking[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                        formatter: function(cell, formatterParams, onRendered){
                            return DispFunc(cell, formatterParams, onRendered, 1, false, false);
                        }
                    }
                ],
            },
            {
                title: "Opp Stuffs",
                columns:[
                    { 
                        title: "Total", field: "rushStuffs", 
                        headerSortStartingDir: "desc", headerSort: sortable, sorter: sortFunc,
                        width: `${runBlocking[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                       formatter: function(cell, formatterParams, onRendered){
                            return DispFunc(cell, formatterParams, onRendered, 0, false, false);
                        }
                    },
                    { 
                        title: "Pct", field: "rushStuffPct", cssClass: "break", 
                        headerSortStartingDir: "desc", headerSort: sortable, sorter: sortFunc, 
                        width: `${runBlocking[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                        formatter: function(cell, formatterParams, onRendered){
                            return DispFunc(cell, formatterParams, onRendered, 1, false, false);
                        }
                    }        
                ],
            },
            { 
                title: "Run Block Points Earned", field: "pointsEarned", 
                headerSortStartingDir: "desc", headerSort: sortable, sorter: sortFunc, 
                titleFormatter:"textarea",
                width: `${runBlocking[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                formatter: function(cell, formatterParams, onRendered){
                    return DispFunc(cell, formatterParams, onRendered, 0, false, false);
                }
            }
        ];
        
        this.el = React.createRef();
        this.tabulator = null;

    }

    // Consolidate the data into one data array to be used for tabulator(called on component mount)
    ConsolidateData(){
        let data = [];
        let player = '';
        let playerId = 0;
        let positionName = '';
        let season = 0;
        let team = '';
        let rushSnap = 0;
        let bbRuns = 0;
        let bBperRushSnap = 0;
        let rushPenalties = 0;
        let rushHolds = 0;
        let otherPenalties = 0;
        let rushPenaltyPct = 0;
        let rushStuffs = 0;
        let rushStuffPct = 0;
        let pointsEarned = 0;
        if (this.blockingstats.length !== 0){
            for (var i=0; i<this.blockingstats.length; i++) {
                if (this.blockingstats[i].rushSnap >= this.minFilter){
                    player = this.blockingstats[i].player;
                    playerId = this.blockingstats[i].playerId;
                    positionName = this.blockingstats[i].positionName;
                    season = this.blockingstats[i].season;
                    team = this.blockingstats[i].team;
                    rushSnap = this.blockingstats[i].rushSnap;
                    bbRuns = this.blockingstats[i].bbRuns;
                    bBperRushSnap = this.blockingstats[i].bBperRushSnap*100;
                    rushPenalties = this.blockingstats[i].rushPenalties;
                    rushHolds = this.blockingstats[i].rushHolds;
                    otherPenalties = NullSub(this.blockingstats[i].rushPenalties,this.blockingstats[i].rushHolds);
                    rushPenaltyPct = this.blockingstats[i].rushPenaltyPct*100;
                    rushStuffs = this.blockingstats[i].rushStuffs;
                    rushStuffPct = this.blockingstats[i].rushStuffPct*100;
                    pointsEarned = Tst2015(this.blockingstats[i].season,this.blockingstats[i].runBlockEPS);
        
                    data.push(
                        {
                            player,
                            playerId,
                            positionName,
                            season,
                            team,
                            rushSnap,
                            bbRuns,
                            bBperRushSnap,
                            rushPenalties,
                            rushHolds,
                            otherPenalties,
                            rushPenaltyPct,
                            rushStuffs,
                            rushStuffPct,
                            pointsEarned
                        }
                    );
                }
            }
            this.data = data;
        }
    }

    // Add totals row to data.(called on component mount if this is the players page.)
    SumData() {
        let season = 'Total';
        let team = '';
        let rushSnap = 0;
        let bbRuns = 0;
        let bBperRushSnap = 0;
        let rushPenalties = 0;
        let rushHolds = 0;
        let otherPenalties = 0;
        let rushPenaltyPct = 0;
        let rushStuffs = 0;
        let rushStuffPct = 0;
        let pointsEarned = 0;
        if (this.blockingstats.length !== 0){
            for (var i = 0; i < this.blockingstats.length; i++) {
                rushSnap = NullSum(rushSnap,this.blockingstats[i].rushSnap);
                bbRuns = NullSum(bbRuns,this.blockingstats[i].bbRuns);
                rushPenalties =  NullSum(rushPenalties,this.blockingstats[i].rushPenalties);
                rushHolds = NullSum(rushHolds,this.blockingstats[i].rushHolds);
                rushStuffs = NullSum(rushStuffs,this.blockingstats[i].rushStuffs);
                pointsEarned = NullBypassSum(pointsEarned,Tst2015(this.blockingstats[i].season,this.blockingstats[i].runBlockEPS));
            };
            otherPenalties = NullSub(rushPenalties,rushHolds);
            if (rushSnap) {
                bBperRushSnap = NullDiv(bbRuns,rushSnap)*100;
                rushPenaltyPct = NullDiv(rushPenalties,rushSnap)*100;
                rushStuffPct = NullDiv(rushStuffs,rushSnap)*100;
            };
    
            this.data.push(
                {
                    season,
                    team,
                    rushSnap,
                    bbRuns,
                    bBperRushSnap,
                    rushPenalties,
                    rushHolds,
                    otherPenalties,
                    rushPenaltyPct,
                    rushStuffs,
                    rushStuffPct,
                    pointsEarned
                }
            );
        }
    }
    
    componentDidMount() {
        this.ConsolidateData();
        let pagination = 'local';
        let paginationSize = 50;
        if (!this.isLeaderboards){
            this.SumData();
            pagination = null;
            paginationSize = 0;
        }
        this.tabulator = new Tabulator(this.el, {
            data: this.data,
            reactiveData: true,
            columns: this.cols,
            layout: "fitColumns",  // added by Ruben 7/25/19
            rowFormatter:function(row){
                var data = row.getData();
                if (data.season === 'Total'){
                    row.getElement().style.borderTop = "2px solid #6f88a8";
                    row.getElement().style.borderBottom = "2px solid #6f88a8";
                }
            },
            pagination: pagination,
            paginationSize: paginationSize,
            initialSort: this.initSort,
            resizableColumns: false,
            selectable: false
        });
    }

    componentDidUpdate() {
        this.tabulator.replaceData(this.data);
    }

    onSubmit(e, minFilter){
        this.minFilter = minFilter;
        this.ConsolidateData();
        this.forceUpdate();
    }

    render() {
        return (
            <React.Fragment>
                {
                    this.isLeaderboards &&
                    <div className="shadowdiv" id="filtersDiv">
                        <Filters minFilter={this.minFilter} onSubmit={this.onSubmit} label={"Rush Snaps"} />
                        <div ref={el => (this.el = el)} />
                    </div>
                }
                {
                    !this.isLeaderboards &&
                    <div className="shadowdiv">
                        <div id="table-title">Run Blocking</div>
                        <div ref={el => (this.el = el)} />
                    </div>
                }
            </React.Fragment>
        );
    }
}

export default TableQBSummaryTabulator;