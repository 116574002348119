import React from 'react';
import {NullSum, NullBypassSum, NullMax, NullDiv, DispFunc} from '../common/CommonFunctions';
import { punting } from '../common/TableColumnWidths';
import Tabulator from 'tabulator-tables';
import "tabulator-tables/dist/css/tabulator.min.css";
import Filters from '../common/Filters';

class TableKPPuntingTabulator extends React.Component {
    constructor(props) {
        super(props);
        this.puntingstats = this.props.puntingstats;
        this.playerlist = this.props.playerlist;
        this.isLeaderboards = this.props.isLeaderboards;
        this.minFilter = 1;
        this.data = [];
        this.initSort = null;
        this.colNum = this.isLeaderboards ? 0 : 1;

        this.onSubmit = this.onSubmit.bind(this);

        // Custom sort function for players page so Total stays at the bottom of the table.
        let sortFunc = (a, b, aRow, bRow, column, dir, sorterParams) => {
            var aData = aRow.getData();
            var bData = bRow.getData();
            if (aData.season === 'Total'){
                if (dir === 'desc'){
                    return -1;
                }
                else if (dir === 'asc'){
                    return 1
                }
            }
            else if (bData.season === 'Total'){
                if (dir === 'desc'){
                    return 1;
                }
                else if (dir === 'asc'){
                    return -1;
                }            
            }
            else {
                return a-b;
            }
        };

        // Set first group of columns
        // If this is a leaderboards page include player name, remove custom sorter and set initial sort to proper column.
        if (this.isLeaderboards){
            this.cols = [
                { 
                    title: "Player", field: "player", cssClass: "single-row-header textColumn",
                    width: `${punting[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                    formatter: "link", formatterParams:{
                        labelField: "player",
                        urlPrefix:"/players/",
                        urlField: "playerId"
                    }
                },
                // { title: "Pos", field: "positionName", cssClass: "single-row-header textColumn", sorter: sortFunc },
                { 
                    title: "Year", field: "season", cssClass: "single-row-header textColumn",
                    width: `${punting[this.colNum++][this.isLeaderboards ? 0 : 1]}`
                },
                { 
                    title: "Team", field: "team", cssClass: "single-row-header textColumn",
                    width: `${punting[this.colNum++][this.isLeaderboards ? 0 : 1]}`
                },
                { 
                    title: "G", field: "games", cssClass: "single-row-header small-column break",
                    width: `${punting[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                    headerSortStartingDir: "desc"
                },
                { 
                    title: "Punts", field: "punts", cssClass: "single-row-header",
                    headerSortStartingDir: "desc",
                    width: `${punting[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                    formatter: function(cell, formatterParams, onRendered){
                        return DispFunc(cell, formatterParams, onRendered, 0, false, false);
                    }
                },
                { 
                    title: "Yards", field: "puntYards", cssClass: "single-row-header",
                    headerSortStartingDir: "desc",
                    width: `${punting[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                    formatter: function(cell, formatterParams, onRendered){
                        return DispFunc(cell, formatterParams, onRendered, 0, true, false);
                    }
                },
                { 
                    title: "Avg", field: "puntAvgYards", cssClass: "single-row-header break",
                    headerSortStartingDir: "desc",
                    width: `${punting[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                    formatter: function(cell, formatterParams, onRendered){
                        return DispFunc(cell, formatterParams, onRendered, 1, false, false);
                    }
                },
                { 
                    title: "Opp Ret", field: "puntsReturned", cssClass: "single-row-header",
                    headerSortStartingDir: "desc",
                    width: `${punting[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                    formatter: function(cell, formatterParams, onRendered){
                        return DispFunc(cell, formatterParams, onRendered, 0, true, false);
                    }
                },
                { 
                    title: "Ret Yards", field: "returnYards", cssClass: "single-row-header",
                    headerSortStartingDir: "desc",
                    width: `${punting[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                    formatter: function(cell, formatterParams, onRendered){
                        return DispFunc(cell, formatterParams, onRendered, 0, false, false);
                    }
                },
                { 
                    title: "Net Avg", field: "puntAvgNetYards", cssClass: "single-row-header break",
                    headerSortStartingDir: "desc",
                    width: `${punting[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                    formatter: function(cell, formatterParams, onRendered){
                        return DispFunc(cell, formatterParams, onRendered, 1, false, false);
                    }
                },
                { 
                    title: "Long", field: "long", cssClass: "single-row-header",
                    headerSortStartingDir: "desc",
                    width: `${punting[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                    formatter: function(cell, formatterParams, onRendered){
                        return DispFunc(cell, formatterParams, onRendered, 0, false, false);
                    }
                },
                {
                    title: "Blocks", field: "blockedPunts", cssClass: "single-row-header",
                    headerSortStartingDir: "desc",
                    width: `${punting[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                    formatter: function (cell, formatterParams, onRendered) {
                        return DispFunc(cell, formatterParams, onRendered, 0, false, false);
                    }
                },
                {
                    title: "Partial Blocks", field: "partialBlockedPunts", cssClass: "single-row-header",
                    headerSortStartingDir: "desc",
                    titleFormatter: "textarea",
                    width: `${punting[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                    formatter: function (cell, formatterParams, onRendered) {
                        return DispFunc(cell, formatterParams, onRendered, 0, false, false);
                    }
                },
                { 
                    title: "Fair Catches", field: "fCs", cssClass: "single-row-header",
                    headerSortStartingDir: "desc",
                    titleFormatter:"textarea",
                    width: `${punting[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                    formatter: function(cell, formatterParams, onRendered){
                        return DispFunc(cell, formatterParams, onRendered, 0, false, false);
                    }
                },
                { 
                    title: "Touchbacks", field: "touchBacks", cssClass: "single-row-header",
                    headerSortStartingDir: "desc",
                    titleFormatter:"textarea",
                    width: `${punting[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                    formatter: function(cell, formatterParams, onRendered){
                        return DispFunc(cell, formatterParams, onRendered, 0, false, false);
                    }
                },
                { 
                    title: "Inside 20", field: "puntsInside20", cssClass: "single-row-header break",
                    headerSortStartingDir: "desc",
                    width: `${punting[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                    formatter: function(cell, formatterParams, onRendered){
                        return DispFunc(cell, formatterParams, onRendered, 0, false, false);
                    }
                },
                { 
                    title: "Tot Points Earned", field: "sumPointsEarned", cssClass: "single-row-header", 
                    headerSortStartingDir: "desc",
                    titleFormatter:"textarea",
                    width: `${punting[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                    formatter: function(cell, formatterParams, onRendered){
                        return DispFunc(cell, formatterParams, onRendered, 0, false, false);
                    }
                }
            ];
            this.sorterFunc = null;
            this.initSort = [
                { column: "sumPointsEarned", dir: "desc" }
            ];
        }
        // Else don't include player name and set initial sort to season.
        else{
            this.cols = [
                { 
                    title: "Year", field: "season", cssClass: "single-row-header textColumn", 
                    width: `${punting[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                    headerSort: false, sorter: sortFunc
                },
                { 
                    title: "Team", field: "team", cssClass: "single-row-header textColumn",
                    width: `${punting[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                    headerSort: false, sorter: sortFunc                },
                { 
                    title: "G", field: "games", cssClass: "single-row-header small-column textColumn break", 
                    width: `${punting[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                    headerSortStartingDir: "desc", headerSort: false, sorter: sortFunc
                },
                { 
                    title: "Punts", field: "punts", cssClass: "single-row-header", 
                    headerSortStartingDir: "desc", headerSort: false, sorter: sortFunc,
                    width: `${punting[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                    formatter: function(cell, formatterParams, onRendered){
                        return DispFunc(cell, formatterParams, onRendered, 0, false, false);
                    }
                },
                { 
                    title: "Yards", field: "puntYards", cssClass: "single-row-header", 
                    headerSortStartingDir: "desc", headerSort: false, sorter: sortFunc, 
                    width: `${punting[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                    formatter: function(cell, formatterParams, onRendered){
                        return DispFunc(cell, formatterParams, onRendered, 0, true, false);
                    }
                },
                { 
                    title: "Avg", field: "puntAvgYards",  cssClass: "single-row-header break", 
                    headerSortStartingDir: "desc", headerSort: false, sorter: sortFunc, 
                    width: `${punting[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                    formatter: function(cell, formatterParams, onRendered){
                        return DispFunc(cell, formatterParams, onRendered, 1, false, false);
                    }
                },
                { 
                    title: "Opp Ret", field: "puntsReturned", cssClass: "single-row-header", 
                    headerSortStartingDir: "desc", headerSort: false, sorter: sortFunc, 
                    width: `${punting[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                    formatter: function(cell, formatterParams, onRendered){
                        return DispFunc(cell, formatterParams, onRendered, 0, true, false);
                    }
                },
                { 
                    title: "Ret Yards", field: "returnYards", cssClass: "single-row-header", 
                    headerSortStartingDir: "desc", headerSort: false, sorter: sortFunc,
                    width: `${punting[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                    formatter: function(cell, formatterParams, onRendered){
                        return DispFunc(cell, formatterParams, onRendered, 0, false, false);
                    }
                },
                { 
                    title: "Net Avg", field: "puntAvgNetYards",  cssClass: "single-row-header break", 
                    headerSortStartingDir: "desc", headerSort: false, sorter: sortFunc, 
                    width: `${punting[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                    formatter: function(cell, formatterParams, onRendered){
                        return DispFunc(cell, formatterParams, onRendered, 1, false, false);
                    }
                },
                { 
                    title: "Long", field: "long", cssClass: "single-row-header", 
                    headerSortStartingDir: "desc", headerSort: false, sorter: sortFunc,
                    width: `${punting[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                    formatter: function(cell, formatterParams, onRendered){
                        return DispFunc(cell, formatterParams, onRendered, 0, false, false);
                    }
                },
                { 
                    title: "Blocks", field: "blockedPunts", cssClass: "single-row-header", 
                    headerSortStartingDir: "desc", headerSort: false, sorter: sortFunc,
                    width: `${punting[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                    formatter: function(cell, formatterParams, onRendered){
                        return DispFunc(cell, formatterParams, onRendered, 0, false, false);
                    }
                },
                {
                    title: "Partial Blocks", field: "partialBlockedPunts", cssClass: "single-row-header",
                    headerSortStartingDir: "desc",
                    titleFormatter: "textarea",
                    width: `${punting[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                    formatter: function (cell, formatterParams, onRendered) {
                        return DispFunc(cell, formatterParams, onRendered, 0, false, false);
                    }
                },
                { 
                    title: "Fair Catches", field: "fCs", cssClass: "single-row-header", 
                    headerSortStartingDir: "desc", headerSort: false, sorter: sortFunc, 
                    titleFormatter:"textarea",
                    width: `${punting[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                    formatter: function(cell, formatterParams, onRendered){
                        return DispFunc(cell, formatterParams, onRendered, 0, false, false);
                    }
                },
                { 
                    title: "Touchbacks", field: "touchBacks", cssClass: "single-row-header", 
                    headerSortStartingDir: "desc", headerSort: false, sorter: sortFunc, 
                    width: `${punting[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                    formatter: function(cell, formatterParams, onRendered){
                        return DispFunc(cell, formatterParams, onRendered, 0, false, false);
                    }
                },
                { 
                    title: "Inside<br/>20", field: "puntsInside20",  cssClass: "single-row-header break", 
                    headerSortStartingDir: "desc", headerSort: false, sorter: sortFunc,
                    titleFormatter:"textarea",
                    width: `${punting[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                    formatter: function(cell, formatterParams, onRendered){
                        return DispFunc(cell, formatterParams, onRendered, 0, false, false);
                    }
                },
                { 
                    title: "Tot Points Earned", field: "sumPointsEarned", cssClass: "single-row-header", 
                    headerSortStartingDir: "desc", headerSort: false, sorter: sortFunc, 
                    titleFormatter:"textarea",
                    width: `${punting[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                    formatter: function(cell, formatterParams, onRendered){
                        return DispFunc(cell, formatterParams, onRendered, 0, false, false);
                    }
                }
            ];
            this.initSort = [
                { column: "season", dir: "asc" }
            ];
        }
        // Set Remaining Columns
        
        
        this.el = React.createRef();
        this.tabulator = null;

    }

    // Consolidate the data into one data array to be used for tabulator(called on component mount)
    ConsolidateData(){
        let data = [];
        let player = '';
        let playerId = 0;
        let positionName = '';
        let season = 0;
        let team = '';
        let games = 0;
        let punts = 0;
        let puntYards = 0;
        let puntAvgYards = 0;
        let puntsReturned = 0;
        let returnYards = 0;
        let netPuntYards = 0;
        let puntAvgNetYards = 0;
        let long = 0;
        let blockedPunts = 0;
        let partialBlockedPunts = 0;
        let fCs = 0;
        let touchBacks = 0;
        let puntsInside20 = 0;
        let sumPointsEarned = 0;
        if (this.puntingstats.length !== 0){
            for (var i=0; i<this.puntingstats.length; i++) {
                if (!this.isLeaderboards || this.puntingstats[i].punts >= this.minFilter){
                    player = this.puntingstats[i].player;
                    playerId = this.puntingstats[i].playerId;
                    positionName = this.puntingstats[i].positionName;
                    season = this.puntingstats[i].season;
                    team = this.puntingstats[i].team;
                    games = this.puntingstats[i].games;
                    punts = this.puntingstats[i].punts;
                    puntYards = this.puntingstats[i].puntYards;
                    puntAvgYards = NullDiv(this.puntingstats[i].puntYards,this.puntingstats[i].punts);
                    puntsReturned = this.puntingstats[i].puntsReturned;
                    returnYards = this.puntingstats[i].returnYards;
                    netPuntYards = this.puntingstats[i].netPuntYards;
                    puntAvgNetYards = NullDiv(this.puntingstats[i].netPuntYards, this.puntingstats[i].punts - this.puntingstats[i].blockedPunts);
                    long = this.puntingstats[i].long;
                    blockedPunts = this.puntingstats[i].blockedPunts;
                    partialBlockedPunts = this.puntingstats[i].partialBlockedPunts;
                    fCs = this.puntingstats[i].fCs;
                    touchBacks = this.puntingstats[i].touchBacks;
                    puntsInside20 = this.puntingstats[i].puntsInside20;
                    sumPointsEarned = this.puntingstats[i].eps;
                    data.push(
                        {
                            player,
                            playerId,
                            positionName,
                            season,
                            team,
                            games,
                            punts,
                            puntYards,
                            puntAvgYards,
                            puntsReturned,
                            returnYards,
                            netPuntYards,
                            puntAvgNetYards,
                            long,
                            blockedPunts,
                            partialBlockedPunts,
                            fCs,
                            touchBacks,
                            puntsInside20,
                            sumPointsEarned
                        }
                    );
                }
            }
            this.data = data;
        }
    }

    // Add totals row to data.(called on component mount if this is the players page.)
    SumData() {
        let season = 'Total';
        let team = '';
        let games = 0;
        let punts = 0;
        let puntYards = 0;
        let puntsReturned = 0;
        let returnYards = 0;
        let netPuntYards=0;
        let long = 0;
        let blockedPunts = 0;
        let partialBlockedPunts = 0;
        let fCs = 0;
        let touchBacks = 0;
        let puntsInside20 = 0;
        let puntAvgYards = 0;
        let puntAvgNetYards = 0;
        let sumPointsEarned = 0;
        if (this.data.length !== 0){
            for (var i = 0; i < this.data.length; i++) {
                games = NullSum(games,this.data[i].games);
                punts = NullSum(punts,this.data[i].punts);
                puntYards = NullSum(puntYards,this.data[i].puntYards);
                puntsReturned = NullSum(puntsReturned,this.data[i].puntsReturned);
                returnYards = NullSum(returnYards,this.data[i].returnYards);
                netPuntYards = NullSum(netPuntYards,this.data[i].netPuntYards);
                long = NullMax(long,this.data[i].long);
                blockedPunts = NullSum(blockedPunts, this.data[i].blockedPunts);
                partialBlockedPunts = NullSum(partialBlockedPunts, this.data[i].partialBlockedPunts);
                touchBacks = NullSum(touchBacks,this.data[i].touchBacks);
                fCs = NullSum(fCs,this.data[i].fCs);
                puntsInside20 = NullSum(puntsInside20, this.data[i].puntsInside20);
                sumPointsEarned = NullBypassSum(sumPointsEarned, this.data[i].sumPointsEarned);
            }
            puntAvgYards = NullDiv(puntYards,punts);
            puntAvgNetYards = NullDiv(netPuntYards, punts - blockedPunts);
    
            this.data.push(
                {
                    season,
                    team,
                    games,
                    punts,
                    puntYards,
                    puntAvgYards,
                    puntsReturned,
                    returnYards,
                    puntAvgNetYards,
                    long,
                    blockedPunts,
                    partialBlockedPunts,
                    fCs,
                    touchBacks,
                    puntsInside20,
                    sumPointsEarned
                }
            );
        }
    }
    
    componentDidMount() {
        this.ConsolidateData();
        let pagination = 'local';
        let paginationSize = 50;
        if (!this.isLeaderboards){
            this.SumData();
            pagination = null;
            paginationSize = 0;
        }
        this.tabulator = new Tabulator(this.el, {
            data: this.data,
            reactiveData: true,
            columns: this.cols,
            layout: "fitColumns",  // added by Ruben 7/25/19
            rowFormatter:function(row){
                var data = row.getData();
                if (data.season === 'Total'){
                    row.getElement().style.borderTop = "2px solid #6f88a8";
                    row.getElement().style.borderBottom = "2px solid #6f88a8";
                }
            },
            pagination: pagination,
            paginationSize: paginationSize,
            initialSort: this.initSort,
            resizableColumns: false,
            selectable: false
        });
    }

    componentDidUpdate() {
        this.tabulator.replaceData(this.data);
    }

    onSubmit(e, minFilter){
        this.minFilter = minFilter;
        this.ConsolidateData();
        this.forceUpdate();
    }

    render() {
        return (
            <React.Fragment>
                {
                    this.isLeaderboards &&
                    <div className="shadowdiv" id="filtersDiv">
                        <Filters minFilter={this.minFilter} onSubmit={this.onSubmit} label={"Punts"} />
                        <div ref={el => (this.el = el)} />
                    </div>
                }
                {
                    !this.isLeaderboards &&
                    <div className="shadowdiv">
                        <div id="table-title">Punting</div>
                        <div ref={el => (this.el = el)} />
                    </div>
                }
            </React.Fragment>
        );
    }
}

export default TableKPPuntingTabulator;