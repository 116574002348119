import React from 'react';
import {NullSum, NullBypassSum, NullDiv, Tst2015, DispFunc} from '../common/CommonFunctions';
import { passBlocking } from '../common/TableColumnWidths';
import Tabulator from 'tabulator-tables';
import "tabulator-tables/dist/css/tabulator.min.css";
import Filters from '../common/Filters';

class TableQBSummaryTabulator extends React.Component {
    constructor(props) {
        super(props);
        this.blockingstats = this.props.blockingstats;
        this.isLeaderboards = this.props.isLeaderboards;
        this.minFilter = 1;
        this.data = [];
        let bioCols = null;
        let sortable = false;
        this.initSort = null;
        this.colNum = this.isLeaderboards ? 0 : 2;

        this.onSubmit = this.onSubmit.bind(this);

        // Custom sort function for players page so Total stays at the bottom of the table.
        let sortFunc = (a, b, aRow, bRow, column, dir, sorterParams) => {
            var aData = aRow.getData();
            var bData = bRow.getData();
            if (aData.season === 'Total'){
                if (dir === 'desc'){
                    return -1;
                }
                else if (dir === 'asc'){
                    return 1
                }
            }
            else if (bData.season === 'Total'){
                if (dir === 'desc'){
                    return 1;
                }
                else if (dir === 'asc'){
                    return -1;
                }            
            }
            else {
                return a-b;
            }
        };

        // Set first group of columns
        // If this is a leaderboards page include player name, remove custom sorter and set initial sort to proper column.
        if (this.isLeaderboards){
            sortable = true;
            bioCols = [
                { 
                    title: "Player", field: "player", cssClass: "textColumn", 
                    width: `${passBlocking[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                    formatter: "link", formatterParams:{
                        labelField: "player", 
                        urlPrefix:"/players/", 
                        urlField: "playerId"
                    }
                },
                { 
                    title: "Pos", field: "positionName", cssClass: "textColumn",
                    width: `${passBlocking[this.colNum++][this.isLeaderboards ? 0 : 1]}`
                },
                { 
                    title: "Year", field: "season", cssClass: "textColumn",
                    width: `${passBlocking[this.colNum++][this.isLeaderboards ? 0 : 1]}`
                },
                { 
                    title: "Team", field: "team", cssClass: "textColumn break", 
                    width: `${passBlocking[this.colNum++][this.isLeaderboards ? 0 : 1]}`
                },
            ];
            this.sorterFunc = null;
            this.initSort = [
                { column: "pointsEarned", dir: "desc" }
            ];
        }
        // Else don't include player name and set initial sort to season.
        else{
            bioCols = [
                { 
                    title: "Year", field: "season", cssClass: "textColumn", 
                    width: `${passBlocking[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                    headerSort: sortable, sorter: sortFunc
                },
                { 
                    title: "Team", field: "team", cssClass: "textColumn break", 
                    width: `${passBlocking[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                    headerSort: sortable, sorter: sortFunc
                },
            ];
            this.initSort = [
                { column: "season", dir: "asc" }
            ];
        }
        // Set Remaining Columns
        this.cols = [
            { 
                title: "",
                columns: bioCols
            },
            { 
                title: "Passing Snaps", field: "passSnap", cssClass: "break", 
                headerSortStartingDir: "desc", headerSort: sortable, sorter: sortFunc, 
                titleFormatter:"textarea",
                width: `${passBlocking[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                formatter: function(cell, formatterParams, onRendered){
                    return DispFunc(cell, formatterParams, onRendered, 0, true, false);
                }
            },
            {
                title: "Blown Blocks",
                columns:[
                    { 
                        title: "Total", field: "bbPass", 
                        headerSortStartingDir: "desc", headerSort: sortable, sorter: sortFunc,
                        width: `${passBlocking[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                        formatter: function(cell, formatterParams, onRendered){
                            return DispFunc(cell, formatterParams, onRendered, 0, false, false);
                        }
                    },
                    { 
                        title: "Pct", field: "bbPerPassSnap", cssClass: "break", 
                        headerSortStartingDir: "desc", headerSort: sortable, sorter: sortFunc, 
                        width: `${passBlocking[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                        formatter: function(cell, formatterParams, onRendered){
                            return DispFunc(cell, formatterParams, onRendered, 1, false, false);
                        }
                    }
                ],
            },
            {
                title: "Penalties",
                columns:[
                    { 
                        title: "Total", field: "passPenalties", 
                        headerSortStartingDir: "desc", headerSort: sortable, sorter: sortFunc,
                        width: `${passBlocking[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                        formatter: function(cell, formatterParams, onRendered){
                            return DispFunc(cell, formatterParams, onRendered, 0, false, false);
                        }
                    },
                    { 
                        title: "Holding", field: "passHolds", 
                        headerSortStartingDir: "desc", headerSort: sortable, sorter: sortFunc,
                        width: `${passBlocking[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                        formatter: function(cell, formatterParams, onRendered){
                            return DispFunc(cell, formatterParams, onRendered, 0, false, false);
                        }
                    },
                    { 
                        title: "Other", field: "otherPenalties", 
                        headerSortStartingDir: "desc", headerSort: sortable, sorter: sortFunc,
                        width: `${passBlocking[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                        formatter: function(cell, formatterParams, onRendered){
                            return DispFunc(cell, formatterParams, onRendered, 0, false, false);
                        }
                    },
                    { 
                        title: "Pct", field: "passPenaltyPct", cssClass: "break", 
                        headerSortStartingDir: "desc", headerSort: sortable, sorter: sortFunc, 
                        width: `${passBlocking[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                        formatter: function(cell, formatterParams, onRendered){
                            return DispFunc(cell, formatterParams, onRendered, 1, false, false);
                        }
                    }
                ],
            },
            {
                
                title: "Opp Sacks",
                columns:[
                    { 
                        title: "Total", field: "sacksAllowed", 
                        headerSortStartingDir: "desc", headerSort: sortable, sorter: sortFunc,
                        width: `${passBlocking[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                        formatter: function(cell, formatterParams, onRendered){
                            return DispFunc(cell, formatterParams, onRendered, 0, false, false);
                        }
                    },
                    { 
                        title: "Pct", field: "sackPct", cssClass: "break", 
                        headerSortStartingDir: "desc", headerSort: sortable, sorter: sortFunc, 
                        width: `${passBlocking[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                        formatter: function(cell, formatterParams, onRendered){
                            return DispFunc(cell, formatterParams, onRendered, 1, false, false);
                        }
                    }        
                ],
            },
            { 
                title: "Pass Block Points Earned", field: "pointsEarned", 
                headerSortStartingDir: "desc", headerSort: sortable, sorter: sortFunc, 
                titleFormatter:"textarea",
                width: `${passBlocking[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                formatter: function(cell, formatterParams, onRendered){
                    return DispFunc(cell, formatterParams, onRendered, 0, false, false);
                }
            }
        ];
        
        this.el = React.createRef();
        this.tabulator = null;

    }

    // Consolidate the data into one data array to be used for tabulator(called on component mount)
    ConsolidateData(){
        let data = [];
        let player = '';
        let playerId = 0;
        let positionName = '';
        let season = 0;
        let team = '';
        let passSnap = 0;
        let bbPass = 0;
        let bbPerPassSnap = 0;
        let passPenalties = 0;
        let passHolds = 0;
        let otherPenalties = 0;
        let passPenaltyPct = 0;
        let sacksAllowed = 0;
        let sackPct = 0;
        let pointsEarned = 0;
        let passBlockEPS = 0;
        if (this.blockingstats.length !== 0){
            for (var i=0; i<this.blockingstats.length; i++) {
                if (!this.isLeaderboards || this.blockingstats[i].passSnap >= this.minFilter){
                    player = this.blockingstats[i].player;
                    playerId = this.blockingstats[i].playerId;
                    positionName = this.blockingstats[i].positionName;
                    season = this.blockingstats[i].season;
                    team = this.blockingstats[i].team;
                    passSnap = this.blockingstats[i].passSnap;
                    bbPass = this.blockingstats[i].bbPass;
                    bbPerPassSnap = this.blockingstats[i].bbPerPassSnap*100;
                    passPenalties = this.blockingstats[i].passPenalties;
                    passHolds = this.blockingstats[i].passHolds;
                    otherPenalties = this.blockingstats[i].passPenalties - this.blockingstats[i].passHolds;
                    passPenaltyPct = this.blockingstats[i].passPenaltyPct*100;
                    sacksAllowed = this.blockingstats[i].sacksAllowed;
                    sackPct = this.blockingstats[i].sackPct*100;
                    pointsEarned = Tst2015(this.blockingstats[i].season,this.blockingstats[i].passBlockEPS);
                    passBlockEPS = this.blockingstats[i].passBlockEPS;
                    data.push(
                        {
                            player,
                            playerId,
                            positionName,
                            season,
                            team,
                            passSnap,
                            bbPass,
                            bbPerPassSnap,
                            passPenalties,
                            passHolds,
                            otherPenalties,
                            passPenaltyPct,
                            sacksAllowed,
                            sackPct,
                            pointsEarned,
                            passBlockEPS
                        }
                    );
                }
            }
            this.data = data;
        }
    }

    // Add totals row to data.(called on component mount if this is the players page.)
    SumData() {
        let season = 'Total';
        let team = '';
        let passSnap = 0;
        let bbPass = 0;
        let bbPerPassSnap = 0;
        let passPenalties = 0;
        let passHolds = 0;
        let otherPenalties = 0;
        let passPenaltyPct = 0;
        let sacksAllowed = 0;
        let sackPct = 0;
        let pointsEarned = 0;
        if (this.data.length !== 0){
            for (var i = 0; i < this.data.length; i++) {
                passSnap = NullSum(passSnap,this.data[i].passSnap);
                bbPass = NullSum(bbPass,this.data[i].bbPass);
                passPenalties =  NullSum(passPenalties,this.data[i].passPenalties);
                passHolds = NullSum(passHolds,this.data[i].passHolds);
                sacksAllowed = NullSum(sacksAllowed,this.data[i].sacksAllowed);
                pointsEarned =  NullBypassSum(pointsEarned,Tst2015(this.data[i].season,this.data[i].passBlockEPS));
            };
            otherPenalties = (passPenalties - passHolds)
            if (passSnap) {
                bbPerPassSnap = NullDiv(bbPass,passSnap)*100;
                passPenaltyPct = NullDiv(passPenalties,passSnap)*100;
                sackPct = NullDiv(sacksAllowed,passSnap)*100;
            };
    
            this.data.push(
                {
                    season,
                    team,
                    passSnap,
                    bbPass,
                    bbPerPassSnap,
                    passPenalties,
                    passHolds,
                    otherPenalties,
                    passPenaltyPct,
                    sacksAllowed,
                    sackPct,
                    pointsEarned,
                }
            );
        }
    }
    
    componentDidMount() {
        this.ConsolidateData();
        let pagination = 'local';
        let paginationSize = 50;
        if (!this.isLeaderboards){
            this.SumData();
            pagination = null;
            paginationSize = 0;
        }
        this.tabulator = new Tabulator(this.el, {
            data: this.data,
            reactiveData: true,
            columns: this.cols,
            layout: "fitColumns",  // added by Ruben 7/25/19
            rowFormatter:function(row){
                var data = row.getData();
                if (data.season === 'Total'){
                    row.getElement().style.borderTop = "2px solid #6f88a8";
                    row.getElement().style.borderBottom = "2px solid #6f88a8";
                }
            },
            pagination: pagination,
            paginationSize: paginationSize,
            initialSort: this.initSort,
            resizableColumns: false,
            selectable: false
        });
    }

    componentDidUpdate() {
        this.tabulator.replaceData(this.data);
    }

    onSubmit(e, minFilter){
        this.minFilter = minFilter;
        this.ConsolidateData();
        this.forceUpdate();
    }

    render() {
        return (
            <React.Fragment>
                {
                    this.isLeaderboards &&
                    <div className="shadowdiv" id="filtersDiv">
                        <Filters minFilter={this.minFilter} onSubmit={this.onSubmit} label={"Pass Snaps"} />
                        <div ref={el => (this.el = el)} />
                    </div>
                }
                {
                    !this.isLeaderboards &&
                    <div className="shadowdiv">
                        <div id="table-title">Pass Blocking</div>
                        <div ref={el => (this.el = el)} />
                    </div>
                }
            </React.Fragment>
        );
    }
}

export default TableQBSummaryTabulator;