// Interger: format x for display. if x is null return '--' otherwise return x
export function DispInt(x) {
    return(x !== null ? x.toString() : '--')
}

// Text: format x for display. if x is null return '--' otherwise return x
export function DispTxt(x) {
    return(x !== null ? x : '--');
}

// Decimal: format x for display. if x is null return '--' otherwise return x rounded to 1 decimal
export function DispDec(x) {
    return(x !== null ? x.toFixed(1) : '--');
}

// Percent: format x for display. if x is null return '--' otherwise return (x * 100) rounded to 1 decimal
export function DispPct(x) {
    return(x !== null ? (x*100).toFixed(1) : '--');
}

export function DispPct0(x) {
    return(x !== null ? (x*100).toFixed(0) : '--');
}

// Comma: format x for display. if x is null return '--' otherwise return x formatted with comma if > 999
export function DispCom(x) {
    return(x !== null ? x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : '--');
}

// test for null
export function NullTst(x) {
    return(x !== null ? x : null);
}

// sum two numbers. if either is null, return null
export function NullSum(accumulator, addend) {
    return accumulator === null ? null : addend === null ? null : accumulator + addend;
}

// sum two numbers ignoring any nulls unless both are null
export function NullBypassSum(accumulator, addend) {
    return accumulator === null ? (addend === null ? null : addend) : (addend === null ? accumulator : accumulator + addend);
}

// sum two numbers. if either is null, bypass it and return the other only. If both are null, return null
//export function NullBypassSum(accumulator, addend) {
//    return accumulator !== null ? addend !== null ? accumulator + addend : accumulator : null;
//}

// subtract two numbers. if either is null, return null
export function NullSub(minuend, subtrahend) {
    return minuend === null ? null : subtrahend === null ? null : minuend - subtrahend;
}

// determine the greater of two numbers. if either is null, return null
export function NullMax(currVal, newVal) {
    return currVal === null ? null : newVal === null ? null : MaxVal(currVal, newVal);
}

// determine the lesser of two numbers. if either is null, return null
export function NullMin(currVal, newVal) {
    return currVal === null ? null : newVal === null ? null : MinVal(currVal, newVal);
}

// determine the greater of two numbers.
export function MaxVal(val1, val2) {
    return val1 > val2 ? val1 : val2;
}

// determine the lesser of two numbers.
export function MinVal(val1, val2) {
    return val1 > val2 ? val2 : val1;
}

// divide two numbers. if either is null, return null
export function NullDiv(dividend, divisor) {
    return dividend === null ? null : divisor === null ? null : divisor === 0 ? 0 : dividend/divisor;
}

// multiply two numbers. if either is null, return null
export function NullMult(factor1, factor2) {
    return factor1 === null ? null : factor2 === null ? null : factor1 * factor2;
}

// id season is 2015, return null otherwise return passed value
export function Tst2015(season, passedValue) {
    return season === 2015 ? null : passedValue;
}

// this function implements a standard NFL calculation for passer/receiver rating
export function CalcPlayerRating(attempts,completions,yards,td,int) {
    if (attempts === null || completions === null || yards === null || td === null || int === null) {
        return(null); // can't calculate if any value is null
    }
    if (attempts === 0) {
        return(0); // can't calculate if devisor is zero
    }
    let compPct = ((completions/attempts) - .3) * 5;    // NFL completion percentage component
    let yardsPerAtt = ((yards/attempts) - 3) * .25;     // NFL yards per attempt component
    let tdPct = (td/attempts) * 20;                     // NFL TS percent component
    let intPct = 2.375 - ((int/attempts) * 25);         // NFL interception percent component
    // then next four line ensure that all  component values are between 0 and 2.375 per NFL
    compPct = (compPct < 0) ? 0 : (compPct > 2.375) ? 2.375 : compPct;
    yardsPerAtt = (yardsPerAtt < 0) ? 0 : (yardsPerAtt > 2.375) ? 2.375 : yardsPerAtt;
    tdPct = (tdPct > 2.375) ? 2.375 : tdPct;
    intPct = (intPct < 0) ? 0 : intPct;
    return(((compPct + yardsPerAtt + tdPct + intPct) / 6) * 100); // divide the sum of components by 6 and multiply by 100 per NFL.
}

// calculate age in years given the birth date as a date type
export function GetAge(byear,bmonth,bday) {
    var today = new Date(); // get current date as a date variable
    var birthDate = new Date(parseInt(byear),parseInt(bmonth)-1,parseInt(bday)); // convert parameters to a date variable
    var age = today.getFullYear() - birthDate.getFullYear(); // subtract birth year from current year to get full years
    var m = today.getMonth() - birthDate.getMonth(); // subtract birth month form current month
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) { // if birth month and day are less than current month and day
        age--; //reduce age by 1 year since current birthdate hasn't ocurred yet
    }
    return age;
}

// this function formats number adding the appropriate 'st' ,'nd', 'rd' or 'th' suffix to any number
export function FormatNumSuffixed(num) {
    let suffix=["st","nd","rd","th"]; // suffixes
    let i = 0; // suffix index
    if (num === null) {
        return('--');
    } else {
        if (num === 11 || num === 12 || num === 13) { // exception: these allways end in 'th' rather than 'st', 'dn', 'rd'
            i = 3; // force index to 3 to point to 'th'
        } else {
            i = num%10 // get just the ones place
            i = (i === 0 || i > 4 ? 4 : i) - 1; // if ones place is 0 or > 4, force 4, otherwise use ones place. subtract 1 to point to array.
        }
        return(num + suffix[i]); // return the number with the appropriate suffix
    }
}

export function DispFunc(cell, formatterParams, onRendered, decimals = 0, withComma = false, withPercent = false) {
    let val = cell.getValue();
    // console.log("DispFunc", cell.getField(), cell.getValue());
    if (val === null){
        return "--";
    }
    val = val.toFixed(decimals);
    if (withComma){
        val = val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    }
    if (withPercent){
        val = val + "%";
    }
    return val
}

export function GameResult(teamScore, opponentScore) {
    return  (teamScore > opponentScore ? "W" : "L") + " " + teamScore.toString() + "-" +opponentScore.toString();
}