import React from 'react';
import {NullSum, NullBypassSum, NullSub, NullDiv, Tst2015, DispFunc, GameResult} from '../common/CommonFunctions';
import { cgtGameSummary } from '../common/TableColumnWidths';
import Tabulator from 'tabulator-tables';
import "tabulator-tables/dist/css/tabulator.min.css";

class GameLogOLSummaryTabulator extends React.Component {
    constructor(props) {
        super(props);
        this.blockingstats = this.props.blockingstats;
        this.data = [];
        let gameInfoCols = null;
        this.colNum = 0;
        this.maxPlayerSeason = ''

        // Set game information columns
        gameInfoCols = [
            {
                title: "Date", field: "gameSeasonDateSSSSYYYYMMDD", cssClass: "single-row-header textColumn",
                titleFormatter: "html", headerSort: false,
                formatter: function (cell, formatterParams, onRendered) {
                    return (cell.getValue().substr(9, 1) === 'T' ? 'Total' : parseInt(cell.getValue().substr(9, 2)) + '/' + parseInt(cell.getValue().substr(12, 2)));
                },
                width: `${cgtGameSummary[this.colNum++]}`
            },
            {
                title: "Wk", field: "gameWeek", cssClass: "single-row-header small-column-center",
                titleFormatter: "html", headerSort: false,
                width: `${cgtGameSummary[this.colNum++]}`,
                //              formatter: function (cell, formatterParams, onRendered) {
                //                 return cell = 99 ? '' : cell;
                //              }
            },
            {
                title: "Teams", field: "matchup", cssClass: "single-row-header textColumn",
                titleFormatter: "html", headerSort: false,
                width: `${cgtGameSummary[this.colNum++]}`
            },
            {
                title: "Score", field: "gameResult", cssClass: "single-row-header break textColumn",
                titleFormatter: "html", headerSort: false,
                width: `${cgtGameSummary[this.colNum++]}`
            },
            {
                title: "G", field: "games", cssClass: "single-row-header small-column",
                titleFormatter: "html", headerSort: false,
                width: `${cgtGameSummary[this.colNum++]}`
            },
            {
                title: "GS", field: "gamesStarted", cssClass: "single-row-header break small-column",
                titleFormatter: "html", headerSort: false,
                width: `${cgtGameSummary[this.colNum++]}`
            }
        ];

        // Set Remaining Columns
        this.cols = [
            { 
                title: "",
                columns: gameInfoCols
            },
            { 
                title: "Total Snaps", field: "snaps", cssClass: "single-row-header break", 
                headerSortStartingDir: "desc", headerSort: false,
                titleFormatter:"textarea", 
                width: `${cgtGameSummary[this.colNum++]}`,
                formatter: function(cell, formatterParams, onRendered){
                    return DispFunc(cell, formatterParams, onRendered, 0, true, false);
                }
            },
            {
                title: "Blown Blocks",
                columns:[
                    { 
                        title: "Total", field: "blownBlocks", 
                        headerSortStartingDir: "desc", headerSort: false,
                        width: `${cgtGameSummary[this.colNum++]}`,
                        formatter: function(cell, formatterParams, onRendered){
                            return DispFunc(cell, formatterParams, onRendered, 0, false, false);
                        }
                    },
                    { 
                        title: "Pass", field: "bbPass", 
                        headerSortStartingDir: "desc", headerSort: false,
                        width: `${cgtGameSummary[this.colNum++]}`,
                        formatter: function(cell, formatterParams, onRendered){
                            return DispFunc(cell, formatterParams, onRendered, 0, false, false);
                        }
                    },
                    { 
                        title: "Rush", field: "bbRuns", 
                        headerSortStartingDir: "desc", headerSort: false,
                        width: `${cgtGameSummary[this.colNum++]}`,
                        formatter: function(cell, formatterParams, onRendered){
                            return DispFunc(cell, formatterParams, onRendered, 0, false, false);
                        }
                    },      
                    { 
                        title: "Pct", field: "blownBlockPct", cssClass: "break", 
                        headerSortStartingDir: "desc", headerSort: false,
                        width: `${cgtGameSummary[this.colNum++]}`,
                        formatter: function(cell, formatterParams, onRendered){
                            return DispFunc(cell, formatterParams, onRendered, 1, false, false);
                        }
                    },
                ],
            },
            {
                title: "Penalties",
                columns:[
                    { 
                        title: "Total", field: "penalties", 
                        headerSortStartingDir: "desc", headerSort: false,
                        width: `${cgtGameSummary[this.colNum++]}`,
                        formatter: function(cell, formatterParams, onRendered){
                            return DispFunc(cell, formatterParams, onRendered, 0, false, false);
                        }
                    },
                    { 
                        title: "Holding", field: "totalHolds", 
                        headerSortStartingDir: "desc", headerSort: false,
                        width: `${cgtGameSummary[this.colNum++]}`,
                        formatter: function(cell, formatterParams, onRendered){
                            return DispFunc(cell, formatterParams, onRendered, 0, false, false);
                        }
                    },
                    { 
                        title: "False Starts", field: "falseStarts", 
                        headerSortStartingDir: "desc", headerSort: false,
                        width: `${cgtGameSummary[this.colNum++]}`,
                        formatter: function(cell, formatterParams, onRendered){
                            return DispFunc(cell, formatterParams, onRendered, 0, false, false);
                        }
                    },
                    { 
                        title: "Other", field: "otherPenalties", 
                        headerSortStartingDir: "desc", headerSort: false,
                        width: `${cgtGameSummary[this.colNum++]}`,
                        formatter: function(cell, formatterParams, onRendered){
                            return DispFunc(cell, formatterParams, onRendered, 0, false, false);
                        }
                    },
                    { 
                        title: "Pct", field: "penaltyPct", cssClass: "break", 
                        headerSortStartingDir: "desc", headerSort: false,
                        width: `${cgtGameSummary[this.colNum++]}`,
                        formatter: function(cell, formatterParams, onRendered){
                            return DispFunc(cell, formatterParams, onRendered, 1, false, false);
                        }
                    }
                ],
            },
            { 
                title: "Tot Points Earned", field: "pointsEarned", 
                headerSortStartingDir: "desc", headerSort: false,
                titleFormatter:"textarea",
                width: `${cgtGameSummary[this.colNum++]}`,
                formatter: function(cell, formatterParams, onRendered){
                    return DispFunc(cell, formatterParams, onRendered, 0, false, false);
                }
            }
        ];
        this.el = React.createRef();
        this.tabulator = null;
    }

    // Consolidate the data into one data array to be used for tabulator(called on component mount)
    ConsolidateData(){
        // no blocking data
        if (this.blockingstats.length === 0) {
            return ('');
        }
        // declare and initialize detail data fields
        // these fields will be used to create objects for each game
        let blockingstats = this.blockingstats;
        let sortKey = 0;
        let season = 0;
        let playerName = '';
        let playerId = 0;
        let positionName = '';
        let team = '';
        let matchup = '';
        let oppTeam = '';
        let gameWeek = 0;
        let games = 0;
        let gameDate = '';
        let gameSeasonDateSSSSYYYYMMDD = '';
        let gameResult = '';
        let gamesStarted = 0;
        let snaps = 0;
        let blownBlocks = 0;
        let bbPass = 0;
        let bbRuns = 0;
        let blownBlockPct = 0;
        let penalties = 0;
        let totalHolds = 0;
        let falseStarts = 0;
        let otherPenalties = 0;
        let penaltyPct = 0;
        let pointsEarned = 0;
        let data = [];
        for (var i=0; i<blockingstats.length; i++) {
            sortKey = (9999 - blockingstats[i].season) * 1000000 + blockingstats[i].season * 100 + blockingstats[i].week; //XXXXYYYYWW
            season = blockingstats[i].season;
            playerName = blockingstats[i].playerName;
            playerId = blockingstats[i].playerId;
            positionName = blockingstats[i].positionName;
            team = blockingstats[i].teamName;
            matchup = blockingstats[i].teamName.trim() + ' ' + blockingstats[i].oppTeamName.trim();
            games = 1;
            gameDate = blockingstats[i].gameDate;
            gameSeasonDateSSSSYYYYMMDD = blockingstats[i].season.toString() + blockingstats[i].gameDate.substring(0, 10);
            gameResult = GameResult(blockingstats[i].teamScore, blockingstats[i].oppTeamScore);
            gameWeek = blockingstats[i].week;
            gamesStarted = blockingstats[i].starts;
            oppTeam = blockingstats[i].oppTeamName;
            snaps = NullSum(blockingstats[i].passSnap,blockingstats[i].rushSnap);
            blownBlocks = NullSum(blockingstats[i].bBpass,blockingstats[i].bBruns);
            bbPass = blockingstats[i].bBpass;
            bbRuns = blockingstats[i].bBruns;
            blownBlockPct = NullDiv(blownBlocks,snaps)*100;
            penalties = blockingstats[i].penalties;
            totalHolds = blockingstats[i].passHolds + blockingstats[i].rushHolds;
            falseStarts = blockingstats[i].falseStarts;
            otherPenalties = NullSub(blockingstats[i].penalties,NullSum(blockingstats[i].passHolds,NullSum(blockingstats[i].rushHolds,blockingstats[i].falseStarts)));
            penaltyPct = NullDiv(blockingstats[i].penalties,snaps)*100;
            pointsEarned = Tst2015(blockingstats[i].season,blockingstats[i].totalEPS);
            data.push(
                {
                    sortKey,
                    season,
                    playerName,
                    playerId,
                    positionName,
                    team,
                    matchup,
                    games,
                    gameDate,
                    gameSeasonDateSSSSYYYYMMDD,
                    gameResult,
                    gameWeek,
                    gamesStarted,
                    oppTeam,
                    snaps,
                    blownBlocks,
                    bbPass,
                    bbRuns,
                    blownBlockPct,
                    penalties,
                    totalHolds,
                    falseStarts,
                    otherPenalties,
                    penaltyPct,
                    pointsEarned
                }
            );
        }
        if (data.length !== 0) {
            data.sort((a, b) => a.sortKey > b.sortKey ? 1 : -1); // ensure rows are in season and gamedate order
            this.SumData(data);
        }
        this.data = data;
    }

    // Add totals row to data.(called on component mount if this is the players page.)
    SumData(d) {
        let sortKey = 0;
        let season = d[0].season;
        let playerName = '';
        let playerId = '';
        let positionName = '';
        let team = '';
        let matchup = '';
        let games = 0;
        let gameDate = '';
        let gameSeasonDateSSSSYYYYMMDD = '';
        let gameResult = '';
        let gameWeek = '';
        let gamesStarted = 0;
        let oppTeam = ''
        let snaps = 0;
        let blownBlocks = 0;
        let bbPass = 0;
        let bbRuns = 0;
        let blownBlockPct = 0;
        let penalties = 0;
        let totalHolds = 0;
        let falseStarts = 0;
        let otherPenalties = 0;
        let penaltyPct = 0;
        let pointsEarned = 0;
        let rowcount = d.length;
        for (var i = 0; i < rowcount; i++) {
            if (season !== d[i].season) {
                sortKey = (9999 - season) * 1000000 + season * 100 + 99; //XXXXYYYY99
                gameSeasonDateSSSSYYYYMMDD = season.toString() + season.toString() + " Total";
                blownBlocks = NullSum(bbPass, bbRuns);
                otherPenalties = NullSub(penalties, NullSum(totalHolds, falseStarts));
                if (snaps) {
                    blownBlockPct = NullDiv(blownBlocks, snaps) * 100;
                    penaltyPct = NullDiv(penalties, snaps) * 100;
                };
                d.push(
                    {
                        sortKey,
                        season,
                        playerName,
                        playerId,
                        positionName,
                        team,
                        matchup,
                        games,
                        gameDate,
                        gameSeasonDateSSSSYYYYMMDD,
                        gameResult,
                        gameWeek,
                        gamesStarted,
                        oppTeam,
                        snaps,
                        blownBlocks,
                        bbPass,
                        bbRuns,
                        blownBlockPct,
                        penalties,
                        totalHolds,
                        falseStarts,
                        otherPenalties,
                        penaltyPct,
                        pointsEarned
                    }
                );
                season = d[i].season;
                playerName = '';
                playerId = '';
                positionName = '';
                team = '';
                matchup = '';
                games = 0;
                gameDate = '';
                gameSeasonDateSSSSYYYYMMDD = '';
                gameResult = '';
                gameWeek = '';
                gamesStarted = 0;
                oppTeam = ''
                snaps = 0;
                blownBlocks = 0;
                bbPass = 0;
                bbRuns = 0;
                blownBlockPct = 0;
                penalties = 0;
                totalHolds = 0;
                falseStarts = 0;
                otherPenalties = 0;
                penaltyPct = 0;
                pointsEarned = 0;
            }
            games = NullSum(games,d[i].games);
            gamesStarted = NullSum(gamesStarted, d[i].gamesStarted);
            snaps = NullSum(snaps,d[i].snaps);
            bbPass = NullSum(bbPass,d[i].bbPass);
            bbRuns = NullSum(bbRuns,d[i].bbRuns);
            penalties = NullSum(penalties,d[i].penalties);
            totalHolds = NullSum(totalHolds,d[i].totalHolds);
            falseStarts = NullSum(falseStarts,d[i].falseStarts);
            pointsEarned = NullBypassSum(pointsEarned, Tst2015(d[i].season, d[i].pointsEarned));
        };
        sortKey = (9999 - season) * 1000000 + season * 100 + 99; //XXXXYYYY99
        gameSeasonDateSSSSYYYYMMDD = season.toString() + season.toString() + " Total";
        blownBlocks = NullSum(bbPass,bbRuns);
        otherPenalties = NullSub(penalties,NullSum(totalHolds,falseStarts));
        if (snaps) {
            blownBlockPct = NullDiv(blownBlocks,snaps)*100;
            penaltyPct = NullDiv(penalties,snaps)*100;
        };
        d.push(
            {
                sortKey,
                season,
                playerName,
                playerId,
                positionName,
                team,
                matchup,
                games,
                gameDate,
                gameSeasonDateSSSSYYYYMMDD,
                gameResult,
                gameWeek,
                gamesStarted,
                oppTeam,
                snaps,
                blownBlocks,
                bbPass,
                bbRuns,
                blownBlockPct,
                penalties,
                totalHolds,
                falseStarts,
                otherPenalties,
                penaltyPct,
                pointsEarned
            }
        );
        d.sort((a, b) => a.sortKey > b.sortKey ? 1 : -1); // places season totals after corresponding season's games
    }
    
    componentDidMount() {
        this.ConsolidateData();
        let pagination = 'local';
        let paginationSize = 50;
        let maxPlayerSeason = this.data.length ? this.data[0].season : 0;
        pagination = null;
        paginationSize = 0;
        this.tabulator = new Tabulator(this.el, {
            data: this.data,
            reactiveData: true,
            columns: this.cols,
            layout: "fitColumns",  // added by Ruben 7/25/19
            rowFormatter:function(row){
                var data = row.getData();
                if (data.gameSeasonDateSSSSYYYYMMDD.substr(9, 5) === 'Total') {
                    row.getElement().style.borderTop = "2px solid #6f88a8";
                    row.getElement().style.borderBottom = "2px solid #6f88a8";
                }
            },
            pagination: pagination,
            paginationSize: paginationSize,
            groupBy: function (row) {
                return row.gameSeasonDateSSSSYYYYMMDD.substr(0, 4);
            },
            groupHeader: function (groupKey, rowcount, data, group) {
                return groupKey + ' Season <span style="color:SlateBlue">&#9660</span>';
            },
            groupStartOpen: function (groupKey, rowcount, data, group) {
                return groupKey === maxPlayerSeason.toString();
            },
            groupToggleElement: "header",
            resizableColumns: false,
            selectable: false
        });
    }

    componentDidUpdate() {
        this.tabulator.replaceData(this.data);
    }

    render() {
        return (
            <React.Fragment>
                <div className="shadowdiv">
                    <div id="table-title">Summary</div>
                    <div ref={el => (this.el = el)} />
                </div>
            </React.Fragment>
        );
    }
}

export default GameLogOLSummaryTabulator;