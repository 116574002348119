import React from 'react';
import {NullSum, NullBypassSum, NullDiv, Tst2015, DispFunc} from '../common/CommonFunctions';
import { qbAdvPassing } from '../common/TableColumnWidths';
import Tabulator from 'tabulator-tables';
import "tabulator-tables/dist/css/tabulator.min.css";
import Filters from '../common/Filters';

class TableQBAdvancedPassingTabulator extends React.Component {
    constructor(props) {
        super(props);
        this.passingstats = this.props.passingstats;
        this.isLeaderboards = this.props.isLeaderboards;
        this.minFilter = 1;
        this.data = [];
        this.initSort = null;
        this.colNum = this.isLeaderboards ? 0 : 1;

        this.onSubmit = this.onSubmit.bind(this);

        // Custom sort function for players page so Total stays at the bottom of the table.
        let sortFunc = (a, b, aRow, bRow, column, dir, sorterParams) => {
            var aData = aRow.getData();
            var bData = bRow.getData();
            if (aData.season === 'Total'){
                if (dir === 'desc'){
                    return -1;
                }
                else if (dir === 'asc'){
                    return 1
                }
            }
            else if (bData.season === 'Total'){
                if (dir === 'desc'){
                    return 1;
                }
                else if (dir === 'asc'){
                    return -1;
                }            
            }
            else {
                return a-b;
            }
        };

        // Set first group of columns
        // If this is a leaderboards page include player name, remove custom sorter and set initial sort to proper column.
        if (this.isLeaderboards){
            this.cols = [
                { 
                    title: "Player", field: "playerName", cssClass: "single-row-header textColumn", 
                    width: `${qbAdvPassing[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                    formatter: "link", formatterParams:{
                        labelField: "playerName", 
                        urlPrefix:"/players/", 
                        urlField: "playerId"
                    } 
                },
                //{ title: "Pos", field: "positionName", cssClass: "single-row-header textColumn", },
                { 
                    title: "Year", field: "season", cssClass: "single-row-header textColumn",
                    width: `${qbAdvPassing[this.colNum++][this.isLeaderboards ? 0 : 1]}`
                },
                { 
                    title: "Team", field: "team", cssClass: "single-row-header textColumn break", 
                    width: `${qbAdvPassing[this.colNum++][this.isLeaderboards ? 0 : 1]}`
                },
                { 
                    title: "Drop-<br/>backs", field: "passDropbacks", cssClass: "single-row-header", 
                    headerSortStartingDir:"desc",
                    width: `${qbAdvPassing[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                    formatter: function(cell, formatterParams, onRendered){
                        return DispFunc(cell, formatterParams, onRendered, 0, false, false);
                    }
                },
                { 
                    title: "Sacks", field: "passSacks", cssClass: "single-row-header", 
                    headerSortStartingDir:"desc",
                    width: `${qbAdvPassing[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                    formatter: function(cell, formatterParams, onRendered){
                        return DispFunc(cell, formatterParams, onRendered, 0, false, false);
                    }
                },
                { 
                    title: "Sack<br/>Yards", field: "passSackYards", cssClass: "single-row-header", 
                    headerSortStartingDir:"desc",
                    width: `${qbAdvPassing[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                    formatter: function(cell, formatterParams, onRendered){
                        return DispFunc(cell, formatterParams, onRendered, 0, true, false);
                    }
                },
                { 
                    title: "Sack %", field: "passSackPct", cssClass: "single-row-header break", 
                    headerSortStartingDir:"desc", 
                    width: `${qbAdvPassing[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                    formatter: function(cell, formatterParams, onRendered){
                        return DispFunc(cell, formatterParams, onRendered, 1, false, false);
                    } 
                },
                { 
                    title: "TD %", field: "passTdRate", cssClass: "single-row-header", 
                    headerSortStartingDir:"desc", 
                    width: `${qbAdvPassing[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                    formatter: function(cell, formatterParams, onRendered){
                        return DispFunc(cell, formatterParams, onRendered, 1, false, false);
                    }
                },
                { 
                    title: "Int %", field: "passIntRate", cssClass: "single-row-header break", 
                    headerSortStartingDir:"desc", 
                    width: `${qbAdvPassing[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                    formatter: function(cell, formatterParams, onRendered){
                        return DispFunc(cell, formatterParams, onRendered, 1, false, false);
                    }
                },
                { 
                    title: "On<br/>Target", field: "passOnTargetThrows", cssClass: "single-row-header", 
                    headerSortStartingDir:"desc", 
                    width: `${qbAdvPassing[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                    formatter: function(cell, formatterParams, onRendered){
                        return DispFunc(cell, formatterParams, onRendered, 0, true, false);
                    }
                },
                { 
                    title: "Catchable", field: "passCatchableThrows", cssClass: "single-row-header", 
                    headerSortStartingDir:"desc", 
                    width: `${qbAdvPassing[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                    formatter: function(cell, formatterParams, onRendered){
                        return DispFunc(cell, formatterParams, onRendered, 0, true, false);
                    }
                },
                { 
                    title: "On<br/>Target%", field: "passOnTargetPct", cssClass: "single-row-header", 
                    headerSortStartingDir:"desc", 
                    width: `${qbAdvPassing[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                    formatter: function(cell, formatterParams, onRendered){
                        return DispFunc(cell, formatterParams, onRendered, 1, false, false);
                    } 
                },
                { 
                    title: "Catchable %", field: "passCatchablePct", cssClass: "single-row-header break", 
                    headerSortStartingDir:"desc", 
                    width: `${qbAdvPassing[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                    formatter: function(cell, formatterParams, onRendered){
                        return DispFunc(cell, formatterParams, onRendered, 1, false, false);
                    }
                },
                { 
                    title: "Completed<br/>Air-Yards", field: "passComplAirYards", cssClass: "single-row-header", 
                    headerSortStartingDir:"desc", 
                    width: `${qbAdvPassing[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                    formatter: function(cell, formatterParams, onRendered){
                        return DispFunc(cell, formatterParams, onRendered, 0, true, false);
                    }
                },
                { 
                    title: "Yards After<br/>Catch", field: "passYdsAfterCatch", cssClass: "single-row-header", 
                    headerSortStartingDir:"desc", 
                    width: `${qbAdvPassing[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                    formatter: function(cell, formatterParams, onRendered){
                        return DispFunc(cell, formatterParams, onRendered, 0, true, false);
                    }
                },
                { 
                    title: "Air-<br/>Yards%", field: "passAirYardsPct", cssClass: "single-row-header", 
                    headerSortStartingDir:"desc", 
                    width: `${qbAdvPassing[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                    formatter: function(cell, formatterParams, onRendered){
                        return DispFunc(cell, formatterParams, onRendered, 1, false, false);
                    }
                },
                {
                    title: "Intended<br/>Air-Yards", field: "passIntendedAirYards", cssClass: "single-row-header",
                    headerSortStartingDir: "desc",
                    width: `${qbAdvPassing[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                    formatter: function (cell, formatterParams, onRendered) {
                        return DispFunc(cell, formatterParams, onRendered, 0, true, false);
                    }
                },
                { 
                    title: "Average<br/>Throw Depth", field: "passAvgThrowDepth", cssClass: "single-row-header break", 
                    headerSortStartingDir:"desc", 
                    width: `${qbAdvPassing[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                    formatter: function(cell, formatterParams, onRendered){
                        return DispFunc(cell, formatterParams, onRendered, 1, false, false);
                    }
                },
                { 
                    title: "Pass Points Earned", field: "passPointsEarned", cssClass: "single-row-header", 
                    headerSortStartingDir:"desc",
                    titleFormatter:"textarea",
                    width: `${qbAdvPassing[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                    formatter: function(cell, formatterParams, onRendered){
                        return DispFunc(cell, formatterParams, onRendered, 0, false, false);
                    }
                }
            ];
            this.sorterFunc = null;
            this.initSort = [
                { column: "passPointsEarned", dir: "desc" }
            ];
        }
        // Else don't include player name and set initial sort to season.
        else{
            this.cols = [
                { 
                    title: "Year", field: "season", cssClass: "single-row-header textColumn", 
                    width: `${qbAdvPassing[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                    headerSort: false, sorter: sortFunc 
                },
                { 
                    title: "Team", field: "team", cssClass: "single-row-header textColumn break",
                    width: `${qbAdvPassing[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                    headerSort: false, sorter: sortFunc
                },
                { 
                    title: "Drop-<br/>backs", field: "passDropbacks", cssClass: "single-row-header", 
                    headerSortStartingDir:"desc",
                    width: `${qbAdvPassing[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                    formatter: function(cell, formatterParams, onRendered){
                        return DispFunc(cell, formatterParams, onRendered, 0, false, false);
                    }
                },
                { 
                    title: "Sacks", field: "passSacks", cssClass: "single-row-header", 
                    headerSortStartingDir:"desc", headerSort: false, sorter: sortFunc,
                    width: `${qbAdvPassing[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                    formatter: function(cell, formatterParams, onRendered){
                        return DispFunc(cell, formatterParams, onRendered, 0, false, false);
                    }
                },
                { 
                    title: "Sack<br/>Yards", field: "passSackYards", cssClass: "single-row-header", 
                    headerSortStartingDir:"desc", headerSort: false, sorter: sortFunc, 
                    width: `${qbAdvPassing[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                    formatter: function(cell, formatterParams, onRendered){
                        return DispFunc(cell, formatterParams, onRendered, 0, true, false);
                    }
                },
                { 
                    title: "Sack%", field: "passSackPct", cssClass: "single-row-header break", 
                    headerSortStartingDir:"desc", headerSort: false, sorter: sortFunc, 
                    width: `${qbAdvPassing[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                    formatter: function(cell, formatterParams, onRendered){
                        return DispFunc(cell, formatterParams, onRendered, 1, false, false);
                    }
                },
                { 
                    title: "TD%", field: "passTdRate", cssClass: "single-row-header", 
                    headerSortStartingDir:"desc", headerSort: false, sorter: sortFunc, 
                    width: `${qbAdvPassing[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                    formatter: function(cell, formatterParams, onRendered){
                        return DispFunc(cell, formatterParams, onRendered, 1, false, false);
                    }
                },
                { 
                    title: "Int%", field: "passIntRate", cssClass: "single-row-header break", 
                    headerSortStartingDir:"desc", headerSort: false, sorter: sortFunc, 
                    width: `${qbAdvPassing[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                    formatter: function(cell, formatterParams, onRendered){
                        return DispFunc(cell, formatterParams, onRendered, 1, false, false);
                    }
                },
                { 
                    title: "On Target", field: "passOnTargetThrows", cssClass: "single-row-header", 
                    headerSortStartingDir:"desc", headerSort: false, sorter: sortFunc, 
                    width: `${qbAdvPassing[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                    formatter: function(cell, formatterParams, onRendered){
                        return DispFunc(cell, formatterParams, onRendered, 0, true, false);
                    }
                },
                { 
                    title: "Catchable", field: "passCatchableThrows", cssClass: "single-row-header", 
                    headerSortStartingDir:"desc", headerSort: false, sorter: sortFunc, 
                    width: `${qbAdvPassing[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                    formatter: function(cell, formatterParams, onRendered){
                        return DispFunc(cell, formatterParams, onRendered, 0, true, false);
                    }
                },
                { 
                    title: "On<br/>Target%", field: "passOnTargetPct", cssClass: "single-row-header", 
                    headerSortStartingDir:"desc", headerSort: false, sorter: sortFunc,
                    width: `${qbAdvPassing[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                    formatter: function(cell, formatterParams, onRendered){
                        return DispFunc(cell, formatterParams, onRendered, 1, false, false);
                    }
                },
                { 
                    title: "Catchable%", field: "passCatchablePct", cssClass: "single-row-header break", 
                    headerSortStartingDir:"desc", headerSort: false, sorter: sortFunc, 
                    width: `${qbAdvPassing[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                    formatter: function(cell, formatterParams, onRendered){
                        return DispFunc(cell, formatterParams, onRendered, 1, false, false);
                    }
                },
                { 
                    title: "Completed<br/>Air-Yards", field: "passComplAirYards", cssClass: "single-row-header", 
                    headerSortStartingDir:"desc", headerSort: false, sorter: sortFunc, 
                    width: `${qbAdvPassing[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                    formatter: function(cell, formatterParams, onRendered){
                        return DispFunc(cell, formatterParams, onRendered, 0, true, false);
                    }
                },
                { 
                    title: "Yards<br/>After Catch", field: "passYdsAfterCatch", cssClass: "single-row-header", 
                    headerSortStartingDir:"desc", headerSort: false, sorter: sortFunc, 
                    width: `${qbAdvPassing[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                    formatter: function(cell, formatterParams, onRendered){
                        return DispFunc(cell, formatterParams, onRendered, 0, true, false);
                    }
                },
                { 
                    title: "Air-<br/>Yards%", field: "passAirYardsPct", cssClass: "single-row-header", 
                    headerSortStartingDir:"desc", headerSort: false, sorter: sortFunc, 
                    width: `${qbAdvPassing[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                    formatter: function(cell, formatterParams, onRendered){
                        return DispFunc(cell, formatterParams, onRendered, 1, false, false);
                    }
                },
                {
                    title: "Intended<br/>Air-Yards", field: "passIntendedAirYards", cssClass: "single-row-header",
                    headerSortStartingDir: "desc", headerSort: false, sorter: sortFunc,
                    width: `${qbAdvPassing[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                    formatter: function (cell, formatterParams, onRendered) {
                        return DispFunc(cell, formatterParams, onRendered, 0, true, false);
                    }
                },
                { 
                    title: "Average<br/>Throw Depth", field: "passAvgThrowDepth", cssClass: "single-row-header break", 
                    headerSortStartingDir:"desc", headerSort: false, sorter: sortFunc, 
                    width: `${qbAdvPassing[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                    formatter: function(cell, formatterParams, onRendered){
                        return DispFunc(cell, formatterParams, onRendered, 1, false, false);
                    }
                },
                { 
                    title: "Pass Points Earned", field: "passPointsEarned", 
                    headerSortStartingDir:"desc", headerSort: false, sorter: sortFunc, 
                    titleFormatter:"textarea",
                    width: `${qbAdvPassing[this.colNum++][this.isLeaderboards ? 0 : 1]}`,
                    formatter: function(cell, formatterParams, onRendered){
                        return DispFunc(cell, formatterParams, onRendered, 0, false, false);
                    }
                }
            ];
            this.initSort = [
                { column: "season", dir: "asc" }
            ];
        }      
        
        this.el = React.createRef();
        this.tabulator = null;

    }

    // Consolidate the data into one data array to be used for tabulator(called on component mount)
    ConsolidateData(){
        let rowData = [];
        let season = 0;
        let playerName = '';
        let playerId = 0;
        let positionName = '';
        let team = '';
        let passAttempts = 0;
        let passTdRate = 0;
        let passIntRate = 0;
        let passOnTargetThrows = 0;
        let passOnTargetPct = null;
        let passCatchableThrows = 0;
        let passCatchablePct = 0;
        let passAirYardsPct = 0;
        let passComplAirYards = 0;
        let passIntendedAirYards = 0;
        let passAvgThrowDepth = 0;
        let passYdsAfterCatch = 0;
        let passDropbacks = 0;
        let passSacks = 0;
        let passSackYards = 0;
        let passSackPct = 0;
        let passScrambles = 0; // only used in calculations at the total level
        let passPointsEarned = 0;
        if (this.passingstats.length !== 0){
            for (var i=0; i<this.passingstats.length; i++) {
                if (!this.isLeaderboards || this.passingstats[i].attempts >= this.minFilter){
                    playerName = this.passingstats[i].playerName;
                    playerId = this.passingstats[i].playerId;
                    positionName = this.passingstats[i].positionName;
                    season = this.passingstats[i].season;
                    team = this.passingstats[i].teamName;
                    passAttempts = this.passingstats[i].attempts;
                    passSacks = this.passingstats[i].sacks;
                    passSackYards = this.passingstats[i].sackYards*-1;
                    passDropbacks = this.passingstats[i].attempts + 
                        this.passingstats[i].sacks +
                        this.passingstats[i].scrambles
                    passSackPct = (this.passingstats[i].sacks / passDropbacks) * 100;
                    passScrambles = this.passingstats[i].scrambles;
                    passTdRate = this.passingstats[i].tdRate;
                    passIntRate = this.passingstats[i].intRate;
                    passOnTargetThrows = this.passingstats[i].onTargetThrows;
                    passCatchableThrows = this.passingstats[i].catchableThrows;
                    if(this.passingstats[i].onTargetPct){
                        passOnTargetPct = this.passingstats[i].onTargetPct*100;
                    }
                    passCatchablePct = this.passingstats[i].catchablePct*100;
                    passComplAirYards = this.passingstats[i].completedAirYards;
                    passYdsAfterCatch = this.passingstats[i].yards - this.passingstats[i].completedAirYards;
                    passAirYardsPct = this.passingstats[i].airYardPct*100;
                    passIntendedAirYards = this.passingstats[i].intendedAirYards;
                    passAvgThrowDepth = this.passingstats[i].intendedAirYards/this.passingstats[i].attempts;
                    passPointsEarned = Tst2015(this.passingstats[i].season,this.passingstats[i].eps);
                    rowData.push(
                        {
                            season,
                            playerName,
                            playerId,
                            positionName,
                            team,
                            passAttempts,
                            passDropbacks,
                            passSacks,
                            passSackYards,
                            passSackPct,
                            passScrambles,
                            passTdRate,
                            passIntRate,
                            passOnTargetThrows,
                            passCatchableThrows,
                            passOnTargetPct,
                            passCatchablePct,
                            passComplAirYards,
                            passYdsAfterCatch,
                            passAirYardsPct,
                            passIntendedAirYards,
                            passAvgThrowDepth,
                            passPointsEarned
                        }
                    );
                }
            }
            this.data = rowData;
        }
    }

    // Add totals row to data.(called on component mount if this is the players page.)
    SumData() {
        let season = 'Total';
        let playerName = '';
        let playerId = 0;
        let positionName = '';
        let team = '';
        let passAttempts = 0;
        let passAttemptsPartial = 0;
        let passYards = 0;
        let passCompletions = 0;
        let passTD = 0;
        let passInt = 0;
        let passTdRate = 0;
        let passIntRate = 0;
        let passOnTargetThrows = 0;
        let passOnTargetPct = null;
        let passCatchableThrows = 0;
        let passCatchablePct = 0;
        let passAirYardsPct = 0;
        let passComplAirYards = 0;
        let passIntendedAirYards = 0;
        let passAvgThrowDepth = 0;
        let passYdsAfterCatch = 0;
        let passDropbacks = 0;
        let passSacks = 0;
        let passSackYards = 0;
        let passSackPct = 0;
        let passScrambles = 0; // used in calculating sack %
        let passPointsEarned = 0;
        if (this.passingstats.length !== 0){
            for (var i = 0; i < this.passingstats.length; i++) {
                passAttempts = NullBypassSum(passAttempts,this.passingstats[i].attempts);
                passAttemptsPartial = NullBypassSum(passAttemptsPartial,this.passingstats[i].season >= 2018 ? this.passingstats[i].attempts : 0);
                passCatchableThrows = NullBypassSum(passCatchableThrows,this.passingstats[i].catchableThrows);
                passYards = NullBypassSum(passYards,this.passingstats[i].yards);
                passTD = NullBypassSum(passTD,this.passingstats[i].td);
                passInt = NullBypassSum(passInt, this.passingstats[i].int);
                passOnTargetThrows = NullBypassSum(passOnTargetThrows, this.passingstats[i].season >= 2018 ? this.passingstats[i].onTargetThrows : null);
                passComplAirYards = NullBypassSum(passComplAirYards, this.passingstats[i].completedAirYards);
                passIntendedAirYards = NullBypassSum(passIntendedAirYards, this.passingstats[i].intendedAirYards);
                passCompletions = NullBypassSum(passCompletions,this.passingstats[i].comp);
                passDropbacks = NullBypassSum(passDropbacks, NullSum(passAttempts, NullSum(passSacks, passScrambles)));
                passSacks = NullBypassSum(passSacks,this.passingstats[i].sacks);
                passSackYards = NullBypassSum(passSackYards, this.passingstats[i].sackYards);
                passScrambles = NullBypassSum(passScrambles, this.passingstats[i].scrambles);
                passPointsEarned = NullBypassSum(passPointsEarned,Tst2015(this.passingstats[i].season,this.passingstats[i].eps));
            }
            passSackYards = passSackYards * -1;
            passYdsAfterCatch = passYards - passComplAirYards;
            if (passAttempts > 0) {
                passTdRate = NullDiv(passTD,passAttempts)*100;
                passIntRate = NullDiv(passInt,passAttempts)*100;
                passCatchablePct = NullDiv(passCatchableThrows,passAttempts)*100;
            };
            passOnTargetPct = passAttemptsPartial ? NullDiv(passOnTargetThrows,passAttemptsPartial)*100 : 0;
            passAirYardsPct = NullDiv(passComplAirYards,passYards)*100;
            passAvgThrowDepth = NullDiv(passIntendedAirYards,passAttempts);
            passSackPct = NullDiv(passSacks, passDropbacks)*100;
            this.data.push(
                {
                    season,
                    playerName,
                    playerId,
                    positionName,
                    team,
                    passAttempts,
                    passDropbacks,
                    passSacks,
                    passSackYards,
                    passSackPct,
                    passScrambles,
                    passTdRate,
                    passIntRate,
                    passOnTargetThrows,
                    passCatchableThrows,
                    passOnTargetPct,
                    passCatchablePct,
                    passComplAirYards,
                    passYdsAfterCatch,
                    passAirYardsPct,
                    passIntendedAirYards,
                    passAvgThrowDepth,
                    passPointsEarned
                }
            );
        }
    }
    
    componentDidMount() {
        this.ConsolidateData();
        let pagination = 'local';
        let paginationSize = 50;
        if (!this.isLeaderboards){
            this.SumData();
            pagination = null;
            paginationSize = 0;
        }
        this.tabulator = new Tabulator(this.el, {
            data: this.data,
            reactiveData: true,
            columns: this.cols,
            layout: "fitColumns",  // added by Ruben 7/25/19
            rowFormatter:function(row){
                var data = row.getData();
                if (data.season === 'Total'){
                    row.getElement().style.borderTop = "2px solid #6f88a8";
                    row.getElement().style.borderBottom = "2px solid #6f88a8";
                }
            },
            pagination: pagination,
            paginationSize: paginationSize,
            initialSort: this.initSort,
            resizableColumns: false,
            selectable: false
        });
    }

    componentDidUpdate() {
        this.tabulator.replaceData(this.data);
    }

    onSubmit(e, minFilter){
        this.minFilter = minFilter;
        this.ConsolidateData();
        this.forceUpdate();
    }

    render() {
        return (
            <React.Fragment>
                {
                    this.isLeaderboards &&
                    <div className="shadowdiv" id="filtersDiv">
                        <Filters minFilter={this.minFilter} onSubmit={this.onSubmit} label={"Pass Attempts"} />
                        <div ref={el => (this.el = el)} />
                    </div>
                }
                {
                    !this.isLeaderboards &&
                    <div className="shadowdiv">
                        <div id="table-title">Advanced Passing</div>
                        <div ref={el => (this.el = el)} />
                    </div>
                }
            </React.Fragment>
        );
    }
}

export default TableQBAdvancedPassingTabulator;